// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country__lOp1d {
  display: flex;
  margin-bottom: 0.4rem;
  font-size: 14px;
  line-height: 2.4rem;
  align-items: center;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country__lOp1d .ContactInfo_name__QJu9t {
  display: flex;
  align-items: center;
  max-width: 300px;
  overflow-wrap: anywhere;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country__lOp1d .ContactInfo_dot__\\+qFQ5 {
  border-radius: 50%;
  background: rgba(20, 20, 20, 0.5058823529);
  margin: 0.8rem;
  height: 0.4rem;
  width: 0.4rem;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country__lOp1d .ContactInfo_check__ZpahC {
  height: 5px;
  width: 5px;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country__lOp1d .ContactInfo_country__J3a0x {
  display: flex;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country_receivable_report__MXnjd {
  display: flex;
  margin-bottom: 0.4rem;
  font-size: 14px;
  line-height: 2.4rem;
  align-items: flex-start;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_name_country_receivable_report__MXnjd .ContactInfo_dot__\\+qFQ5 {
  border-radius: 50%;
  background: rgba(20, 20, 20, 0.5058823529);
  margin: 0.8rem;
  height: 0.4rem;
  width: 0.4rem;
}
.ContactInfo_contact_info__Qg3xs .ContactInfo_email__q8ajP {
  font-size: 14px;
  color: rgba(30, 51, 63, 0.5);
  line-height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Layout/ContactInfo.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AAAR;AAEQ;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAZ;AAGQ;EACI,kBAAA;EACA,0CAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;AADZ;AAIQ;EACI,WAAA;EACA,UAAA;AAFZ;AAKQ;EACI,aAAA;AAHZ;AAMI;EACI,aAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;AAJR;AAMQ;EACI,kBAAA;EACA,0CAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;AAJZ;AAQI;EACI,eAAA;EACA,4BAAA;EACA,iBAAA;AANR","sourcesContent":[".contact_info{\n    .name_country{\n        display: flex;\n        margin-bottom: 0.4rem;\n        font-size: 14px;\n        line-height: 2.4rem;\n        align-items: center;\n\n        .name{\n            display: flex;\n            align-items: center;\n            max-width: 300px;\n            overflow-wrap: anywhere;\n        }\n\n        .dot{\n            border-radius: 50%;\n            background: #14141481;\n            margin: 0.8rem;\n            height: 0.4rem;\n            width: 0.4rem;\n        }\n\n        .check{\n            height: 5px;\n            width: 5px;\n        }\n\n        .country{\n            display: flex;\n        }\n    }\n    .name_country_receivable_report{\n        display: flex;\n        margin-bottom: 0.4rem;\n        font-size: 14px;\n        line-height: 2.4rem;\n        align-items: flex-start;\n\n        .dot{\n            border-radius: 50%;\n            background: #14141481;\n            margin: 0.8rem;\n            height: 0.4rem;\n            width: 0.4rem;\n        }\n    }\n\n    .email{\n        font-size: 14px;\n        color: rgba(30, 51, 63, 0.5);\n        line-height: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact_info": `ContactInfo_contact_info__Qg3xs`,
	"name_country": `ContactInfo_name_country__lOp1d`,
	"name": `ContactInfo_name__QJu9t`,
	"dot": `ContactInfo_dot__+qFQ5`,
	"check": `ContactInfo_check__ZpahC`,
	"country": `ContactInfo_country__J3a0x`,
	"name_country_receivable_report": `ContactInfo_name_country_receivable_report__MXnjd`,
	"email": `ContactInfo_email__q8ajP`
};
export default ___CSS_LOADER_EXPORT___;
