import classes from './InvoiceDetailsModalHeader.module.scss'
import Button from '../../../Layout/Button'
import {Breadcrumb, Spin, Tooltip} from 'antd'
import sideBarIcon from '../../../assets/Images/SideBarIcon.svg'
import { useDispatch, useSelector} from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'
import { GlobalActions } from '../../../Store/global-redux'
// import ReactDOM from 'react-dom'
// import EditInvoice from "Components/Invoice/EditInvoice";
import clsx from 'clsx'
import useClickOutside from '../../../hooks/useClickOutside'
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import UseApiCall from '../../../hooks/useApiCall'
import {apiGetInvoiceDetails, apiSignOut, apiVerifyInvoice} from '../../../services'
// import PaymentStatusTag from 'Components/ReportTable/PaymentStatusTag'
import StatusTag from './StatusTag'
import Action from '../../../Components/ReportTable/Action'
import markDone from '../../../assets/Icons/tickinsidecircleblack.svg'
import editIcon from '../../../assets/Images/edit.svg'
import quoteIcon from '../../../assets/Icons/message-square.svg'
import {
  // showInvoicePaidOutsideModal,
} from '../../../utils/layout'
import { modalRefState } from '../../../recoil/layoutRef'
import { useRecoilValue } from 'recoil'
import {LoadingOutlined} from "@ant-design/icons";
import {CustomersActions, ReceivablesActions} from "../../../Store/customer-redux";
import {enqueueSnackbar, useSnackbar} from "notistack";

const InvoiceDetailsModalHeader = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay);
  const updateOnVerify = useSelector((state) => state.receivable.updateOnVerify);
  const [verifyInv, setVerifyInv] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const { invoice,organizationId } = props;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const modalRef = useRecoilValue(modalRefState)
  const [showOptions, setShowOptions] = useState(false)
  const optRef = useClickOutside(() => setShowOptions(false))
  const [logOut] = UseApiCall(apiSignOut, () => {
    dispatch({type: 'CLEAR_STATE'})
    navigate('/login?mode=email')
  })
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)
  const options = [
    {
      title: 'Log Out',
      onClick: () => {
        logOut()
      },
    },
  ]

  const optionArr = [
    {
      text: 'Edit',
      icon: editIcon,
      // disabled: false,
      onClick: (cellData) => {
        // if (cellData?.status.toLowerCase() === 'in draft' || cellData?.status.toLowerCase() ===  'in review' ||  cellData?.status.toLowerCase() === 'created'
        // ||  cellData?.status.toLowerCase() === 'in progress' ||  cellData?.status.toLowerCase() === 'link sent' ||  cellData?.status.toLowerCase() === 'settled') 
        {
          dispatch(modalActions.editInvoice())
          setSelectedInvoiceId(cellData?.id)
        }
      },
      // onClick: (cellData) => {
      //   // handleInvoiceSelection(cellData?.invoiceId)
      // },
    },
    {
      text : 'Add Remarks',
      icon: quoteIcon,
      onClick: (cellData) => {
        // if (cellData?.status.toLowerCase() === 'in draft' || cellData?.status.toLowerCase() ===  'in review' ||  cellData?.status.toLowerCase() === 'created'
        // ||  cellData?.status.toLowerCase() === 'in progress' ||  cellData?.status.toLowerCase() === 'link sent' ||  cellData?.status.toLowerCase() === 'settled') 
        {
          dispatch(modalActions.editInvoice())
          setSelectedInvoiceId(cellData?.id)
        }
      },
    },
    {
      text: 'Settled Outside Fideo',
      icon: markDone,
      onClick: (cellData) => {
        if (true) console.log("showInvoicePaidOutsideModal moadl lagana hai")
          // showInvoicePaidOutsideModal(modalRef, {
          //   cellData: cellData,
          //   invoiceTab: true,
          // })
      },
    },
    // {
    //   text: 'Mark as Cancelled',
    //   icon: crossSquare,
    //   onClick: (cellData) => {
    //     if (true)
    //       showInvoiceCancelledModal(modalRef, {
    //         cellData: cellData,
    //       })
    //   },
    // },
    // { text: 'Copy', icon: copy },
  ]

    const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
        dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
        // setSelectedInvoice(res?.data)
        // dispatch(modalActions.showInvoiceDisplay())
    }, (err) => {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const [verifyInvoice, fetchingInvoice,InvoiceData,Invoiceerror] = UseApiCall(apiVerifyInvoice,
        (res) => {
            // console.log(res?.data)
            setVerifyInv(true)
            // enqueueSnackbar('', snackbarOptions);

            enqueueSnackbar('Invoice verified successfully.', {
                variant: 'success',
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                autoHideDuration: 3000, // Specify the duration in milliseconds
                style: { fontSize: '10px' } // Adjust the font size as needed
            });


            setLoaderState(true)

            const fetchData = () => {
                setLoaderState(false)
                dispatch(ReceivablesActions.setUpdateOnVerify(true));
            };

            const timer = setTimeout(fetchData, 3000);
            return () => {
                clearTimeout(timer)
            };
        },
        (err)=>{
            // console.log('errhvdb',JSON.stringify(err?.data?.error_response?.errors[0]))
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`,
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    // console.log('updateCustomers',updateCustomers)
    // console.log('Invoiceerror',Invoiceerror)

    const handleVerify = () => {
        // console.log('data',data)
        // setLoaderId(data?.id)
        invoice && organizationId && verifyInvoice({ invoiceId: invoice?.invoice_id, organizationId: organizationId, paymentRail: ''})
    }

    const handleInvoiceSelection = async (invoiceId, organizationId) => {
        // setOrganizationId(organizationId);
        // setInvoiceId(invoiceId);
        localStorage.setItem('zoomkaindex', '0')
        await getSelectedInvoice({ invoiceId, organizationId })
        // let tab = 'details'
        // if (section === 'details') {
        //   tab = 'details'
        //   window.history.pushState(
        //     {},
        //     undefined,
        //     `/receivables/${tab}?invoiceId=${invoiceId}`
        //   )
        // } else if (section === 'paymentStatus') {
        //   tab = 'payment-status'
        //   window.history.pushState(
        //     {},
        //     undefined,
        //     `/receivables/${tab}?invoiceId=${invoiceId}`
        //   )
        // } else if (section === 'bankAccount') {
        //   tab = 'bank-account'
        //   window.history.pushState(
        //     {},
        //     undefined,
        //     `/receivables/${tab}?invoiceId=${invoiceId}`
        //   )
        // }
    }

    useEffect(() => {
        if (updateOnVerify) {
            invoice && organizationId && handleInvoiceSelection(invoice?.id,organizationId)
            // data && handleInvoiceSelectionDraft(data?.invoiceId,data?.organizationId)
        }
    }, [updateOnVerify])

    console.log('updateOnVerify',updateOnVerify)

    console.log('invoice',invoice)
    // console.log('props.statusIndicators',props.statusIndicators)

  return (
    <div ref={optRef} className={`${classes.page_intro} ${props.className}`}>
      {/* {editInvoiceDisplay &&
        ReactDOM.createPortal(
          <EditInvoice
            selectedInvoiceId={selectedInvoiceId}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
      )} */}
      <img
        src={sideBarIcon}
        alt="side-bar-icon"
        className={classes.side_bar}
        onClick={() => dispatch(GlobalActions.setShowSideBarMobile())}
      />
      <div className={classes.pageInfo}>
        <div
          className={`flex items-center -sm:flex-col -sm:gap-1 gap-4 -sm:items-center`}
        >
          <div className={classes.page_name}>{props.pageName}</div>
          {props.statusIndicators?.length && (
            props?.statusIndicators[0]?.status?.split('_').join('').toLowerCase() === "settledoutside"
              ?
                <Tooltip
                  title={'Settled Outside Fideo'}
                  placement="bottom"
                >
                  <div className={"flex"}>
                    {props.statusIndicators.map((item, index) => (
                      <div className='ml-4 invoiceDetailsStatusTag'>
                        <StatusTag
                          key={`${item.status}-${index}`}
                          status={item.status}
                          type="light"
                          className={`rounded-full`}
                        />
                      </div>
                    ))}
                  </div>
                </Tooltip>
              :
                <div className={"flex"}>
                  {props.statusIndicators.map((item, index) => (
                    <div className='ml-4 invoiceDetailsStatusTag'>
                      <StatusTag
                        key={`${item.status}-${index}`}
                        status={item.status}
                        type="light"
                        className={`rounded-full`}
                      />
                    </div>
                  ))}
                </div>
          )}
        </div>
        <div className={classes.pageSubtitleContainer}>
          {props.message && (
            <div className={classes.message}>{props.message}</div>
          )}
          <div className="-md:hidden">
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Invoices',
                },
                {
                  title: `${props.invoiceId}`,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={clsx(classes.button, '-md:flex-col flex-row justify-end flex-wrap gap-5')}>
          {invoice?.invoice_status === 'IN_REVIEW' &&(
              <button
                  // className="px-[16px] w-[100px] text-[1.6rem] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                  className={`px-[16px] w-[100px] text-[1.6rem] text-[#fff] bg-black rounded-[8px]  ${(invoice?.verificationStatus === "VERIFICATION_IN_PROGRESS" )  ? 'opacity-25 cursor-not-allowed' : 'cursor-pointer'}`}

                  onClick={() => handleVerify()}
                  disabled={loaderState || invoice?.verificationStatus === "VERIFICATION_IN_PROGRESS"}
              >
                  {(fetchingInvoice || loaderState) ? (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#FFFFFF', strokeWidth: 8 }} spin />} />
                  ) : (
                      "Verify"
                  )}
              </button>
          )}

        {props.arrBtn?.map((item, index) => (
          <Button
            key={`${item.text}-${index}`}
            text={item.text}
            icon={item.icon}
            disabled={!item.active}
            onClick={() => item.onClick()}
            className={clsx(classes.btn)}
            btnClass={clsx(classes.btnClass, !item.active && '!bg-[#bfc2ca] !text-[#fff] !cursor-not-allowed', item.isVisible || 'hidden')}
            btnClassIcon={clsx(
              classes.btnClass_icon
            )}
            btnClassText={clsx(
              classes.btnClass_text
            )}
          />
        ))}
        {/* <div className={clsx(
          classes.cell_action,
          'hidden md:block'
        )} style={{ borderRadius: "8px", border: "1px solid rgba(24, 28, 48, 0.20)", background: "#FFF", padding: "7px" }}>
          <Action
            option={
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'in review' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'link sent' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'in draft' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'created'  
                ? !invoice?.invoice_details_summary?.invoice_attributes?.remarks ?optionArr : optionArr.filter((el)=>el.text!=="Add Remarks")
                : !invoice?.invoice_details_summary?.invoice_attributes?.remarks?[...optionArr.slice(0, 2)]:[...optionArr.slice(0, 1)]
            }
            cellData={invoice}
          />
        </div> */}
      </div>
      <div className={clsx(
            classes.cell_action,
            'block md:hidden'
          )} style={{ borderRadius: "8px", border: "0px solid rgba(24, 28, 48, 0.20)", background: "#FFF", padding: "0px", position: "absolute", right: "20px" }}>
            <Action
              option={
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'in review' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'link sent' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'in draft' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'created'
                  ? !invoice?.invoice_details_summary?.invoice_attributes?.remarks ?optionArr : optionArr.filter((el)=>el.text!=="Add Remarks")
                  : !invoice?.invoice_details_summary?.invoice_attributes?.remarks?[...optionArr.slice(0, 2)]:[...optionArr.slice(0, 1)]
              }
              cellData={invoice}
            />
          </div>
      <div
        className={classes.profile}
        onClick={() => setShowOptions((pre) => !pre)}
      >
        {/* <img src={adminAvatar} alt="admin-img" className={classes.userImg} /> */}
        <div
          className={clsx(
            'absolute top-32 right-[12px] min-w-[200px] z-10 bg-bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700',
            showOptions ? 'block' : 'hidden'
          )}
        >
          <ul
            className="py-2 w-full text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {options?.map((option) => (
              <li onClick={option.onClick}>
                <p className="block cursor-pointer w-full text-center px-8 py-4 text-2xl hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {option.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default InvoiceDetailsModalHeader
