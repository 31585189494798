import React from 'react';
import classes from "./SearchDetailsInfo.module.scss"
import Modal from "../../Layout/Modal"
import ReactDOM from 'react-dom'
import clsx from "clsx"
import Backdrop from "../../Layout/Backdrop"
import { useDispatch } from "react-redux"
import { modalActions } from "../../Store/modal-redux"
import { Switch,RightOutlined, Tabs } from "antd"
import Close from "../../Layout/Close"
import ExporterProfile from "./ExporterProfile"
import ComplianceChecksProfile from "./ComplianceChecksProfile"
import useClickOutside from '../../hooks/useClickOutside';
import arrowRight from "../../assets/Icons/arrowRightBold.png"
import dayjs from 'dayjs';

const SearchDetailsInfo = (props) => {
    const dispatch = useDispatch();
    // const [showOptions, setShowOptions] = useState(false);
    const optRef = useClickOutside(()=>dispatch(modalActions.setSearchDetails(false)));

    console.log(props)
    
    return(
        <div ref={optRef}>
        <Modal className={clsx(
            classes.modal,
            'p-0 min-w-[100px] max-w-[100px] z-[100] !bg-[#F4F6FA] bottom-0 !pb-[20px]'
          )}
          
        >
            {
                ReactDOM.createPortal(
                <Backdrop
                onClick={() => {
                    dispatch(modalActions.exporterProfileInfo(false))
                }}
                className={classes.backdrop}
                />,
                document.getElementById('backdrop-root')
            )}
            <div className={classes.profileInfo}>
                <div className={classes.profileInfoHeader}>
                    <div className='flex items-center '>Case <img src={arrowRight} width={"25px"} height={"25px"}  /> {props?.searchDetailsData?.exporter?.name ? props?.searchDetailsData?.exporter?.name : "-"} 
                    <img src={arrowRight} width={"25px"} height={"25px"}  /> 
                    {props?.searchDetailsData?.verificationType ? props?.searchDetailsData?.verificationType : "-"} </div>
                    <Close
                        onClick = {()=>dispatch(modalActions.setSearchDetails(false))}
                    />
                </div>
                {/* <div className={classes.profileInfoHeader}>
                    <div>Search Details</div>
                    <Close
                        onClick = {()=>dispatch(modalActions.setSearchDetails(false))}
                    />
                </div> */}
                <div className={classes.profileInfoHeaderSub}>
                        <div>Search Details</div>
                    {/*<div className='flex items-center justify-center gap-[10px]'>*/}
                    {/*    <div >Monitoring</div>*/}
                    {/*    <Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />*/}
                    {/*</div>*/}
                </div>
                <div className={classes.exporterProfile}>
                    <div className={classes.basicDetailsBox}>
                        {/* <div className={classes.basicDetailsHead}>
                            Basic Details
                        </div> */}
                        
                        <div className={classes.basicDetailsCont}>
                            <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Search Id
                                </div>
                                <div className={classes.bdCont}>
                                    {props.searchDetailsData.searchId ? props.searchDetailsData.searchId : "-"}
                                </div>
                            </div>
                            <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Search Date 
                                </div>
                                <div className={classes.bdCont}>
                                    {props.searchDetailsData.searchDate ? dayjs(props.searchDetailsData.searchDate).format(`D MMM YY`) : "-"}
                                </div>
                            </div>
                        </div>
                        <div className={classes.basicDetailsCont}>
                            <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Type
                                </div>
                                <div className={classes.bdCont}>
                                    {props.searchDetailsData.verificationType ? props.searchDetailsData.verificationType : "-"}
                                </div>
                            </div>
                            <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Value
                                </div>
                                <div className={classes.bdCont}>
                                    {props.searchDetailsData.value ? props.searchDetailsData.value : "-"}
                                </div>
                            </div>
                        </div>
                        <div className={classes.basicDetailsCont}>
                            <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Fuzziness
                                </div>
                                <div className={classes.bdCont}>
                                    {'50%'}
                                </div>
                            </div>
                            <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Search Via
                                </div>
                                <div className={classes.bdCont}>
                                    {props?.searchDetailsData?.searchVia ? props?.searchDetailsData?.searchVia : "-"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.profileInfoHeaderSub}`} style={{marginTop : "20px"}}>
                    <div>Response</div>
                </div>
                <div className={classes.exporterProfile} style={{marginTop : "20px"}}>
                    <div className={classes.basicDetailsBoxResponse}>
                        <div className={classes.basicDetailsContResponse}>
                            <div className={classes.bdResponse}>
                                <div className={classes.bdContResponse}>
                                    {props.searchDetailsData.response ? props.searchDetailsData.response : "-"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        </div>
    )
}

export default SearchDetailsInfo