import classes from './TransactionDetails.module.scss'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
// import Dropdown from 'Components/Dropdown/Dropdown'
// import downIcon from 'assets/Images/chevron-down.svg'
// import summarySaved from 'assets/Images/summary-saved.svg'
// import useClickOutside from 'hooks/useClickOutside'
// import Close from 'Layout/Close'
// import Status from 'Components/ReportTable/Status'
// import BgOrnamentCircle from 'assets/Icons/bg-ornament-circle.svg'
// import BgOrnamentDots from 'assets/Icons/bg-ornament-dots.svg'
// import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { formatNumberToLocaleString } from '../hooks/formatNumberToLocaleString'
import WalletBrown from "../assets/Icons/downloadCloud.svg"
import Shimmer from '../Layout/Shimmer'
import { Dropdown } from 'antd';
import down from "../assets/Icons/chevron-down.svg";

// const dropDown = [
//   {
//     id: 1,
//     heading: 'Year to date',
//     def: 'YTD',
//   },
//   {
//     id: 2,
//     heading: 'Month to date',
//     def: 'MTD',
//   },
//   {
//     id: 3,
//     heading: 'Last 7 days',
//     def: 'Last 7 days',
//   },
//   {
//     id: 4,
//     heading: 'Today',
//     def: 'Today',
//   },
//   {
//     id: 5,
//     heading: 'Select Custom Range',
//     def: 'SCR',
//   },
// ]

const TransactionDetails = (props) => {

  const {dashboardNumbersDTO,dashboardSummaryDTO} = props?.transactionData

  const [activeTab, setActiveTab] = useState("ALL")
  const [windowSize, setWindowSize] = useState(800)
  const [totalInvoicesCreated, setTotalInvoicesCreated] = useState({ count: 0, price: 0 })
  const [totalSettledInvoices, setTotalSettledInvoices] = useState({ count: 0, price: 0 })
  const [totalOutstandingInvoices, setTotalOutstandingInvoices] = useState({ count: 0, price: 0 })
  const [totalOverdueInvoices, setTotalOverdueInvoices] = useState({ count: 0, price: 0 })
  const [totalPaidOutsideInvoices, setTotalPaidOutsideInvoices] = useState({ count: 0, price: 0 })
  const [totalInProgressInvoices, setTotalInProgressInvoices] = useState({ count: 0, price: 0 })
  const [currencyCodeSign, setCurrencyCodeSign] = useState('$')
  const [savingAmount, setSavingAmount] = useState({ sign: '₹', price: 2781.00 })
  const [more, setMore] = useState('More')

  const items = [
    {
      key: 'CAD',
      label: <div className='text-[16px] font-inter'>CAD</div>,
    },
    {
      key: 'AED',
      label: <div className='text-[16px] font-inter '>AED</div>,
    },
  ];

  // const navigateToReceievable = () => {
  //   navigate('/receivables')
  // }

  // const onClickDropdown = (value) => {
  //   setShowDropdown(false)
  //   setSelectedDropdown(value)
  // }
  const data = dashboardSummaryDTO

  const fetchTotalInvoicesCount = (data, checkStatus, checkCurrencyCode) => {
    let totalCounts = 0
    let totalPrice = 0

    data?.forEach((item) => {
      const status = item.tag

      if (status === checkCurrencyCode) {
        if(checkStatus === 'CREATED'){
          totalCounts = item?.totalInvoices;
          totalPrice = item?.totalAmount
        }else if(checkStatus === 'SETTLED'){
          totalCounts = item?.totalSettledInvoices;
          totalPrice = item?.totalSettledAmount
        }else if(checkStatus === 'OUTSTANDING'){
          totalCounts = item?.outstandingInvoices;
          totalPrice = item?.outstandingAmount
        }else if(checkStatus === 'SETTLED_OUTSIDE_FIDEO'){
          totalCounts = item?.settledOutsideInvoices;
          totalPrice = item?.settledOutsideAmount
        }else if(checkStatus === 'IN_PROGRESS'){
          totalCounts = item?.inProgressInvoices;
          totalPrice = item?.inProgressAmount
        }
      }
    })

    return {count: totalCounts, price: formatNumberToLocaleString((totalPrice), 2, 2)};
  }

  useEffect(() => {
    handleCount(activeTab)
  }, [data])

  const handleDropdownItemClick = (e) => {
    console.log(e.key);
    setMore(e.key);
    handleCount(e.key);
  };

  const handleCount = (status) => {
    setActiveTab(status)
    setSavingAmount({
      sign: '₹',
      price: formatNumberToLocaleString((0), 2, 2)
    })
    if (status === "ALL") {
      setCurrencyCodeSign('$')
    }
    else if (status === 'USD') {
      setCurrencyCodeSign('$')
    } else if (status === 'CAD') {
      setCurrencyCodeSign('$')
    } else if (status === 'GBP') {
      setCurrencyCodeSign('£')
    } else if (status === 'EUR') {
      setCurrencyCodeSign('€')
    } else if (status === 'AED') {
      setCurrencyCodeSign('د.إ')
    }
    setTotalInvoicesCreated(fetchTotalInvoicesCount(data, 'CREATED', status))
    setTotalSettledInvoices(fetchTotalInvoicesCount(data, 'SETTLED', status))
    setTotalOutstandingInvoices(fetchTotalInvoicesCount(data, 'OUTSTANDING', status))
    // setTotalOverdueInvoices(fetchTotalInvoicesCount(data, 'OVERDUE', status))
    setTotalPaidOutsideInvoices(fetchTotalInvoicesCount(data, 'SETTLED_OUTSIDE_FIDEO', status))
    setTotalInProgressInvoices(fetchTotalInvoicesCount(data, 'IN_PROGRESS', status))
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`${classes.transaction_details} ${props.className}`}>
      <div className={classes.box1}>
        <div className={classes.receivablesCard} style={{ padding: "16px", width: "100%" }}>
          <div className={clsx(classes.inner)}>
            <div className='flex flex-col gap-[16px] justify-between items-center self-stretch'>
              <div className={clsx(classes.infoText, 'flex justify-between items-center w-[100%]')}>
                <div className={classes.heading}>Total Exporters:</div>
                <div className={classes.circleTwo}>
                  <div className={classes.dollar}>{dashboardNumbersDTO?.totalExporter ? dashboardNumbersDTO?.totalExporter : "-"}</div>
                </div>
              </div>
              <div className={clsx(classes.infoText, 'flex justify-between items-center w-[100%]')}>
                <div className={classes.heading}>Total Receivables:</div>
                <div className={classes.circleTwo}>
                  <div className={classes.dollar}>{dashboardNumbersDTO?.totalReceivables ? dashboardNumbersDTO?.totalReceivables : "-"}</div>
                </div>
              </div>
              <div className={clsx(classes.infoText, 'flex justify-between items-center w-[100%]')}>
                <div className={classes.heading}>Total Customers:</div>
                <div className={classes.circleTwo}>
                  <div className={classes.dollar}>{dashboardNumbersDTO?.totalCustomers ? dashboardNumbersDTO?.totalCustomers : "-"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        // windowSize <= 640
        // ?
        //   <div
        //     className={clsx(
        //       classes.box2,
        //       'bg-white border border-solid border-[#D2D5DB99] rounded-2xl'
        //     )}
        //   >
        //     <div>
        //       <div className={clsx(classes.tabHeader)}>
        //         <div className={clsx(activeTab === "ALL" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("ALL")}>All</div>
        //         <div className={clsx(activeTab === "USD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("USD")}>USD</div>
        //         <div className={clsx(activeTab === "EUR" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("EUR")}>EUR</div>
        //         <div className={clsx(activeTab === "GBP" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("GBP")}>GBP</div>
        //         <Dropdown
        //           menu={{
        //             onClick: handleDropdownItemClick,
        //             items: items,
        //           }}
        //           placement="bottom"
        //           overlayStyle={{width:"100px", fontSize:"16px !important" }}
        //         >
        //           <div className={clsx((activeTab === "CAD" || activeTab === "AED") ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}><>{more}</><img src={down} className="w-[20px] h-[20px]" /></div>
        //         </Dropdown>
        //       </div>

        //       <div className={classes.mainCard}>

        //         <div className={classes.receivablesCard}>
        //           <div className={clsx(classes.inner)}>
        //             <div className='flex justify-between items-center self-stretch'>
        //               <div className=''>
        //                 <div className={classes.heading}>Total Receivables Value</div>
        //                 <div className={clsx(classes.text, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[150px] h-[36px] rounded-lg"/> : totalInvoicesCreated?.price}</div>
        //               </div>
        //               <div className={classes.circle}>
        //                 <div className={classes.dollar}>{currencyCodeSign}</div>
        //               </div>
        //             </div>
        //             {
        //               props.loading
        //                 ?
        //                   <Shimmer className="w-[100%] h-[18px] rounded-lg"/>
        //                 :
        //                   <div className={clsx(classes.ytd)}>
        //                     {totalInvoicesCreated?.count}{' '}
        //                     {totalInvoicesCreated?.count > 1 ? 'Invoices' : 'Invoice'} created TD
        //                   </div>
        //             }
        //           </div>
        //         </div>

        //         <div className={classes.outstandingCard}>
        //           <div className='w-[100%]'>
        //             <div className={classes.heading}>Outstanding Payment</div>
        //             <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalOutstandingInvoices?.price}</div>
        //           </div>
        //           {
        //             props.loading
        //               ?
        //                 <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
        //               :
        //                 <div className='flex justify-between items-center self-stretch'>
        //                   <div className={classes.count}>{totalOutstandingInvoices?.count} {totalOutstandingInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
        //                   <div className={clsx('')}>
        //                     {/* <Status
        //                       status={`OVERDUE_summary`}
        //                       statusText={`${totalOverdueInvoices?.count} OVERDUE`}
        //                     /> */}
        //                   </div>
        //                 </div>
        //           }
        //         </div>

        //         <div className={classes.settledCard}>
        //           <div className='w-[100%]'>
        //             <div className={classes.heading}>Settled via Fideo</div>
        //             <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalSettledInvoices?.price}</div>
        //           </div>
        //           {
        //             props.loading
        //               ?
        //                 <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
        //               :
        //                 <div className='flex justify-between items-center self-stretch'>
        //                   <div className={classes.count}>{totalSettledInvoices?.count} {totalSettledInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
        //                   <div className={clsx('')}>
        //                     <div className={clsx('bg-[#FFE55E]',`rounded-full w-fit flex flex-row items-center justify-between gap-2 px-[0.8rem] py-[0.2rem]`)}>
        //                       <img src={WalletBrown} alt="wallet" className="w-6 h-6" />{' '}
        //                       <p className="text-[1.2rem] font-[600] text-center leading-[1.6rem]">
        //                         <span className="inline-block text-[#8C6825]">
        //                           {savingAmount?.sign}{savingAmount?.price} SAVED
        //                         </span>
        //                       </p>
        //                     </div>
        //                   </div>
        //                 </div>
        //           }
        //         </div>

        //         <div className={classes.paidOutsideCard}>
        //           <div className='w-[100%]'>
        //             <div className={classes.heading}>Settled outside Fideo</div>
        //             <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalPaidOutsideInvoices?.price}</div>
        //           </div>
        //           <div className='w-[100%]'>
        //             {
        //               props.loading
        //                 ?
        //                   <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
        //                 :
        //                   <div className={classes.count}>{totalPaidOutsideInvoices?.count} {totalPaidOutsideInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
        //             }
        //           </div>
        //         </div>

        //         <div className={classes.progressCard}>
        //           <div className='w-[100%]'>
        //             <div className={classes.heading}>In-Progress</div>
        //             <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalInProgressInvoices?.price}</div>
        //           </div>
        //           <div className='w-[100%]'>
        //             {
        //               props.loading
        //                 ?
        //                   <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
        //                 :
        //                   <div className={classes.count}>{totalInProgressInvoices?.count} {totalInProgressInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
        //             }
        //           </div>
        //         </div>
        //       </div>

        //     </div>

        //     {/* <div className="flex md:flex-row flex-col col-span-2 h-[100%]">
        //       <div
        //         className={`${classes[`transaction_${data[1].status}`]} ${
        //           activeState === data[1].id
        //             ? classes.transaction_active
        //             : undefined
        //         } relative overflow-hidden mr-[0px]`}
        //         onClick={() => {
        //           navigateToReceievable()
        //         }}
        //       >
        //         <img
        //           src={BgOrnamentCircle}
        //           alt=""
        //           className="absolute w-[35%] z-0 bottom-0 right-0"
        //         />
        //         <img
        //           src={BgOrnamentCircle}
        //           alt=""
        //           className="absolute w-[35%] top-0 rotate-180 -right-[17%]"
        //         />
        //         <div className={clsx(classes.money_status)}>
        //           <div className={classes.status}>
        //             <div
        //               style={{
        //                 fontFamily: 'Inter',
        //                 fontStyle: 'normal',
        //                 fontWeight: 600,
        //                 fontSize: '14px',
        //                 lineHeight: '20px',
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 color: 'rgba(30, 51, 63, 0.5)',
        //               }}
        //             >
        //               Settled in your bank account
        //             </div>
        //           </div>
        //           <div className={clsx(classes.money)}>
        //             <div className="flex flex-col">
        //               {settledAmounts?.length > 0 ? (
        //                 settledAmounts.map((item, index) => {
        //                   return (
        //                     <div
        //                       key={index}
        //                       className={clsx(classes.summaryCurrencyFont)}
        //                     >
        //                       {item.sign.toLocaleString('en-US')}{' '}
        //                       {formatNumberToLocaleString((item?.amount), 2, 2)}
        //                     </div>
        //                   )
        //                 })
        //               ) : (
        //                 <div className={clsx(classes.summaryCurrencyFont)}>0</div>
        //               )}
        //             </div>
        //           </div>
        //         </div>
        //         <div className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}>
        //           {totalSettledInvoices}{' '}
        //           {totalSettledInvoices > 1 ? 'Invoices' : 'Invoice'}
        //           <div className="h-[20px]">
        //           </div>
        //         </div>
        //       </div>
        //       <div
        //         className={`${classes[`transaction_${data[3].status}`]} ${
        //           activeState === data[1].id
        //             ? classes.transaction_active
        //             : undefined
        //         } relative overflow-hidden`}
        //         onClick={() => {
        //           navigateToReceievable()
        //         }}
        //       >
        //         <img
        //           src={BgOrnamentDots}
        //           alt=""
        //           className="absolute w-[24%] z-0 bottom-0 right-0"
        //         />
        //         <img
        //           src={BgOrnamentDots}
        //           alt=""
        //           className="absolute w-[24%] min-w-[50px] rotate-[35deg] -top-[12%] right-2 "
        //         />
        //         <div className={classes.money_status}>
        //           <div className={classes.status}>
        //             <div
        //               style={{
        //                 fontFamily: 'Inter',
        //                 fontStyle: 'normal',
        //                 fontWeight: 600,
        //                 fontSize: '14px',
        //                 lineHeight: '20px',
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 color: 'rgba(30, 51, 63, 0.5)',
        //               }}
        //             >
        //               Pending payment
        //             </div>
        //           </div>
        //           <div className={classes.money}>
        //             <div className="flex flex-col items-start">
        //               {totalOutstanding?.length > 0 ? (
        //                 totalOutstanding.map((item, index) => {
        //                   return (
        //                     <div
        //                       key={index}
        //                       className={clsx(classes.summaryCurrencyFont)}
        //                     >
        //                       {item.sign.toLocaleString('en-US')}{' '}
        //                       {formatNumberToLocaleString((item?.amount), 2, 2)}
        //                     </div>
        //                   )
        //                 })
        //               ) : (
        //                 <div className={clsx(classes.summaryCurrencyFont)}>0</div>
        //               )}
        //             </div>
        //           </div>
        //         </div>
        //         <div className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}>
        //           <p className="">
        //             {totalOutstandingInvoices}{' '}
        //             {totalOutstandingInvoices > 1 ? 'Invoices' : 'Invoice'}
        //           </p>
        //           <div className="h-[20px]">
        //             {totalOverdueInvoices?.count > 0 && (
        //               <Status
        //                 status={`OVERDUE_summary`}
        //                 statusText={`${totalOverdueInvoices?.count} OVERDUE >`}
        //               />
        //             )}
        //           </div>
        //         </div>
        //       </div>
        //     </div> */}
        //   </div>
        // :
          <div
            className={clsx(
              classes.box2,
              'bg-white border border-solid border-[#D2D5DB99] rounded-2xl'
            )}
          >
            <div>
              <div className={clsx(classes.tabHeader)}>
                <div className={clsx(activeTab === "ALL" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("ALL")}>All</div>
                <div className={clsx(activeTab === "USD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("USD")}>USD</div>
                <div className={clsx(activeTab === "EUR" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("EUR")}>EUR</div>
                <div className={clsx(activeTab === "GBP" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("GBP")}>GBP</div>
                <Dropdown
                  menu={{
                    onClick: handleDropdownItemClick,
                    items: items,
                  }}
                  placement="bottom"
                  overlayStyle={{width:"100px", fontSize:"16px !important" }}
                  // open={true}
                >
                  <div className={clsx((activeTab === "CAD" || activeTab === "AED") ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}><>{more}</><img src={down} className="w-[20px] h-[20px]" /></div>
                </Dropdown>
              </div>

              <div className={classes.mainCard}>
                <div className={classes.receivablesCard}>
                  <div className={clsx(classes.inner)}>
                    <div className={classes.circle}>
                      <div className={classes.dollar}>{currencyCodeSign}</div>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className={classes.heading}>Total Receivables Value</div>
                      <div className={clsx(classes.text, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] min-w-[180px] h-[36px] rounded-lg"/> : (totalInvoicesCreated?.price ? totalInvoicesCreated?.price : '0.00')}</div>
                    </div>
                    {
                      props.loading
                        ?
                          <Shimmer className="w-[100%] h-[18px] rounded-lg"/>
                        :
                          <div className={clsx(classes.ytd)}>
                            {totalInvoicesCreated?.count  ? totalInvoicesCreated?.count : '0'}{' '}
                            {totalInvoicesCreated?.count > 1 ? 'Invoices' : 'Invoice'} created TD
                          </div>
                    }
                  </div>
                </div>

                <div className={classes.bothCards}>
                  <div className={classes.firstGroup}>
                    <div className={classes.outstandingCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>Outstanding Payment</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalOutstandingInvoices?.price ? totalOutstandingInvoices?.price : '0.00'}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalOutstandingInvoices?.count ? totalOutstandingInvoices?.count : '0'} {totalOutstandingInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                              <div className={clsx('')}>
                                {/* <Status
                                  status={`OVERDUE_summary`}
                                  statusText={`${totalOverdueInvoices?.count} OVERDUE`}
                                /> */}
                              </div>
                            </div>
                      }
                    </div>
                    <div className={classes.settledCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>Settled via Fideo</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalSettledInvoices?.price ? totalSettledInvoices?.price : '0.00'}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalSettledInvoices?.count ? totalSettledInvoices?.count : '0'} {totalSettledInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                              <div className={clsx('')}>
                                {/* <div className={clsx('bg-[#FFE55E]',`rounded-full w-fit flex flex-row items-center justify-between gap-2 px-[0.8rem] py-[0.2rem]`)}>
                                  <img src={WalletBrown} alt="wallet" className="w-6 h-6" />{' '}
                                  <p className="text-[1.2rem] font-[600] text-center leading-[1.6rem]">
                                    <span className="inline-block text-[#8C6825]">
                                      {savingAmount?.sign}{savingAmount?.price} SAVED
                                    </span>
                                  </p>
                                </div> */}
                              </div>
                            </div>
                      }
                    </div>
                  </div>

                  <div className={classes.firstGroup}>
                    <div className={classes.paidOutsideCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>Settled outside Fideo</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalPaidOutsideInvoices?.price ? totalPaidOutsideInvoices?.price : '0.00'}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalPaidOutsideInvoices?.count  ? totalPaidOutsideInvoices?.count : '0'} {totalPaidOutsideInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                            </div>
                      }
                    </div>
                    <div className={classes.progressCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>In-Progress</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalInProgressInvoices?.price ? totalInProgressInvoices?.price : '0.00'}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalInProgressInvoices?.count ? totalInProgressInvoices?.count : '0'} {totalInProgressInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                            </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
      }
    </div>
  )
}

export default TransactionDetails
