import ReactDOM from "react-dom";
import { Skeleton, Tabs } from "antd"
import PageIntro from "../Layout/PageIntro"
import classes from "./Dashboard.module.scss"
import TransactionDetails from "./TransactionDetails"
import { useEffect, useState } from "react"
import clsx from "clsx"
import YearlyReportTable from "./YearlyReportTable"
import UseApiCall from "../hooks/useApiCall"
import { apiGetSummary, apiGetTableData } from "../services"
import { DashboardSummaryActions } from "../Store/customer-redux"
import { useDispatch, useSelector } from "react-redux"
import { formatNumberToLocaleString } from "../hooks/formatNumberToLocaleString"
import ReceiveBulkUploadPayment from '../Components/Payment/ReceiveBulkUploadPayment'
import ReceivePayment from "../Components/Payment/ReceivePayment";

const Dashboard = () => {
    // const [defaultActiveKey] = useState("2023");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const summary = useSelector((state) => state.dashboardSummary.summary);
    const dashboardTableData = useSelector((state) => state.dashboardSummary.dashboardTableData);
    const receivePaymentDisplay = useSelector((state) => state.modal.receivePaymentDisplay);
    const bulkUploaderDisplay = useSelector((state) => state.modal.bulkUploaderDisplay);

    const [allData, setAllData] = useState([]);

    const [tempData, setTempData] = useState({});

    const [activeTab, setActiveTab] = useState("ALL");
    const [defaultData, setDefaultData] = useState({});
    const [more, setMore] = useState('More');
    // const [monthlyData, setMonthlyData] = useState([]);
    // const [monthlyDataTotal, setMonthlyDataTotal] = useState({});
    const [currencyCodeSign, setCurrencyCodeSign] = useState('$');
    const [savingAmount, setSavingAmount] = useState({ sign: '₹', price: 2781.00 })
    const [currencyWise, setCurrencyWise] = useState({
        "ALL" : {
            "monthlyData" : [],
            "monthlyDataTotal" : {}
        },
        "USD" : {
            "monthlyData" : [],
            "monthlyDataTotal" : {}
        },
        "EUR" : {
            "monthlyData" : [],
            "monthlyDataTotal" : {}
        },
        "GBP" : {
            "monthlyData" : [],
            "monthlyDataTotal" : {}
        },
        "CAD" : {
            "monthlyData" : [],
            "monthlyDataTotal" : {}
        },
        "AED" : {
            "monthlyData" : [],
            "monthlyDataTotal" : {}
        },
      });

    const showSkeleton = () => {
      setLoading(false);
    }
    
  
    const onTabChange = (key) => {
      showSkeleton()
    }

    const handleDropdownItemClick = (e) => {        
        setMore(e.key);
        handleCount(e.key);
      };

      const handleCount = (status) => {
        setActiveTab(status)
        setSavingAmount({
          sign: '₹',
          price: formatNumberToLocaleString((0), 2, 2)
        })
    
        if (status === 'ALL') {
          setCurrencyCodeSign('$');
        }
        else if (status === 'USD') {
          setCurrencyCodeSign('$')
        } else if (status === 'CAD') {
          setCurrencyCodeSign('$');
        } else if (status === 'GBP') {
          setCurrencyCodeSign('£');
        } else if (status === 'EUR') {
          setCurrencyCodeSign('€');
        } else if (status === 'AED') {
          setCurrencyCodeSign('د.');
        }
    
        const allMonths = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startYear = 2023;

        for (let year = startYear; year <= currentYear; year++) {
            const endMonth = year === currentYear ? currentDate.getMonth() : 11;

            for (let month = 0; month <= endMonth; month++) {
            const formattedMonth = new Date(year, month, 1).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
            });
            allMonths.push(formattedMonth);
            }
        }

        const currencyMonthlyData = allMonths.map((month) => ({
            month,
            values: null, 
        }
        ));

        const currencyMonthlyDataTotal = {};

        dashboardTableData?.filter((data) => data?.tag === status)?.map((ele) => {
          const monthlyEntries = Object.entries(ele?.dashboardSummaryDTOMonthlySummary) || [];
     
          const categorizedData = monthlyEntries.reduce(
            (result, [month, values]) => {
              if (month === "TOTAL") {
                result.totalData = values;
              } else {
                result.otherMonthsData.push({ month, values });
              }
              return result;
            },
            { totalData: null, otherMonthsData: [] }
          );
    
          categorizedData?.otherMonthsData.forEach((entry) => {
            const index = currencyMonthlyData.findIndex((item) => item.month === entry.month);
            if (index !== -1) {
              currencyMonthlyData[index].values = entry.values;
            }
          });

        
          currencyMonthlyDataTotal[status] = categorizedData?.totalData;
        })
    
        currencyMonthlyData.sort((a, b) => {
            const dateA = new Date(a.month);
            const dateB = new Date(b.month);
        
            if (dateA.getFullYear() !== dateB.getFullYear()) {
              return dateB.getFullYear() - dateA.getFullYear();
            }
        
            return  dateB.getMonth() - dateA.getMonth();
          });
        
          setCurrencyWise((prevCurrencyWise) => ({
            ...prevCurrencyWise,
            [status]: {
              monthlyData: currencyMonthlyData,
              monthlyDataTotal: currencyMonthlyDataTotal[status] || {}
            }
          }));
    }

    const [getSummary, fetchingSummary, data, error] = UseApiCall(apiGetSummary, 
        (res) => {
        dispatch(DashboardSummaryActions.setSummary(res?.data));
        setAllData(res?.data?.dashboardSummaryDTO);
            allData.map((data) =>{
                if(data.tag === "ALL"){
                    setDefaultData(data)
                }
            })
      }, (err) => {
        dispatch(DashboardSummaryActions.setShowBackendError(err));
    });

    const [getTableData, fetchinggetTableData, Tabledata, Tableerror] = UseApiCall(apiGetTableData, 
        (res) => {
        dispatch(DashboardSummaryActions.setDashboardTableData(res?.data));
      }, (err) => {
        dispatch(DashboardSummaryActions.setShowBackendError(err));
    });

    useEffect(() => {
        showSkeleton();
        getSummary();
        getTableData();
    }, [])

    const tableHeader = [
        {
            id: 1,
            title: "Month",
            width: 12,
            minWidth: 100,
        },
        {
            id: 2,
            title: "Exporters",
            title1: "Onboarded",
            width: 10,
            minWidth: 100,
        },
        {
            id: 3,
            title: `Receivables`,
            title1: "Uploaded",
            title2: "Amount",
            width: 14,
            minWidth: 120,
        },
        {
            id: 4,
            title: "Payments",
            title1: "Received",
            width: 14,
            minWidth: 120,
        },
        {
            id: 5,
            title: "Fully",
            title1: "Settled",
            width: 14,
            minWidth: 120,
        },
        {
            id: 6,
            title: "Partially",
            title1: "Settled",
            width: 14,
            minWidth: 120,
        },
        {
            id: 7,
            title: "Settled",
            title1: "Outside Fideo",
            width: 14,
            minWidth: 120,
        },
        {
            id: 8,
            title: "Pending",
            width: 14,
            minWidth: 120,
        },
    ];

// Insert this object if activeTab is "ALL"
//     if (activeTab === "ALL") {
//         const exporterOnboardedObject = {
//             id: 2,
//             title: "Exporters",
//             title1: "Onboarded",
//             width: 10,
//             minWidth: 100,
//         };
//         const insertionIndex = tableHeader.findIndex((item) => item.id === 3); // You may adjust this based on your specific requirements
//         tableHeader.splice(insertionIndex, 0, exporterOnboardedObject);
//     }

    useEffect(() => {
        handleCount(activeTab)
    }, [dashboardTableData])

    useEffect(()=>{
        if ( localStorage.getItem('isRefresh') === "true") {
            localStorage.setItem('isRefresh',false);
            window.location.reload();
        }
    },[])

    useEffect(() => {
        allData.map((data) => {
            if(data.tag === activeTab){
                setTempData(data);
            }
        })
    },[activeTab,allData])

    return(
        <>
          {(receivePaymentDisplay) &&
            ReactDOM.createPortal(
              <ReceivePayment />,
              document.getElementById("modal-root")
          )}

          {/* {bulkUploaderDisplay &&
            ReactDOM.createPortal(
              <ReceiveBulkUploadPayment />,
              document.getElementById('modal-root')
          )} */}
            <PageIntro
                pageName={"Dashboard"}
                message={"Track and Manage cross-border transactions and exporters with ease"}
                dontShowBtn={true}
            />
            <TransactionDetails transactionData={summary} loading={fetchingSummary} />
            <div
                className="flex flex-col gap-[0px]  w-[100%]"
                style={{
                    background: '#F3F6FA',
                }}
            >
                <div className={classes.box1}>
                    <div>Annual Monthly Overview</div>
                </div>
                {/* <Tabs
                    defaultActiveKey={defaultActiveKey}
                    onChange={onTabChange}
                    style={{ backgroundColor: 'transparent', borderTopWidth: 0 }}
                >
                {items.map(item => (
                    <Tabs.TabPane
                        key={item.key}
                        className={clsx('custom-tab', classes.firstTab)}
                        tab={<span>{item.label}</span>}
                    >
                        <Skeleton loading={loading}>{item.children}</Skeleton>
                    </Tabs.TabPane>
                ))}
                </Tabs> */}
                <YearlyReportTable
                    activeTab={activeTab}
                    handleCount={handleCount}
                    handleDropdownItemClick={handleDropdownItemClick}
                    more={more}
                    currencyCodeSign={currencyCodeSign}
                    currencyWise={currencyWise}

                    tableHeader={tableHeader}
                    loading={fetchinggetTableData || fetchingSummary}
                    tempData = {tempData}
                />
            </div>
        </>
    )
}

export default Dashboard