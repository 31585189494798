// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_btn__d0peN {
  display: flex;
  background-color: #181C30;
  border: none;
  color: white;
  border-radius: 9px;
  padding: 10.8px 14.4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Button_btn__d0peN .Button_icon__jgg5j {
  margin-right: 7.2px;
  height: 18px;
  width: 18px;
}
.Button_btn__d0peN .Button_text__-OkCa {
  font-size: 14.4px;
  line-height: 21.6px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Layout/Button.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAAJ;AAEI;EACI,mBAAA;EACA,YAAA;EACA,WAAA;AAAR;AAGI;EACI,iBAAA;EACA,mBAAA;EACA,gBAAA;AADR","sourcesContent":[".btn{\n\n    display: flex;\n    background-color: #181C30 ;\n    border: none;\n    color: white;\n    border-radius: 9px;\n    padding: 10.8px 14.4px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n\n    .icon{\n        margin-right: 7.2px;\n        height: 18px;\n        width: 18px;\n    }\n    \n    .text{\n        font-size: 14.4px;\n        line-height: 21.6px;\n        font-weight: 600;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Button_btn__d0peN`,
	"icon": `Button_icon__jgg5j`,
	"text": `Button_text__-OkCa`
};
export default ___CSS_LOADER_EXPORT___;
