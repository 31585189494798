// import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
// import classes from './CustomerDetailsTabContent.module.scss'

import clsx from 'clsx'
import MobileCustomerDetailsTable from './MobileCustomerDetailsTable'
import { Card, Divider } from 'antd'

const CustomerDetailsTable = (props) => {
    const { details, address } = props

    return (
        <div className={clsx('customerDetailsTableScreen')}>
            <Card
                className="modalDetailsCard"
                title={'Basic Information'}
                bordered={false}
                style={{
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <div className="customerDetails">
                    {details.map((dataRow, index) => {
                        return (
                            <>
                                <div
                                    key={index}
                                    className="flex flex-row flex-nowrap justify-start items-center w-full"
                                >
                                    <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                                        <p className="accordianDetailsDataHeader">
                                            {dataRow[0]?.value || ""}
                                        </p>
                                        <p className="accordianDetailsDataSubText">
                                            {dataRow[0].label}
                                        </p>
                                    </div>
                                    <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                                        <p className="accordianDetailsDataHeader">
                                            {dataRow[1]?.value || ""}
                                        </p>
                                        <p className="accordianDetailsDataSubText">
                                            {dataRow[1].label}
                                        </p>
                                    </div>
                                </div>
                                {details.length - 1 !== index && <Divider />}
                            </>
                        )
                    })}
                </div>
            </Card>
            <Card
                className="modalDetailsCard"
                title={'Address'}
                bordered={false}
                style={{
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <div className="customerDetails">
                    {address.map((dataRow, index) => {
                        return (
                            <>
                                <div
                                    key={index}
                                    className="flex flex-row flex-nowrap justify-start items-center w-full"
                                >
                                    <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                                        <p className="accordianDetailsDataHeader">
                                            {dataRow[0]?.value || ""}
                                        </p>
                                        <p className="accordianDetailsDataSubText">
                                            {dataRow[0].label}
                                        </p>
                                    </div>
                                    <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                                        <p className="accordianDetailsDataHeader">
                                            {dataRow[1]?.value || ""}
                                        </p>
                                        <p className="accordianDetailsDataSubText">
                                            {dataRow[1].label}
                                        </p>
                                    </div>
                                </div>
                                {address.length - 1 !== index && <Divider />}
                            </>
                        )
                    })}
                </div>
            </Card>
            <MobileCustomerDetailsTable address={address} details={details} />
        </div>
    )
}
export default CustomerDetailsTable
