import CustomerDetailsTable from "./CustomerDetailsTable";

const CustomerDetailsTabContent = (props) => {
    const {details} = props;
    const formattedCustomerDetailsTable1 = () => {
        let {customer_name, nick_name, email_address, mobile_country_code, mobile_number} = details;
        return [
            [
                { label: 'Full name', value: customer_name || "" },
                { label: 'Nick name', value: nick_name || "" }
            ],
            [
                { label: 'Email Address', value: email_address || "" },
                { label: 'Phone', value: mobile_country_code && mobile_number ? `${mobile_country_code} ${mobile_number}` : "" }
            ],
        ]
    }
    const formattedCustomerDetailsTable2 = () => {
        const {address_line1, address_line2, city, state, postal_code, country} = details;
        return [
            [
                { label: 'Address line 1', value: address_line1 || "" },
                { label: 'Address line 2', value: address_line2 || "" }
            ],
            [
                { label: 'City', value: city || "" },
                { label: 'State', value: state || "" }
            ],
            [
                { label: 'Postal Code', value: postal_code || "" },
                { label: 'Country', value: country || '' }
            ],
        ]
    }
    return (
        <>
            <CustomerDetailsTable address={formattedCustomerDetailsTable2()} details={formattedCustomerDetailsTable1()} heading={'Basic Information'} containerClass={`mt-12 -sm:mt-8 -md:mt-7`}/>
        </>
    );
}
export default CustomerDetailsTabContent;