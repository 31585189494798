import classes from "./PageIntro.module.scss";
import Button from "../Layout/Button";
import sideBarIcon from "../assets/Icons/SideBarIcon.svg";
import downloadIcon from "../assets/Icons/download-cloud.svg"
// import adminAvatar from "assets/Icons/account.svg";
import userIcon from "../assets/Icons/userIcon.svg";
import logoutIcon from "../assets/Icons/logoutIcon.svg";
import { modalActions } from "../Store/modal-redux";
import arrowDown from "../assets/Images/arrow_down.svg";
import ArrowDown from "../assets/Icons/arrow-down-triangle-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { GlobalActions } from "../Store/global-redux";
import clsx from "clsx";
// import useClickOutside from "hooks/useClickOutside";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import UseApiCall from "hooks/useApiCall";
// import { apiSignOut } from "services";
// import { tourArrays } from "constants/Tour";
// import { Tooltip } from "antd";
// import TooltipTourData from "Components/Tour/TooltipTourData";
import PlusIcon from "../assets/Icons/plus-black.svg";
import UploadInvoicesIcon from "../assets/Icons/upload-invoices.svg";
// import CryptoJS from 'crypto-js';
// import { secretKey } from "constants";
// import downloadIcon from "../assets/Icons/downloadCloud.svg"
// import Excel from 'exceljs';
// import { saveAs } from 'file-saver';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
// import { baseUrl } from "constants/Network";

const PageIntro = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoiceActionDisplay = useSelector((state) => state.modal.invoiceActionDisplay);
  // const [showOptions, setShowOptions] = useState(false);
  // const showTourModal = useSelector((state) => state.global.showTour);
  // const stepNumber = useSelector((state) => state.global.stepNumberInTour);
  // const optRef = useClickOutside(() => setShowOptions(false));
  // const [tourArray, setTourArray] = useState(tourArrays[1]);
  // const decryptValue = (encryptedValue) => {
  //   const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // };

  // const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  // useEffect(() => {
  //   if(storedUserRole === 'malik') setTourArray(tourArrays[0])
  // }, [])

  // const [logOut, loggingOut] = UseApiCall(apiSignOut, () => {
  //   dispatch({type: 'CLEAR_STATE'})
  //   navigate("/login?mode=email");
  // });

  // const HandleProfile = () => {
  //   navigate("/profile-settings");
  //   dispatch(GlobalActions.setSelectedOption(null))
  // }

  // const options = [
  //   {
  //     title: "Profile and Settings",
  //     icon: userIcon,
  //     onClick: () => {
  //       HandleProfile();
  //     },
  //   },
  //   {
  //     title: "Log Out",
  //     icon: logoutIcon,
  //     onClick: () => {
  //       logOut();
  //     },
  //   },
  // ];

  // const exportData = async () => {
  //   if (props.pageName === "Payouts") {
  //     const workbook = new Excel.Workbook();

  //     const columns = [
  //       { header: 'Exporter', key: 'exporter_name' },
  //       { header: 'Initiated On', key: 'initiated_on' },
  //       { header: 'Payout Reference', key: 'payout_reference' },
  //       { header: 'Invoice Id', key: 'invoice_id' },
  //       { header: 'Gross Payout Total (Currency)', key: 'gross_payout_total_currency' },
  //       { header: 'Gross Payout Breakup (Value)', key: 'gross_payout_breakup' },
  //       { header: 'Gross Payout Total (Value)', key: 'gross_payout_total_value' },
  //       { header: 'Settled Amount Total (Currency)', key: 'settled_amount_total_currency' },
  //       { header: 'Settled Amount Breakup (Value)', key: 'settled_amount_breakup' },
  //       { header: 'Settled Amount Total (Value)', key: 'settled_amount_total_value' },
  //       { header: 'Payment Advice', key: 'payment_advice' },
  //     ];

  //     let data = [];

  //     props?.exportDataList?.forEach((item, ind) => {
        
  //       item?.invoices?.forEach((subItem, index) => {
  //         if (true) {
  //           // const idRow = { 'payout_reference': '', 'invoice_id': `${subItem?.invoiceId}` };
  //           const idRow = {
  //             'exporter_name': `${item?.exporter?.name}`,
  //             'initiated_on': `${dayjs(item?.initiatedOn).format('D MMM YYYY')}`,
  //             'payout_reference': `${item?.payoutReference}`,
  //             'invoice_id': `${subItem?.invoiceId}`,
  //             'gross_payout_total_currency': `${item?.invoiceCurrency?.code}`,
  //             'gross_payout_breakup': `${subItem?.grossAmount?.toFixed(2)}`,
  //             'gross_payout_total_value': `${item?.grossPayout?.toFixed(2)}`,
  //             'settled_amount_total_currency': `${item?.settledAmountCurrency?.code}`,
  //             'settled_amount_breakup': `${subItem?.settledAmount?.toFixed(2)}`,
  //             'settled_amount_total_value': `${item?.settledAmount?.toFixed(2)}`,
  //             'payment_advice': `${item?.firaStatus}`,
  //           }
  //           data.push(idRow);
  //         }
  //       });
  //     });
  
  //     try {
  //       const fileName = "PayoutsReport";
  
  //       const worksheet = workbook.addWorksheet("PayoutsTableData");
  
  //       worksheet.columns = columns;
  
  //       worksheet.getRow(1).fill = {
  //         type: 'pattern',
  //         pattern: 'solid',
  //         fgColor: { argb: 'FF434343' },
  //       };
  
  //       worksheet.getColumn(1).font = { bold: true };
  
  //       worksheet.getRow(1).font = {
  //         color: { argb: 'FFFFFFFF' },
  //         bold: true,
  //       };
  
  //       const columnsToAlignRight = [5, 6, 8, 9];
  
  //       columnsToAlignRight.forEach(columnIndex => {
  //         worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
  //       });

  //       worksheet.getRow(1).alignment = { horizontal: 'left' };
  
  //       worksheet.columns.forEach(column => {
  //         column.width = column.header.length;
  //       });
  
  //       data.forEach(singleData => {
  //         worksheet.addRow(singleData);
  //       });

  //       worksheet.eachRow({ includeEmpty: false }, row => {
  //         const currentCell = row._cells;
  
  //         currentCell.forEach(singleCell => {
  //           const cellAddress = singleCell._address;
  
  //           worksheet.getCell(cellAddress).border = {
  //             top: { style: 'thin' },
  //             left: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             right: { style: 'thin' }
  //           };
  //         });
  //       });
  
  //       const buf = await workbook.xlsx.writeBuffer();
  
  //       saveAs(new Blob([buf]), `${fileName}.xlsx`);
  //     } catch (error) {
  //       console.error('<<<ERRROR>>>', error);
  //       console.error('Something Went Wrong', error.message);
  //     } finally {
  //       workbook.removeWorksheet('MyWorkBook');
  //     }
  //   }
  // }


  return (
    <div className={`${classes.page_intro} ${props.className} w-[100%]`} 
    // style={{marginRight : "42px"}}
    >
      <img
        src={sideBarIcon}
        alt="side-bar-icon"
        className={classes.side_bar}
        onClick={() => dispatch(GlobalActions.setShowSideBarMobile())}
      />
              <div className={classes.pageInfo}>
                <div className={classes.page_name}>{props.pageName}</div>
                <div className={classes.message}>{props.message}</div>
              </div>
      <div className={clsx(classes.button, '-md:flex-col gap-5')}>
        { props.arrBtn?.map((item, index) => (
              <Button
                key={`${item.text}-${index}`}
                text={item.text}
                icon={item.icon}
                // secondicon={item.secondicon}
                disabled={!item.active}
                onClick={() => item.onClick()}
                className={clsx(classes.btn, (item.text === 'Upload Invoices')  && `bg-[#FFF] border border-solid border-[1px] border-[#060a0d1f]`)}
                btnClass={classes.btnClass}
                btnClassIcon={clsx(
                  classes.btnClass_icon,
                  !item.active && 'opacity-50'
                )}
                btnClassText={clsx(
                  classes.btnClass_text,
                  !item.active && 'opacity-50',
                  (item.text === 'Upload Invoices')  && `text-[#000]`,
                )}
              />
          ))
        }
        
        {/* { ((props.pageName === "Customers" || props.pageName === "Payouts" || props.pageName === "Reports") && props.downloadBtn) &&
          <div className={classes.right}>
            <Button
              text={''}
              icon={downloadIcon}
              // disabled={props.loadingDownloadBtn}
              onClick={() => exportData()}
              className={clsx(classes.downloadBtn, props.loadingDownloadBtn ? 'cursor-default' : 'cursor-pointer')}
              btnClassIcon={clsx(
                classes.downloadBtnImg,
                props.loadingDownloadBtn && 'opacity-50'
              )}
            />
          </div>
        } */}
        { props.dontShowBtn === true  &&
            <button ref={props.optRef}
              onClick={() => {
                if(invoiceActionDisplay){
                dispatch(modalActions.invoiceActionDisplay(false))
                }
                else{
                dispatch(modalActions.invoiceActionDisplay(true))
                }
              }}
              className='flex items-stretch relative'
              >
                <div className=""></div>
                <div className={clsx(classes.btnLeftPart, 'flex items-center rounded-s-2xl')}>
                  <img
                      src={arrowDown}
                      alt={`ArrowLeft-icon`}
                      className='h-8 w-8'
                  />
                  <div className={classes.btnFontText} style={{ color: true ? "gray" : "white", fontSize: "1.6rem", lineHeight: "2.4rem" }}>Receive Payment</div>
                </div>
                <div className={clsx(classes.btnRightPart, 'flex items-center rounded-e-2xl')}>
                  <img
                      src={ArrowDown}
                      alt={`ArrowLeft-icon`}
                      className='h-[2.4rem] w-8'
                  />
                </div>
                {
                invoiceActionDisplay && 
                <div  className={clsx(`${classes.receiveDropDown} absolute top-[53px] right-[-5px] bg-[#FFF]`)}>
                   <div className={clsx(classes.dropOption,'border-b-[1px] border-[#ECEFF2]')} onClick={()=>{dispatch(modalActions.receivePayment()); dispatch(modalActions.receivePaymentBulk(false))}} ><img src={PlusIcon} className={classes.dropOptionImg}/> Add Invoice </div>
                   <div className={classes.dropOption} onClick={()=>{dispatch(modalActions.receivePayment()); dispatch(modalActions.receivePaymentBulk(true))}}><img src={UploadInvoicesIcon} className={classes.dropOptionImg}/> Upload Invoice</div>
                </div>
                }
            </button>
        }
      </div>
      {props.downloadBtn && <button className="bg-[#fff] p-[12px] rounded-[8px] border-[1px] border-[rgba(6, 10, 13, 0.12)]" onClick={props.handleExport}>
        <img src={downloadIcon} alt="download" className="w-[24px] h-[24px]" />
      </button>
      }
      {/* <div
        className={clsx(classes.profile, "relative")}
      >
        {/* <img src={adminAvatar} alt="admin-img" className={classes.userImg} /> */}
      </div>
  )
};

export default PageIntro;
