import { createSlice } from "@reduxjs/toolkit";

const globalSliceInitialState = {
    showSideBarMobile : false, 
    showSideBarTablet : false,
    selectedOption : '1',
    scrollEffect : true,
    scrollYValue : 0,
    scrollXValue : 0,
    showTour: false,
    stepNumberInTour: 0,
};

const GlobalSlice = createSlice({
    name : 'global-states',
    initialState : globalSliceInitialState,
    reducers : {
        setShowSideBarMobile(state, action){
            // console.log('sidemobile')
            state.showSideBarMobile = action.payload === undefined ? !state.showSideBarMobile : action.payload;
        },
        setShowSideBarTablet(state, action){
            state.showSideBarTablet = action.payload === undefined ? !state.showSideBarTablet : action.payload;
        },
        setSelectedOption(state,action){
            state.selectedOption = action.payload;
        },
        setScrollEffect(state,action){
            state.scrollEffect = action.payload.scrollEffect;
        },
        setScrollYValue(state,action){
            state.scrollYValue = action.payload;
        },
        setScrollXValue(state,action){
            state.scrollXValue = action.payload;
        },
        setShowTour(state, action){
            state.showTour = action.payload;
        },
        setStepNumberInTour(state, action){
            state.stepNumberInTour = action.payload;
        }
    }
})

export const GlobalActions = GlobalSlice.actions;

export default GlobalSlice;