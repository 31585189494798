import classes from './Error.module.scss';
import Button from "../../Layout/Button"
import leftPointer from '../../assets/Icons/left-pointer.svg'
import { useNavigate, useRouteError } from 'react-router-dom';


const Error = (props) => {
  const error = useRouteError()
  const navigate = useNavigate()

  // console.log("System Error Occurred:", error);

  let status = 404
  let message =
    'The page you were looking for was either removed or doesn’t exist.'
  let btnText = 'Back to Home'

  status = status.toString()
  const statusSuffix = status.slice(-1)
  const statusPrefix = status.substring(0, status.length - 1)

  return (
    <div className={classes.error}>
      <div className={classes.heading}>Error</div>
      <div className={classes.status}>
        {statusPrefix}
        <span className={classes.rotate}>{statusSuffix}</span>
      </div>
      <div className={classes.message}>{props?.message || message}</div>
      <Button
        btnClassIcon={classes.btn_icon}
        icon={leftPointer}
        className={classes.btn}
        text={btnText}
        onClick={() => navigate(`/`)}
      />
      <div className={classes.leftRed}>
        <div className={classes.leftRed_inner}></div>
      </div>
      <div className={classes.leftWhite}></div>
      <div className={classes.rightRed}>
        <div className={classes.rightRed_inner}></div>
      </div>
      <div className={classes.rightWhite}></div>
    </div>
  )
}

export default Error;