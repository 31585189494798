import classes from "./AdminHeader.module.scss"
import fideoLogo from "../../assets/Images/FideoLogo.svg";
import adminICon from "../../assets/Icons/account.svg"
import downIcon from "../../assets/Icons/chevron-down.svg"
import logoutIcon from "../../assets/Icons/logoutIcon.svg"
import useClickOutside from "../../hooks/useClickOutside";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UseApiCall from "../../hooks/useApiCall";
import { apiSignOut } from "../../services";
import {useDispatch, useSelector} from "react-redux";
import {LoginActions} from "../../Store/auth-redux";

const AdminHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutOpen, setLogoutOpen] = useState(false);
  const userName = useSelector(state => state?.login?.userName);

  const [logOut, loggingOut] = UseApiCall(apiSignOut, () => {
      dispatch({type: 'CLEAR_STATE'})
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      localStorage.removeItem('exp')
      // localStorage.removeItem('cookie')
      props?.setIsLogin(false)
      navigate("/login?mode=email");
  });


  const optRef = useClickOutside(() => {
    setLogoutOpen(false)
  });

    const storedUsername = localStorage.getItem('username');
    const storedUserId = localStorage.getItem('userid');

    if (storedUsername !== null) {
        const firstName = storedUsername.split(' ')[0]; // Extract the first name
        const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1); // Capitalize the first letter
        dispatch(LoginActions.setUserName(formattedFirstName));
        dispatch(LoginActions.setUserId(storedUserId));
    }

    return (
        <div className={`${classes.adminHeader} ${props.className} !z-[100]`}>
           <div className={classes.fideoLogo}>
             <img src={fideoLogo} alt="fideo-logo" />
           </div>
           <div ref={optRef}>
            <div className="flex items-center mr-[4.4rem] relative">
                <div className={classes.adminView}>Viewing as: <p className=" ml-[10px]">Admin</p>
                  {/* <div className={classes.adminView}>Admin</div> */}
                </div>
                <p className="mr-[20px]">|</p>
                <div className="flex items-center cursor-pointer" onClick={()=>setLogoutOpen(!logoutOpen)}>
                    <img src={adminICon} alt="admin-icon" className="mr-[8px]"/>
                    <div className="text-[14px] flex items-center">
                        <p>{userName}</p>
                        <img src={downIcon} className="ml-[4px]"/>
                     </div>
                </div>
              {logoutOpen && 
                  <div className="flex bg-[#fff] flex-col py-[8px] w-[260px] rounded-[12px] absolute top-[48px] right-[0px] z-[10]"
                    style={{boxShadow : "0px 4px 48px 0px rgba(30, 51, 63, 0.16)"}}
                       onClick={()=> logOut()}
                  >
                    <div className="flex py-[16px] px-[20px] items-center gap-[12px] w-[100%] cursor-pointer">
                      <img src={logoutIcon} className="w-[24px] h-[24px]" />
                      <div className="text-[#1E333F] text-[16px] leading-[24px] font-[500]"
                      >Logout</div>
                    </div>
                  </div>
              }
            </div>
           </div>
        </div>
    )
}

export default AdminHeader