import classes from './Action.module.scss'
import actionIcon from "../../assets/Icons/action-icon.svg"
import { useState } from 'react'
import useClickOutside from '../../hooks/useClickOutside'

const Action = (props) => {
    const [actionDisplay, setActionDisplay] = useState(false)
    const optRef = useClickOutside(() => {
        setActionDisplay(false)
    });
    return (
        <>
        <div className={classes.action} ref={optRef}>
            <div className='flex items-center justify-center' onClick={()=>setActionDisplay(!actionDisplay)}>
                <img className="w-[19.5px] h-[31.5px] min-w-[19.5px]" src={actionIcon}  />
            </div>
            {actionDisplay && <div className='flex flex-col py-[7.2px] absolute w-[234px] z-10 rounded-[10.8px] bg-[#FFF] right-[20px] top-[-1.2rem] ' style={{ boxShadow : " 0px 0.4rem 3.2rem rgba(30, 51, 63, 0.12)" }}>
                {
                    props.options.map((item)=>{
                        return <div className={classes.optionDiv} onClick={item.onClick}>
                            {item?.icon && <img src={item?.icon} />}
                            <div>{item?.text}</div>
                        </div>
                    })
                }
            </div>}
        </div>
        </>
    )
}

export default Action