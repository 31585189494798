import Modal from '../../../Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import classes from './ComplianceChecks.module.scss'
import leftPointer from 'assets/Icons/chevron-left.svg'
import alert from 'assets/Icons/alert-circleNew.svg'
import remakIcon from 'assets/Icons/remakIcon.svg'
import downPointer from 'assets/Icons/chevron-downNew.svg'
import React, { useEffect, useRef, useState } from 'react'
import {Pagination, Tooltip} from "antd";
import CompTooltip from "./CompTooltip";
import useClickOutside from "../../../hooks/useClickOutside";
import HitDetailsModalComp from "./HitDetailsModalComp";
import Shimmer from "../../../Layout/Shimmer";
import UseApiCall from "../../../hooks/useApiCall";
import {apiGetSearchComp, apiUpdateComp, apiUpdateCompRiskLevel, apiUpdateRiskLevelHit} from "../../../services";
import {useSnackbar} from "notistack";
import CommentsBox from "./CommentsBox";
import CustomModal from "../../atom/CustomModal";
import { showVerificationModalRemarks} from "../../../utils/layout";
import dayjs from "dayjs";
import ComplianceStatusTag from "../../atom/ComplianceStatusTag";


const SearchDetailsModalComp = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    const [tooltip, setTooltip] = useState(false);
    const [hitIndex, setHitIndex] = useState(null);
    const [hitSelectArray, setHitSelectArray] = useState({});
    const [hitSelectArrLen, setHitSelectArrLen] = useState();
    // const [riskLevel, setRiskLevel] = useState(props?.SearchData?.riskLevel);
    // const [matchStatus, setMatchStatus] = useState(props.SearchData?.matchStatus);
    const [remarks, setRemarks] = useState("");
    const [riskLevelOpenMulti, setRiskLevelOpenMulti] = useState(false);
    const [matchStatusOpenMulti, setMatchStatusOpenMulti] = useState(false);

    const [riskLevelOpen, setRiskLevelOpen] = useState(false);
    const [matchStatusOpen, setMatchStatusOpen] = useState(false);
    const [commentsOpen, setCommentsOpen] = useState(false);
    const [hitData, setHitData] = useState(null);
    const [dobArray, setDobArray] = useState(null);
    const [countryArray, setCountryArray] = useState(null);
    const [typeCountArray, setTypeCountArray] = useState(null);
    const [groupedStringsResult, setGroupedStringsResult] = useState(null);
    const [entities, setEntities] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(20);
    // const [prevPage, setPrevPage] = useState();
    const showHitComp = useSelector((state) => state.modal.showHitComp)
    const showCommentBox = useSelector((state) => state.modal.showCommentBox);

    const customerDetailModalRef = useRef(null);
    // const tooltipRef = useRef(null);

    const tooltipRef = useClickOutside(() => setTooltip(false));
    const riskLevelRef = useClickOutside(() => setRiskLevelOpen(false));
    const matchStatusRef = useClickOutside(() => setMatchStatusOpen(false));
    const riskLevelRefMulti = useClickOutside(() => setRiskLevelOpenMulti(false));
    const matchStatusRefMulti = useClickOutside(() => setMatchStatusOpenMulti(false));
    const [modalRef, setModalRef] = useState(null);

    const handlePaginate = (page)=>{
        // console.log('page',page)

        // if(end < props.SearchData?.totalHits)
        setStart((page - 1) * 20)
        setEnd((page) * 20)

        const section = document.getElementById('sectionId');
        if (section) {
            section.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    const handleHitCall = (hit,index,dobArray,countryArray,typeCountArray,groupedStringsResult) =>{
        // console.log('hit index',index)
        setHitIndex(index)
        setHitData(hit);
        setDobArray(dobArray);
        setCountryArray(countryArray);
        setTypeCountArray(typeCountArray);
        setGroupedStringsResult(groupedStringsResult);
        dispatch(modalActions.setShowHitComp(true))
    }

    const handleRiskLevel = () => {
        setRiskLevelOpen(!riskLevelOpen)
        setMatchStatusOpen(false)
    }

    const addRemarks = (level) => {
        props.setRiskLevel(level)
        updateCompliance({
            searchId:props.searchId,
            riskLevel : level,
            remarks : remarks,
        })
    }

    const handleRiskLevelSelect = (level) => {
        setRiskLevelOpen(false)
        setMatchStatusOpen(false)

        showVerificationModalRemarks(modalRef, {
            action: "riskLevel",
            searchId : props.searchId,
            updateCompliance : updateCompliance,
            docId : props.searchId,
            level: level,
        })
    }

    const handleMatchStatusSelect = (status) => {
        setRiskLevelOpen(false)
        setMatchStatusOpen(false)

        showVerificationModalRemarks(modalRef, {
            action: "matchStatus",
            searchId : props.searchId,
            updateCompliance : updateComplianceMatchStatus,
            docId : props.searchId,
            status: status
        })
    }

    const handleRemarks = () => {
        showVerificationModalRemarks(modalRef, {
            action: "remarks",
            searchId : props.searchId,
            updateCompliance : updateRemarks,
            docId : props.searchId,
        })
    }

    const handleMatchStatus = () => {
        setMatchStatusOpen(!matchStatusOpen)
        setRiskLevelOpen(false)
    }

    const handleRiskLevelMulti = () => {
        setRiskLevelOpenMulti(!riskLevelOpenMulti)
        setMatchStatusOpenMulti(false)
        setRiskLevelOpen(false)
        setMatchStatusOpen(false)
    }

    const handleRiskLevelSelectMulti = (level) => {
        const trueKeys = Object.entries(hitSelectArray)
            .filter(([key, value]) => value === true)
            .map(([key, value]) => key);

        updateRiskLevel(
            {
                doc_id : trueKeys,
                search_id:props.searchId,
                risk_level:level || null
            }
        )
    }

    const handleMatchStatusSelectMulti = (status) => {
        const trueKeys = Object.entries(hitSelectArray)
            .filter(([key, value]) => value === true)
            .map(([key, value]) => key);

        updateMatchStatus(
            {
                doc_id : trueKeys,
                search_id:props.searchId,
                match_status: status || null,
            }
        )
    }

    const handleMatchStatusMulti = () => {
        setMatchStatusOpenMulti(!matchStatusOpenMulti)
        setRiskLevelOpenMulti(false)
        setRiskLevelOpen(false)
        setMatchStatusOpen(false)
    }

    const handleHitSelection = (e,hit) => {
        e.stopPropagation()
        const { name, checked } = e.target;
        setHitSelectArray({...hitSelectArray, [name]:checked})
        const isAnySelect = Object.values(hitSelectArray)?.filter(value => value === true);
    }

    // const handleComments = () => {
    //     // setMatchStatusOpen(!matchStatusOpen)
    //     // setCommentsOpen(!commentsOpen)
    //     dispatch(modalActions.setShowCommentBox(true))
    // }
    const ParseData = async (SearchData) => {
        // console.log('typeof',typeof SearchData?.data?.data)
        if(typeof SearchData?.data?.data === "string"){
            const myObject = SearchData?.data?.data ? await JSON.parse(SearchData?.data?.data) : null;
            const myRemarks = SearchData?.data?.comment ? await JSON.parse(SearchData.data.comment) : null;

            const filteredRemarks = myRemarks && myRemarks?.filter(remark => remark?.remark && remark?.remark.trim() !== '');

            if(filteredRemarks !== null)
                filteredRemarks?.sort((a, b) => b?.createdAt - a?.createdAt);

            if (filteredRemarks !== null || filteredRemarks?.length > 0) {
                const latestRemark = filteredRemarks[0];
                // console.log('latestRemark',latestRemark)
                setRemarks(latestRemark);
            } else {
                setRemarks(null);
            }

            setEntities(myObject)

            if (hitIndex >= 0 && hitIndex < myObject?.hits?.length) {
                const selectedHit = myObject?.hits[hitIndex];
                setHitData(selectedHit);
            }
        }

    }

    const [getSearchDetails, fetchingSearch,SearchData] = UseApiCall(apiGetSearchComp, (res) => {
        ParseData(res)
    })

    useEffect(() => {
        if(props.searchId)
            getSearchDetails({ searchId: props.searchId})
    }, []);

    const [updateRemarks, fetchingRemarks,RemarksData] = UseApiCall(apiUpdateCompRiskLevel, (res) => {
            enqueueSnackbar('Remarks updated successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )
            getSearchDetails({ searchId: props.searchId})
            props.getComplianceDetails({ customerId: props?.customerId})
        },
        (err)=>{
            enqueueSnackbar('Error while updating remarks.',
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    const [updateCompliance, fetchingUpdateCom,UpdateComplianceData] = UseApiCall(apiUpdateCompRiskLevel, (res) => {
            // console.log(res?.data)
            enqueueSnackbar('Risk level updated successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )
            getSearchDetails({ searchId: props.searchId})
            props.getComplianceDetails({ customerId: props?.customerId})
        },
        (err)=>{
            // console.log('err11',err)
            enqueueSnackbar('Error while updating risk level.',
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    const [updateComplianceMatchStatus, fetchingUpdateComMatchStatus,UpdateComplianceDataMatchStatus] = UseApiCall(apiUpdateComp, (res) => {
            // console.log(res?.data)

            enqueueSnackbar('Match status updated successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )
            getSearchDetails({ searchId: props.searchId})
            props.getComplianceDetails({ customerId: props?.customerId})
        },
        (err)=>{
            // console.log('errhvdb',err)
            enqueueSnackbar('Error while updating match status.',
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    const [updateRiskLevel, fetchingRiskLevel,UpdateRiskLevelData] = UseApiCall(apiUpdateRiskLevelHit, (res) => {
            // console.log(res?.data)

            enqueueSnackbar('Risk level updated successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )
            setHitSelectArray([])
            getSearchDetails({ searchId: props.searchId})
            // props.getComplianceDetails({ customerId: props?.customerId})
        },
        (err)=>{
            // console.log('err11',err)
            setHitSelectArray([])
            enqueueSnackbar('Error while updating risk level.',
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    const [updateMatchStatus, fetchingMatchStatus,UpdateMatchStatusData] = UseApiCall(apiUpdateRiskLevelHit, (res) => {
                // console.log(res?.data)

                enqueueSnackbar('Match status updated successfully.',
                    {
                        variant: 'success' ,
                        anchorOrigin: { horizontal: 'center', vertical: 'top' },
                        autoHideDuration: 3000, // Specify the duration in milliseconds
                        style: { fontSize: '10px' }
                    }
                )
                setHitSelectArray([])
                getSearchDetails({ searchId: props.searchId})
                // props.getComplianceDetails({ customerId: props?.customerId})
            },
            (err)=>{
                // console.log('err11',err)
                setHitSelectArray([])
                enqueueSnackbar('Error while updating match status.',
                    {
                        variant: 'error',
                        anchorOrigin: { horizontal: 'center', vertical: 'top' },
                        autoHideDuration: 3000, // Specify the duration in milliseconds
                        style: { fontSize: '10px' }
                    })
            }
        )

    // console.log('UpdateComplianceData',UpdateComplianceData);
    // console.log('UpdateComplianceDataMatchStatus',UpdateComplianceDataMatchStatus);
    // console.log('matchStatus',props.matchStatus);
    // console.log('props.fetchingSearch',props.fetchingSearch);
    // console.log('remarks',remarks);
    // console.log('SearchData',SearchData?.data);

    return (
        <>
            {
                showHitComp &&
                ReactDOM.createPortal(
                    <HitDetailsModalComp
                        hitData={hitData}
                        searchId={props.searchId}
                        SearchData={SearchData?.data}
                        getSearchDetails={getSearchDetails}
                        dobArray={dobArray}
                        countryArray={countryArray}
                        typeCountArray={typeCountArray}
                        groupedStringsResult={groupedStringsResult}
                        fetchingUpdateCom={fetchingUpdateCom}
                    />,
                    document.getElementById('modal-root')
                )
            }

            {
                showCommentBox &&
                ReactDOM.createPortal(
                    <CommentsBox/>,
                    document.getElementById('modal-root')
                )
            }
            <Modal
                className={clsx(
                    classes.modal,
                    `bg-[#F3F6FA] z-[40] p-[0px] overflow-scroll`
                )}
            >
                <CustomModal ref={setModalRef}/>
                <div
                    ref={customerDetailModalRef}
                    className={clsx(classes.topHeader, "flex items-start justify-between border-b-[1px] border-[#E8EAEF] px-[20px] md:px-[60px] py-[20px] bg-[#FFFFFF]")}
                >
                    {/*left*/}
                    <div className="flex gap-[16px]">
                        <div
                            className="flex items-center justify-center w-[48px] h-[48px] rounded-[100px] px-[12px] py-[8px] bg-[#1E333F14] cursor-pointer"
                            onClick={() => {
                                dispatch(modalActions.setShowSearchDataComp(false))
                            }}
                        >
                            <img src={leftPointer} style={{width: "48px", height: "48px"}}/>
                        </div>


                        <div className="flex-start flex-col gap-[4px]">
                            <p className="font-inter font-[700] text-[16px] leading-[20px] text-[#1E333F]">{SearchData?.data?.searchTerm ? SearchData?.data?.searchTerm : "--"}</p>
                            <div className="flex items-start md:items-center gap-[4px]">
                                <div
                                    className="flex flex-col items-start md:items-center justify-center md:flex-row gap-[4px]">
                                    <p className="font-inter font-[500] text-[14px] leading-[16px] text-[#1E333F66]">Search
                                        Reference</p>
                                    <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">{SearchData?.data?.searchId ? SearchData?.data?.searchId : "--"}</p>
                                </div>

                                <div
                                    ref={tooltipRef}
                                    className="relative cursor-pointer"
                                    onClick={() => setTooltip(!tooltip)}
                                >
                                    <img src={alert} style={{width: "24px", height: "24px"}}/>
                                    {tooltip &&
                                        <CompTooltip
                                            SearchData={SearchData?.data}
                                        />
                                    }
                                </div>

                            </div>
                        </div>

                    </div>

                    {/*right*/}
                    <div className="flex gap-[16px] relative">
                        <div className="flex flex-col gap-[4px] relative cursor-pointer"
                             onClick={handleRiskLevel}
                        >
                            {/*Risk Level*/}
                            <div className="flex px-[12px] py-[0px] absolute top-[-8px] left-[10px] ">
                                <div className="flex px-[4px] py-[0px] bg-[#FFFFFF]">
                                    <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#1E333F80]">Risk
                                        Level</p>
                                </div>
                            </div>
                            <div
                                className="flex min-w-[140px] justify-between gap-[10px] border-[1px] border-[#D2D6D9] rounded-[8px] px-[16px] py-[12px]  "
                                // style={{width: SearchData?.data?.riskLevel === "UNKNOWN" ? '140px' : '120px'}}
                            >
                                {(fetchingUpdateCom )
                                    ?
                                    [1]?.map((item, index) => (
                                        <Shimmer key={index} className="w-[100%] h-[20px] rounded-[8px] mt-[0px]"/>
                                    ))
                                    :
                                    <div
                                        className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#1E333F]">
                                        {SearchData?.data?.riskLevel ? SearchData?.data?.riskLevel?.slice(0, 1)?.toUpperCase() + SearchData?.data?.riskLevel?.slice(1, SearchData?.data?.riskLevel?.length)?.toLowerCase() : ' '}
                                    </div>
                                }

                                <img src={downPointer} className=""/>
                            </div>
                        </div>
                        {riskLevelOpen &&
                            (<div
                                className="flex min-w-[140px] flex-col gap-[10px] bg-[#FFFFFF] shadow-lg rounded-[8px] px-[16px] py-[16px] absolute top-[52px] left-[0px] z-[100]"
                                style={{
                                    // width: SearchData?.data?.riskLevel === "UNKNOWN" ? '140px' : '120px',
                                      // left: props.SearchData?.riskLevel === "UNKNOWN" ? '0px' : '0px',
                            }}
                                  ref={riskLevelRef}
                            >
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(LOW)=>handleRiskLevelSelect('LOW')}
                                >
                                    LOW</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(MEDIUM)=>handleRiskLevelSelect('MEDIUM')}
                                >
                                    MEDIUM</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(HIGH)=>handleRiskLevelSelect('HIGH')}
                                >
                                    HIGH</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(UNKNOWN)=>handleRiskLevelSelect('UNKNOWN')}
                                >
                                    UNKNOWN</p>
                            </div>)
                        }

                        <div className="flex gap-[4px] relative cursor-pointer"
                             onClick={handleMatchStatus}
                        >
                            {/*match status*/}
                            <div className="flex px-[12px] py-[0px] absolute top-[-8px] left-[10px] ">
                                <div className="flex px-[4px] py-[0px] bg-[#FFFFFF]">
                                    <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#1E333F80]">Match
                                        Status</p>
                                </div>
                            </div>
                            <div
                                className="flex min-w-[200px] justify-between  border-[1px] border-[#D2D6D9] rounded-[8px] px-[16px] py-[12px]  "
                                // style={{width: SearchData?.data?.matchStatus === "POTENTIAL_MATCH" ? '210px' : '150px'}}
                            >
                                {(fetchingUpdateComMatchStatus)
                                    ?
                                    [1]?.map((item, index) => (
                                        <Shimmer key={index} className="w-[100%] h-[20px] rounded-[8px]"/>
                                    ))
                                    :
                                    <div
                                        className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#1E333F]">
                                    <ComplianceStatusTag status={SearchData?.data?.matchStatus} type="light"/>
                                    </div>
                                }
                                <img src={downPointer} className=""/>
                            </div>
                        </div>
                        {matchStatusOpen &&
                            (<div className="flex min-w-[220px] flex-col gap-[10px] bg-[#FFFFFF] shadow-lg rounded-[8px] px-[16px] py-[16px] absolute top-[52px] left-[155px] z-[100]"
                                  style={{
                                      // width: SearchData?.data?.matchStatus === "POTENTIAL_MATCH" ? '210px' : '150px',
                                      // left: SearchData?.data?.riskLevel === "UNKNOWN" ? '155px' : '135px',
                            }}
                                  ref={matchStatusRef}
                            >
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('NO_MATCH')}
                                >NO MATCH</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('POTENTIAL_MATCH')}
                                >POTENTIAL MATCH</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('UNKNOWN')}
                                >UNKNOWN</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('FALSE_POSITIVE')}
                                >FALSE POSITIVE</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('TRUE_POSITIVE')}
                                >TRUE POSITIVE</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('TRUE_POSITIVE_REJECT')}
                                >TRUE POSITIVE - REJECT</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={()=>handleMatchStatusSelect('TRUE_POSITIVE_APPROVE')}
                                >TRUE POSITIVE - APPROVE</p>
                            </div>)
                        }

                        {/*comments*/}
                        {/*<div*/}
                        {/*    className="flex gap-[8px] px-[16px] py-[12px] bg-[#FFFFFF] border-[1px] border-[#060A0D] rounded-[8px] cursor-pointer"*/}
                        {/*    onClick={handleComments}*/}
                        {/*>*/}
                        {/*    <img src={comment}/>*/}
                        {/*    <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#1E333F]">Comments (2)</p>*/}
                        {/*</div>*/}

                    </div>

                </div>

                <div className="flex flex-col gap-[24px] px-[20px] md:px-[60px] py-[20px] bg-[#F3F6FA]"
                     id={'sectionId'}
                >
                    {(remarks !== null ) &&
                        <div className='flex justify-center '>
                        <div className='flex justify-center items-center bg-[#DBDFE7] rounded-tl-[8px] rounded-bl-[8px] px-[4px]'>
                            <img className='w-[20px] h-[20px]' src={remakIcon} alt='quote-icon'/>
                        </div>
                        <div className='flex py-[4px] px-[16px] items-center justify-between bg-[#EAEDF3] rounded-tr-[8px] rounded-br-[8px] w-[100%]'>
                            <div className='flex items-center gap-[8px]'>
                                <div className='text-#1E333F text-[14px] font-[400] leading-[20px]'>
                                    { `Remarks${remarks?.remark ?`(${ dayjs(remarks?.createdAt).local().format(`D MMM YYYY`) })`: ""}` }: <span className='text-[#1e333f80]'>{ remarks?.remark } </span>
                                </div>
                            </div>
                            <div className='text-#1E333F text-[14px] ml-[12px] font-medium leading-20 underline cursor-pointer'
                                 onClick={()=>handleRemarks(SearchData?.data?.riskLevel)}
                            >
                                Edit
                            </div>
                        </div>
                    </div>}

                    <div className={clsx(classes.result, "flex items-start md:items-center justify-between")}>
                        <p className="font-inter font-[600] text-[14px] md:text-[16px] leading-[20px] text-[#1E333F]">{entities?.hits?.length !== undefined ? entities?.hits?.length + " Results" : "0 Results"}</p>

                        {Object.values(hitSelectArray)?.filter(value => value === true)?.length > 0 &&
                            (<div className="flex gap-[16px] relative">
                                <div className="flex flex-col gap-[4px] relative cursor-pointer"
                                     onClick={handleRiskLevelMulti}
                                >
                                    {/*Risk Level*/}
                                    <div className="flex px-[12px] py-[0px] absolute top-[-8px] left-[10px] ">
                                        <div className="flex px-[4px] py-[0px] bg-[#F3F6FA]">
                                            <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#1E333F80]">Risk
                                                Level</p>
                                        </div>
                                    </div>
                                    <div
                                        className="flex min-w-[140px] justify-between gap-[10px] border-[1px] border-[#D2D6D9] rounded-[8px] px-[16px] py-[12px]  "
                                        // style={{width: SearchData?.data?.riskLevel === "UNKNOWN" ? '140px' : '120px'}}
                                    >
                                        {(fetchingRiskLevel)
                                            ?
                                            [1]?.map((item, index) => (
                                                <Shimmer key={index}
                                                         className="w-[100%] h-[20px] rounded-[8px] mt-[0px]"/>
                                            ))
                                            :
                                            <div
                                                className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#1E333F]">
                                                {SearchData?.data?.riskLevel ? SearchData?.data?.riskLevel?.slice(0, 1)?.toUpperCase() + SearchData?.data?.riskLevel?.slice(1, SearchData?.data?.riskLevel?.length)?.toLowerCase() : ' '}
                                            </div>
                                        }

                                        <img src={downPointer} className=""/>
                                    </div>
                                </div>
                                {riskLevelOpenMulti &&
                                    (<div
                                        className="flex min-w-[140px] flex-col gap-[10px] bg-[#FFFFFF] shadow-lg rounded-[8px] px-[16px] py-[16px] absolute top-[52px] left-[0px]"
                                        style={{
                                            // width: SearchData?.data?.riskLevel === "UNKNOWN" ? '140px' : '120px',
                                            // left: props.SearchData?.riskLevel === "UNKNOWN" ? '0px' : '0px',
                                        }}
                                        ref={riskLevelRefMulti}
                                    >
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={(LOW) => handleRiskLevelSelectMulti('LOW')}
                                        >
                                            LOW</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={(MEDIUM) => handleRiskLevelSelectMulti('MEDIUM')}
                                        >
                                            MEDIUM</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={(HIGH) => handleRiskLevelSelectMulti('HIGH')}
                                        >
                                            HIGH</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={(UNKNOWN) => handleRiskLevelSelectMulti('UNKNOWN')}
                                        >
                                            UNKNOWN</p>
                                    </div>)
                                }

                                <div className="flex gap-[4px] relative cursor-pointer"
                                     onClick={handleMatchStatusMulti}
                                >
                                    {/*match status*/}
                                    <div className="flex px-[12px] py-[0px] absolute top-[-8px] left-[10px] ">
                                        <div className="flex px-[4px] py-[0px] bg-[#F3F6FA]">
                                            <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#1E333F80]">Match
                                                Status</p>
                                        </div>
                                    </div>
                                    <div
                                        className="flex min-w-[210px] justify-between  border-[1px] border-[#D2D6D9] rounded-[8px] px-[16px] py-[12px]  "
                                        // style={{width: SearchData?.data?.matchStatus === "POTENTIAL_MATCH" ? '210px' : '150px'}}
                                    >
                                        {(fetchingMatchStatus)
                                            ?
                                            [1]?.map((item, index) => (
                                                <Shimmer key={index} className="w-[100%] h-[20px] rounded-[8px]"/>
                                            ))
                                            :
                                            <div
                                                className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#1E333F]">
                                                <ComplianceStatusTag status={SearchData?.data?.matchStatus} type="light"/>
                                            </div>
                                        }
                                        <img src={downPointer} className=""/>
                                    </div>
                                </div>
                                {matchStatusOpenMulti &&
                                    (<div
                                        className="flex min-w-[210px] flex-col gap-[10px] bg-[#FFFFFF] shadow-lg rounded-[8px] px-[16px] py-[16px] absolute top-[52px] left-[155px]"
                                        style={{
                                            // width: SearchData?.data?.matchStatus === "POTENTIAL_MATCH" ? '210px' : '150px',
                                            // left: SearchData?.data?.riskLevel === "UNKNOWN" ? '155px' : '135px',
                                        }}
                                        ref={matchStatusRefMulti}
                                    >
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('NO_MATCH')}
                                        >NO MATCH</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('POTENTIAL_MATCH')}
                                        >POTENTIAL MATCH</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('UNKNOWN')}
                                        >UNKNOWN</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('FALSE_POSITIVE')}
                                        >FALSE POSITIVE</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('TRUE_POSITIVE')}
                                        >TRUE POSITIVE</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('TRUE_POSITIVE_REJECT')}
                                        >TRUE POSITIVE - REJECT</p>
                                        <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                           onClick={() => handleMatchStatusSelectMulti('TRUE_POSITIVE_APPROVE')}
                                        >TRUE POSITIVE - APPROVE</p>
                                    </div>)
                                }
                            </div>)
                        }
                    </div>

                    <div
                        className={clsx(classes.shadowCustom, "flex flex-col justify-center rounded-[12px] p-[20px] bg-[#FFFFFF]")}>
                        {(false)
                            ?
                            [1, 2, 3, 4]?.map((item, index) => (
                                <Shimmer key={index} className="w-[100%] h-[180px] rounded-[8px] mb-[16px]"/>
                            ))
                            :
                            entities?.hits?.length > 0 &&
                            (<div className="grid grid-cols-1 gap-[16px] min-h-fit xl:grid-cols-2">
                                {/*detail box*/}
                                {entities?.hits && entities?.hits?.slice(start, end)?.map((hit, index) => {
                                    let dobArray = '';
                                    const countryArray = [];

                                    hit?.doc?.fields?.forEach(field => {
                                        if (field.name === "Date of Birth") {
                                            // dobArray.push(field.value);
                                            dobArray = field.value.split('-')
                                        } else if (field.name === "Country") {
                                            if (!countryArray.includes(field.value))
                                                countryArray.push(field.value);
                                        }
                                    });

                                    const typeCounts = {};

                                    hit?.doc?.types.forEach(type => {
                                        typeCounts[type] = (typeCounts[type] || 0) + 1;
                                    });

                                    const typeCountArray = Object.keys(typeCounts).map(key => ({
                                        type: key,
                                        count: typeCounts[key]
                                    }));

                                    // console.log('hit?.doc?.types',hit?.doc?.types)

                                    // const result = hit?.doc?.types.map(type => type.startsWith("adverse-media-v2") ? type.slice("adverse-media-v2".length + 1) : type.startsWith("pep-class") ? type.slice("pep".length + 1) : type);
                                    //
                                    // console.log('result',result);

                                    const groupedStrings = {};

                                    hit?.doc?.types.forEach(str => {
                                        const prefix = str.split("-")[0]; // Extract the prefix
                                        if (!groupedStrings[prefix]) {
                                            groupedStrings[prefix] = [];
                                        }
                                        groupedStrings[prefix].push(str);
                                    });

                                    const groupedStringsResult = Object.values(groupedStrings);

                                    // console.log('groupedStringsResult',groupedStringsResult);


                                    return (<div
                                            className="flex flex-col  rounded-[12px] p-[20px] gap-[12px] border-[1px] border-[#ECEFF2]
                                                        hover:cursor-pointer hover:bg-[#F3F4F6] hover:border-[black]"
                                            onClick={() => handleHitCall(hit, index, dobArray, countryArray, typeCountArray, groupedStringsResult)}
                                            key={index}
                                        >
                                            {/*top*/}
                                            {/*<div className="flex items-center md:justify-between ">*/}
                                            <div
                                                className={clsx(classes.status, "flex items-start justify-between  gap-[8px]")}>
                                                <div className='flex items-start justify-between  gap-[8px]'>
                                                    <p className="font-inter font-[600] text-[14px] md:text-[16px] leading-[20px] text-[#1E333F]">{hit?.doc?.name}</p>
                                                    {hit?.doc?.match_status &&
                                                        <ComplianceStatusTag status={hit?.doc?.match_status} type="light"/>
                                                    }
                                                </div>
                                                <div
                                                    className="w-[15px] h-[15px]"
                                                    // onClick={(e) => handleHitSelction(e, hit)}
                                                    >
                                                    <input
                                                        type={"checkbox"}
                                                        className={classes.dropdownCheckbox}
                                                        name={hit?.doc?.id}
                                                        checked={hitSelectArray[hit?.doc?.id] || false}
                                                        onClick={(e) => handleHitSelection(e, hit)}
                                                    />
                                                </div>
                                                {/*{hit?.doc?.match_status &&*/}
                                                {/*    <PaymentStatusTag status={hit?.doc?.match_status} type="light"/>*/}
                                                {/*}*/}
                                            </div>
                                            {/*<div className="flex flex-col">*/}
                                            {/*</div>*/}
                                            {/*</div>*/}

                                            {/*bottom*/}
                                            <div className="flex flex-col">

                                                {/*Appears on*/}
                                                <div
                                                    className={clsx(classes.detail, "flex items-start justify-between py-[12px]")}>
                                                    <p className="font-inter min-w-[100px] font-[400] text-[12px] leading-[16px] text-[#1E333F66]">APPEARS
                                                        ON</p>
                                                    <div
                                                        className="flex flex-wrap justify-start items-end md:justify-end gap-[10px] py-[2px]"
                                                    >
                                                        {groupedStringsResult &&
                                                            groupedStringsResult.map((group, groupIndex) => (
                                                                <div key={groupIndex}
                                                                     className="flex flex-col gap-[5px]">
                                                                    <Tooltip
                                                                        title={
                                                                            ((group[0] === 'adverse-media' && group?.length > 2) || (group[0] !== 'adverse-media' && group?.length > 1)) &&
                                                                            <div>
                                                                                {(group[0] === 'adverse-media' || group[0] === 'pep') ?
                                                                                    group.slice(1, group?.length).map((item, index) => (
                                                                                        <div
                                                                                            key={index}
                                                                                            className="px-[15px] py-[2px] text-[12px]"
                                                                                        >
                                                                                            {item &&
                                                                                                (() => {
                                                                                                    let itemModified = '';
                                                                                                    if(item?.startsWith("adverse-media-v2")){
                                                                                                        itemModified = item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ")
                                                                                                    }else{
                                                                                                        itemModified = item?.split('-').join(" ")
                                                                                                    }


                                                                                                    const capitalized = itemModified
                                                                                                        .replace(
                                                                                                            /\b\w/g,
                                                                                                            (char) =>
                                                                                                                char.toUpperCase()
                                                                                                        )
                                                                                                        .replace(
                                                                                                            /\bAml\b/g,
                                                                                                            "AML"
                                                                                                        )
                                                                                                        .replace(
                                                                                                            /\bCft\b/g,
                                                                                                            "CFT"
                                                                                                        );
                                                                                                    return capitalized.replace(
                                                                                                        /Aml|Cft/g,
                                                                                                        (abbr) =>
                                                                                                            abbr.toUpperCase() + "/"
                                                                                                    )
                                                                                                })()}
                                                                                        </div>
                                                                                    )) :

                                                                                    group?.map((item, index) => (
                                                                                        <div
                                                                                            key={index}
                                                                                            className="px-[15px] py-[2px] text-[12px]"
                                                                                        >
                                                                                            {item &&
                                                                                                (() => {
                                                                                                    let itemModified = '';
                                                                                                    if(item?.startsWith("adverse-media-v2")){
                                                                                                        itemModified = item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ")
                                                                                                    }else{
                                                                                                        itemModified = item?.split('-').join(" ")
                                                                                                    }


                                                                                                    const capitalized = itemModified
                                                                                                        .replace(
                                                                                                            /\b\w/g,
                                                                                                            (char) =>
                                                                                                                char.toUpperCase()
                                                                                                        )
                                                                                                        .replace(
                                                                                                            /\bAml\b/g,
                                                                                                            "AML"
                                                                                                        )
                                                                                                        .replace(
                                                                                                            /\bCft\b/g,
                                                                                                            "CFT"
                                                                                                        );
                                                                                                    return capitalized.replace(
                                                                                                        /Aml|Cft/g,
                                                                                                        (abbr) =>
                                                                                                            abbr.toUpperCase() + "/"
                                                                                                    )
                                                                                                })()}
                                                                                        </div>
                                                                                    ))


                                                                                }
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <div>
                                                                            {(group?.length === 2 && group[0] === 'adverse-media') ?
                                                                                group.slice(1, 2).map((item, index) => (
                                                                                    <div key={index}>
                                                                                        <div className="flex items-center min-w-fit gap-[4px] border-[1px] border-[#FFACC0] justify-between rounded-[100px] pl-[8px] pt-[2px] pr-[2px] pb-[2px]">
                                                                                            <p className="font-inter font-[400] text-[11px] leading-[16px] text-[#1E333F] uppercase">
                                                                                                {item ? (item?.startsWith("adverse-media-v2") ?  item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ") : item?.split('-').join(" ")) : "--"}
                                                                                            </p>
                                                                                            <div className="flex items-center justify-center gap-[10px] px-[5px] bg-[#FFE8EE] w-[16px] h-[16px] rounded-[100px]">
                                                                                                <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">
                                                                                                    {(group[0] === 'adverse-media' || group[0] === 'pep') && group.length > 1 ? group.length - 1 : group.length}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                :
                                                                                group.slice(0, 1).map((item, index) => (
                                                                                    <div key={index}>
                                                                                        <div className="flex items-center min-w-fit gap-[4px] border-[1px] border-[#FFACC0] justify-between rounded-[100px] pl-[8px] pt-[2px] pr-[2px] pb-[2px]">
                                                                                            <p className="font-inter font-[400] text-[11px] leading-[16px] text-[#1E333F] uppercase">
                                                                                                {item ? item.split("-").join(" ") : "--"}
                                                                                            </p>
                                                                                            <div className="flex items-center justify-center gap-[10px] px-[5px] bg-[#FFE8EE] w-[16px] h-[16px] rounded-[100px]">
                                                                                                <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">
                                                                                                    {(group[0] === 'adverse-media' || group[0] === 'pep') && group.length > 1 ? group.length - 1 : group.length}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>

                                                <div className="w-[100%] h-[1px] bg-[#ECEFF2]"></div>

                                                {/*dob*/}
                                                <div
                                                    className={clsx(classes.detail, "flex items-start  md:items-center justify-between py-[12px]")}>
                                                    <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F66]">DOB</p>
                                                    <div
                                                        className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">
                                                        {dobArray?.length !== 0 ?
                                                            (dobArray[2] ? new Date(dobArray).getDate() + ' ' : '') +
                                                            (dobArray[1] ? new Date(dobArray).toLocaleString('default', {month: 'short'}) + ' ' : '') +
                                                            (dobArray[0] ? new Date(dobArray).getFullYear() : '')
                                                            : "--"}
                                                    </div>
                                                </div>


                                                <div className="w-[100%] h-[1px] bg-[#ECEFF2]"></div>

                                                {/*recency*/}
                                                {/*<div className="flex items-center justify-between py-[12px]">*/}
                                                {/*    <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#1E333F66]">RECENCY</p>*/}
                                                {/*    <div*/}
                                                {/*        className="font-inter font-[500] text-[14px] leading-[20px] text-[#1E333F]">{'Lorem ipsum'}</div>*/}
                                                {/*</div>*/}

                                                {/*countries*/}
                                                <div className={clsx(classes.detail,"flex items-start justify-between py-[12px]")}>
                                                    <p className="font-inter min-w-[100px] font-[400] text-[12px] leading-[16px] text-[#1E333F66]">COUNTRIES</p>
                                                    <div
                                                        className="flex flex-wrap justify-start items-start md:justify-end gap-[4px] ">
                                                        {countryArray?.length ? countryArray?.map((country, indexC) =>
                                                                (
                                                                    <div
                                                                        className=""
                                                                        key={indexC}
                                                                    >
                                                                        <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">{`${country} ${indexC < countryArray?.length - 1 ? ' , ' : ''}`}</p>
                                                                    </div>
                                                                ))
                                                            :
                                                            <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">{`--`}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>)}

                        {props.SearchData?.totalHits === 0 &&
                            <div className="flex items-center justify-center">
                                <p className="font-inter font-[600] text-[16px] leading-[20px] text-[#1E333F]"
                                >
                                    No Results Found
                                </p>
                            </div>
                        }
                        {/*pagination*/}
                        {
                            entities?.hits?.length > 0 &&
                            <div className={clsx(classes.paginationWrap,"mt-[30px] mb-[10px]")}>
                                <Pagination
                                    // defaultCurrent={1}
                                    total={entities?.hits?.length}
                                    showSizeChanger={false}
                                    // current={props?.currentPage + 1}
                                    pageSize={20}
                                    // defaultPageSize={4}
                                    // itemRender={itemRender}
                                    onChange={handlePaginate}
                                />
                            </div>
                        }
                    </div>


                </div>

            </Modal>
        </>
    )
}

export default SearchDetailsModalComp
