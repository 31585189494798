import classes from './InvoiceDetailsModalHeader.module.scss'
import Button from '../../../Layout/Button'
import clsx from 'clsx'
import useClickOutside from '../../../hooks/useClickOutside'
import { useState } from 'react'
import {
  showPaymentLinkModal,
} from '../../../utils/layout'
import CustomModal from '../../atom/CustomModal/CustomModal'

const InvoiceDetailsPaymentRail = (props) => {
  const { amountDetail, settledWidthPercentage, inProgressWidthPercentage, pendingWidthPercentage, widthPercentage } = props;
  const [modalRef, setModalRef] = useState(null);
  const [showOptions, setShowOptions] = useState(false)
  const optRef = useClickOutside(() => setShowOptions(false))
  const handleClick = () => {
    showPaymentLinkModal(modalRef, {
      invoiceId: props.invoiceId,
      paymentLink: props.paymentLink,
      organizationId: props.organizationId
    })
  }

  return (
    <div ref={optRef} className={``}>
      <CustomModal ref={setModalRef} />
      <div className={clsx(classes.mainRail)}>
        <div className={clsx('flex mb-[20px]')}>
          <div className={clsx('h-[4px] bg-[#94E165]')} style={{ width: `${settledWidthPercentage}%` }}></div>
          <div className={clsx('h-[4px] bg-[#1E333F80]')} style={{ width: `${inProgressWidthPercentage}%` }}></div>
          <div className={clsx('h-[4px] bg-[#FF5151]')} style={{ width: `${100 - Number(settledWidthPercentage) - Number(inProgressWidthPercentage)}%`}}></div>
        </div>
        <div className={clsx('flex items-center justify-between')}>
          {amountDetail && amountDetail?.length > 0 && amountDetail.map((item, index) => (
            <div key={index} className={clsx('flex', `${index === 0 ? 'w-[30%]' : 'w-[20%]'}`)}>
              {
                item?.circle !== "no" &&
                <div className={clsx(classes.lineHeight, 'bg-[#DEE5EB] w-[1px] mr-[16px]')}>
                </div>
              }
              <div>
                <div className={clsx(classes.priceTextInvoiceRail, `${!index && classes.priceTextInvoiceRailMain}`)}>
                  {item?.value?.includes('undefined') ? '--' : item?.value}
                </div>
                <div className={clsx(classes.priceTextSubHead, 'flex items-center', !index && 'font-bold')}>
                  {index > 0 && <div className={clsx('rounded-full h-[8px] w-[8px] mr-[6px]')} style={{ backgroundColor: item?.circle }}></div>}
                  {item?.text}
                </div>
              </div>
            </div>
          ))}
          <Button
            text={"Remind"}
            // icon={item.icon}
            disabled={!props.buttonActive}
            onClick={handleClick}
            className={clsx('!border !border-solid !border-[1px] !border-[#060A0D]', '!bg-[#FFF] rounded-[6px] h-[28px] !px-[8px] !py-[4px]')}
            btnClass={clsx(!props.buttonActive && '!cursor-not-allowed !opacity-50')}
            // btnClassIcon={clsx(
            //   classes.btnClass_icon
            // )}
            btnClassText={clsx(
              classes.btnClass_text, false ? 'text-[#FFF]' : '!text-[#000]', '!text-[14px]'
            )}
          />
        </div>
        <div className={clsx('h-[1px] w-[100%] bg-[#DEE5EB] mt-[20px] mb-[48px]')}></div>
      </div>

      <div className={clsx(classes.hiddenOnLarge)}>
        {/* <div className={clsx('flex flex-col rounded-lg overflow-hidden')}>
          <div className='flex'>
            <div className={clsx('h-[6px] bg-[#94E165]')} style={{ width: "50%" }}></div>
            <div className={clsx('h-[6px] bg-[#FF5151]')} style={{ width: "50%"}}></div>
          </div>
          <div className='h-[18px] w-[100%] bg-[#FF5151]'></div>
        </div> */}
        {/* <div className='flex relative top-[7px]'>
          <div className={clsx(`h-[6px] w-full bg-[#94E165]`, widthPercentage === 100 ? 'rounded-t-2xl' : 'rounded-tl-2xl')} style={{ width: `${widthPercentage}%` }}></div>
          <div className={clsx(`h-[6px] w-full bg-[#FF5151]`, widthPercentage === 0 ? 'rounded-t-2xl' : 'rounded-tr-2xl')} style={{ width: `${100 - Number(widthPercentage)}%`}}></div>
        </div> */}
        <div className='flex relative top-[7px]'>
          <div className={clsx(`h-[6px] w-full bg-[#94E165]`, (inProgressWidthPercentage === 0 && (100 - Number(settledWidthPercentage) - Number(inProgressWidthPercentage)) === 0) ? 'rounded-t-2xl' : 'rounded-tl-2xl')} style={{ width: `${settledWidthPercentage}%` }}></div>
          <div className={clsx(`h-[6px] w-full bg-[#1E333F80]`, settledWidthPercentage === 0 && 'rounded-tl-2xl', (100 - Number(settledWidthPercentage) - Number(inProgressWidthPercentage)) === 0 && 'rounded-tr-2xl')} style={{ width: `${inProgressWidthPercentage}%` }}></div>
          <div className={clsx(`h-[6px] w-full bg-[#FF5151]`, (settledWidthPercentage === 0 && inProgressWidthPercentage === 0) ? 'rounded-t-2xl' : 'rounded-tr-2xl')} style={{ width: `${100 - Number(settledWidthPercentage) - Number(inProgressWidthPercentage)}%`}}></div>
        </div>

        <div className={clsx(classes.railBox, '')}>
          <div className={clsx('flex w-[100%]')}>
            {amountDetail && amountDetail?.length > 0 && amountDetail.map((item, index) => (
              index <= 1 && (
                <div className={clsx('w-[100%] h-[48px]')}>
                  <div key={index} className={clsx('flex justify-between items-center w-[100%]')}>
                      <div className={clsx(classes.priceTextSubHead, 'flex flex-col w-[100%] gap-[4px]', index % 2 ? 'items-end' : 'items-start')}>
                        <div className={clsx(classes.priceTextSubHead, 'text-[12px]', !index && 'font-bold', 'flex items-center')}>
                        { index > 0 && <div className={clsx('rounded-full h-[8px] w-[8px] mr-[6px]')} style={{ backgroundColor: item?.circle }}></div>}{item?.text}
                        </div>
                        <div className={clsx(classes.priceTextInvoiceRail, `${!index && classes.priceTextInvoiceRailMain}`)}>
                          {item?.value?.includes('undefined') ? '--' : item?.value}
                        </div>
                      </div>
                  </div>
                </div>
              )
            ))}
          </div>
          {<div className={clsx('h-[1px] my-[16px] w-[100%] bg-[#DEE5EB]')}></div>}
          <div className={clsx('flex w-[100%]')}>
            {amountDetail && amountDetail?.length > 0 && amountDetail.map((item, index) => (
              index > 1 && (
                <div className={clsx('w-[100%] h-[48px]')}>
                  <div key={index} className={clsx('flex justify-between items-center w-[100%]')}>
                      <div className={clsx(classes.priceTextSubHead, 'flex flex-col w-[100%] gap-[4px]', index % 2 ? 'items-end' : 'items-start')}>
                        <div className={clsx('flex items-center')}>
                          { index > 0 && <div className={clsx('rounded-full h-[8px] w-[8px] mr-[6px]')} style={{ backgroundColor: item?.circle }}></div>}
                          <div className={clsx(classes.priceTextSubHead, 'text-[12px]', !index && 'font-bold')}>
                            {item?.text}
                          </div>
                        </div>
                        <div className={clsx(classes.priceTextInvoiceRail, `${!index && classes.priceTextInvoiceRailMain}`)}>
                          {item?.value?.includes('undefined') ? '--' : item?.value}
                        </div>
                      </div>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>

      {/* <div className={clsx()}>
        <div className={clsx('flex relative top-[7px]')}>
          <div className={clsx('h-[6px] bg-[#94E165]')} style={{ width: `${widthPercentage}%` }}></div>
          <div className={clsx('h-[6px] bg-[#FF5151]')} style={{ width: `${100 - Number(widthPercentage)}%`}}></div>
        </div>
        <div className={clsx(classes.railBox, 'z-50 relative')}>
          {amountDetail && amountDetail?.length > 0 && amountDetail.map((item, index) => (
            <div className={clsx('w-[100%] h-[48px] pt-[12px]')}>
              <div key={index} className={clsx('flex justify-between items-center h-[24px] w-[100%]')}>
                  <div className={clsx(classes.priceTextSubHead, 'flex items-center')}>
                    {index !== 0 && <div className={clsx('rounded-full h-[8px] w-[8px] mr-[6px]')} style={{ backgroundColor: item?.circle }}></div>}
                    {item?.text}
                  </div>
                  <div className={clsx(classes.priceTextInvoiceRail, `${!index && classes.priceTextInvoiceRailMain}`)}>
                    {item?.value?.includes('undefined') ? '--' : item?.value}
                  </div>
              </div>
              {(index + 1 !== amountDetail?.length) && <div className={clsx('h-[1px] mt-[12px] w-[100%] bg-[#DEE5EB]')}></div>}
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className={clsx(
            classes.cell_action,
            'block md:hidden'
          )} style={{ borderRadius: "8px", border: "0px solid rgba(24, 28, 48, 0.20)", background: "#FFF", padding: "0px", position: "absolute", right: "20px" }}>
            <Action
              option={
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'in review' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'link sent' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'in draft' ||
                invoice?.invoice_status?.replace(/_/g, ' ')?.toLowerCase() === 'created'
                  ? optionArr
                  : [...optionArr.slice(0, 1)]
              }
              cellData={invoice}
            />
          </div>
      <div
        className={classes.profile}
        onClick={() => setShowOptions((pre) => !pre)}
      >
        <div
          className={clsx(
            'absolute top-32 right-[12px] min-w-[200px] z-10 bg-bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700',
            showOptions ? 'block' : 'hidden'
          )}
        >
          <ul
            className="py-2 w-full text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {options?.map((option) => (
              <li onClick={option.onClick}>
                <p className="block cursor-pointer w-full text-center px-8 py-4 text-2xl hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {option.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
    </div>
  )
}

export default InvoiceDetailsPaymentRail
