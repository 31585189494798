import clsx from "clsx";
import classes from "./ComplianceChecks.module.scss";
import Modal from "../../../Layout/Modal";
import {useDispatch} from "react-redux";
import useClickOutside from "../../../hooks/useClickOutside";
import {modalActions} from "../../../Store/modal-redux";
import ReactDOM from "react-dom";
import Backdrop from "../../../Layout/Backdrop";
import crossImg from '../../../assets/Icons/crossNew.svg'
import arrowLeft from '../../../assets/Icons/leftBack.svg'

import React from "react";

const CommentBox = () => {
    const dispatch = useDispatch();
    // const [showOptions, setShowOptions] = useState(false);
    const optRef = useClickOutside(()=>dispatch(modalActions.setShowCommentBox(false)));

    return(
        <div ref={optRef}>
            <Modal className={clsx(
                classes.modal,
                'p-0 min-w-[100px] max-w-[100px] z-[100] bg-[#FFFFFF] bottom-0 !pb-[20px]'
            )}
            >
                {
                    ReactDOM.createPortal(
                        <Backdrop
                            onClick={() => {
                                dispatch(modalActions.setShowCommentBox(false))
                            }}
                            className={classes.backdrop}
                        />,
                        document.getElementById('backdrop-root')
                    )
                }

                <div className="flex items-center justify-start w-[100%] ">
                    <div className="flex flex-col gap-[32px] w-[100%]">
                        {/*top*/}
                        <div className="flex items-center justify-between w-[100%] max-h-[68px] pt-[20px] "
                             onClick={()=>dispatch(modalActions.setShowCommentBox(false))}
                        >
                            <div className="flex items-center max-h-[48px] gap-[12px] cursor-pointer">
                                <div className="flex items-center justify-center bg-[#1E333F1F] rounded-[4px] w-[24px] h-[24px] ">
                                    <img src={arrowLeft}/>
                                </div>
                                <p className="font-inter text-[16px] font-[600] leading-[48px] text-center text-[#1E333F]">Search Comments (2)</p>
                            </div>

                            <img src={crossImg} className="w-[32px] h-[32px] cursor-pointer"/>
                        </div>

                        {/*bottom*/}
                        <div className="w-[100%]"></div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CommentBox;