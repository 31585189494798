import ReactDOM from 'react-dom'
import classes from './InvoicesTable.module.scss'
// import sorting from 'assets/Images/sorting-icon.svg'
import ReportTableLayout from '../../../Layout/ReportTableLayout'
import eye from '../../../assets/Images/eye.svg'
import edit from '../../../assets/Images/edit.svg'
// import moreInfo from 'assets/Images/moreInfo.svg'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import MobileInvoiceTable from './MobileInvoiceTable'
import clsx from 'clsx'
import PaymentStatusTag from '../../../ReportTable/PaymentStatusTag'
import Action from '../../ReportTable/Action'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'
import {Tooltip} from 'antd';
import UseApiCall from '../../../hooks/useApiCall'
import { apiGetInvoiceDetails } from '../../../services'
import { CustomersActions, ReceivablesActions } from '../../../Store/customer-redux'
import { Pagination } from 'antd'
import { enqueueSnackbar } from 'notistack'
// import EditInvoice from "Components/Invoice/EditInvoice";
import { formatNumberToLocaleString } from '../../../hooks/formatNumberToLocaleString';
import Shimmer from "../../../Layout/Shimmer";
import InvoiceDetailsModal from "../InvoiceDetailsModal/InvoiceDetailsModal";

const InvoicesTable = (props) => {
    // console.log(props)


    const dispatch = useDispatch()
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)
    const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);

    const getTime = useCallback(
        (date) => {
            const invoiceDate = dayjs(date)
            const now = dayjs()
            return now.diff(invoiceDate, 'days')
        },
        [props]
    )
    const optionArr = [
        {
            text: 'View',
            icon: eye,
            active: true,
            onClick: (cellData) => {
                if (cellData?.status?.toLowerCase() !== "in draft") handleInvoiceSelection(cellData?.id);
            },
        },
        {
            text: 'Edit',
            icon: edit,
            // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
            onClick: (cellData) => {
                // if (cellData?.status?.toLowerCase() === 'in draft')
                {
                    dispatch(modalActions.editInvoice())
                    setSelectedInvoiceId(cellData?.id)
                }
            },
        },
    ]

    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [activeTab, setActiveTab] = useState('details')

    const showInvoiceDisplay = useSelector((state) => state.modal.showInvoiceDisplay)


    const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
        dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
        dispatch(CustomersActions.setSelectedInvoice(res?.data))
        setSelectedInvoice(res?.data);
        dispatch(CustomersActions.setActiveInvoiceTab('details'))
        dispatch(modalActions.showInvoiceDisplay())
    }, (err) => {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const handleInvoiceSelection = async (invoiceId) => {
        await getSelectedInvoice({ invoiceId , organizationId:props.organizationId })
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>
        }
        if (type === 'next') {
            return <a>Next</a>
        }
        return originalElement
    }

    const [current, setCurrent] = useState(1);
    const handlePaginate = (page) => {
        setCurrent(page);
        props.handlePaginate(page - 1)
    };

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            }
            else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {/*{editInvoiceDisplay &&*/}
            {/*    ReactDOM.createPortal(*/}
            {/*        <EditInvoice*/}
            {/*            selectedInvoiceId={selectedInvoiceId}*/}
            {/*            nextPageFunctionality={() => dispatch(modalActions.removeAll())}*/}
            {/*        />,*/}
            {/*        document.getElementById("modal-root")*/}
            {/*    )}*/}

            {
                showInvoiceDisplay &&
                selectedInvoice &&
                ReactDOM.createPortal(
                    <InvoiceDetailsModal
                        activeTab={activeTab}
                        invoice={selectedInvoice}
                        organizationId={props.organizationId}
                        mobileTableData
                    />,
                    document.getElementById('modal-root')
                )
            }
            <div className={classes.allWrap} >
                <div className={clsx(`${classes.table}  ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                    <thead>
                    {props.tableHeader.map((header, index) => (
                        <th key={`${header}-${index}`} className={classes.cell_header1}
                            style={{width:`${header.width}%`}}  valign="top"
                        >
                            <div className={classes.cell_header2} >
                                <div className={classes.head}>{header.title}</div>
                                {/* <img
                        src={sorting}
                        alt="sorting-icon"
                        className={classes.sorting}
                      /> */}
                            </div>
                        </th>
                    ))}
                    <th className={classes.cell_header1}
                        style={{width:`20%`}} valign="top"
                    >
                        <div className={`${classes.cell_header2}`}  >
                            <div className={classes.head}>{'ESTD. Savings'}</div>
                            {/* <img
                      src={sorting}
                      alt="sorting-icon"
                      className={classes.sorting}
                    /> */}
                        </div>
                    </th>
                    <th className={classes.cell_header1}
                        style={{width:`10%`}} valign="top"
                    >
                        <div
                            className={`${classes.cell_header2} ${classes.actionHeader}`}
                        >
                            <div className={`${classes.head}`}>Action</div>
                        </div>
                    </th>
                    </thead>
                </div>
                <div className={`${classes.reportTableWrap} `} id="tbody"  ref={tbodyRef}>
                    <ReportTableLayout className={clsx(classes.table,"!mt-[0px]")}>
                        <tbody className={classes.tbody}>
                        {props.loading
                            ?
                            [1,3,4]?.map((item, index) => (
                                <Shimmer key={index} className="w-[910%] h-[80px] mb-[12px]"/>
                            ))
                            :

                                props.tableData && props.tableData?.length > 0 && props.tableData.map((data) => (
                             <tr key={data.id} className={classes.row}>
                                <td
                                    className={`${classes.cell}`}
                                    // style={{
                                    //   userSelect: 'none',
                                    //   cursor: 'pointer',
                                    // }}
                                    style={{width: '15%'}} valign="top"
                                    onClick={() => {
                                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id)
                                        else {
                                            dispatch(modalActions.editInvoice())
                                            setSelectedInvoiceId(data?.id)
                                        }
                                    }}
                                >
                                    {data?.createdTime ? dayjs(data?.createdTime).format('D MMM YYYY') : "--"}
                                </td>
                                <td
                                    className={`${classes.cell} ${classes.invoice}`}
                                    style={{
                                        userSelect: 'none',
                                        cursor: 'pointer',
                                        width: '19%',
                                        // minWidth: '112.26px'
                                    }}
                                    valign="top"
                                    onClick={() => {
                                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id)
                                        else {
                                            dispatch(modalActions.editInvoice())
                                            setSelectedInvoiceId(data?.id)
                                        }
                                    }}
                                >
                                    {data?.invoiceId ? data.invoiceId : "--"}
                                </td>
                                <td className={classes.cell}
                                    style={{width: '20%'}} valign="top"
                                    onClick={() => {
                                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id)
                                        else {
                                            dispatch(modalActions.editInvoice())
                                            setSelectedInvoiceId(data?.id)
                                        }
                                    }}
                                >
                                    <div className={classes.amount}>
                                        {data?.invoiceCurrency?.sign +
                                            formatNumberToLocaleString((data.invoiceAmount), 2, 2)}
                                    </div>

                                    <div className={classes.amount}>
                                        {data?.conversionInvoiceCurrency ? (
                                            <span>
                            {data?.convertedInvoiceAmount && `${data?.conversionInvoiceCurrency.sign}${data?.convertedInvoiceAmount?.toLocaleString(
                                'en-IN'
                            )}`}
                          </span>
                                        ) : ('')}
                                    </div>
                                    {/* <div className={classes.amount}>{`${
                        data.conversionInvoiceCurrency?.sign
                      }${data.convertedInvoiceAmount?.toLocaleString(
                        'en-IN'
                      )}`}</div> */}
                                </td>
                                <td className={classes.cell}
                                    style={{width: '20%'}} valign="top"
                                    onClick={() => {
                                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id)
                                        else {
                                            dispatch(modalActions.editInvoice())
                                            setSelectedInvoiceId(data?.id)
                                        }
                                    }}
                                >
                                    <PaymentStatusTag status={data.status} type="light"/>
                                    <div className={classes.text}>
                                        {getTime(data.lastUpdatedTime)} day
                                        {getTime(data.lastUpdatedTime) > 1 ? 's' : ''} ago
                                    </div>
                                </td>
                                <td className={classes.cell}
                                    style={{width: '20%'}} valign="top"
                                    onClick={() => {
                                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id)
                                        else {
                                            dispatch(modalActions.editInvoice())
                                            setSelectedInvoiceId(data?.id)
                                        }
                                    }}
                                >
                                    {
                                        data?.savingAmount
                                            ?
                                            <Tooltip
                                                title={'Estimated Savings'}
                                                placement="bottom"
                                            >
                                                {(data?.savingCurrency?.sign === "₹" ? (data?.savingCurrency?.sign + data?.savingAmount?.toLocaleString('en-IN', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })) : (data?.savingCurrency?.sign + data?.savingAmount?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })))}
                                            </Tooltip>
                                            :
                                            '--'
                                    }
                                </td>
                                <td className={classes.cell}
                                    style={{width: '10%'}} valign="top"
                                >
                                    <div className={classes.cell_action}>
                                        <Action option={optionArr} cellData={data}/>
                                    </div>
                                </td>
                            </tr>
                        ))
                        }
                        {(props.tableData && props?.tableData.length === 0 && !props.loading )  ?
                            (<div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop: '4rem',
                                    fontSize: '2.5rem'
                                }}
                                className={classes.table}
                            >
                                No invoice created yet !
                            </div>) :
                                null
                        }
                        </tbody>
                    </ReportTableLayout>

                        </div>
                {props.tableData && props.tableData?.length > 0 && <div className="-md:hidden w-full flex flex-row justify-end mt-[16px] p-[16px]">
                    <Pagination showSizeChanger={false} current={current} total={props?.pages} itemRender={itemRender} onChange={handlePaginate} />
                </div>}
            </div>
            <MobileInvoiceTable
                tableData={props.mobileTableData}
                option={optionArr}
            />
            {props.tableData && props.tableData?.length > 0 && <div className="md:hidden w-full flex flex-row justify-end mt-[16px]">
                <Pagination showSizeChanger={false} current={current} total={props?.pages} itemRender={itemRender} onChange={handlePaginate} />
            </div>}

        </>
    )
}

export default InvoicesTable
