import { createSlice } from "@reduxjs/toolkit";

const modalInitialState = {
  payoutAmountInfo:false,
  exporterProfileInfo:false,
  searchDetails: false,
  showInvoiceDisplay: false,
  showFileDisplay: false,
  showBankDetails:false,
  showUserDetails:false,
  receivePaymentDisplay: false,
  receivePaymentBulkDisplay: false,
  sendPaymentDisplay: false,
  createInvoiceDisplay: false,
  backdropDisplay: false,
  addContactDisplay: false,
  addContactOrganizationDisplay: false,
  addPaymentDetailDisplay: false,
  showPaymentDisplay: false,
  showCustomerDisplay: false,
  editInvoiceDisplay: false,
  fileDisplayUrl: "",
  bulkUploaderDisplay: false,
  invoiceActionDisplay: false,
  invoiceActionDisplayMwebRec: false,
  showBankDropDown:false,
  invoiceTooltipDisplay: false,
  reconcileModalDisplay: false,
  purposeCodeDropDown: false,
  addNewUserDisplay: false,
  invoiceViewRecon: false,
  tourStart:false,
  downloadFiraMobile:false,
  mobileInvoiceSuccess:false,
  createInvoiceModal:false,
  createInvoiceModalRec:false,
  createInvoiceModalRecDraft:false,
  createInvoiceReceivePayment:false,
  createInvoiceReceivePaymentRec:false,
  createInvoiceReceivePaymentRecDraft:false,
  showPreviewDisplay: false,
  showDraftEditInvoice:false,
  showComplianceCustomSearch:false,
  showSearchDataComp:false,
  showHitComp:false,
  showCommentBox:false,
}

export const ModalSlice = createSlice({
  name: 'modal-slice',
  initialState: modalInitialState,
  reducers: {
    // payoutAmountInfo(state, action) {
    //   state.payoutAmountInfo = action.payload;
    // },
    exporterProfileInfo(state, action) {
      state.exporterProfileInfo = action.payload;
    },
    setSearchDetails(state, action) {
      state.searchDetails = action.payload;
    },
    showInvoiceDisplay(state) {
      state.showInvoiceDisplay = !state.showInvoiceDisplay;
    },
    showFileDisplay(state) {
      state.showFileDisplay = !state.showFileDisplay;
    },
    showUserDetails(state, action) {
      state.showUserDetails = action.payload
    },
    receivePayment(state) {
      state.receivePaymentDisplay = !state.receivePaymentDisplay
    },
    receivePaymentBulk(state, action) {
      state.receivePaymentBulkDisplay = action.payload === undefined ? !state.receivePaymentBulkDisplay : action.payload
    },
    sendPayment(state) {
      state.sendPaymentDisplay = !state.sendPaymentDisplay
    },
    createInvoice(state) {
      state.createInvoiceDisplay = !state.createInvoiceDisplay
    },
    backdrop(state) {
      state.backdropDisplay = !state.backdropDisplay
    },
    addContact(state) {
      state.addContactDisplay = !state.addContactDisplay
    },
    addContactOrganization(state) {
      state.addContactOrganizationDisplay = !state.addContactOrganizationDisplay
    },
    addPaymentDetail(state) {
      state.addPaymentDetailDisplay = !state.addPaymentDetailDisplay
    },
    showPaymentDisplay(state) {
      state.showPaymentDisplay = !state.showPaymentDisplay
    },
    showCustomerDisplay(state) {
      state.showCustomerDisplay = !state.showCustomerDisplay;
    },
    editInvoice(state) {
      state.editInvoiceDisplay = !state.editInvoiceDisplay;
    },
    fileDisplayUrl(state, action) {
      state.fileDisplayUrl = action.payload;
    },
    bulkUploaderDisplay(state, action) {
      state.bulkUploaderDisplay = action.payload;
    },
    invoiceActionDisplay(state, action) {
      state.invoiceActionDisplay = action.payload;
    },
    invoiceActionDisplayMwebRec(state, action) {
      state.invoiceActionDisplayMwebRec = action.payload;
    },
    showBankDropDown(state, action) {
      state.showBankDropDown = action.payload===undefined?!state.showBankDropDown:action.payload ;
    },
    invoiceTooltipDisplay(state, action) {
      state.invoiceTooltipDisplay = action.payload;
    },
    reconcileModalDisplay(state, action) {
      state.reconcileModalDisplay = action.payload;
    },
    purposeCodeDropDown(state, action) {
      state.purposeCodeDropDown = action.payload;
    },
    addNewUserDisplay(state, action) {
      state.addNewUserDisplay = action.payload;
    },
    invoiceViewRecon(state, action) {
      state.invoiceViewRecon = action.payload;
    },
    tourStart(state, action){
      state.tourStart = action.payload;
    },
    downloadFiraMobile(state, action) {
      state.downloadFiraMobile = action.payload;
    },
    mobileInvoiceSuccess(state, action) {
      state.mobileInvoiceSuccess = action.payload;
    },
    payoutAmountInfo(state, action) {
      state.payoutAmountInfo = action.payload;
    },
    setCreateInvoiceModal(state, action) {
      state.createInvoiceModal = action.payload;
    },
    setCreateInvoiceModalRec(state, action) {
      state.createInvoiceModalRec = action.payload;
    },
    setCreateInvoiceModalRecDraft(state, action) {
      state.createInvoiceModalRecDraft = action.payload;
    },
    setCreateInvoiceReceivePayment(state, action) {
      state.createInvoiceReceivePayment = action.payload;
    },
    setCreateInvoiceReceivePaymentRec(state, action) {
      state.createInvoiceReceivePaymentRec = action.payload;
    },
    setCreateInvoiceReceivePaymentRecDraft(state, action) {
      state.createInvoiceReceivePaymentRecDraft = action.payload;
    },
    setShowPreviewDisplay(state,action) {
      state.showPreviewDisplay = action.payload;
    },
    setShowDraftEditInvoice(state,action) {
      state.showDraftEditInvoice = action.payload;
    },
    setShowComplianceCustomSearch(state,action) {
      state.showComplianceCustomSearch = action.payload;
    },
    showBankDetails(state, action){
      state.showBankDetails = action.payload;
    },

    setShowSearchDataComp(state,action) {
      state.showSearchDataComp = action.payload;
    },

    setShowHitComp(state,action) {
      state.showHitComp = action.payload;
    },

    setShowCommentBox(state,action) {
      state.showCommentBox = action.payload;
    },

    
    removeAll(state) {
      state.payoutAmountInfo = false
      state.exporterProfileInfo = false
      state.searchDetails = false
      state.showInvoiceDisplay = false
      state.showFileDisplay = false
      state.backdropDisplay = false
      state.receivePaymentDisplay = false
      state.sendPaymentDisplay = false
      state.createInvoiceDisplay = false
      state.addContactDisplay = false
      state.addContactOrganizationDisplay = false
      state.addPaymentDetailDisplay = false
      state.showPaymentDisplay = false
      state.editInvoiceDisplay = false
      state.fileDisplayUrl = ""
      state.bulkUploaderDisplay = false
      state.invoiceActionDisplay = false
      state.invoiceActionDisplayMwebRec = false
      state.showBankDropDown = false
      state.invoiceTooltipDisplay = false
      state.reconcileModalDisplay = false
      state.purposeCodeDropDown = false
      state.addNewUserDisplay = false
      state.showUserDetails = false
      state.invoiceViewRecon = false
      state.tourStart = false
      state.downloadFiraMobile = false
      state.mobileInvoiceSuccess = false
      state.payoutAmountInfo = false
      state.createInvoiceModal = false
      state.createInvoiceModalRec = false
      state.createInvoiceModalRecDraft = false
      state.createInvoiceReceivePayment = false
      state.createInvoiceReceivePaymentRec = false
      state.createInvoiceReceivePaymentRecDraft = false
      state.showPreviewDisplay = false
      state.showDraftEditInvoice = false
      state.receivePaymentBulk = false
      state.showCustomerDisplay = false
      state.showComplianceCustomSearch = false
      state.showBankDetails = false
      state.showSearchDataComp = false
      state.showHitComp = false
      state.showCommentBox = false
    },
  },
})

export const modalActions = ModalSlice.actions;
