import classes from "./Customers.module.scss"
import clsx from "clsx"
import PageIntro from "../Layout/PageIntro"
import CustomerReportTable from "../ReportTable/CustomerReportTable";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from "react";
import CustomerFilter from "../Components/atom/customerFilter";
import UseApiCall from "../hooks/useApiCall";
import {
    apiGetCustomers,
    apiVerifyCustomers,
    apiGetFilterExportersList
} from "../services";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

const tableHeader = [

    {
        id: 1,
        title: "Cust. ID",
        width: 20,
        minWidth: 150,
    },
    {
        id: 2,
        title: "customer details",
        width: 20,
        minWidth: 216.8,
    },
    {
        id: 3,
        title: "Exporter",
        width: 10,
        minWidth: 150,
    },
    {
        id: 4,
        title: "Total receivables",
        width: 16.5,
        minWidth: 130,
    },
    {
        id: 5,
        title: "Settled",
        width: 14,
        minWidth: 130,
    },
    {
        id: 6,
        title: "settled outside fideo",
        width: 15,
        minWidth: 130,
    },
    {
        id: 7,
        title: "Pending",
        width: 14,
        minWidth: 130,
    },
];

const Customers = () => {
    const [filteredData, setFilteredData] = useState();
    const [resData, setResData] = useState();
    const [curentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(30);
    const [verifyCust, setVerifyCust] = useState(false);

    const [selectedExporters, setSelectedExporters] = useState({});
    const [showExportersDropdown, setShowExportersDropdown] = useState(false);
    const [exportersList, setExportersList] = useState();
    const [exportersCounter, setExportersCounter] = useState('0');

    const [selectedCustomers, setSelectedCustomers] = useState({});
    const [allSelected, setAllSelected] = useState(true);
    const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [isSearch, setIsSearch] = useState();
    const [customers, setCustomers] = useState();
    const [customerCounter, setCustomerCounter] = useState('0');
    const [statuses, setStatuses] = useState();
    const [selectedStatus, setSelectedStatus] = useState(["verified", "UNDER REVIEW"]);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [allStatusSelected, setAllStatusSelected] = useState(true);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showInvoiceDateDropdown, setShowInvoiceDateDropdown] = useState(false);
    const [showDateCounter, setShowDateCounter] = useState(false);
    const [statusCounter, setStatusCounter] = useState('0');
    const [activeDate, setActiveDate] = useState('all');
    const [selectedDate, setSelectedDate] = useState({
        fromDate: '',
        toDate: ''
    });

    const updateCustomers = useSelector((state) => state.customer.updateCustomers);

    const handlePaginate = (page) => {
        setCurrentPage(page - 1)
    }

    const toggleCustomerDropdown = () => {
        setShowCustomerDropdown(!showCustomerDropdown);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleSearchDropdown = () => {
        setShowSearchDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleCustomerSelection = async (customerId, len) => {
        // setSelectedCustomers(prevSelectedCustomers => {
        //     const updatedSelection = {
        //         ...prevSelectedCustomers,
        //         [customerId]: !prevSelectedCustomers[customerId]
        //     };
        //     const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;

        //     setCustomerCounter(countTrueKeys);
        //     setAllSelected(countTrueKeys === len);
        //     return updatedSelection;
        // });
    };

    const toggleExportersSelection = async (exporterId, len) => {
        setCurrentPage(0)
        setSelectedExporters(prevSelectedExporters => {
            const updatedSelection = {
                ...prevSelectedExporters,
                [exporterId]: !prevSelectedExporters[exporterId]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;

            setCustomerCounter(countTrueKeys);
            setAllSelected(countTrueKeys === len);
            return updatedSelection;
        });
    };

    const toggleStatusDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(!showStatusDropdown);
        setShowInvoiceDateDropdown(false);
    };

    const toggleStatusSelection = (status, len) => {
        // setCurrentPage(1)
        if (status === 'reset') {
            setAllStatusSelected(false)
            setStatusCounter('0')
            return setSelectedStatuses({});
        }
        setSelectedStatuses(prevSelectedStatuses => {
            const updatedSelection = {
                ...prevSelectedStatuses,
                [status]: !prevSelectedStatuses[status]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            setStatusCounter(countTrueKeys)
            setAllStatusSelected(statuses?.length === countTrueKeys)

            return updatedSelection;
        });
    };

    const toggleInvoiceDateDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(!showInvoiceDateDropdown);
    };

    const handleDateSelection = (period) => {
        // setCurrentPage(1)
        setShowDateCounter(period === 'all' ? false : true)
        setActiveDate(period)
        const currentDate = dayjs();
        let fromDate, toDate;

        switch (period) {
            case 'all':
                fromDate = '';
                toDate = '';
                break;
            case 'one':
                fromDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'three':
                fromDate = currentDate.subtract(3, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'six':
                fromDate = currentDate.subtract(6, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            default:
                // Handle default case or throw an error if needed
                break;
        }

        setSelectedDate({
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const getCurrencySign = (status) => {
        if (status === 'ALL' || status === 'USD' || status === 'CAD') {
            return '$'
        }
        else if (status === 'GBP') {
            return '£'
        } else if (status === 'EUR') {
            return '€'
        } else if (status === 'AED') {
            return 'د.'
        }
    }

    const [getCustomerTableData, fetchingtCustomer, CustomerTabledata, Tableerror] = UseApiCall(apiGetCustomers,
        (res) => {
            setFilteredData(res?.data?.content)
            setResData(res?.data)

            const uniqueOrganizationsMap = new Map();

            res?.data?.content?.forEach((organization) => {
                const organizationName = organization.organizationName;
                if (!uniqueOrganizationsMap.has(organizationName)) {
                    uniqueOrganizationsMap.set(organizationName, organization);
                    return true
                } else {
                    return false
                }
            });

            const uniqueOrganizations = Array.from(uniqueOrganizationsMap.values());
            setCustomers(uniqueOrganizations);
            // dispatch(DashboardSummaryActions.setDashboardTableData(res?.data));
        }, (err) => {
            console.log(err)
            // dispatch(DashboardSummaryActions.setShowBackendError(err));
        });

    const [getFilterExportersList, fetchingFilterExportersList, filterExportersListData] = UseApiCall(apiGetFilterExportersList, (res) => {
        setExportersList(res?.data)
        const resultObject = {};
        res?.data.forEach(item => {
            resultObject[item?.organizationId] = true;
        });
        setSelectedExporters(resultObject);
    }, (err) => {
        enqueueSnackbar('Error while fetching Exporters List.', { variant: 'error' })
    })

    const selectedExportersList = Object.keys(selectedExporters).filter(status => selectedExporters[status]);

    useEffect(() => {
        getFilterExportersList('customers');
    }, [])

    const snackbarOptions = {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        action: (key) => (
            <div style={{ fontFamily: 'Inter', fontSize: '8px', fontWeight: 500, color: '#FFF' }}>
                Customer verified successfully.
            </div>
        )
    };

    const [verifyCustomer, fetchingCost, ComplianceData] = UseApiCall(apiVerifyCustomers, (res) => {
        setVerifyCust(true)
        enqueueSnackbar('', snackbarOptions);

        // enqueueSnackbar('Customer verified successfully.',
        //     { variant: 'success' , anchorOrigin: { horizontal: "center", vertical: "top" },
        //         // style={ fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, color: '#FFF' }
        //     }
        // )
        // dispatch(CustomersActions.setSelectedCustomer(res?.data))
        // setSelectedCustomer(res?.data)
        // !showCustomerDisplay && dispatch(modalActions.showCustomerDisplay())
    })

    const handleVerify = (data) => {
        verifyCustomer({ customerId: data?.id, remark: '' })
    }

    useEffect(() => {
        if (!fetchingFilterExportersList && Object.keys(selectedExporters)?.length) {
            let params = {
                pageNum: curentPage,
                size: pageSize,
            }
            if (Object.keys(selectedExporters)?.length !== selectedExportersList?.length) {
                params.organizationIds = selectedExportersList;
            }
            getCustomerTableData(params);
        }
    }, [curentPage, pageSize, selectedExporters, updateCustomers])

    const exportData = async () => {
        const workbook = new Excel.Workbook();

        const columns = [
            { header: 'Exporter', key: 'exporter' },
            { header: 'Customer ID', key: 'customer_id' },
            { header: 'Customer Name', key: 'customer_name' },
            { header: 'Customer Country', key: 'customer_country' },
            { header: 'Total Receivables Amount (Currency)', key: 'total_receivables_amount_currency' },
            { header: 'Total Receivables Amount (Value)', key: 'total_receivables_amount' },
            { header: 'Count Of Total Receivables Invoices', key: 'count_of_total_receivables_invoices' },
            { header: 'Settled Amount (Currency)', key: 'settled_amount_currency' },
            { header: 'Total Settled Amount (Value)', key: 'settled_amount' },
            { header: 'Count Of Settled Invoices', key: 'count_of_settled_invoices' },
            { header: 'Settled Outside Fideo Amount (Currency)', key: 'paid_outside_fideo_amount_currency' },
            { header: 'Total Settled Outside Fideo Amount (Value)', key: 'paid_outside_fideo_amount' },
            { header: 'Count Of Settled Outside Fideo Invoices', key: 'count_of_paid_outside_fideo_invoices' },
            { header: 'Pending Amount (Currency)', key: 'pending_amount_currency' },
            { header: 'Total Pending Amount (Value)', key: 'pending_amount' },
            { header: 'Count Of Pending Invoices', key: 'count_of_pending_invoices' },
        ];

        let data = [];

        filteredData?.forEach((item, ind) => {
            item?.reportsData?.forEach((subItem, index) => {
                if (true) {
                    // const idRow = { 'payout_reference': '', 'invoice_id': `${subItem?.invoiceId}` };
                    const idRow = {
                        'exporter': item?.organizationName,
                        'customer_id': item?.customerId,
                        'customer_name': item?.customerName,
                        'customer_country': item?.customerCountry,
                        'total_receivables_amount_currency': subItem?.currency ? subItem?.currency : '--',
                        'total_receivables_amount': subItem?.totalAmount ? subItem?.totalAmount?.toFixed(2) : '--',
                        'count_of_total_receivables_invoices': subItem?.totalInvoices ? subItem?.totalInvoices : '--',
                        'settled_amount_currency': subItem?.currency ? subItem?.currency : '--',
                        'settled_amount': subItem?.settledAmount ? subItem?.settledAmount?.toFixed(2) : '--',
                        'count_of_settled_invoices': subItem?.settledInvoices ? subItem?.settledInvoices : '--',
                        'paid_outside_fideo_amount_currency': subItem?.currency ? subItem?.currency : '--',
                        'paid_outside_fideo_amount': subItem?.settledOutsideAmount ? subItem?.settledOutsideAmount?.toFixed(2) : '--',
                        'count_of_paid_outside_fideo_invoices': subItem?.settledOutsideInvoices ? subItem?.settledOutsideInvoices : '--',
                        'pending_amount_currency': subItem?.currency ? subItem?.currency : '--',
                        'pending_amount': subItem?.outstandingAmount ? subItem?.outstandingAmount?.toFixed(2) : '--',
                        'count_of_pending_invoices': subItem?.outstandingInvoices ? subItem?.outstandingInvoices : '--',
                    }
                    data.push(idRow);
                }
            });
        });

        try {
            const fileName = "CustomersReport";

            const worksheet = workbook.addWorksheet("CustomerTableData");

            worksheet.columns = columns;

            worksheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF434343' },
            };

            worksheet.getColumn(1).font = { bold: true };

            worksheet.getRow(1).font = {
                color: { argb: 'FFFFFFFF' },
                bold: true,
            };

            const columnsToAlignRight = [6, 7, 9, 10, 12, 13, 15, 16];

            columnsToAlignRight.forEach(columnIndex => {
                worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
            });

            worksheet.columns.forEach(column => {
                column.width = column.header.length;
                // column.alignment = { horizontal: 'center' };
            });

            data?.forEach(singleData => {
                worksheet.addRow(singleData);
            });

            worksheet.eachRow({ includeEmpty: false }, row => {
                const currentCell = row._cells;

                currentCell.forEach(singleCell => {
                    const cellAddress = singleCell._address;

                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });
            const buf = await workbook.xlsx.writeBuffer();

            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            workbook.removeWorksheet('MyWorkBook');
        }
    }

    return (
        <>
            <PageIntro
                pageName={"Customers"}
                message={`View your ${CustomerTabledata?.data?.content?.length ? CustomerTabledata?.data?.content?.length : ''} customers at one place`}
                downloadBtn={true}
                handleExport={exportData}
            />

            <CustomerFilter
                allData={CustomerTabledata?.data?.content}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                isStatus={false}
                placeHolder={'Search by Customer id / Customer name'}
                customers={customers}
                setCustomers={setCustomers}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                customerCounter={customerCounter}
                setCustomerCounter={setCustomerCounter}
                exportersList={exportersList}
                setExportersList={setExportersList}
                selectedExporters={selectedExporters}
                setExportersCounter={setExportersCounter}
                exportersCounter={exportersCounter}
                setSelectedExporters={setSelectedExporters}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
                toggleSearchDropdown={toggleSearchDropdown}
                setShowSearchDropdown={setShowSearchDropdown}
                toggleExportersSelection={toggleExportersSelection}
                toggleCustomerDropdown={toggleCustomerDropdown}
                showCustomerDropdown={showCustomerDropdown}
                setShowCustomerDropdown={setShowCustomerDropdown}
                statuses={statuses}
                allStatusSelected={allStatusSelected}
                setAllStatusSelected={setAllStatusSelected}
                setSelectedStatuses={setSelectedStatuses}
                showStatusDropdown={showStatusDropdown}
                setShowStatusDropdown={setShowStatusDropdown}
                toggleStatusDropdown={toggleStatusDropdown}
                statusCounter={statusCounter}
                setStatusCounter={setStatusCounter}
                toggleStatusSelection={toggleStatusSelection}
                selectedStatuses={selectedStatuses}
                setShowInvoiceDateDropdown={setShowInvoiceDateDropdown}
                toggleInvoiceDateDropdown={toggleInvoiceDateDropdown}
                showDateCounter={showDateCounter}
                showInvoiceDateDropdown={showInvoiceDateDropdown}
                handleDateSelection={handleDateSelection}
                activeDate={activeDate}
                setIsSearch={setIsSearch}
            />
            <CustomerReportTable
                tableData={filteredData}
                resData={resData}
                tableHeader={tableHeader}
                loading={fetchingtCustomer}
                handlePaginate={handlePaginate}
                curentPage={curentPage}
                pageSize={pageSize}
                isSearch={isSearch}
                handleVerify={handleVerify}
                verifyCust={verifyCust}
                fetchingCost={fetchingCost}
            />

        </>
    )
}

export default Customers