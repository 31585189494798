// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExporterProfileInfo_backdrop__jC15n {
  z-index: 10;
  top: 0;
  height: 100vh;
}

.ExporterProfileInfo_modal__cu3YV {
  height: calc(100vh - var(--admin_header_length));
}

.ExporterProfileInfo_profileInfoHeader__JED68 {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.5rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.ExporterProfileInfo_closeBtn__Rasp1 {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #D1D5DB;
  border-bottom-left-radius: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/ExporterProfileInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,MAAA;EACA,aAAA;AACJ;;AACA;EACI,gDAAA;AAEJ;;AAAA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,oBAAA;EACA,gBAAA;EACA,mBAAA;AAGJ;;AADA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,yBAAA;EACA,+BAAA;AAIJ","sourcesContent":[".backdrop{\n    z-index: 10;\n    top: 0;\n    height: 100vh;\n}\n.modal{\n    height: calc(100vh - var(--admin_header_length));\n}\n.profileInfoHeader{\n    padding: 2rem 0;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 2.5rem;\n    line-height: 2.25rem;\n    font-weight: 700;\n    margin-bottom: 20px;\n}\n.closeBtn{\n    position: absolute;\n    top: 0;\n    right: 0;\n    background-color: #D1D5DB;\n    border-bottom-left-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `ExporterProfileInfo_backdrop__jC15n`,
	"modal": `ExporterProfileInfo_modal__cu3YV`,
	"profileInfoHeader": `ExporterProfileInfo_profileInfoHeader__JED68`,
	"closeBtn": `ExporterProfileInfo_closeBtn__Rasp1`
};
export default ___CSS_LOADER_EXPORT___;
