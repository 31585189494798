import classes from "./Exporter.module.scss"
import PageIntro from "../Layout/PageIntro"
import ExporterTable from "../ReportTable/ExporterReportTable"
import ExporterFilter from "../Components/atom/ExporterFilter"
import { useEffect, useState } from "react"
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import UseApiCall from "../hooks/useApiCall"
import { apiGetOrganizationData } from "../services"
import {OrganizationActions} from "../Store/customer-redux";
import {useDispatch, useSelector} from "react-redux";
import login from "./Login";
import dayjs from "dayjs";


const Exporters = () => {
    const dispatch = useDispatch();
    const exporterData = useSelector((state) => state.exporterList.exporterData);
    const [filteredData, setFilteredData] = useState();
    const [selectedCustomers, setSelectedCustomers] = useState({});
    const [allSelected, setAllSelected] = useState(true);
    const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [isSearch, setIsSearch] = useState();
    const [customers,setCustomers] = useState();
    const [customerCounter, setCustomerCounter] = useState('0');
    const [statuses,setStatuses] = useState();
    const [selectedStatus, setSelectedStatus] = useState(["verified", "UNDER REVIEW"]);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [allStatusSelected, setAllStatusSelected] = useState(true);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showInvoiceDateDropdown, setShowInvoiceDateDropdown] = useState(false);
    const [showDateCounter, setShowDateCounter] = useState(false);
    const [statusCounter, setStatusCounter] = useState('0');
    const [activeDate, setActiveDate] = useState('all');
    const [selectedDate, setSelectedDate] = useState({
        fromDate:'',
        toDate:''
    });

    const toggleCustomerDropdown = () => {
        setShowCustomerDropdown(!showCustomerDropdown);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleSearchDropdown = () => {
        setShowSearchDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleCustomerSelection = async (customerId, len) => {
        // setCurrentPage(1)
        // console.log('customerId',customerId)
        // console.log('len',len)
        setSelectedCustomers(prevSelectedCustomers => {
            const updatedSelection = {
                ...prevSelectedCustomers,
                [customerId]: !prevSelectedCustomers[customerId]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            // console.log('countTrueKeys',countTrueKeys)

            setCustomerCounter(countTrueKeys);
            setAllSelected(countTrueKeys === len);
            return updatedSelection;
        });
    };

    const toggleStatusDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(!showStatusDropdown);
        setShowInvoiceDateDropdown(false);
    };

    const toggleStatusSelection = (status, len) => {
        // setCurrentPage(1)
        if(status === 'reset'){
            setAllStatusSelected(false)
            setStatusCounter('0')
            return setSelectedStatuses({});
        }
        setSelectedStatuses(prevSelectedStatuses => {
            const updatedSelection = {
                ...prevSelectedStatuses,
                [status]: !prevSelectedStatuses[status]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            setStatusCounter(countTrueKeys)
            setAllStatusSelected(statuses?.length === countTrueKeys)

            return updatedSelection;
        });
    };

    const toggleInvoiceDateDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(!showInvoiceDateDropdown);
    };

    const handleDateSelection = (period) => {
        // setCurrentPage(1)
        setShowDateCounter(period === 'all' ? false : true)
        setActiveDate(period)
        const currentDate = dayjs();
        let fromDate, toDate;

        switch (period) {
            case 'all':
                fromDate = '';
                toDate = '';
                break;
            case 'one':
                fromDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'three':
                fromDate = currentDate.subtract(3, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'six':
                fromDate = currentDate.subtract(6, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            default:
                // Handle default case or throw an error if needed
                break;
        }

        setSelectedDate({
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const [getOrganizationData, fetchinggetOrganizationData, organizationData, organizationDataerror] = UseApiCall(apiGetOrganizationData, 
        (res) => {
            setFilteredData(res?.data)

            const uniqueOrganizationsMap = new Map();

            res?.data?.forEach((organization) => {
                const organizationName = organization.organizationName;
                if (!uniqueOrganizationsMap.has(organizationName)) {
                    uniqueOrganizationsMap.set(organizationName, organization);
                    return true
                }else {
                    return false
                }
            });

            const uniqueOrganizations = Array.from(uniqueOrganizationsMap.values());
            setCustomers(uniqueOrganizations);
        dispatch(OrganizationActions.setOrganizationTableData(res?.data));
      }, (err) => {
        console.log(err)
        // dispatch(DashboardSummaryActions.setShowBackendError(err));
      });
      
      useEffect(() => {
        getOrganizationData();
      }, [])

    const exportData = async () => {
        const workbook = new Excel.Workbook();
    
        const columns = [
          // { header: 'Exporter Name', key: "exporter_name" },
          // { header: 'Total Receivables', key: 'total_receivables' },
          // { header: 'Outstanding', key: 'outstanding' },
          // { header: 'Settled + Partially Paid', key: "settled" },
          // { header: 'V Bank Details', key: 'v_bank_details' },

            { header: 'Exporter Name', key: "exporter_name" },
            { header: 'Total Receivables Amount (Currency)', key: 'total_receivables_amount_currency' },
            { header: 'Total Receivables Amount (Value)', key: 'total_receivables_amount' },
            { header: 'Count Of Total Receivables Invoices', key: 'count_of_total_receivables_invoices' },
            { header: 'Total Outstanding Amount (Currency)', key: 'total_outstanding_amount_currency' },
            { header: 'Total Outstanding Amount (Value)', key: 'total_outstanding_amount' },
            { header: 'Count Of Total Outstanding Invoices', key: 'count_of_total_outstanding_invoices' },
            { header: 'Total Settled + Partially Paid Amount (Currency)', key: 'total_settled_partially_amount_currency' },
            { header: 'Total Settled + Partially Paid Amount (Value)', key: 'total_settled_partially_amount' },
            { header: 'Count Of Total Settled + Partially Paid Invoices', key: 'count_of_total_settled_partially_invoices' },
            { header: 'V Bank Details', key: 'v_bank_details' },
            { header: 'Total V Bank Amount (Currency)', key: 'total_v_bank_amount_currency' },
            { header: 'Total V Bank Amount (Value)', key: 'total_v_bank_amount' },
        ];

        const data = exporterData?.map(row => (
            row?.dashboardData?.map((data, indexOf) => ({
                exporter_name: indexOf === 0 ? data?.organizationName : " ",
                total_receivables_amount_currency: data?.currency ? data?.currency : "--",
                total_receivables_amount: data?.totalAmount ? data?.totalAmount.toFixed(2) : "--",
                count_of_total_receivables_invoices: data?.totalInvoices ? data?.totalInvoices : "--",
                total_outstanding_amount_currency: data?.currency ? data?.currency : "--",
                total_outstanding_amount: data?.outstandingAmount ? data?.outstandingAmount.toFixed(2) : "--",
                count_of_total_outstanding_invoices: data?.outstandingInvoices ? data?.outstandingInvoices : "--",
                total_settled_partially_amount_currency: data?.currency ? data?.currency : "--",
                total_settled_partially_amount: data?.settledAmount ? data?.settledAmount.toFixed(2) : "--",
                count_of_total_settled_partially_invoices: data?.settledInvoices ? data?.settledInvoices : "--",
                total_v_bank_amount_currency: row?.bankBalanceData[indexOf]?.balance ? row?.bankBalanceData[indexOf]?.currency?.code : "--",
                total_v_bank_amount: row?.bankBalanceData[indexOf]?.balance ? row?.bankBalanceData[indexOf]?.balance.toFixed(2) : "--",
            }))
        )).flat();

        try {
          const fileName = "ExporterReport";
    
          const worksheet = workbook.addWorksheet("ExporterTableData");
    
          worksheet.columns = columns;
    
          worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF434343' },
          };
    
          worksheet.getColumn(1).font = { bold: true };
    
          worksheet.getRow(1).font = {
            color: { argb: 'FFFFFFFF' },
            bold: true,
          };
    
          const columnsToAlignRight = [2, 3, 4, 5];
    
          columnsToAlignRight.forEach(columnIndex => {
            worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
          });
    
          worksheet.columns.forEach(column => {
            column.width = column.header.length;
            // column.alignment = { horizontal: 'center' };
          });
    
          data.forEach(singleData => {
            worksheet.addRow(singleData);
          });
    
          worksheet.eachRow({ includeEmpty: false }, row => {
            // store each cell to currentCell
            const currentCell = row._cells;
    
            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCell.forEach(singleCell => {
              // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
              const cellAddress = singleCell._address;
    
              // apply border
              worksheet.getCell(cellAddress).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            });
          });
    
          const buf = await workbook.xlsx.writeBuffer();
    
          saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
          console.error('<<<ERRROR>>>', error);
          console.error('Something Went Wrong', error.message);
        } finally {
          workbook.removeWorksheet('MyWorkBook');
        }
    }

    // console.log('organizationData',organizationData)

    return (
        <>
        <PageIntro 
            pageName={"Exporters"}
            message={`${organizationData?.data?.length ? organizationData?.data?.length : ''} Exporters are onboarded on platform`}
            downloadBtn = {true}
            handleExport = {exportData}
        />
        {/* <div className="py-[16px] px-[12px] mb-[48px] text-[14px] bg-[#FFF] rounded-[8px]">
            <span className="text-[18px]">{dummyData.length} Exporters  </span> are onboarded on platform
        </div> */}
        <ExporterFilter 
            allData = {organizationData?.data}
            filteredData = {filteredData}
            setFilteredData = {setFilteredData}
            selectedStatus = {selectedStatus}
            setSelectedStatus = {setSelectedStatus}
            isStatus={false}
            placeHolder={'Search by Exporter name'}
            customers={customers}
            setCustomers={setCustomers}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            customerCounter={customerCounter}
            setCustomerCounter={setCustomerCounter}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            toggleSearchDropdown={toggleSearchDropdown}
            setShowSearchDropdown={setShowSearchDropdown}
            toggleCustomerSelection={toggleCustomerSelection}
            toggleCustomerDropdown={toggleCustomerDropdown}
            showCustomerDropdown={showCustomerDropdown}
            setShowCustomerDropdown={setShowCustomerDropdown}
            statuses={statuses}
            allStatusSelected={allStatusSelected}
            setAllStatusSelected={setAllStatusSelected}
            setSelectedStatuses={setSelectedStatuses}
            showStatusDropdown={showStatusDropdown}
            setShowStatusDropdown={setShowStatusDropdown}
            toggleStatusDropdown={toggleStatusDropdown}
            statusCounter={statusCounter}
            setStatusCounter={setStatusCounter}
            toggleStatusSelection={toggleStatusSelection}
            selectedStatuses={selectedStatuses}
            setShowInvoiceDateDropdown={setShowInvoiceDateDropdown}
            toggleInvoiceDateDropdown={toggleInvoiceDateDropdown}
            showDateCounter={showDateCounter}
            showInvoiceDateDropdown={showInvoiceDateDropdown}
            handleDateSelection={handleDateSelection}
            activeDate={activeDate}
            setIsSearch={setIsSearch}
        />
        <ExporterTable
            tableData = {filteredData}
            loading={fetchinggetOrganizationData}
            isSearch={isSearch}
        />
        </>
    )
}

export default Exporters