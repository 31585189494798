// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_box1__4CgAQ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-bottom: 1.2rem;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,gBAAA;AACJ","sourcesContent":[".box1 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 1.8rem;\n    line-height: 2.8rem;\n    margin-bottom: 1.2rem;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `Dashboard_box1__4CgAQ`
};
export default ___CSS_LOADER_EXPORT___;
