import classes from "./ExporterFilter.module.scss"
import searchIcon from "../../assets/Icons/search-icon.svg"
import up from "../../assets/Icons/chevron-up.svg"
import down from "../../assets/Icons/chevron-down.svg"
import { useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"

// const apiStatusToDisplayStatus = (status) => {
//     switch (status.split(' ').join('').toLowerCase()) {
//         case 'created':
//             return {
//                 title: 'CREATED',
//             }
//         case 'createdat':
//             return {
//                 title: 'CREATED',
//             }
//         case 'initiated':
//             return {
//                 title: 'INITIATED',
//             }
//         case 'verified':
//             return {
//                 title: 'VERIFIED',
//             }
//         case 'inprogress':
//             return {
//                 title: 'IN PROGRESS',
//             }
//         case 'linksent':
//             return {
//                 title: 'LINK SENT',
//             }
//         case 'settled':
//             return {
//                 title: 'SETTLED',
//             }
//         case 'success':
//             return {
//                 title: 'SUCCESS',
//             }
//         case 'inreview':
//             return {
//                 title: 'UNDER REVIEW',
//             }
//         case 'cancelled':
//             return {
//                 title: 'CANCELLED',
//             }
//         case 'rejected':
//             return {
//                 title: 'REJECTED',
//             }
//         case 'settledoutside':
//             return {
//                 title: 'SETTLED OUTSIDE',
//             }
//         case 'indraft':
//             return {
//                 title: 'DRAFT',
//             }
//         case 'overdue':
//             return {
//                 title: 'OVERDUE',
//             }
//         case 'partiallypaid':
//             return {
//                 title: 'PARTIALLY PAID',
//             }
//         case 'active':
//             return {
//                 title: 'ACTIVE',
//             }
//         default:
//             return {
//                 title: status,
//             }
//     }
// }

const CustomerFilter = (props) => {
    const {
        exportersList,
        setExportersList,
        selectedExporters,
        setExportersCounter,
        exportersCounter,
        setSelectedExporters,
        customers,
        setCustomers,
        selectedCustomers,
        setSelectedCustomers,
        customerCounter,
        setCustomerCounter,
        allSelected,
        setAllSelected,
        toggleSearchDropdown,
        setShowSearchDropdown,
        toggleExportersSelection,
        toggleCustomerDropdown,
        showCustomerDropdown,
        setShowCustomerDropdown,
        statuses,
        setSelectedStatuses,
        setAllStatusSelected,
        allStatusSelected,
        showStatusDropdown,
        setShowStatusDropdown,
        toggleStatusDropdown,
        statusCounter,
        setStatusCounter,
        toggleStatusSelection,
        selectedStatuses,
        setShowInvoiceDateDropdown,
        toggleInvoiceDateDropdown,
        showDateCounter,
        showInvoiceDateDropdown,
        handleDateSelection,
        activeDate,
    } = props;
    const [statusFilterOpen, setStatusFilterOpen] = useState(false)
    const customerDropdownRef = useClickOutside(() => setShowCustomerDropdown(false));
    // const statusDropdownRef = useClickOutside(() => setShowStatusDropdown(false));
    // const invoiceDateDropdownRef = useClickOutside(() => setShowInvoiceDateDropdown(false));
    // const searchDropdownRef = useClickOutside(() => setShowSearchDropdown(false));
    // const optRef = useClickOutside(() => {
    //     setStatusFilterOpen(false)
    // });

    const handleAllExportersToggle = (allSelected) => {
        if (!allSelected) {
            const newSelectedExporters = {};
            exportersList?.forEach(data => {
                newSelectedExporters[data?.id] = true;
            });
            setSelectedExporters(newSelectedExporters);
            setExportersCounter(Object.values(newSelectedExporters).filter(value => value).length)
            setAllSelected(!allSelected);
        } else {
            const newSelectedExporters = {};
            exportersList?.forEach(data => {
                newSelectedExporters[data?.id] = false;
            });
            setSelectedExporters(newSelectedExporters)
            // setSelectedExporters({})
            setAllSelected(false);
        }
    };

    // const handleAllStatusToggle = (allStatusSelected) => {
    //     if (!allStatusSelected) {
    //         const newSelectedStatuses = {};
    //         statuses.forEach(status => {
    //             newSelectedStatuses[status] = true;
    //         });
    //         setSelectedStatuses(newSelectedStatuses)
    //         setStatusCounter(statuses?.length)
    //         setAllStatusSelected(!allStatusSelected);
    //     } else {
    //         setSelectedStatuses({})
    //         setAllStatusSelected(false);
    //         const newSelectedStatuses = {};
    //         statuses.forEach(status => {
    //             newSelectedStatuses[status] = false;
    //         });
    //         setSelectedStatuses(newSelectedStatuses)
    //         setStatusCounter(0)
    //         setAllStatusSelected(false);
    //     }
    // };

    return (
        <>
            <div className={classes.filterPage}>
                <div className={classes.left}>
                    <div
                        className={`${classes.searchBar} flex item-center justify-between border-[1px] border-[rgba(6, 10, 13, 0.32)] rounded-[8px] gap-[8px] px-[16px] py-[14px] bg-[#FFF] w-[280px]`}
                        onClick={toggleSearchDropdown}
                    >
                        <img src={searchIcon} className="w-[20px] h-[20px]" />
                        <input
                            type="text"
                            placeholder={props?.placeHolder}
                            className={classes.searchInput}
                            onChange={(event) => {
                                const searchTerm = event.target.value.toLowerCase();
                                props?.setIsSearch(searchTerm)

                                // Filter based on customerId or customerName
                                const filteredData = props?.allData?.filter(item =>
                                    item?.customerId.toLowerCase().includes(searchTerm) ||
                                    item?.customerName.toLowerCase().includes(searchTerm)
                                );

                                props.setFilteredData(filteredData);
                            }}
                        />
                    </div>
                    <div className={classes.dropdownWrapper} ref={customerDropdownRef}>
                        <div className={classes.customer} onClick={toggleCustomerDropdown}>
                            <span className={classes.text}>Exporters</span>
                            <div className={classes.counter}>
                                {/*{customerCounter >= 1 && customerCounter !== customers?.length &&*/}
                                {/*    (<div className={classes.count}>*/}
                                {/*        <span className={classes.countText}>{customerCounter}</span>*/}
                                {/*    </div>)}*/}
                                <img src={showCustomerDropdown ? up : down} className={classes.imgIcon} alt="dropdown" />
                            </div>
                        </div>
                        {showCustomerDropdown && (
                            <div className={`${classes.dropdownCustomer}`}>
                                <div className={`${classes.scrollableDropdown}`}>
                                    <div className={classes.dropdownListCustomer}
                                        onClick={(e) => handleAllExportersToggle(allSelected)}>
                                        <input type='checkbox' className={classes.dropdownCheckbox}
                                            checked={allSelected}
                                        />
                                        <span className={classes.dropdownText}>All Exporters</span>
                                    </div>
                                    {exportersList && exportersList.map((exporter, index) => (
                                        <div className={classes.dropdownListCustomerMap} key={index} onClick={() => toggleExportersSelection(exporter?.id, exportersList?.length)}>
                                            <input
                                                type='checkbox'
                                                className={classes.dropdownCheckbox}
                                                checked={selectedExporters[exporter?.id] || allSelected}
                                            // onChange={() => toggleExportersSelection(exporter.id, exportersList?.length)}
                                            />
                                            <span
                                                className={classes.dropdownText}
                                            // onClick={() => toggleExportersSelection(exporter.id, exportersList?.length)}
                                            >
                                                {exporter?.businessName?.length > 22
                                                    ? `${exporter?.businessName?.slice(0, 22)}...`
                                                    : exporter?.businessName
                                                }
                                            </span>
                                        </div>
                                    )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {/*<div className={classes.dropdownWrapper} ref={statusDropdownRef}>*/}
                    {/*    <div className={`${classes.status}`} onClick={toggleStatusDropdown}>*/}
                    {/*        <span className={classes.text}>Status</span>*/}
                    {/*        <div className={classes.counter}>*/}
                    {/*            /!*{statusCounter >= 1 && statusCounter !== statuses?.length && !allStatusSelected &&*!/*/}
                    {/*            /!*    (<div className={classes.count}>*!/*/}
                    {/*            /!*        <span className={classes.countText}>{statusCounter}</span>*!/*/}
                    {/*            /!*    </div>)}*!/*/}
                    {/*            <img src={showStatusDropdown ? up : down} className={classes.imgIcon} alt="dropdown"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    {showStatusDropdown && (*/}
                    {/*        <div className={`${classes.dropdownStatus}`}>*/}
                    {/*            <div className={`${classes.scrollableDropdown}`}>*/}
                    {/*                <div className={`${classes.dropdownListStatus}`}*/}
                    {/*                     onClick={(e) => handleAllStatusToggle(allStatusSelected)}>*/}
                    {/*                    <input type='checkbox' className={classes.dropdownCheckbox}*/}
                    {/*                           checked={allStatusSelected}*/}
                    {/*                    />*/}
                    {/*                    <span className={classes.dropdownText}>All Statuses</span>*/}
                    {/*                </div>*/}
                    {/*                <div>*/}
                    {/*                    {statuses?.map(status => (*/}
                    {/*                        <div className={`${classes.dropdownListStatusMap}`} key={status}*/}
                    {/*                             onClick={() => toggleStatusSelection(status, statuses?.length)}>*/}
                    {/*                            <input type='checkbox' className={classes.dropdownCheckbox}*/}
                    {/*                                   checked={selectedStatuses[status] || allStatusSelected}*/}
                    {/*                                // onChange={() => toggleStatusSelection(status, statuses?.length)}*/}
                    {/*                            />*/}
                    {/*                            <span className={classes.dropdownText}*/}
                    {/*                            >*/}
                    {/*                                {apiStatusToDisplayStatus(status)?.title?.length > 20*/}
                    {/*                                    ? `${apiStatusToDisplayStatus(status)?.title?.slice(0, 20)}...`*/}
                    {/*                                    : apiStatusToDisplayStatus(status)?.title*/}
                    {/*                                }*/}
                    {/*                            </span>*/}
                    {/*                        </div>*/}
                    {/*                    ))}*/}
                    {/*                </div>*/}
                    {/*                /!* <div className={classes.dropdownListReset} onClick={() => toggleStatusSelection('reset')}>*/}
                    {/*                <img src={rotate} className={classes.dropdownListImg} />*/}
                    {/*                <span className={classes.dropdownText}*/}
                    {/*                >Reset</span>*/}
                    {/*            </div> *!/*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    {/*<div className={classes.dropdownWrapper} ref={invoiceDateDropdownRef}>*/}
                    {/*    <div className={classes.date} onClick={toggleInvoiceDateDropdown}>*/}
                    {/*        <span className={classes.text}>Issue Date</span>*/}
                    {/*        <div className={classes.counter}>*/}
                    {/*            /!*{showDateCounter &&*!/*/}
                    {/*            /!*    (<div className={classes.count}>*!/*/}
                    {/*            /!*        <span className={classes.countText}>1</span>*!/*/}
                    {/*            /!*    </div>)}*!/*/}
                    {/*            <img src={showInvoiceDateDropdown ? up : down} className={classes.imgIcon}*/}
                    {/*                 alt="dropdown"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    {showInvoiceDateDropdown && (*/}
                    {/*        <div className={classes.dropdownDate}>*/}
                    {/*            <div className={classes.dropdownListDate} onClick={() => handleDateSelection('all')}>*/}
                    {/*                <span className={classes.dropdownText}>All Issue Dates</span>*/}
                    {/*                {activeDate === 'all' && <img src={check} className={classes.dropdownListImg}/>}*/}
                    {/*            </div>*/}
                    {/*            <div className={classes.dropdownListMap} onClick={() => handleDateSelection('one')}>*/}
                    {/*                <span className={classes.dropdownText}>Last month</span>*/}
                    {/*                {activeDate === 'one' && <img src={check} className={classes.dropdownListImg}/>}*/}
                    {/*            </div>*/}
                    {/*            <div className={classes.dropdownListMap} onClick={() => handleDateSelection('three')}>*/}
                    {/*                <span className={classes.dropdownText}>Last 3 months</span>*/}
                    {/*                {activeDate === 'three' && <img src={check} className={classes.dropdownListImg}/>}*/}
                    {/*            </div>*/}
                    {/*            <div className={classes.dropdownListMap} onClick={() => handleDateSelection('six')}>*/}
                    {/*                <span className={classes.dropdownText}>Last 6 months</span>*/}
                    {/*                {activeDate === 'six' && <img src={check} className={classes.dropdownListImg}/>}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {/*{(((customerCounter >= 1 && !allSelected) && (statusCounter >= 1 && !allStatusSelected)) ||*/}
                    {/*        ((customerCounter >= 1 && !allSelected) && (activeDate === 'one' || activeDate === 'three' || activeDate === 'six')) ||*/}
                    {/*        ((activeDate === 'one' || activeDate === 'three' || activeDate === 'six') && (statusCounter >= 1 && !allStatusSelected)))*/}
                    {/*    &&*/}
                    {/*    (*/}
                    {/*        <div className={classes.resetAll} onClick={() => toggleAllStatesSelection('resetAll')}>*/}
                    {/*            <img src={rotate} className={classes.dropdownListImg}/>*/}
                    {/*            <span className={classes.dropdownText}>Clear All</span>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}
                </div>
                <div className={classes.right}>
                    {/*<div*/}
                    {/*    className={clsx(classes.downloadBtn, props.loadingDownloadBtn ? 'cursor-default opacity-50' : 'cursor-pointer opacity-100')}*/}
                    {/*    onClick={() => {*/}
                    {/*        if (!props.loadingDownloadBtn) props.exportData()*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <img src={downloadIcon} alt='download' className={clsx(classes.downloadBtnImg)}/>*/}
                    {/*</div>*/}
                </div>
                {/* <div className={classes.right} ></div> */}
            </div>
        </>
    )
}
export default CustomerFilter;