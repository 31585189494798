import classes from "./DatabaseMatchTag.module.scss"
import {retry} from "@reduxjs/toolkit/query";

const DatabaseMatchTag = (props) => {
    const getStatusName = (status) => {
        switch (status){
            case "blacklistedEntities" :
                return "Black Listed Entities"

            case "adverseMedia" :
                return "Adverse Media"

            case "bseDefaultersExpelled" :
                return "Bse Defaulters Expelled"

            case "bseDelistedCompanies" :
                return "Bse Delisted Companies"

            case "bseSuspendedCompanies" :
                return "Bse Suspended Companies"

            case "enforcement" :
                return "Enforcement"

            case "financialIntelligenceUnitDefaulters" :
                return "Financial Intelligence Defaulters"

            case "irdaDefaulters" :
                return "Irda Defaulters"

            case "MCAdefaulterEntities" :
                return "MCA Defaulter Entities"

            case "MCAMLMCompany" :
                return "MCa MLM Company"

            case "mcxDefaulters" :
                return "MCX Defaulters"

            case "NCDEXCessationMembers" :
                return "NCDEX Cessation Members"

            case "NCDEXDefaulterMembers" :
                return "NCDEX Defaulter Members"

            case "NCDEXExpelledMembers" :
                return "NCDEX Expelled Members"

            case "nseDefaulters" :
                return "nse Defaulters"

            case "SFIOConviction" :
                return "SFIO Conviction"

            case "SFIOProclaimed" :
                return "SFIO Proclaimed"

            case "SFIOProsecution" :
                return "SFIO Prosecution"

            case "sebiIlegalMoneyList" :
                return "sebi Illegal Money List"

            case "ofacSDNCriminalEntities" :
                return "ofac SDN Criminal Entities"

            case "nbfc" :
                return "nbfc"

            case "fcracancelled" :
                return "fcra cancelled"

            case "cibilDefaulters" :
                return "cibil Defaulters"

            case "UNSanctionsListEntities" :
                return "UN Sanctions List Entities"

            case "OFSIUKSanctionList":
                return "OFSI UK Sanction List"

            case "Registrations" :
                return "Registrations"

            case "SOE" :
                return "SOE"

            case "SanctionList" :
                return "Sanction List"

            case "EUSanctionList" :
                return "EU Sanction List"

            case "OFSIUKSanctionlist" :
                return "OFSI UK Sanction list"

            case "mcaProclaimedOffendors" :
                return "mca Proclaimed Offendors"

            case "associatedEntity" :
                return "associated Entity"

            case "fatf" :
                return "fatf"

            case "EOCNUAE" :
                return "EOCNUAE"

            case "worldBank" :
                return "world Bank"

            case "mhaUAPA" :
                return "mha UAPA"

            case "ofacNonSDN" :
                return "ofac Non SDN"

            case "blacklistedNgos" :
                return "black listed Ngos"

            case "fcraSuspended" :
                return "fcraSuspended"

            case "dvatDefaulterDealers" :
                return "dvat Defaulter Dealers"

            case "mastercardMatch" :
                return "master card Match"

            case "usSanctionList" :
                return "us Sanction List"

            default :
                return "no match"
        }

    }
    return(
        <div className={classes.bigTag}>
            {getStatusName(props?.status)}
            <span className={classes.smallTag}>{props?.numbers}</span>
        </div>
    )
}

export default DatabaseMatchTag;