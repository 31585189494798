import classes from "Components/ReportTable/NormalAction.module.scss";
// import actionIcon from "assets/Images/action-icon.svg";
// import { useState, useCallback, useEffect } from "react";
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "hooks/useClickOutside";
import Close from "Layout/Close";
import ReactDOM from "react-dom";
import Backdrop from 'Layout/Backdrop'
import clsx from 'clsx'
import Button from 'Layout/Button'
import { useNavigate } from "react-router-dom";
// import dayjs from 'dayjs'

const NormalAction = (props) => {
  const { title, heading, text, buttonShow, buttonText, showReconPartial, partialText } = props
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoiceTooltipDisplay = useSelector((state) => state.modal.invoiceTooltipDisplay);
  // const [showOption, setShowOption] = useState(false)
  // const [notShowFiraButton, setNotShowFiraButton] = useState(false)
  const optRef = useClickOutside(() => dispatch(modalActions.invoiceTooltipDisplay(false)))
  // const handleOptionClick = (option) => {
  //   // if (option?.onClick) option?.onClick(cellData)
  //   dispatch(modalActions.invoiceTooltipDisplay(false))
  // }

  // const status = cellData?.customer_detail?.verificationStatus;
  // const receivablesCustomerStatus = cellData?.status?.split(' ')?.join('')?.toLowerCase();

  return (
    <>
      {invoiceTooltipDisplay &&
        ReactDOM.createPortal(
          <Backdrop className={classes.backdrop}
          // onClick={()=>{
          //   if (props.handleClose) props.handleClose()
          // }}
            />,
          document.getElementById('backdrop-root')
        )}
      <div ref={optRef} className={`${classes.action} ${props.className}`}>
        {invoiceTooltipDisplay && (
          <div className={classes.action_all}>
            <div className={classes.header}>
              <div className={clsx(classes.heading, 'text-[16px]')}>{title}</div>
              <Close
                onClick={() => {
                  if (props.handleClose) props.handleClose()
                  dispatch(modalActions.invoiceTooltipDisplay(false))
                }
                }
                className={classes.close}
              />
            </div>
            <div className={clsx(props.mainClass, 'px-[20px] py-[24px]')}>
              {
                heading && <div className={classes.heading}>{heading}</div>
              }
              {
                props.showIcon &&
                  <img
                    src={props.icon}
                    alt="show-icon"
                    className="h-[88px] w-[88px] transition-transform duration-200"
                  />
              }
              <div className={clsx(classes.text, props.textClass)}>{text}</div>
              { (buttonShow && showReconPartial)
                  ?
                    <div className={clsx(classes.errorBox, 'w-[100%] gap-[20px] flex flex-col bg-[#FED1D1] rounded-[8px] p-[20px]')}>
                      <div className={clsx(classes.text, props.textClass, 'm-[0px]')}>{partialText}</div>
                      <button
                          className={clsx('w-[100%] h-[40px] py-[8px] px-[10px] rounded-[8px] bg-[#FFF] border-[2px] border-solid border-[#181C30] flex justify-center')}
                          onClick={() => {
                            // if (props.navigation) navigate(`${props.route}`);
                            dispatch(modalActions.invoiceTooltipDisplay(false))
                            if (props.handleClose) props.handleClose()
                          }}
                      >
                          <div className={'w-[34px] text-[14px] font-[600] text-[#181C30]'}>
                            {buttonText}
                          </div>
                      </button>
                    </div>
                  :
                      (buttonShow) && (
                        <button
                          // key={item.text}
                          // text={buttonText}
                          onClick={() => {
                            // if (props.navigation) navigate(`${props.route}`);
                            dispatch(modalActions.invoiceTooltipDisplay(false))
                            if (props.handleClose) props.handleClose()
                          }}
                          className={'w-full h-[48px]'}
                          style={{
                            borderRadius: "8px",
                            background: "#181C30",
                            display: "flex",
                            padding: "12px 20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          // btnClass={clsx(classes.btn)}
                          // disabled={!item.active}
                          // btnClassIcon={clsx(!item.active, !item.active && 'opacity-50')}
                          // btnClassText={clsx('text-[#FFF] !text-[20px]')}
                        >
                          <div className={clsx(classes.heading, 'text-[#FFF] flex')}>
                            {buttonText}
                          </div>
                        </button>
                      )
              }
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default NormalAction;
