import classes from './ReconTable.module.scss'
import ReportTableLayout from "../Layout/ReportTableLayout";
import dayjs from 'dayjs'
import clsx from 'clsx'
import { formatNumberToLocaleString } from '../hooks/formatNumberToLocaleString';
import linkIcon from '../assets/Icons/linkIcon.svg'
import Shimmer from '../Layout/Shimmer'
import ReactDOM from 'react-dom'
import { modalActions } from '../Store/modal-redux'
import InvoiceViewRecon from "../Components/atom/InvoiceViewReconModal";
import { useDispatch,useSelector } from 'react-redux'
import { useEffect, useState } from 'react'


const ReconcilationTable = (props) =>{
    const [viewInvoice,setViewInvoice] = useState(null);
    const [selectedBigData,setSelectedBigData] = useState([]);
    const dispatch = useDispatch()
    const invoiceViewRecon = useSelector((state) => state.modal.invoiceViewRecon);

    useEffect(() => {
        setSelectedBigData([
            ...(props?.selectedInvoices || []),
            ...(props?.tableData?.filter(el => !props?.selectedInvoices.some(selected => selected.invoice_id === el?.invoice_id)) || [])
        ]);
    }, [props?.tableData]);


    const checkedInvoiceId = (event, invoice_id, total_reconcile_amount, invoiceCurrency,invoice_issue_date,reconcile_customer_details,amount_pending) => {
        if (props.selectedInvoices?.length < 1000) {
            !event.target.checked
                ? props.setSelectedInvoices(props.selectedInvoices.filter((invoice) => invoice.invoice_id !== invoice_id))
                : props.setSelectedInvoices((prev) => [...prev, {invoice_id, total_reconcile_amount, invoiceCurrency, amount: 0, error_key: false,invoice_issue_date,reconcile_customer_details,amount_pending}]);
        } else {
            return false;
        }
    };

    const handleClickReconRow = (event, invoice_id, total_reconcile_amount, invoiceCurrency, invoice_issue_date,reconcile_customer_details, amount_pending) => {
        if (!props.disabledInput){
            props.selectedInvoices.filter((invoice) => invoice.invoice_id === invoice_id)?.length > 0
                ? props.setSelectedInvoices(props.selectedInvoices.filter((invoice) => invoice.invoice_id !== invoice_id))
                : props.setSelectedInvoices((prev) => [...prev, {invoice_id, total_reconcile_amount, invoiceCurrency, amount: 0, error_key: false, invoice_issue_date,reconcile_customer_details,amount_pending}]);
        }
    }

    const headerData = [
        {
            header:'invoice id',
            maxWidth:190
        },
        {
            header:'issue date',
            maxWidth:120
        },
        {
            header:'customer details',
            maxWidth:170
        },
        {
            header:'pending amount',
            maxWidth:170
        },
        {
            header:'amount to reconcile',
            maxWidth:300
        }
    ]

    return(
        <>
            {invoiceViewRecon &&
                ReactDOM.createPortal(
                    <InvoiceViewRecon
                        setViewInvoice = {setViewInvoice}
                        viewInvoice = {viewInvoice}
                    />,
                    document.getElementById('modal-root')
                )
            }
            {/* <div className={classes.tableWrap}> */}
            <ReportTableLayout className={clsx(classes.table,'!p-0')}>
                <thead className={classes.theadBox}>
                <th className={`${classes.cell_header1} ${classes.topCheckBox}`} style={{width:'60px',maxWidth:'60px',paddingLeft:'20px'}}>
                    <input
                        type="checkbox"
                        name="selectall"
                        className={classes.termsCheckboxForInvoice}
                        id="selectall"
                        disabled={props.disabledInput}
                        style={{ cursor: "pointer" }}
                        checked={props?.selectedInvoices?.length > 0 && props?.selectedInvoices?.length === selectedBigData?.length}
                        onChange={(e)=>{
                            e.target.checked
                                ?
                                props?.setSelectedInvoices((prevSelectedInvoices) => {
                                    const newSelectedInvoices = props?.tableData?.map((item) => {
                                        const isAlreadySelected = prevSelectedInvoices.some(selectedItem => selectedItem?.invoice_id === item?.invoice_id);

                                        if (!isAlreadySelected) {
                                            return {
                                                invoice_id: item?.invoice_id,
                                                total_reconcile_amount: item?.amount_pending,
                                                invoiceCurrency: props?.currencyCodeSign,
                                                amount: 0,
                                                error_key: false,
                                                invoice_issue_date: item?.invoice_issue_date,
                                                reconcile_customer_details: item?.reconcile_customer_details,
                                                amount_pending: item?.amount_pending
                                            };
                                        }

                                        return null;
                                    }).filter(Boolean);

                                    return [...prevSelectedInvoices, ...newSelectedInvoices];
                                })
                                :
                                props.setSelectedInvoices([])
                        }}
                    />
                </th>
                {headerData.map((header, index) => (
                    <th key={`${header}-${index}`} className={classes.cell_header1} style={{width:`${header.maxWidth}px`,maxWidth:`${header.maxWidth}px`}}>
                        <div className={classes.cell_header2}>
                            <div className={classes.head}  dangerouslySetInnerHTML={{ __html:  `${header.header}` }}></div>
                        </div>
                    </th>
                ))}

                </thead>
                <tbody>
                { selectedBigData?.length > 0
                    ?
                    selectedBigData?.map((data,index) => (
                        <tr key={index} className={classes.row} vAlign="top">
                            <td className={clsx(`${classes.cell} ${classes.invoice} ${(index === selectedBigData?.length-1)?"border-none":"border-b border-dashed border-gray-300"}`)} style={{paddingLeft:'20px'}} >
                                <input
                                    type="checkbox"
                                    name="selectall"
                                    className={classes.termsCheckboxForInvoice}
                                    id="selectall"
                                    disabled={props.disabledInput}
                                    style={{ cursor: "pointer"}}
                                    checked={props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.length}
                                    onChange={(e) => checkedInvoiceId(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign, data?.invoice_issue_date, data?.reconcile_customer_details, data?.amount_pending)}
                                />
                            </td>
                            <td
                                className={clsx(`${classes.cell} ${classes.invoice} ${(index === selectedBigData?.length-1)?"border-none":"border-b border-dashed border-gray-300"}`)}
                                onClick={(e) => handleClickReconRow(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign, data?.invoice_issue_date, data?.reconcile_customer_details, data?.amount_pending)}
                            >
                                <div className={classes.td1Wrap} style={{ maxWidth: "130px", overflowWrap: "anywhere" }}>
                                    {
                                        // data?.invoice_id?.length > 12
                                        // ?
                                        //   <Tooltip
                                        //     title={<div>{data.invoice_id}</div>}
                                        //     placement="bottomLeft"
                                        //   >
                                        //     {abbreviateString(data.invoice_id, 12)}
                                        //   </Tooltip>
                                        // :
                                        data?.invoice_id
                                    }
                                    <img src={linkIcon} className={classes.linkIconBtn} alt="icon"
                                         onClick={(e)=>{
                                             dispatch(modalActions.invoiceViewRecon(true))
                                             localStorage.setItem('zoomkaindex',"0")
                                             setViewInvoice(data)
                                             e.stopPropagation()
                                         }}
                                    />
                                </div>

                            </td>
                            <td
                                className={clsx(`${classes.cell} ${classes.invoice} ${(index === selectedBigData?.length-1)?"border-none":"border-b border-dashed border-gray-300"}`)}
                                onClick={(e) => handleClickReconRow(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign, data?.invoice_issue_date, data?.reconcile_customer_details, data?.amount_pending)}
                            >
                                {dayjs(data?.invoice_issue_date).local().format('D MMM YYYY')}
                            </td>
                            <td
                                className={clsx(`${classes.cell} ${classes.invoice} ${(index === selectedBigData?.length-1)?"border-none":"border-b border-dashed border-gray-300"}`)}
                                onClick={(e) => handleClickReconRow(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign, data?.invoice_issue_date, data?.reconcile_customer_details, data?.amount_pending)}
                            >
                                <div className={classes.customerDetails}
                                >
                                    <p className={classes.customerName}>{data?.reconcile_customer_details?.customer_name}</p>
                                    <p className={classes.countryName}>{data?.reconcile_customer_details?.customer_email}</p>
                                </div>
                            </td>
                            <td
                                className={clsx(`${classes.cell} ${classes.invoice} ${(index === selectedBigData?.length-1)?"border-none":"border-b border-dashed border-gray-300"}`)}
                                onClick={(e) => handleClickReconRow(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign, data?.invoice_issue_date, data?.reconcile_customer_details, data?.amount_pending)}
                            >
                                <div className={classes.amount}>
                                    {props?.currencyCodeSign}{formatNumberToLocaleString((data?.amount_pending), 2, 2)}
                                </div>
                            </td>
                            <td className={clsx(`${classes.cell} ${classes.invoice} ${(index === selectedBigData?.length-1)?"border-none":"border-b border-dashed border-gray-300"}`)}
                                style={{paddingRight:'20px'}}
                                onClick={(e) => handleClickReconRow(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign, data?.invoice_issue_date, data?.reconcile_customer_details, data?.amount_pending)}>
                                <div className={classes.textInputBox}>
                                    <p className={`${classes.textInputBoxLabel} ${
                                        props.selectedInvoices.filter((el)=>el.invoice_id===data.invoice_id)?.length ?classes.labelActive:classes.labelInactive
                                    }` }>Amount</p>
                                    <span className={classes.textInputCurrencyTag}>{props.currencyCodeValue}</span>
                                    <input
                                        type='number'
                                        placeholder='0.00'
                                        disabled={props.disabledInput}
                                        value={
                                            props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.length === 0
                                                ?
                                                ''
                                                :
                                                props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)[0]?.amount > 0 ?
                                                    props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)[0]?.amount
                                                    :
                                                    ''
                                        }
                                        onClick={(e) => {
                                            if (props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.length !== 0) e.stopPropagation();
                                        }}
                                        className={clsx(`${classes.textInputBoxInput} ${props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.length ? "bg-[#FFF]" : "bg-[#F4F6FA] border-[1px] border-solid border-[#D2D6D933]"} ${props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id && (el.amount > data?.amount_pending || el.amount < 0 || el?.error_key))?.length?"border-2 border-solid border-[#FF6868]":"border-2 border-solid border-[#181C30]"}`)}
                                        onChange={(e)=>{
                                            const newValue = Number(e.target.value);
                                            props.setSelectedInvoices((prevObj) =>
                                                prevObj.map((item) =>
                                                    item.invoice_id === data?.invoice_id ? { ...item, total_reconcile_amount: data?.amount_pending, amount: newValue, error_key: false } : item
                                                )
                                            );
                                        }}
                                        onWheel={(e) => {
                                            e.currentTarget.blur();
                                        }}
                                    />
                                    {props.selectedInvoices.map((item)=>{
                                        if(item?.invoice_id === data?.invoice_id && item?.amount > data?.amount_pending){
                                            return <p className={clsx(classes.errortag,`text-[1.2rem] mt-[0.2rem] text-[#FF6868] font-[400]`)}>Input amount should not exceed pending amount</p>
                                        }
                                        else if(item?.invoice_id === data?.invoice_id && item?.amount < 100){
                                            return <p className={clsx(classes.errortag,`text-[1.2rem] mt-[0.2rem] text-[#FF6868] font-[400]`)}>Input amount cannot be less than 100</p>
                                        }
                                        else if(item?.invoice_id === data?.invoice_id && item?.error_key){
                                            return <p className={clsx(classes.errortag,`text-[1.2rem] mt-[0.2rem] text-[#FF6868] font-[400]`)}>Reconcilation failed</p>
                                        }
                                        else return null
                                    })}
                                </div>
                            </td>
                        </tr>
                    ))
                    :
                    (
                        props?.tableData?.length === 0 ?
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop: '4rem',
                                    fontSize: '2.5rem'
                                }}
                                className={classes.table}
                            >
                                No Invoices To Reconcile.
                            </div>
                            :
                            [1,3,4]?.map((item, index) => (
                                <Shimmer key={index} className="w-[1660%] h-[80px] mt-[12px]"/>
                            ))
                    )
                }
                </tbody>
            </ReportTableLayout>
            {/* </div> */}
        </>
    )
}

export default ReconcilationTable