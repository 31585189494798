import React from 'react';
import attachDoc from "../../../assets/Icons/noattachDoc.svg";
import classes from "./profileComponents.module.scss";

const KycContentNot = () => {
  return (
    <div className={`${classes.kycContentNot}`}>
        <div className={`${classes.kycContentLeftNot}`}>
            <div className={`${classes.kycContentLeftNotImg}`}>
                <img src={attachDoc} alt='documents' className={`${classes.kycContentLeftNotOneImg}`} />
            </div>
            <span className={`${classes.kycContentLeftNotText}`}>No attachment</span>
        </div>
    </div>
  )
}

export default KycContentNot