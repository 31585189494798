import InvoiceVericationPrompt from '../Components/atom/InvoiceVericationPrompt/InvoiceVericationPrompt'
import SendSinglePaymentLinkPrompt from '../Components/atom/SendSinglePaymentLinkPrompt/SendSinglePaymentLinkPrompt'
import SendPaymentLinkPrompt from '../Components/atom/SendPaymentLinkPrompt/SendPaymentLinkPrompt'
// import SendSinglePaymentLinkPrompt from 'Components/atom/SendSinglePaymentLinkPrompt/SendSinglePaymentLinkPrompt'
// import CancelledPaymentPrompt from 'Components/atom/CancelledOrPaidOutsidePaymentPrompt/CancelledOrPaidOutsidePaymentPrompt'
import ReconSuccess from '../Components/atom/ReconSuccessModal'
import LeadVerificationModal from "../Components/atom/LeadVerificationModal";
import RemarksVerificationModal from "../Components/molecule/ComplianceChecks/RemarksVerificationModal";

export const showPaymentLinkModal = (modalRef, props) => {
  const { invoiceId, paymentLink, organizationId } = props
  modalRef.show({
    title: 'Send Payment Link',
    child: (
      <SendPaymentLinkPrompt
        invoiceId={invoiceId}
        paymentLink={paymentLink}
        organizationId={organizationId}
        closeModal={() => {
          modalRef?.close()
        }}
      />
    ),
    onClose: () => {},
    compactSize: true,
    closeOnClickOutside: true,
  })
}

export const showReconSuccess = (modalRef,props) =>{
  const {mainIcon , subHeading ,reconCurrency ,reconAmount , buttonText ,handleBtn, title, showReconPartial, reconPartialAmount, partialCount} = props
  modalRef.show({
    title: title,
    child:(
      <ReconSuccess
      mainIcon = {mainIcon}
      subHeading = {subHeading}
      reconCurrency = {reconCurrency}
      reconAmount ={reconAmount}
      reconPartialAmount={reconPartialAmount}
      partialCount={partialCount}
      buttonText = {buttonText}
      handleBtn = {handleBtn}
      showReconPartial={showReconPartial}
      closeModal={() => {
        modalRef?.close()
      }}
      />
    ),
    onClose: () => {handleBtn()},
    compactSize: true,
    closeOnClickOutside: true,
  })
}

export const showInvoiceVerificationPrompt = (modalRef, props) => {
  const { invoiceId, whereCalled } = props
  modalRef.show({
    title: 'Invoice Verification',
    child: (
      <InvoiceVericationPrompt
        invoiceId={invoiceId}
        whereCalled={whereCalled}
        closeModal={() => {
          modalRef?.close()
        }}
      />
    ),
    onClose: () => {},
    compactSize: true,
    closeOnClickOutside: true,
  })
}

// export const showInvoicePaidOutsideModal = (modalRef, props) => {
//   const { cellData, invoiceTab } = props;

//   modalRef.show({
//     title: 'Confirm action',
//     child: (
//       invoiceTab ?
//       <CancelledPaymentPrompt
//         customerName={cellData?.invoice_details_summary?.customer_attributes?.customer_name}
//         email={cellData?.invoice_details_summary?.customer_attributes?.customer_email}
//         country={cellData?.invoice_details_summary?.customer_attributes?.customer_country}
//         currentStatus={cellData?.invoice_status?.replace(/_/g, '')}
//         invoiceId={cellData?.invoice_details_summary?.invoice_attributes?.invoice_id}
//         purposeCode={cellData?.invoice_details_summary?.invoice_attributes?.purpose_code}
//         issueDate={cellData?.invoice_details_summary?.invoice_attributes?.issue_date}
//         dueDate={cellData?.invoice_details_summary?.invoice_attributes?.due_date}
//         invoiceAmount={cellData?.invoice_details_summary?.invoice_attributes?.invoice_amount}
//         invoiceCurrencyCode={cellData?.invoice_details_summary?.invoice_attributes?.invoice_amount_currency}
//         invoiceCurrencySign={cellData?.invoice_details_summary?.invoice_attributes?.invoice_amount_currency_sign}
//         savingAmount={cellData?.invoice_details_summary?.savings_details?.saving_amount}
//         savingCurrencySign={cellData?.invoice_details_summary?.savings_details?.saving_amount_currency_sign}
//         closeModal={() => {
//           modalRef?.close()
//         }}
//         invoiceTab={invoiceTab}
//         description={false}
//       />
//       :
//       <CancelledPaymentPrompt
//         customerName={cellData?.customer?.customerName}
//         email={cellData?.customer?.emailAddress}
//         country={cellData?.customer?.country}
//         currentStatus={cellData?.status}
//         invoiceId={cellData?.invoiceId}
//         purposeCode={cellData?.purposeCode}
//         issueDate={cellData?.issueDate}
//         dueDate={cellData?.dueDate}
//         invoiceAmount={cellData?.invoiceAmount}
//         invoiceCurrencyCode={cellData?.invoiceCurrency?.code}
//         invoiceCurrencySign={cellData?.invoiceCurrency?.sign}
//         savingAmount={cellData?.savingAmount}
//         savingCurrencySign={cellData?.savingCurrency?.sign}
//         closeModal={() => {
//           modalRef?.close()
//         }}
//         invoiceTab={invoiceTab}
//         description={false}
//       />
//     ),
//     onClose: () => {},
//     compactSize: true,
//     closeOnClickOutside: true,
//   })
// }

// // export const showInvoiceCancelledModal = (modalRef, props) => {
// //   const { cellData } = props;
// //   modalRef.show({
// //     title: 'Confirm action',
// //     child: (
// //       <CancelledPaymentPrompt
// //         customerName={cellData?.customer?.customerName}
// //         email={cellData?.customer?.emailAddress}
// //         country={cellData?.customer?.country}
// //         currentStatus={cellData?.status}
// //         invoiceId={cellData.invoiceId}
// //         purposeCode={cellData?.purposeCode}
// //         issueDate={cellData?.issueDate}
// //         dueDate={cellData?.dueDate}
// //         invoiceAmount={cellData?.invoiceAmount}
// //         invoiceCurrency={cellData?.invoiceCurrency}
// //         savingAmount={cellData?.savingAmount}
// //         savingCurrency={cellData?.savingCurrency}
// //         closeModal={() => {
// //           modalRef?.close()
// //         }}
// //         description={true}
// //       />
// //     ),
// //     onClose: () => {},
// //     compactSize: true,
// //     closeOnClickOutside: true,
// //   })
// // }

export const showSinglePaymentLinkModal = (modalRef, props) => {
  const { cellData, organizationId } = props
  modalRef.show({
    title: 'Send Single Payment Link',
    child: (
      <SendSinglePaymentLinkPrompt
        customerDisplayId={cellData?.customer_detail?.displayId}
        customerId={cellData?.customer_id}
        organizationId ={organizationId}
        closeModal={() => {
          modalRef?.close()
        }}
      />
    ),
    onClose: () => {},
    compactSize: true,
    closeOnClickOutside: true,
  })
}

export const showVerificationModal = (modalRef, props) => {
    const {action, leadId, getLeadDetails, callId} = props;
    modalRef.show({
        title: "Confirm Action",
        child: (
            <LeadVerificationModal
                action = {action}
                leadId = {leadId}
                getLeadDetails = {getLeadDetails}
                callId = {callId}
                closeModal = {() => {
                    modalRef.close()
                }}
            />
        ),
        onClose: () => {
        },
        compactSize: true,
        closeOnClickOutside: true,
    })
}

export const showVerificationModalRemarks = (modalRef, props) => {
    const {action, searchId, updateCompliance, docId, status,remarks,setRemarks,level} = props;
    modalRef.show({
        title: "Confirm Action",
        child: (
            <RemarksVerificationModal
                action = {action}
                searchId = {searchId}
                updateCompliance = {updateCompliance}
                docId = {docId}
                status={status}
                level={level}
                closeModal = {() => {
                    modalRef.close()
                }}
            />
        ),
        onClose: () => {
        },
        compactSize: true,
        closeOnClickOutside: true,
    })
}

