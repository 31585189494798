import classes from './Modal.module.scss';

const Modal = (props) => {
    return (
        <div className={`${classes.modal} ${props.className}`}>{props.children}</div>
    )
}

export default Modal;

// const ModalSearchDetails = (props) => {
//     return (
//         <div className={`${classes.modalSearchDetails} ${props.className}`}>{props.children}</div>
//     )
// }

// export ModalSearchDetails;