// import { useDispatch } from "react-redux";
import Button from "../../../Layout/Button";
import MailIcon from "../../../assets/Icons/mail-white.svg";
import CopyIcon from '../../../assets/Icons/copy-black.svg'
import { apiSendSinglePaymentLink, apiGenerateSinglePaymentLink } from '../../../services'
// import { ReceivablesActions } from 'Store/customer-redux'
import UseApiCall from '../../../hooks/useApiCall'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import userIcon from "../../../assets/Icons/user-icon-white.svg";
import { useEffect, useState } from "react";

const SendSinglePaymentLinkPrompt = (props) => {
  const { customerId, customerDisplayId , organizationId} = props
  const { enqueueSnackbar } = useSnackbar()
  const [paymentLink, setPaymentLink] = useState("")
  // const dispatch = useDispatch()

  // const [getReceivables, fetching] = UseApiCall(apiGetReceivables, (res) => {
  //   dispatch(ReceivablesActions.getReceivabelReports(res?.data.invoice_list))
  // })

  const [sendSinglePaymentLink, sendingLink] = UseApiCall(apiSendSinglePaymentLink, () => {
    enqueueSnackbar('Single Payment link sent Successfully', { variant: 'success' })
    if (props.closeModal) {
      props.closeModal()
    }
  })

  const [generateSinglePaymentLink, generateLinkLoader, generateLinkData] = UseApiCall(
    apiGenerateSinglePaymentLink,
  (res) => {
    setPaymentLink(res?.data)
  }, () => {
    enqueueSnackbar('Unable to generate single payment link.', { variant: 'error' })
  })

  const copyTextToClipboard = (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
  }

  useEffect(() => {
    if (customerId) generateSinglePaymentLink({customerId})
  }, [customerId])

  const handleClick = () => {
    sendSinglePaymentLink({ customerId: Number(customerId), paymentLink: encodeURIComponent(paymentLink), organizationId:organizationId })
  }
  const handleCopy = () => {
    try {
      copyTextToClipboard(paymentLink)
      enqueueSnackbar('Single Payment link copied!', { variant: 'success' })
    } catch (error) {}
  }
  return (
    <div className="w-[90vw] max-w-[500px] p-10 sm:p-[40px] h-auto flex flex-col items-center justify-center">
      <div
        className="rounded-full w-[72px] h-[72px] flex items-center justify-center"
        style={{
          background: 'linear-gradient(135deg, #18CD8C 0%, #75D6FF 100%)',
        }}
      >
        <img src={userIcon} alt="file" className="w-[24px] h-[24px]" />
      </div>
      <p className="text-[12px] relative -top-4 text-[#FFFFFF] bg-[#1E333F] px-4 py-2 rounded-full text-center mb-5 font-semibold">
        {customerDisplayId ? `${customerDisplayId}` : ''}
      </p>

      <p className="text-[#1E333F] font-bold text-[16px]">
        {' '}
        Payment link
      </p>
      <p className="text-[14px] font-medium text-center mt-1 text-[#1E333F80]">
        This link will have details of all pending invoices that need to be paid by the customer
      </p>
      <div className="w-full grid grid-cols-3 gap-6 mt-10">
        <Button
          className={clsx(
            'w-full col-span-2',
            (generateLinkLoader || paymentLink === "") && 'cursor-not-allowed',
            (generateLinkLoader || paymentLink === "") ? '!text-[#ffffff80]' : '!text-[#ffffff]',
          )}
          icon={MailIcon}
          text="Send instant via Email"
          disabled={(generateLinkLoader || paymentLink === "")}
          onClick={handleClick}
        />
        <Button
          className={clsx(
            'w-full !bg-white !border !border-solid !text-black !border-[#181C3033] ',
            (generateLinkLoader || paymentLink === "") && 'cursor-not-allowed',
            // (generateLinkLoader || paymentLink === "") ? 'text-[#ffffff80]' : 'text-[#ffffff]',
          )}
          icon={CopyIcon}
          text={window.innerWidth > 640 ? 'Copy Link' : ''}
          disabled={(generateLinkLoader || paymentLink === "")}
          onClick={handleCopy}
        />
      </div>
    </div>
  )
}
export default SendSinglePaymentLinkPrompt;
