import React from 'react';
import classes from './Shimmer.module.scss';

const Shimmer = (props) => {
  return (
    <div className={`${classes.loadingShimmer} ${props.className}`}>
    </div>
  );
};

export default Shimmer;