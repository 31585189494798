import Close from "Layout/Close";
import classes from "Components/General/ListOfContact.module.scss";
import ReactDOM from "react-dom";
import Backdrop from "Layout/Backdrop";
import Search from "Components/Global/Search";
import ContactInfo from "Components/Global/ContactInfo";
import Button from "Layout/Button";
import arrowRight from "assets/Images/arrow-right.svg";
import userAdd from "assets/Images/user-add-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AddContact from "Components/General/AddContact";
import CreateInvoice from "Components/Invoice/CreateInvoice";
import AddPaymentDetail from "Components/Payment/AddPaymentDetail";
import leftPointer from "assets/Images/left-pointer.svg";
import chevronDownIcon from "assets/Images/chevron-down.svg";
import useClickOutside from "hooks/useClickOutside";
import CountryDropdown from "Components/Dropdown/CountryDropdown";
import { modalActions } from "Store/modal-redux";
import ReportsPaymentStatusTag from "ReportTable/ReportsPaymentStatusTag";
import clsx from "clsx";
import Shimmer from "Layout/Shimmer";
import {DashboardSummaryActions} from "../../Store/customer-redux";
import ListOfContact from "./ListOfContact";
import Modal from "Layout/Modal";
import { apiGetCustomerContactList } from '../../services';
import UseApiCall from "hooks/useApiCall";
import { ContactsActions } from '../../Store/customer-redux';
import ReceiveBulkUploadPayment from "Components/Payment/ReceiveBulkUploadPayment";

const ListOfOrganization = (props) => {
  const dispatch = useDispatch();
  const [contactPassOn, setContactPassOn] = useState({});
  const [organizationId, setOrganizationId] = useState();
  const contactList = useSelector(state => state.customerContacts.contacts)
  const createInvoiceDisplay = useSelector(
    (state) => state.modal.createInvoiceDisplay
  );
  const addContactDisplay = useSelector(
    (state) => state.modal.addContactDisplay
  );
  const addPaymentDetailDisplay = useSelector(
    (state) => state.modal.addPaymentDetailDisplay
  );
  const bulkUploaderDisplay = useSelector((state) => state.modal.bulkUploaderDisplay);

  // const [getContactList, fetching] = UseApiCall(apiGetCustomerContactList, (res) => {
  //   dispatch(ContactsActions.setcontacts(res.data))
  // })

  // useEffect(() => {
  //   getContactList({organizationId: org.organizationId});
  // }, [])

  // const [showCountry, setShowCountry] = useState(false);
  // const [selectedCountry, setSelectedCountry] = useState("All");

  // const dropdownRef = useClickOutside(() => setShowCountry(false));

  const nextPageAfterLOCHandler = async (org) => {
    setOrganizationId(org.organizationId);
    // await getContactList({organizationId: org.organizationId});
    // dispatch(modalActions.addContact())
    // dispatch(modalActions.addContact())
    // dispatch(DashboardSummaryActions.setPassOnContact([]));
    // dispatch(DashboardSummaryActions.setPassOnContact(contact));
    // setContactPassOn(contact);
    props.nextPageFunctionality();
  };

  // const selectCountry = (code) => {
  //   setShowCountry((prev) => !prev);
  //   setSelectedCountry(code);
  // };

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop
          onClick={() => {
            dispatch(modalActions.removeAll());
          }}
          className={classes.backdrop}
        />,
        document.getElementById("backdrop-root")
      )}
      {bulkUploaderDisplay &&
        ReactDOM.createPortal(
          <ReceiveBulkUploadPayment
            organizationId={organizationId}
          />,
          document.getElementById('modal-root')
      )}
      {addContactDisplay &&
        ReactDOM.createPortal(
          <Modal className={classes.modal}>
            <ListOfContact
              contactType={'Customer'}
              contactFunctionality={'receive'}
              organizationId={organizationId}
              btnFunction={(type) => {
                  if (type === "addContact") {
                      dispatch(modalActions.addContact())
                  } else {
                      dispatch(modalActions.receivePayment())
                  }
                  // dispatch(modalActions.receivePayment())
                  // dispatch(modalActions.addContact())
              }}
              btnText={'Add Customer'}
              addContactURL={''}
              nextPageFunctionality={() => {
                  props.modal === "createInvoice" ?
                      dispatch(modalActions.setCreateInvoiceModal(true))
                      :
                  dispatch(modalActions.createInvoice())
              }}
              onClose={
                  props.modal === "createInvoice" ?
                  () => {
                  dispatch(modalActions.setCreateInvoiceReceivePayment(false))
                  } :
                      () => {
                          dispatch(modalActions.addContact())
                      }
              }
              loading = {true}
              modal={props.modal}
            />
          </Modal>,
          document.getElementById("modal-root")
        )}
      {addPaymentDetailDisplay &&
        ReactDOM.createPortal(
          <AddPaymentDetail />,
          document.getElementById("modal-root")
        )}
      <div className={classes.loc}>
        <div className={classes.header}>
          <img
            src={leftPointer}
            alt="left-pointer"
            className={classes.left_pointer}
            onClick={props.onClose}
          />
          <div className={classes.subHeader}>
            <div className={classes.sub1}>Select {props.contactType}</div>
            <div className={classes.sub2}>
              To {props.contactFunctionality} payment
            </div>
          </div>
          <Close onClick={props.onClose} className={classes.close} />
        </div>
        {/* <div className={classes.filter}>
                    <Search className={classes.search} placeholder={'Search'}/>
                    <div ref={dropdownRef} className={classes.country}>
                        <div className={classes.country_label}>Country</div>
                        <div className={classes.country_box}>
                            <div className={classes.country_box_input}>{selectedCountry}</div>
                            <img src={chevronDownIcon} alt='chevron-icon' onClick={() => setShowCountry(prev => !prev)} className={`${classes.chevronDownIcon} ${showCountry && classes.chevronDownIcon_active}`}/>
                        </div>
                        {showCountry &&
                            <CountryDropdown
                                className={classes.dropdown}
                                onClick={(value) => selectCountry(value.dial_code)}
                                mobileCode={selectedCountry}
                            />
                        }
                    </div>
                </div> */}
        <div className={classes.all_list}>
          {
            props?.loading 
              ? 
              (
                [1,2,3,4,5,6,7]?.map((item, index) => (
                  <Shimmer key={index} className="w-[100%] h-[90px] mt-[12px] mb-[12px]"/>
                )) 
              ) 
              :
            (props?.contact?.length > 0 ? props?.contact?.map((cnt) => (
              <div
                key={cnt.id}
                className={classes.list}
                onClick={(e) => {
                  nextPageAfterLOCHandler(cnt);
                }}
              >
                <div className={classes.short_name}>
                  {/* {cnt.organizationName?.split?.(" ").length > 1
                    ? cnt.organizationName.split(" ")[0][0] +
                      cnt.organizationName.split(" ")[1][0]
                    : cnt.organizationName[0]} */}
                  {
                    cnt.organizationName?.split(' ')
                    .map((char) => char.charAt(0).toUpperCase())
                    .join('').slice(0,2)
                  }
                </div>
                <div className={classes.nameInfo}>
                <ContactInfo
                  className={classes.contact_info}
                  name={cnt?.organizationName}
                  country={cnt?.country}
                  phoneNumber={cnt?.phoneNumber}
                  email={cnt?.emailAddress}
                />
                {cnt.verificationStatus === "PENDING_VERIFICATION" && <ReportsPaymentStatusTag status={cnt?.verificationStatus} type="light" />}
                </div>
                <img
                  src={arrowRight}
                  alt="right-arrow-icon"
                  className={classes.arrow}
                />
              </div>
            ))
            :
            (
              props?.contact?.length === 0 && (
                <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '4rem',
                  fontSize: '2.5rem',
                  // color: 'rgba(24, 28, 49, 0.7)'
                }}
              >
                No Organization Added Yet.
              </div>
              )
            )
          )
          }
        </div>
        <div className={classes.button}>
          {/* <Button
            className={classes.btn}
            text={props.btnText}
            icon={userAdd}
            onClick={() => props.btnFunction("addContact")}
          /> */}
        </div>
      </div>
    </>
  );
};

export default ListOfOrganization;