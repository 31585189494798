// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_dropdown_all__2P-sU {
  padding: 0.8rem 0;
  z-index: var(--level_1);
}`, "",{"version":3,"sources":["webpack://./src/Components/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,uBAAA;AACJ","sourcesContent":[".dropdown_all{\n    padding: 0.8rem 0;\n    z-index: var(--level_1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown_all": `Dropdown_dropdown_all__2P-sU`
};
export default ___CSS_LOADER_EXPORT___;
