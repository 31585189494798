import React, {useEffect, useRef, useState} from "react";
import ReportTableLayout from "../../Layout/ReportTableLayout";
import classes from "./VBanksInfo.module.scss";
import clsx from "clsx";
import Shimmer from "../../Layout/Shimmer";
import {useDispatch, useSelector} from "react-redux";
import ReactDOM from "react-dom";
import ExporterProfileInfo from "./ExporterProfileInfo";
import BankDetails from "./BankDetails";
import {modalActions} from "../../Store/modal-redux";
import FullScreenModal from "../../Layout/FullScreenModal";
import Reconcilation from "../../Pages/Reconcilation";


const tableHeaderData = [
    {
        header: "VBank Currency",
        width: 50,
        maxWidth: 170
    },
    {
        header: "VBank Balance",
        width: 60,
        maxWidth: 170
    }
]

const VBanksInfo = (props) => {

    const [displayRecon,setDisplayRecon] = useState(false);
    const [selectedAccountDetails, setSelectedAccountDetails] = useState({})

    const [currency, setCurrency] = useState("");

    const showBankDetail = useSelector(state=>state.modal.showBankDetails)

    const dispatch = useDispatch();

    const handleRecon = () =>{
        props?.reconClick();
        setDisplayRecon(!displayRecon)
    }

    const handleClick = () => {
        setDisplayRecon(!displayRecon)
    }

    const handleReconClick = (row) => {
        if(row?.balance>0){
            setSelectedAccountDetails(row)
            setDisplayRecon(true);
        }

    }

    const theadRef = useRef(null);
    const tbodyRef = useRef(null);

    const [currencyDetails, setCurrencyDetails] = useState({});

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            }
            else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDetailClick = (currency) => {
        setCurrencyDetails(props?.currencyDetails[currency]?.details)
        setCurrency(currency);
        dispatch(modalActions.showBankDetails(true))
    }

    return(
        <>
            {
                showBankDetail &&
                ReactDOM.createPortal(
                    <BankDetails
                        currencyDetails = {currencyDetails}
                        currency = {currency}
                        exporterName = {props?.exporterName}
                        organizationId = {props?.organizationId}
                    />,
                    document.getElementById('modal-root')
                )
            }
            {
                displayRecon &&
                ReactDOM.createPortal(<FullScreenModal
                    children={
                    <Reconcilation
                        handleClick={handleClick}
                        handleRecon={handleRecon}
                        exporterName = {props?.exporterName}
                        totalBalance={{ sign: (selectedAccountDetails?.sign || "$"), balance: Number(selectedAccountDetails?.balance || 0)}}
                        selectedAccountDetails={selectedAccountDetails}
                        organizationId = {props?.organizationId}
                    />
                }
                    className = "!z-100"
                />,
                document.getElementById('modal-root')
                )
            }
            <div className={classes.allWrap}>
                <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                    <thead>
                    {tableHeaderData.map((header, index) => (
                        <th key={`${header}-${index}`} className={classes.cell_header1}
                            style={{width:`${header.width}%`, minWidth:`${header.maxWidth}px`, maxWidth:`${header.maxWidth}px`}}
                            valign="top"
                        >
                            <div className={classes.cell_header2}>
                                <div className={classes.head}>{header.header}</div>
                            </div>
                        </th>

                    ))}
                    <th className={classes.cell_header1}
                        style={{width:"10%", minWidth:"130px", maxWidth:"130px"}}
                        align="center"
                        valign="top"
                    >
                        <div className="flex justify-center">
                            Actions
                        </div>
                    </th>
                    </thead>
                </div>

                <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef} >
                    <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
                        <tbody>
                        { props?.loading
                            ? (
                                [1,2,3,4]?.map((item, index) => (
                                    <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                                ))
                            )
                            :
                            props?.tableData && props?.tableData.map((row,index) => {
                             return   <tr className={classes.row} >
                                    <td className={classes.cell}
                                        style={{width: '50%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                                        valign="top"
                                    >
                                        <div className = "flex flex-col gap-[4px]">
                                            <p className = "font-[600]">{row?.currency}</p>
                                            <p className = "text-[#1e333f80]">{row?.description}</p>
                                        </div>
                                    </td>
                                    <td className={classes.cell}
                                        style={{width: '60%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                                        valign="top"
                                    >
                                        <div className="flex"><span>{row?.sign}</span><span>{row?.balance}</span></div>

                                    </td>
                                    <td className={classes.cell}
                                        style={{width: '10%', minWidth: '130px', maxWidth: '130px', overflowWrap: "anywhere" }}
                                        valign="top"
                                    >
                                        <div className = "flex flex-col gap-[4px]">
                                            <button
                                                className = {`p-[4px] text-[#fff] bg-black rounded-[4px] ${row?.balance > 0 ? "" :"opacity-25 cursor-not-allowed"}`}
                                                onClick={()=>handleReconClick(row)}
                                            >Reconcile
                                            </button>
                                            <button
                                                className = "p-[4px] text-[#fff] bg-black rounded-[4px]"
                                                onClick={()=>handleDetailClick(row?.currency)}
                                            >
                                                Details
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                            </tbody>
                    </ReportTableLayout>
                </div>
            </div>
        </>
    )
}

export default VBanksInfo;