import { createSlice } from "@reduxjs/toolkit";
import action from "../Components/atom/Action";

const receivableInitialState = {
    invoice_list: [],
    selected_invoice: null,
    updateReceivables: false,
    updateOnVerify: false,
    bulkUploadData: null,
};

export const ReceivablesSlice = createSlice({
    name: "receivableReports",
    initialState: receivableInitialState,
    reducers: {
        getReceivabelReports(state, action) {
            state.invoice_list = action.payload;
        },
        getSelectedInvoice(state, action) {
            state.selected_invoice = action.payload;
        },
        setUpdateReceivables(state) {
            state.updateReceivables = !state.updateReceivables;
        },
        setUpdateOnVerify(state,action) {
            state.updateOnVerify = action.payload;
        },
        getBulkUploadData(state, action) {
            state.bulkUploadData = action.payload;
        },
        getTotalInvoiceList(state,action) {
            state.total_invoices = action.payload;
        }
    },
});

export const ReceivablesActions = ReceivablesSlice.actions;

const organizationInitialState = {
    exporterData: [],
};

export const OrganizationSlice = createSlice({
    name: "organizationReports",
    initialState: organizationInitialState,
    reducers: {
        setOrganizationTableData(state,action) {
            state.exporterData = action.payload;
        },
    },
});


export const OrganizationActions = OrganizationSlice.actions;

const customerInitialState = {
    customers : [],
    selected_customer: null,
    selected_invoice: null,
    active_invoice_tab: null,
    updateCustomers: false
}

export const CustomersSlice = createSlice({
    name: 'customer-slice',
    initialState: customerInitialState,
    reducers: {
        setcustomers(state, action){
            state.customers = action.payload
        },
        setSelectedCustomer(state, action){
            state.selected_customer = action.payload
        },
        setSelectedInvoice(state, action){
            state.selected_invoice = action.payload
        },
        setActiveInvoiceTab(state, action){
            state.active_invoice_tab = action.payload
        },
        setUpdateCustomers(state) {
            state.updateCustomers = !state.updateCustomers;
        }
    }
})

export const CustomersActions = CustomersSlice.actions

const contactInitialState = {
    contacts : []
}
const dashboardSummaryInitialState = {
    summary:[],
    dashboardTableData:[],
    showBackendError : false,
    updateDashboard : false,
    passOnContact : []
}

export const DashboardSummarySlice = createSlice({
    name: 'dashboardSummary-slice',
    initialState : dashboardSummaryInitialState,
    reducers : {

        setSummary(state, action){
            state.summary = action.payload;
        },
        setDashboardTableData(state, action){
            state.dashboardTableData = action.payload;
        },
        setShowBackendError(state, action){
            state.showBackendError = action.payload;
        },
        setUpdateDashboard(state){
            state.updateDashboard = !state.updateDashboard;
        },
        setPassOnContact(state,action){
            state.passOnContact = action.payload
        }
    }
})

export const DashboardSummaryActions = DashboardSummarySlice.actions;

export const ContactsSlice = createSlice({
    name: 'contact-slice',
    initialState: contactInitialState,
    reducers: {
        setcontacts(state, action){
            state.contacts = action.payload
        }
    }
})

export const ContactsActions= ContactsSlice.actions