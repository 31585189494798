import ReactDOM from 'react-dom'
import classes from './ComplianceChecks.module.scss'
import ReportTableLayout from '../../../Layout/ReportTableLayout'
import dayjs from 'dayjs'
import React, {useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import Shimmer from "../../../Layout/Shimmer";
import {modalActions} from "../../../Store/modal-redux";
import SearchDetailsModalComp from "./SearchDetailsModalComp";
import MobileComplianceChecksTable from "./MobileComplianceChecksTable";
import remakIcon from 'assets/Icons/remakIcon.svg'
import {Tooltip} from "antd";
import ComplianceStatusTag from "../../atom/ComplianceStatusTag";

const ComplianceTable = (props) => {
    const dispatch = useDispatch()

    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)
    const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);

    const showSearchDataComp = useSelector((state) => state.modal.showSearchDataComp)

    const handleSearchTerm = async (data) => {
        // console.log('data',data)
        dispatch(modalActions.setShowSearchDataComp(true))
        props.setSearchId(data?.searchId)
        // await getSearchDetails({ searchId: data?.searchId})
        // await fetchData(data?.searchId)
    }

    // const [getSearchDetails, fetchingSearch,SearchData] = UseApiCall(apiGetSearchComp, (res) => {
    //     // console.log('res?.data',res?.data)
    //     // dispatch(CustomersActions.setSelectedCustomer(res?.data))
    //     // setSelectedCustomer(res?.data)
    //     dispatch(modalActions.setShowSearchDataComp(true))
    // })
    //
    // useEffect(() => {
    //     if(props.searchId)
    //     getSearchDetails({ searchId: props.searchId})
    // }, [props.matchStatus,props.riskLevel]);

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            }
            else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // console.log('showSearchDataComp',showSearchDataComp)
    // console.log('props.tableData',props.tableData)
    // console.log('SearchData',SearchData)

    return (
        <>
            {
                showSearchDataComp &&
                ReactDOM.createPortal(
                    <SearchDetailsModalComp
                        searchId={props.searchId}
                        // fetchingUpdateCom={props.fetchingUpdateCom}
                        // fetchingUpdateComMatchStatus={props.fetchingUpdateComMatchStatus}
                        riskLevel={props.riskLevel}
                        setRiskLevel={props.setRiskLevel}
                        matchStatus={props.matchStatus}
                        setMatchStatus={props.setMatchStatus}
                        customerId={props?.customerId}
                        getComplianceDetails={props.getComplianceDetails}
                    />,
                    document.getElementById('modal-root')
                )
            }

            <div className={classes.allWrap} >
                <div className={clsx(`${classes.table}  ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}
                >
                    <thead>
                    {props?.tableHeader?.map((header, index) => (
                        <th key={`${header}-${index}`} className={classes.cell_header1}
                            style={{
                                width:`${header.width}%`,
                                minWidth:`${header.minWidth}px`,
                                maxWidth:`${header.minWidth}px`
                            }}
                            valign="top"
                        >
                            <div className={classes.cell_header2} >
                                <div className={classes.head}>{header?.title}</div>
                                {header?.title2 &&
                                    <div className={classes.head}>{header?.title2}</div>
                                }
                            </div>
                        </th>
                    ))}
                    </thead>
                </div>
                <div className={`${classes.reportTableWrap} `} id="tbody"  ref={tbodyRef}
                >
                    <ReportTableLayout className={clsx(classes.table,"!mt-[0px]")}>
                        <tbody className={classes.tbody}>
                        {(props.loading || props.fetchingUpdateCom)
                            ?
                            [1,2,3,4]?.map((item, index) => (
                                <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                            ))
                            :

                            props?.tableData && props?.tableData?.length > 0 && props?.tableData?.map((data,index) => {
                                const myRemarks = data?.comment ? JSON.parse(data?.comment) : null;

                                const filteredRemarks = myRemarks && myRemarks?.filter(remark => remark?.remark && remark?.remark.trim() !== '');

                                if(filteredRemarks !== null)
                                    filteredRemarks?.sort((a, b) => b?.createdAt - a?.createdAt);

                                // console.log('filteredRemarks',filteredRemarks)

                                return (
                                    <tr key={data.id} className={classes.row}>
                                        <td
                                            className={`${classes.cell} ${classes.invoice}`}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[0].width}%`,
                                                minWidth: `${props.tableHeader[0].minWidth}px`,
                                                maxWidth: `${props.tableHeader[0].minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            {data?.searchId ? data.searchId : "--"}
                                        </td>
                                        <td
                                            className={`${classes.cell}`}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[1].width}%`,
                                                minWidth: `${props.tableHeader[1].minWidth}px`,
                                                maxWidth: `${props.tableHeader[1].minWidth}px`
                                            }}
                                            valign="top"

                                        >
                                            <div className={classes.dateColumn}>
                                                <div className={classes.dateOne}>
                                                    {data?.searchDate ? dayjs(data?.searchDate).local().format('DD MMM YYYY') : "--"}
                                                </div>
                                                <div className={classes.dateTwo}>
                                                    {data?.searchDate && dayjs(data?.searchDate).local().format('hh:mm A')}
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            className={`${classes.cell} ${classes.invoice}`}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[2].width}%`,
                                                minWidth: `${props.tableHeader[2].minWidth}px`,
                                                maxWidth: `${props.tableHeader[2].minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            {data?.searchTerm ? data.searchTerm : "--"}
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[3].width}%`,
                                                minWidth: `${props.tableHeader[3].minWidth}px`,
                                                maxWidth: `${props.tableHeader[3].minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <div className={classes.amount}>
                                                {data?.totalHits ? data?.totalHits : "--"}
                                            </div>
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[4].width}%`,
                                                minWidth: `${props.tableHeader[4].minWidth}px`,
                                                maxWidth: `${props.tableHeader[4].minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            {data?.matchStatus ?
                                                <ComplianceStatusTag status={data?.matchStatus} type="light"/> : "--"}
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[5].width}%`,
                                                minWidth: `${props.tableHeader[5].minWidth}px`,
                                                maxWidth: `${props.tableHeader[5].minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            {data?.riskLevel ? `${data?.riskLevel?.slice(0,1)?.toUpperCase()}${data?.riskLevel?.slice(1, data?.riskLevel?.length)?.toLowerCase()}` : "--"}
                                        </td>

                                        <td className={classes.cell}
                                            style={{
                                                // userSelect: 'none',
                                                // cursor: 'pointer',
                                                width: `${props.tableHeader[6].width}%`,
                                                minWidth: `${props.tableHeader[6].minWidth}px`,
                                                maxWidth: `${props.tableHeader[6].minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <div className="flex  gap-[8px]">
                                                {filteredRemarks?.length > 0 ?
                                                    <Tooltip
                                                        title={<div>{filteredRemarks[0]?.remark}</div>}
                                                        placement="left"
                                                    >
                                                        <img className='w-[20px] h-[20px] cursor-pointer' src={remakIcon}
                                                             alt='quote-icon'/>
                                                    </Tooltip>
                                                    :
                                                    <div className='w-[20px] h-[20px]'>{" "}</div>
                                                }
                                                <button
                                                    className="p-[4px] text-[#fff] bg-black  rounded-[4px] cursor-pointer"
                                                    onClick={() => handleSearchTerm(data)}
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        {(props.tableData && props?.tableData.length === 0 && !props.loading) ?
                            (<div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop: '4rem',
                                    fontSize: '16px',
                                    fontWeight: '500'
                                }}
                                className={classes.table}
                            >
                                No compliance search found.
                            </div>) :
                            null
                        }
                        </tbody>
                    </ReportTableLayout>
                </div>

            </div>
            <MobileComplianceChecksTable
                tableData={props.tableData}
                resData={props?.resData}
                loading={props.loading}
                handleCustomerSelection={handleSearchTerm}
            />
        </>
    )
}

export default ComplianceTable;
