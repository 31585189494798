import classes from './Receivables.module.scss';
import PageIntro from '../Layout/PageIntro';
import ReceivableReportTable from '../ReportTable/ReceivableReportTable';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs'
import ReceivablesFilter from '../Components/atom/ReceivablesFilter';
import { useEffect, useMemo, useState } from 'react';
import UseApiCall from '../hooks/useApiCall';
import {
  apiGetReceivablesData,
  apiGetFilterExportersList,
  apiGetFilterCustomersList,
  apiGetFilterStatusesList
} from '../services';
import CustomModal from '../Components/atom/CustomModal/CustomModal';
import { useDispatch, useSelector } from "react-redux";
import { ReceivablesActions } from "../Store/customer-redux";
import { enqueueSnackbar } from 'notistack';

const Receivables = () => {
  const dispatch = useDispatch()
  const [filteredData, setFilteredData] = useState();
  const [resData, setResData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [modalRef, setModalRef] = useState(null);

  const [selectedExporters, setSelectedExporters] = useState({});
  const [showExportersDropdown, setShowExportersDropdown] = useState(false);
  const [exportersList, setExportersList] = useState();
  const [exportersCounter, setExportersCounter] = useState('0');

  const [selectedCustomers, setSelectedCustomers] = useState({});
  const [allSelected, setAllSelected] = useState(true);
  const [allSelectedExporters, setAllSelectedExporters] = useState(true);
  const [showExporterDropdown, setShowExporterDropdown] = useState(false);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [isSearch, setIsSearch] = useState();
  const [customers, setCustomers] = useState();
  const [customerCounter, setCustomerCounter] = useState('0');
  const [statuses, setStatuses] = useState();
  const [selectedStatus, setSelectedStatus] = useState(["verified", "UNDER REVIEW"]);
  const [selectedStatuses, setSelectedStatuses] = useState({});
  const [allStatusSelected, setAllStatusSelected] = useState(true);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showInvoiceDateDropdown, setShowInvoiceDateDropdown] = useState(false);
  const [showDateCounter, setShowDateCounter] = useState(false);
  const [statusCounter, setStatusCounter] = useState('0');
  const [activeDate, setActiveDate] = useState('all');
  const [selectedDate, setSelectedDate] = useState({
    fromDate: '',
    toDate: ''
  });
  const [firstApiCall, setFirstApiCall] = useState(false);
  const [pageRendered, setPageRendered] = useState(true);

  const updateOnVerify = useSelector((state) => state.receivable.updateOnVerify);

  const handlePaginate = (page) => {
    setCurrentPage(page - 1)
  }

  const toggleCustomerDropdown = () => {
    setShowCustomerDropdown(!showCustomerDropdown);
    setShowExporterDropdown(false);
    setShowStatusDropdown(false);
    setShowInvoiceDateDropdown(false);
  };

  const toggleExporterDropdown = () => {
    setShowCustomerDropdown(false);
    setShowExporterDropdown(!showExporterDropdown);
    setShowStatusDropdown(false);
    setShowInvoiceDateDropdown(false);
  };

  const toggleSearchDropdown = () => {
    setShowSearchDropdown(false);
    setShowStatusDropdown(false);
    setShowInvoiceDateDropdown(false);
  };

  const toggleCustomerSelection = async (customerId, len) => {
    setCurrentPage(0)
    setSelectedCustomers(prevSelectedCustomers => {
      const updatedSelection = {
        ...prevSelectedCustomers,
        [customerId]: !prevSelectedCustomers[customerId]
      };
      const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;

      setCustomerCounter(countTrueKeys);
      setAllSelected(countTrueKeys === len);
      return updatedSelection;
    });
  };

  const toggleExportersSelection = async (exporterId, len) => {
    setCurrentPage(0)
    setSelectedExporters(prevSelectedExporters => {
      const updatedSelection = {
        ...prevSelectedExporters,
        [exporterId]: !prevSelectedExporters[exporterId]
      };
      const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;

      setExportersCounter(countTrueKeys);
      setAllSelectedExporters(countTrueKeys === len);
      return updatedSelection;
    });
  };

  const toggleStatusDropdown = () => {
    setShowCustomerDropdown(false);
    setShowExporterDropdown(false);
    setShowStatusDropdown(!showStatusDropdown);
    setShowInvoiceDateDropdown(false);
  };

  const toggleStatusSelection = (status, len) => {
    // setCurrentPage(1)
    if (status === 'reset') {
      setAllStatusSelected(false)
      setStatusCounter('0')
      return setSelectedStatuses({});
    }
    setSelectedStatuses(prevSelectedStatuses => {
      const updatedSelection = {
        ...prevSelectedStatuses,
        [status]: !prevSelectedStatuses[status]
      };
      const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
      setStatusCounter(countTrueKeys)
      setAllStatusSelected(statuses?.length === countTrueKeys)

      return updatedSelection;
    });
  };

  const toggleInvoiceDateDropdown = () => {
    setShowCustomerDropdown(false);
    setShowExporterDropdown(false);
    setShowStatusDropdown(false);
    setShowInvoiceDateDropdown(!showInvoiceDateDropdown);
  };

  const handleDateSelection = (period) => {
    // setCurrentPage(1)
    setShowDateCounter(period === 'all' ? false : true)
    setActiveDate(period)
    const currentDate = dayjs();
    let fromDate, toDate;

    switch (period) {
      case 'all':
        fromDate = '';
        toDate = '';
        break;
      case 'one':
        fromDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
        toDate = currentDate.format('YYYY-MM-DD');
        break;
      case 'three':
        fromDate = currentDate.subtract(3, 'month').format('YYYY-MM-DD');
        toDate = currentDate.format('YYYY-MM-DD');
        break;
      case 'six':
        fromDate = currentDate.subtract(6, 'month').format('YYYY-MM-DD');
        toDate = currentDate.format('YYYY-MM-DD');
        break;
      default:
        // Handle default case or throw an error if needed
        break;
    }

    setSelectedDate({
      fromDate: fromDate,
      toDate: toDate
    });
  };

  const exportData = async () => {
    const workbook = new Excel.Workbook();

    const columns = [
      { header: 'Exporter Name', key: 'exporter_name' },
      { header: 'Invoice ID', key: 'invoice_id' },
      { header: 'Issue Date', key: 'issue_date' },
      { header: 'Customer Name', key: 'customer_name' },
      { header: 'Customer Country', key: 'customer_country' },
      { header: 'Receivable Amount (Currency)', key: 'receivable_amount_currency' },
      { header: 'Receivable Amount (Value)', key: 'receivable_amount' },
      { header: 'Converted Receivable Amount (Currency)', key: 'converted_receivable_amount_currency' },
      { header: 'Converted Receivable Amount (Value)', key: 'converted_receivable_amount' },
      { header: 'Pending Amount (Currency)', key: 'pending_amount_currency' },
      { header: 'Pending Amount (Value)', key: 'pending_amount' },
      //   { header: 'Due Date', key: "due_date"},
      { header: 'Status', key: 'status' },
      { header: 'Status Updated On', key: 'status_updated_on' },
      //   { header: 'Estimated Savings (Currency)', key: 'estimated_savings_currency' },
      //   { header: 'Estimated Savings Amount (Value)', key: 'estimated_savings_amount' },
      //   { header: 'Remarks', key:'remarks' }
    ];
    const data = filteredData?.map(item => ({
      exporter_name: item?.organizationName,
      invoice_id: item?.invoiceId,
      issue_date: `${dayjs(item?.issueDate).format('D MMM YYYY')}`,
      customer_name: item?.customer?.customerName,
      customer_country: item?.customer?.countryName,
      receivable_amount_currency: `${item?.invoiceCurrency?.code ? item?.invoiceCurrency?.code : '--'}`,
      receivable_amount: item?.receivingAmount ? item?.receivingAmount?.toFixed(2) : '--',
      converted_receivable_amount_currency: `${item?.conversionInvoiceCurrency?.code ? item?.conversionInvoiceCurrency?.code : '--'}`,
      converted_receivable_amount: item?.convertedInvoiceAmount ? item?.convertedInvoiceAmount?.toFixed(2) : '--',
      pending_amount_currency: `${item?.invoiceCurrency?.code ? item?.invoiceCurrency?.code : '--'}`,
      pending_amount: item?.pendingAmount ? item?.pendingAmount?.toFixed(2) : '--',
      //   due_date: item?.dueDate ? item?.dueDate : '--',
      status: item?.status,
      status_updated_on: `${item?.lastUpdatedTime ? dayjs(item?.lastUpdatedTime).format('D MMM YYYY') : '--'}`,
      //   estimated_savings_currency: `${item?.savingCurrency?.code ? item?.savingCurrency?.code : '--'}`,
      //   estimated_savings_amount: item?.savingAmount ? item?.savingAmount?.toFixed(2) : '--',
      //   remarks: item?.remarks ? item?.remarks : '--'
    }));

    try {
      const fileName = "ReceivableReport";

      const worksheet = workbook.addWorksheet("ReceivableTableData");

      worksheet.columns = columns;

      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF434343' },
      };

      worksheet.getColumn(1).font = { bold: true };

      worksheet.getRow(1).font = {
        color: { argb: 'FFFFFFFF' },
        bold: true,
      };

      const columnsToAlignRight = [7, 9, 11];

      columnsToAlignRight.forEach(columnIndex => {
        worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
      });

      worksheet.columns.forEach(column => {
        column.width = column.header.length;
        // column.alignment = { horizontal: 'center' };
      });

      data.forEach(singleData => {
        worksheet.addRow(singleData);
      });

      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();

      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet('MyWorkBook');
    }
  }

  const [getReceivableTableData, fetchinggetReceivableTableData, ReceivableTabledata, Tableerror] = UseApiCall(apiGetReceivablesData,
    (res) => {
      setResData(res?.data)
      setFilteredData([])
      setFilteredData(res?.data?.content)

      const uniqueOrganizationsMap = new Map();

      res?.data?.content?.forEach((organization) => {
        const organizationName = organization.organizationName;
        if (!uniqueOrganizationsMap.has(organizationName)) {
          uniqueOrganizationsMap.set(organizationName, organization);
          return true
        } else {
          return false
        }
      });

      const uniqueOrganizations = Array.from(uniqueOrganizationsMap.values());
      // setCustomers(uniqueOrganizations);

      // const uniqueStatuses = [...new Set(res?.data?.content?.map(data => data?.status))];
      // setStatuses(uniqueStatuses);
      dispatch(ReceivablesActions.setUpdateOnVerify(false))

      // dispatch(DashboardSummaryActions.setDashboardTableData(res?.data));
    }, (err) => {
      console.log(err)
      // dispatch(DashboardSummaryActions.setShowBackendError(err));
    });

  // useEffect(() => {
  //     getReceivableTableData({
  //       pageNum : currentPage,
  //       size : pageSize
  //     });
  // }, [currentPage,pageSize,updateOnVerify])

  const selectedCustomerIds = useMemo(() => {
    return Object.keys(selectedCustomers)?.filter(customerId => selectedCustomers[customerId]);
  }, [selectedCustomers]);

  const [getFilterExportersList, fetchingFilterExportersList, filterExportersListData] = UseApiCall(apiGetFilterExportersList, (res) => {
    setExportersList(res?.data)
    const resultObject = {};
    res?.data.forEach(item => {
      resultObject[item?.organizationId] = true;
    });
    setSelectedExporters(resultObject);
  }, (err) => {
    enqueueSnackbar('Error while fetching Exporters List.', { variant: 'error' })
  })

  const [getReceivableCustomers, fetchingCustomers] = UseApiCall(apiGetFilterCustomersList, (res) => {
    const data = res?.data?.reduce((acc, obj) => {
      if (obj?.id) {
        acc[obj?.id] = true;
      }
      return acc;
    }, {});
    // if (JSON.stringify(res?.data) === '[]') {
    //   setFirstApiCall(true);
    // }
    setCustomerCounter('0')
    setSelectedCustomers(data)
    setAllSelected(true);
    setCustomers(res?.data)
  }, (err) => {
    // enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  })

  const [getReceivableStatuses, fetchingStatuses] = UseApiCall(apiGetFilterStatusesList, (res) => {
    setFirstApiCall(true);
    if (pageRendered) {
      setPageRendered(false)
      const data = res?.data?.reduce((acc, obj) => {
        if (obj) {
          acc[obj] = true;
        }
        return acc;
      }, {});
      setStatusCounter(res?.data?.length)
      setAllStatusSelected(true)
      setSelectedStatuses(data)
    } else {
      const newSelectedStatuses = {};
      res?.data?.forEach(status => {
        if (status in selectedStatuses) {
          newSelectedStatuses[status] = (selectedStatuses[status] === true || allStatusSelected) ? true : false
        } else {
          newSelectedStatuses[status] = allStatusSelected ? true : false
        }
      });
      setSelectedStatuses(newSelectedStatuses)
      setStatusCounter(Object.values(newSelectedStatuses).filter(value => value).length)
    }
      setStatuses(res?.data)
    }, (err) => {
      // enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

  useEffect(() => {
    const keysWithTrueValues = Object.entries(selectedExporters).filter(([key, value]) => value === true).map(([key, value]) => parseInt(key));
    if (keysWithTrueValues?.length > 0 && (keysWithTrueValues?.length !== Object?.keys(selectedExporters)?.length || !firstApiCall)) getReceivableCustomers({organizationIds: keysWithTrueValues});
  }, [selectedExporters]);

  useEffect(() => {
    if (selectedCustomerIds?.length > 0) {
      getReceivableStatuses({
          customerIds: selectedCustomerIds,
      })
    }
  }, [selectedCustomerIds]);

  const selectedExportersList = Object.keys(selectedExporters).filter(status => selectedExporters[status]);

  useEffect(() => {
    getFilterExportersList('default');
  }, [])

  const selectedStatusList = Object.keys(selectedStatuses).filter(status => selectedStatuses[status]);

  // console.log(selectedStatuses, selectedStatusList, statuses, "selectedStatusList")

  useEffect(() => {
    if (selectedCustomerIds?.length > 0 && selectedStatusList?.length > 0 && !firstApiCall) {
      setFirstApiCall(true);
    }
  }, [selectedCustomers, selectedStatuses, selectedExporters, currentPage, updateOnVerify])

  useEffect(() => {
    if (firstApiCall) {
      let params = {
        pageNum: currentPage,
        size: pageSize,
      }

      if (Object.keys(selectedExporters)?.length !== selectedExportersList?.length) {
        params.organizationIds = selectedExportersList;
      }

      if (Object.keys(selectedStatuses)?.length !== selectedStatusList?.length) {
        params.invoiceStatuses = selectedStatusList;
      }
    
      if (customers?.length !== selectedCustomerIds?.length) {
        params.customerIds = selectedCustomerIds;
      }
      getReceivableTableData(params);
    }
  }, [currentPage, selectedStatuses, firstApiCall, updateOnVerify])

  // if(updateOnVerify){
  //   useEffect(() => {
  //     const fetchData = () => {
  //       getReceivableTableData({
  //         pageNum: currentPage,
  //         size: pageSize
  //       });
  //     };
  //
  //     const timer = setTimeout(fetchData, 3000);
  //     return () => {
  //       clearTimeout(timer)
  //       dispatch(ReceivablesActions.setUpdateOnVerify(false))
  //     };
  //   }, [updateReceivables]);
  // }


  return (
    <>
      <div className={classes.receivables}>
        <CustomModal ref={setModalRef} />
        <PageIntro
          pageName={"Receivables"}
          message={`View your ${ReceivableTabledata?.data?.content?.length ? ReceivableTabledata?.data?.content?.length : ''} receivables in one place`}
          downloadBtn={true}
          handleExport={exportData}
        />
        <ReceivablesFilter
          allData={ReceivableTabledata?.data?.content}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          isStatus={false}
          placeHolder={'Search by Invoice id'}
          customers={customers}
          setCustomers={setCustomers}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          customerCounter={customerCounter}
          setCustomerCounter={setCustomerCounter}
          exportersList={exportersList}
          setExportersList={setExportersList}
          selectedExporters={selectedExporters}
          setExportersCounter={setExportersCounter}
          exportersCounter={exportersCounter}
          setSelectedExporters={setSelectedExporters}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          allSelectedExporters={allSelectedExporters}
          setAllSelectedExporters={setAllSelectedExporters}
          toggleSearchDropdown={toggleSearchDropdown}
          setShowSearchDropdown={setShowSearchDropdown}
          toggleExportersSelection={toggleExportersSelection}
          toggleCustomerSelection={toggleCustomerSelection}
          toggleCustomerDropdown={toggleCustomerDropdown}
          toggleExporterDropdown={toggleExporterDropdown}
          showCustomerDropdown={showCustomerDropdown}
          showExporterDropdown={showExporterDropdown}
          setShowCustomerDropdown={setShowCustomerDropdown}
          setShowExporterDropdown={setShowExporterDropdown}
          statuses={statuses}
          allStatusSelected={allStatusSelected}
          setAllStatusSelected={setAllStatusSelected}
          setSelectedStatuses={setSelectedStatuses}
          showStatusDropdown={showStatusDropdown}
          setShowStatusDropdown={setShowStatusDropdown}
          toggleStatusDropdown={toggleStatusDropdown}
          statusCounter={statusCounter}
          setStatusCounter={setStatusCounter}
          toggleStatusSelection={toggleStatusSelection}
          selectedStatuses={selectedStatuses}
          setShowInvoiceDateDropdown={setShowInvoiceDateDropdown}
          toggleInvoiceDateDropdown={toggleInvoiceDateDropdown}
          showDateCounter={showDateCounter}
          showInvoiceDateDropdown={showInvoiceDateDropdown}
          handleDateSelection={handleDateSelection}
          activeDate={activeDate}
          setIsSearch={setIsSearch}
        />
        <ReceivableReportTable
          tableData={filteredData}
          resData={resData}
          loading={fetchinggetReceivableTableData}
          currentPage={currentPage}
          handlePaginate={handlePaginate}
          pageSize={pageSize}
          isSearch={isSearch}
        />
      </div>
    </>
  )
}

export default Receivables