import classes from './ContactInfo.module.scss';
import clsx from 'clsx';
import check from "../assets/Icons/check.svg";
import greenCheck1 from "../assets/Icons/greenCheck1.png";
import greenCheckFill from "../assets/Icons/greenCheckFill.png";
// import { CheckCircleOutlined } from '@ant-design/icons';

const ContactInfo = (props) => {
  return (
    <div className={clsx(classes.contact_info, props.className, 'w-[100%]')}>
      <div className={clsx(classes.name_country_receivable_report, props.classExporterName)}>
        <p className={clsx(classes.name )} style={{ overflowWrap: 'anywhere', display: 'inline-block' }}>
          {props.name}
          {props.country && <div className={classes.dot} style={{ display: 'inline-block', margin: "4px" }}></div>}
          {props.country}

          {props.tick && <img src={greenCheckFill} 
          className={classes.check} 
          style={{ display: 'inline-block',
           marginBottom : '4px'  , marginLeft: "8px" ,
            width : '15px' , height : '15px'}} />}
          {/* {props.tick && 
            <div style={{ color: 'green', fontSize: '24px' }}>
              <CheckCircleOutlined style={{ opacity: '0.5' }} />
            </div>
          } */}
        </p>
      </div>
      <p className={clsx(classes.email, 'truncate w-full', props.emailClass)}>{props.email}</p>
    </div>
  )
}
export default ContactInfo;
