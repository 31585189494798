import * as React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import CustomModal from "../../../Components/atom/CustomModal/CustomModal";
import classes from './FileViewer.module.css'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import Backdrop from '../../../Layout/Backdrop'
import { useDispatch } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import Close from '../../../Layout/Close';

const ZoomButtonsModal = ({ fileUrl }) => {
    const zoomPluginInstance = zoomPlugin();
    const dispatch = useDispatch()
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    const viewerRef = React.useRef(null);

    const [zoomLevel, setZoomLevel] = React.useState(60);
    const [imgZoomLevel , setImgZoomLevel] = React.useState(70);

    const handleZoomIn = () => {
      if (zoomLevel > 10) {
        setZoomLevel(zoomLevel - 10);
      }
      if(imgZoomLevel>10){
        setImgZoomLevel(imgZoomLevel - 10);
      }
    };
  
    const handleZoomOut = () => {
      if (zoomLevel < 90) {
        setZoomLevel(zoomLevel + 10);
      }
      if(imgZoomLevel<190) {
        setImgZoomLevel(imgZoomLevel+10)
      }
    };

    return (
      <CustomModal
        // ref={setModalRef}
        closeOnClickOutside={true}
        onClose={() => {
            localStorage.removeItem('zoomkaindexx')
            dispatch(modalActions.showFileDisplay())
        }}
        className={clsx(
          'customModal',
          'max-h-[100%] overflow-y-auto rounded-lg bg-bg-white backdrop-blur-md min-h-[95%] w-[90%] -sm:w-[100%] min-w-fit shadow-modal'
        )}
      >
        {ReactDOM.createPortal(
            <Backdrop
                onClick={() => {
                    localStorage.removeItem('zoomkaindexx')
                    dispatch(modalActions.showFileDisplay())
                }}
                className={classes.backdrop}
            />,
            document.getElementById('backdrop-root')
        )}
        <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '4px',
                    zIndex: "100",
                    // width: "100%",
                    // left: "30%",
                    // alignItems: "center",
                    // position: "fixed",
                }}
            >
                <div className="w-[32px] h-[32px]"></div>
                <div style={{ display: "flex" }}>
                    <div style={{ padding: '0px 2px' }} onClick={handleZoomIn}>
                        <ZoomOutButton />
                    </div>
                    { ((fileUrl?.type?.includes("jpg") || fileUrl?.type?.includes("jpeg") || fileUrl?.type?.includes("png") || fileUrl?.type?.includes("image"))) ?
                        <div></div> :
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomPopover />
                        </div>
                    }
                    <div style={{ padding: '0px 2px' }} onClick={handleZoomOut}>
                        <ZoomInButton />
                    </div>
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <Close
                        className="w-[32px] h-[32px]"
                        onClick={() => {
                            localStorage.removeItem('zoomkaindexx')
                            dispatch(modalActions.showFileDisplay())
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    // flex: 1,
                    // overflow: (fileUrl?.type?.includes("jpg") || fileUrl?.type?.includes("jpeg") || fileUrl?.type?.includes("png") || fileUrl?.type?.includes("image")) ? 'scroll' : 'hidden',
                    zoom: (fileUrl?.type?.includes("jpg") || fileUrl?.type?.includes("jpeg") || fileUrl?.type?.includes("png") || fileUrl?.type?.includes("image")) ? `${0}%` : '',
                    scale: (fileUrl?.type?.includes("jpg") || fileUrl?.type?.includes("jpeg") || fileUrl?.type?.includes("png") || fileUrl?.type?.includes("image")) ? `1.${0}` : '',
                    paddingTop: (fileUrl?.type?.includes("jpg") || fileUrl?.type?.includes("jpeg") || fileUrl?.type?.includes("png") || fileUrl?.type?.includes("image")) ? `0px` : '0',
                    display:"grid",
                    placeItems:"center",
                    height: "100%"
                }}
            >
                {
                    (false)
                    ?
                        <img src={fileUrl?.url} alt="Image"/>
                    :
                        (
                            (fileUrl?.type?.includes("pdf") || fileUrl?.type?.includes("docx") || fileUrl?.type?.includes("application"))
                            ?
                                <Viewer fileUrl={fileUrl?.url} ref={viewerRef} plugins={[zoomPluginInstance]} />
                            :
                                // <iframe id='myIframe' src={`${fileUrl?.url}`}  style={{ width: "100%", height: "100%" }}></iframe>
                             <img src={`${fileUrl?.url}`} style={{ width: `${imgZoomLevel}%` ,maxWidth:"200%"}}/>
                        )

                }
            </div>
        </div>
        </CustomModal>
    );
};

export default ZoomButtonsModal;