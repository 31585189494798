import CryptoJS from 'crypto-js';

export const handleDecrypt = (encryptedString) => {
    const encryptAndDecryptSecretKey = "testFideo11sfsdf";
    const iv = "testFideoSalt123";
    try {
      const decrypted = CryptoJS.AES.decrypt(
        encryptedString,
        CryptoJS.enc.Utf8.parse(encryptAndDecryptSecretKey),
        { iv: CryptoJS.enc.Utf8.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      const decryptedObject = JSON.parse(decryptedText);
      return decryptedObject;
    } catch (error) {
      console.error('Decryption failed:', error.message);
    }
};