import classes from './Payouts.module.scss';
import { useDispatch, useSelector } from 'react-redux'
import {
    apiGetPayoutReports,
    apiGetFilterExportersList
} from '../services'
import { FiraReportsActions } from '../Store/reports-redux'
import { useEffect, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack';
import PageIntro from '../Layout/PageIntro';
import PayoutReportTable from '../ReportTable/PayoutReportTable';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import UseApiCall from '../hooks/useApiCall';
import PayoutFilter from '../Components/atom/PayoutFilter';


const Payouts = () => {
    const dispatch = useDispatch()
    const payoutReports = useSelector((state) => state.reports.payout_reports);
    const [resData, setResData] = useState();
    const [filteredData, setFilteredData] = useState();
    const [curentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(30);

    const [selectedCustomers, setSelectedCustomers] = useState({});
    const [selectedExporters, setSelectedExporters] = useState({});
    const [allSelected, setAllSelected] = useState(true);
    const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
    const [showExportersDropdown, setShowExportersDropdown] = useState(false);
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [isSearch, setIsSearch] = useState();
    const [customers, setCustomers] = useState();
    const [exportersList, setExportersList] = useState();
    const [customerCounter, setCustomerCounter] = useState('0');
    const [exportersCounter, setExportersCounter] = useState('0');
    const [statuses, setStatuses] = useState();
    const [selectedStatus, setSelectedStatus] = useState(["verified", "UNDER REVIEW"]);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [allStatusSelected, setAllStatusSelected] = useState(true);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showInvoiceDateDropdown, setShowInvoiceDateDropdown] = useState(false);
    const [showDateCounter, setShowDateCounter] = useState(false);
    const [statusCounter, setStatusCounter] = useState('0');
    const [activeDate, setActiveDate] = useState('all');
    const [selectedDate, setSelectedDate] = useState({
        fromDate: '',
        toDate: ''
    });

    const handlePaginate = (page) => {
        setCurrentPage(page - 1)
    }

    const toggleCustomerDropdown = () => {
        setShowCustomerDropdown(!showCustomerDropdown);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleSearchDropdown = () => {
        setShowSearchDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleExportersSelection = async (exporterId, len) => {
        setCurrentPage(0)
        // setSelectedCustomers(prevSelectedCustomers => {
        //     const updatedSelection = {
        //         ...prevSelectedCustomers,
        //         [customerId]: !prevSelectedCustomers[customerId]
        //     };
        //     const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;

        //     setCustomerCounter(countTrueKeys);
        //     setAllSelected(countTrueKeys === len);
        //     return updatedSelection;
        // });

        setSelectedExporters(prevSelectedExporters => {
            const updatedSelection = {
                ...prevSelectedExporters,
                [exporterId]: !prevSelectedExporters[exporterId]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;

            setCustomerCounter(countTrueKeys);
            setAllSelected(countTrueKeys === len);
            return updatedSelection;
        });
    };

    const toggleStatusDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(!showStatusDropdown);
        setShowInvoiceDateDropdown(false);
    };

    const toggleStatusSelection = (status, len) => {
        // setCurrentPage(1)
        if (status === 'reset') {
            setAllStatusSelected(false)
            setStatusCounter('0')
            return setSelectedStatuses({});
        }
        setSelectedStatuses(prevSelectedStatuses => {
            const updatedSelection = {
                ...prevSelectedStatuses,
                [status]: !prevSelectedStatuses[status]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            setStatusCounter(countTrueKeys)
            setAllStatusSelected(statuses?.length === countTrueKeys)

            return updatedSelection;
        });
    };

    const toggleInvoiceDateDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(!showInvoiceDateDropdown);
    };

    const handleDateSelection = (period) => {
        // setCurrentPage(1)
        setShowDateCounter(period === 'all' ? false : true)
        setActiveDate(period)
        const currentDate = dayjs();
        let fromDate, toDate;

        switch (period) {
            case 'all':
                fromDate = '';
                toDate = '';
                break;
            case 'one':
                fromDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'three':
                fromDate = currentDate.subtract(3, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'six':
                fromDate = currentDate.subtract(6, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            default:
                // Handle default case or throw an error if needed
                break;
        }

        setSelectedDate({
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const [otherLoader, setOtherLoader] = useState(true)
    const [getPayoutReports, fetchingPayouts, payoutsData, payoutsError] = UseApiCall(apiGetPayoutReports, (res) => {
        setOtherLoader(false);
        setResData(res?.data)
        setFilteredData(res?.data?.content)
        dispatch(FiraReportsActions.setPayoutReports(res?.data?.content))

        const uniqueOrganizationsMap = new Map();

        res?.data?.content?.forEach((organization) => {
            const organizationName = organization.organizationName;
            if (!uniqueOrganizationsMap.has(organizationName)) {
                uniqueOrganizationsMap.set(organizationName, organization);
                return true
            } else {
                return false
            }
        });

        const uniqueOrganizations = Array.from(uniqueOrganizationsMap.values());
        setCustomers(uniqueOrganizations);

        const uniqueStatuses = [...new Set(res?.data?.content?.map(data => data?.status))];
        setStatuses(uniqueStatuses);
    }, (err) => {
        setOtherLoader(false);
        enqueueSnackbar('Error while fetching Payouts.', { variant: 'error' })
    })

    const [getFilterExportersList, fetchingFilterExportersList, filterExportersListData] = UseApiCall(apiGetFilterExportersList, (res) => {
        setExportersList(res?.data)
        const resultObject = {};
        res?.data.forEach(item => {
            resultObject[item?.organizationId] = true;
        });
        setSelectedExporters(resultObject);
    }, (err) => {
        enqueueSnackbar('Error while fetching Exporters List.', { variant: 'error' })
    })

    const selectedExportersList = Object.keys(selectedExporters).filter(status => selectedExporters[status]);

    useEffect(() => {
        getFilterExportersList('payouts');
    }, [])

    useEffect(() => {
        if (!fetchingFilterExportersList && Object.keys(selectedExporters)?.length) {
            let params = {
                pageNum: curentPage,
                size: pageSize,
            }
            if (Object.keys(selectedExporters)?.length !== selectedExportersList?.length) {
                params.organizationIds = selectedExportersList;
            }
            getPayoutReports(params);
        }
    }, [curentPage, pageSize, selectedExporters])

    const exportData = async () => {
        const workbook = new Excel.Workbook();

        const columns = [
            { header: 'Exporter Name', key: 'exporter_name' },
            { header: 'Initiated On', key: 'initiated_on' },
            { header: 'Payout Reference', key: 'payout_reference' },
            { header: 'Invoice Id', key: 'invoice_id' },
            { header: 'Gross Payout Total (Currency)', key: 'gross_payout_total_currency' },
            { header: 'Gross Payout Breakup (Value)', key: 'gross_payout_breakup' },
            { header: 'Gross Payout Total (Value)', key: 'gross_payout_total_value' },
            { header: 'Settled Amount Total (Currency)', key: 'settled_amount_total_currency' },
            { header: 'Settled Amount Breakup (Value)', key: 'settled_amount_breakup' },
            { header: 'Settled Amount Total (Value)', key: 'settled_amount_total_value' },
            { header: 'Payment Advice', key: 'payment_advice' },
        ];


        let data = [];

        payoutReports?.forEach((item, ind) => {
            item?.invoices?.forEach((subItem, index) => {
                if (true) {

                    const idRow = {
                        'exporter_name': item?.organizationName,
                        'initiated_on': `${dayjs(item?.initiatedOn).format('D MMM YYYY')}`,
                        'payout_reference': `${item?.payoutReference}`,
                        'invoice_id': `${subItem?.invoiceId}`,
                        'gross_payout_total_currency': `${item?.invoiceCurrency?.code}`,
                        'gross_payout_breakup': `${subItem?.grossAmount?.toFixed(2)}`,
                        'gross_payout_total_value': `${item?.grossPayout?.toFixed(2)}`,
                        'settled_amount_total_currency': `${item?.settledAmountCurrency?.code}`,
                        'settled_amount_breakup': `${subItem?.settledAmount?.toFixed(2)}`,
                        'settled_amount_total_value': `${item?.settledAmount?.toFixed(2)}`,
                        'payment_advice': `${item?.firaStatus}`,
                    }
                    // columnsToMerge.forEach(column => {
                    //   mergeRanges.push({ row: `${column}${ind + 2}:${column}${ind + 3}` });
                    // });
                    data.push(idRow);
                }
            });
        });

        try {
            const fileName = "PayoutsReport";

            const worksheet = workbook.addWorksheet("PayoutsTableData");

            worksheet.columns = columns;

            worksheet.getRow(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF434343' },
            };

            worksheet.getColumn(1).font = { bold: true };

            worksheet.getRow(1).font = {
                color: { argb: 'FFFFFFFF' },
                bold: true,
            };

            const columnsToAlignRight = [6, 7, 9, 10];

            columnsToAlignRight.forEach(columnIndex => {
                worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
            });

            worksheet.getRow(1).alignment = { horizontal: 'left' };

            worksheet.columns.forEach(column => {
                column.width = column.header.length;
                // column.alignment = { horizontal: 'center' };
            });

            data.forEach(singleData => {
                worksheet.addRow(singleData);
            });

            // mergeRanges.forEach(({ row }) => {
            //   worksheet.mergeCells(row);
            // });

            worksheet.eachRow({ includeEmpty: false }, row => {
                const currentCell = row._cells;

                currentCell.forEach(singleCell => {
                    const cellAddress = singleCell._address;

                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });

            const buf = await workbook.xlsx.writeBuffer();

            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            workbook.removeWorksheet('MyWorkBook');
        }
    }

    return (
        <div className={classes.payouts}>
            <PageIntro
                pageName={'Payouts'}
                message={`View your ${payoutsData?.data?.content?.length ? payoutsData?.data?.content?.length : ''} payouts in one place`}
                // arrBtn={arrBtn}
                className={classes.pageIntroPayouts}
                downloadBtn={true}
                // exportDataList={payoutReports}
                // loadingDownloadBtn={true}
                handleExport={exportData}
            // loadingDownloadBtn={fetchingPayouts || payoutReports?.length === 0}
            />
            <PayoutFilter
                allData={payoutReports}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                isStatus={false}
                placeHolder={'Search by Payout id'}
                customers={customers}
                setCustomers={setCustomers}
                exportersList={exportersList}
                setExportersList={setExportersList}
                selectedExporters={selectedExporters}
                setExportersCounter={setExportersCounter}
                exportersCounter={exportersCounter}
                setSelectedExporters={setSelectedExporters}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                customerCounter={customerCounter}
                setCustomerCounter={setCustomerCounter}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
                toggleSearchDropdown={toggleSearchDropdown}
                setShowSearchDropdown={setShowSearchDropdown}
                toggleExportersSelection={toggleExportersSelection}
                toggleCustomerDropdown={toggleCustomerDropdown}
                showCustomerDropdown={showCustomerDropdown}
                setShowCustomerDropdown={setShowCustomerDropdown}
                statuses={statuses}
                allStatusSelected={allStatusSelected}
                setAllStatusSelected={setAllStatusSelected}
                setSelectedStatuses={setSelectedStatuses}
                showStatusDropdown={showStatusDropdown}
                setShowStatusDropdown={setShowStatusDropdown}
                toggleStatusDropdown={toggleStatusDropdown}
                statusCounter={statusCounter}
                setStatusCounter={setStatusCounter}
                toggleStatusSelection={toggleStatusSelection}
                selectedStatuses={selectedStatuses}
                setShowInvoiceDateDropdown={setShowInvoiceDateDropdown}
                toggleInvoiceDateDropdown={toggleInvoiceDateDropdown}
                showDateCounter={showDateCounter}
                showInvoiceDateDropdown={showInvoiceDateDropdown}
                handleDateSelection={handleDateSelection}
                activeDate={activeDate}
                setIsSearch={setIsSearch}
            />
            <PayoutReportTable
                tableData={filteredData}
                resData={resData}
                // mobileTableData={filteredData}
                loading={fetchingPayouts}
                handlePaginate={handlePaginate}
                curentPage={curentPage}
                pageSize={pageSize}
                isSearch={isSearch}
            />
            {/* <div className={classes.button}>
                {arrBtn.map((arr) => (
                    <Button
                        key={arr.text}
                        text={arr.text}
                        onClick={arr.onClick}
                        className={classes.btn}
                    />
                ))}
            </div> */}
        </div>
    )
}
export default Payouts