import classes from './ContactInfo.module.scss';
import clsx from 'clsx'

const ContactInfo = (props) => {
  return (
    <div className={clsx(classes.contact_info, props.className, 'w-[100%]')}>
      <div className={clsx(classes.name_country_receivable_report, props.nameCountryClass)}>
        <p className={classes.name} style={{ overflowWrap: 'anywhere', display: 'inline-block' }}>
          {props.name}
         {props.country && <div className={classes.dot} style={{ display: 'inline-block', margin: "4px" }}></div>}
          {props.country}
        </p>
      </div>
      <p className={clsx(classes.email, 'truncate w-full', props.emailClass)}>{props.email}</p>
    </div>
  )
}
export default ContactInfo;