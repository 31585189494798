import classes from "./ExporterProfileInfo.module.scss"
import Modal from "../../Layout/Modal"
import ReactDOM from 'react-dom'
import clsx from "clsx"
import Backdrop from "../../Layout/Backdrop"
import {useDispatch} from "react-redux"
import {modalActions} from "../../Store/modal-redux"
import {Tabs, Spin} from "antd"
import Close from "../../Layout/Close"
import ExporterProfile from "./ExporterProfile"
import ComplianceChecksProfile from "./ComplianceChecksProfile"
import useApiCall from "../../hooks/useApiCall";
import React, {useState, useEffect} from "react";
import {apiGetLeadDetails, apiVerifyLead, apiCreateUserFromLead, apiGetLeadComplianceDetails} from "../../services";
import {enqueueSnackbar} from "notistack";
import {showVerificationModal} from "../../utils/layout";
import CustomModal from "Components/atom/CustomModal";
import Action from "./Action";
import PaymentStatusTag from "./PaymentStatusTag";
import rightIcon from "assets/Icons/tick-circle-white.svg"
import {LoadingOutlined} from "@ant-design/icons";



const LeadsProfileInfo = (props) => {
    const dispatch = useDispatch();

    const [leadDetails, setLeadDetails] = useState([]);
    const [modalRef, setModalRef] = useState(null);
    const [showTick, setShowTick] = useState(false);

    const [getLeadDetails, leadDetailLoading] = useApiCall(apiGetLeadComplianceDetails, (res) => {
        setLeadDetails(res?.data);
    }, (err) => {
        enqueueSnackbar(`${"Error occurred while fetching lead details"}`, {variant: 'error'})
    })



    const [createUserFromLead, createUserFromLeadLoading] = useApiCall(apiCreateUserFromLead, (res) => {
        setShowTick(true);
        enqueueSnackbar(`${"User creation link sent successfully"}`, {variant: 'success'})
    }, (err) => {
        enqueueSnackbar(`${"Error occurred while sending payment link"}`, {variant: 'error'})
    })

    const handleVerificationClick = () => {

        showVerificationModal(modalRef, {
            action: "verification",
            leadId : props?.lead?.leadId
        });
    }

    const handleCreateUser = () => {
        if(!showTick){
            createUserFromLead({
                leadId: props?.lead?.leadId
            })
        }

    }


    useEffect(() => {
        getLeadDetails({
            leadId: props?.lead?.leadId
        })
    }, [])



    const defaultActiveKey = 'profile';
    const items = [
        {
            key: "profile",
            label: "Profile",
            children: (
                <ExporterProfile
                    exporterDetails={leadDetails}
                    loading={leadDetailLoading}
                />
            )
        },
        {
            key: "compliance",
            label: "Compliance Checks",
            children: (
                <ComplianceChecksProfile
                    exporterDetails = {leadDetails}
                    loading = {leadDetailLoading}
                    getLeadDetails = {getLeadDetails}
                    leadId = {props?.lead?.leadId}
                />
            )
        },
    ]

    const optionArr = [
        {
            text: 'Mark as rejected',
            active: true,
            onClick: () => {
                showVerificationModal(modalRef, {
                    action: "rejection",
                    leadId : props?.lead?.leadId
                });
            },
        },
    ]

    return (
        <Modal className={clsx(
            classes.modal,
            'p-0 w-[1200px] z-[51] !bg-[#F4F6FA] bottom-0 !pb-[20px]'
        )}
        >
            <CustomModal ref={setModalRef}/>
            {
                ReactDOM.createPortal(
                    <Backdrop
                        onClick={() => {
                            dispatch(modalActions.exporterProfileInfo(false))
                        }}
                        className={classes.backdrop}
                    />,
                    document.getElementById('backdrop-root')
                )}
            <div className={classes.profileInfo}>
                <div className={classes.profileInfoHeader}>
                    <div className="flex gap-[1.25rem] items-center">
                        <div>
                            Lead Name : {props?.lead?.organizationName}
                        </div>
                        <PaymentStatusTag
                            status={props?.lead?.status?.split('_').join('').toLowerCase()}
                            type="light"
                        />
                    </div>

                    <div className="flex gap-[1.25rem]">
                        {(props?.lead?.status === "IN_PROGRESS" || props?.lead?.status === "CREATED") && <button
                            className="w-[142.2px] px-[1.6rem] py-[1.2rem] rounded-[1rem] bg-[#181C30] text-white text-[1.6rem] leading-[2.4rem] font-[600]"
                            onClick={handleVerificationClick}
                        >
                            Mark as verified
                        </button>}
                        {(props?.lead?.status === "IN_PROGRESS" || props?.lead?.status === "CREATED") &&<div className="bg-white p-[7px] rounded-[8px] border-[1px] border-[#181c3033]">
                            <Action
                                options={optionArr}
                            />
                        </div>}
                        {
                            props?.lead?.status === "SUCCESS" && <button
                                className="flex items-center justify-center w-[142.2px] px-[1.6rem] py-[1.2rem] rounded-[1rem] bg-[#181C30] text-white text-[1.6rem] leading-[2.4rem] font-[600]"
                                onClick={handleCreateUser}
                            >
                                {
                                    createUserFromLeadLoading ?
                                        <Spin indicator={<LoadingOutlined style={{fontSize: 20, color: '#FFFFFF', strokeWidth: 4}} spin/>}/>
                                        :
                                        showTick ?
                                            <img src={rightIcon} alt="Done"/>
                                            :
                                            "Create User"
                                }
                            </button>
                        }

                    </div>

                </div>
                <div className={classes.closeBtn}>
                    <Close
                        onClick={() => {
                            if(showTick) {
                                props?.getLeads({
                                    pageNum : props?.currentPage,
                                    size : props?.pageSize
                                })
                            }
                            dispatch(modalActions.exporterProfileInfo(false))
                        }}
                    />
                </div>
                <Tabs
                    defaultActiveKey={defaultActiveKey}
                    items={items}
                    // onChange={onChange}
                />
            </div>
        </Modal>
    )
}

export default LeadsProfileInfo;