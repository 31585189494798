import { useEffect, useState } from 'react'
import clsx from 'clsx'

import classes from 'ReportTable/PaymentStatusTag.module.scss'
import InitiatedIcon from 'assets/Icons/initiated-arrow-white.svg'
import LinkSentIcon from 'assets/Icons/link-sent-white.svg'
import TickCircleIcon from 'assets/Icons/tick-circle-white.svg'
import EditWhiteIcon from 'assets/Icons/edit-white.svg'
import DiscWhiteIcon from 'assets/Icons/disc-white.svg'
import InfoIcon from 'assets/Icons/info_brown_circle.svg'
import StatusVerifiedIcon from 'assets/Icons/status-verified.svg'

// const getDarkStatusDetails = (status) => {
//   switch (status.split(' ').join('').toLowerCase()) {
//     case 'inreview':
//       return {
//         icon: InfoIcon,
//         title: 'UNDER REVIEW',
//         bgColor: '#8E6C28',
//       }
//     default:
//       return {
//         icon: EditWhiteIcon,
//         title: status,
//         bgColor: '#FFB800',
//       }
//   }
// }

// const getLightStatusDetails = (status) => {
//   switch (status.split(' ').join('').toLowerCase()) {
//     case 'created':
//       return {
//         icon: EditWhiteIcon,
//         title: 'UNDER REVIEW',
//         bgColor: '#FFF4D9',
//         textColor: '#FFB800',
//       }
//     case 'inreview':
//       return {
//         icon: InfoIcon,
//         title: 'UNDER REVIEW',
//         bgColor: '#FFF4DE',
//         textColor: '#8E6C28',
//       }
//     default:
//       return {
//         icon: EditWhiteIcon,
//         title: status,
//         bgColor: '#FFF4D9',
//         textColor: '#FFB800',
//       }
//   }
// }

const getCustomerVerificationDetails = (status) => {
  if (status === "PENDING_VERIFICATION")
    return {
      icon: EditWhiteIcon,
      title: "UNDER REVIEW",
      bgColor: '#FFF4D9',
      textColor: '#FFB800',
    };
  else if (status === "VERIFICATION_FAILED")
    return {
      title: "REJECTED",
      textColor: "#FFEFEF",
      bgColor: "#D56F6F",
    };
  return;
};

const ReportsPaymentStatusTag = (props) => {
  let { status, withIcon = false, type = 'dark' } = props

  if (
    status.toLowerCase() === 'in review'
  ) {
    status = 'created'
  } else if (status.toLowerCase() === 'created') {
    status = 'verified'
  }

  const [statusDetails, setStatusDetails] = useState(
    props?.type === 'light'
      ? getCustomerVerificationDetails(status || '')
      : getCustomerVerificationDetails(status || '')
  )

  useEffect(() => {
    setStatusDetails(
      props?.type === 'light'
        ? getCustomerVerificationDetails(status || '')
        : getCustomerVerificationDetails(status || '')
    )
  }, [props, status])

  return (
    <div
      className={clsx(
        'px-[8px] min-h-[20px] rounded-full flex flex-row items-center w-fit'
      )}
      style={{
        backgroundColor: statusDetails?.bgColor
          ? statusDetails?.bgColor
          : 'inherit',
      }}
    >
      {withIcon && statusDetails.icon && (
        <img
          className="w-[16px] aspect-square mr-1"
          src={statusDetails.icon}
          alt="icon"
        />
      )}
      <div className={clsx(classes.text)}>
        <p
          className="text-white font-bold w-max"
          style={{
            color: statusDetails?.textColor ? statusDetails?.textColor : '#fff',
            fontSize: 12,
          }}
        >
          {statusDetails?.title}
        </p>
      </div>
    </div>
  )
}

export default ReportsPaymentStatusTag
