import React, { useState } from 'react';
import ContentBoxKyc from "./ExportProfileComponent/ContentBoxKyc"
import GrayBox from "./ExportProfileComponent/GrayBox"
import KycContent from "./ExportProfileComponent/KycContent"
import KycContentNot from "./ExportProfileComponent/KycContentNot"
import KycContentWrapper from "./ExportProfileComponent/KycContentWrapper"
import KycHeader from "./ExportProfileComponent/KycHeader"
import classes from "./ExporterProfile.module.scss"
import clsx from "clsx"
import BankAccountDetails from './BankAccountDetails';
import VirtualBankData from './VirtualBankData';
import usaFlag from "../../assets/Images/usaFlag.svg";
import VirtualBankDetails from './VirtualBankDetails';
import Shimmer from "../../Layout/Shimmer";


const ExporterProfile = (props) => {
  const [selectedAcc, setSelectedAcc] = useState('USD');
    const basicUserDetails = props?.exporterDetails?.basic_user_details || {};
    const companyDetails = props?.exporterDetails?.business_details?.company_details || {};
    const companyKycLinks = props?.exporterDetails?.business_details?.company_identification_details || {};
    const businessDirectorDetails = props?.exporterDetails?.business_details?.company_business_director_details || [];
    const businessOwnerDetails = props?.exporterDetails?.business_details?.company_business_owner_details || [];
    const userDetails = props?.exporterDetails?.business_details?.company_account_user_details || [];

    return (
        props?.loading ?
            <div>
                {[1,2,3]?.map((item, index) => (
                    <Shimmer key={index} className="w-[100%] h-[180px] mb-[12px]"/>
                ))}
            </div>
            :
        <>
            <div className={classes.exporterProfile}>
                <div className={classes.basicDetailsBox}>
                    <div className={classes.basicDetailsHead}>
                        Basic Details
                    </div>
                    <div className={classes.basicDetailsCont}>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                First Name
                            </div>
                            <div className={classes.bdCont}>
                                {basicUserDetails?.first_name ? basicUserDetails?.first_name : "--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Last Name
                            </div>
                            <div className={classes.bdCont}>
                                {basicUserDetails?.last_name ? basicUserDetails?.last_name :"--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Email
                            </div>
                            <div className={classes.bdCont}>
                                {basicUserDetails?.email_id ? basicUserDetails?.email_id :"--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Phone Number
                            </div>
                            <div className={classes.bdCont}>
                                {basicUserDetails?.country_code ? basicUserDetails?.country_code : "--"} {basicUserDetails?.mobile_number ? basicUserDetails?.mobile_number : "--"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.basicDetailsBox}>
                    <div className={classes.basicDetailsHead}>
                        Company Particulars
                    </div>
                    <div className={classes.basicDetailsCont}>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Brand Name / Doing Business as
                            </div>
                            <div className={classes.bdCont}>
                                {companyDetails?.brand_name ? companyDetails?.brand_name : "--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Website
                            </div>
                            <div className={classes.bdCont}>
                                {companyDetails?.website ? companyDetails?.website : "--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Business Type
                            </div>
                            <div className={classes.bdCont}>
                                {companyDetails?.business_type ? companyDetails?.business_type : "--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Describe your Product / Services
                            </div>
                            <div className={classes.bdCont}>
                                {companyDetails?.product_description ? companyDetails?.product_description : "--"}
                            </div>
                        </div>
                        <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                Business Address
                            </div>
                            <div className={classes.bdCont}>
                                {`${companyDetails?.business_address_line1 ? companyDetails?.business_address_line1 : "-" } ${companyDetails?.business_address_line2 ? companyDetails?.business_address_line2 : "-"}`}
                            </div>
                        </div>
                    </div>
                </div>
                {(companyKycLinks?.cin_number || companyKycLinks?.gstin_number) && <div className={classes.basicDetailsBox}>
                    <div className={classes.basicDetailsHead}>
                        Registration & Identification
                    </div>
                    <div className={classes.basicDetailsCont}>
                        {companyKycLinks?.cin_number && <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                CIN Number
                            </div>
                            <div className={classes.bdCont}>
                                {companyKycLinks?.cin_number}
                            </div>
                            <ContentBoxKyc>
                                <KycHeader text1={'A'}
                                           text2={'CIN - Certificate of Incorporation/ Registration'}/>
                                {(companyKycLinks?.cin_urls && companyKycLinks?.cin_urls !== 0) &&
                                    (<KycContentWrapper>
                                        {companyKycLinks?.cin_urls?.length !== 0 && (
                                            <>
                                                {companyKycLinks?.cin_urls?.map((url, index) => (
                                                    <React.Fragment key={index}>
                                                        <KycContent text1={'Certificate of Incorporation.'}
                                                                    text2={'10 KB'} url={url}/>
                                                        {companyKycLinks?.cin_urls?.length !== index + 1 && <GrayBox/>}
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )}

                                        {/*{llpin_urls && <GrayBox/> }*/}

                                        {/*{llpin_urls?.length !== 0 && (*/}
                                        {/*<>*/}
                                        {/*    {llpin_urls?.map((url, index) => (*/}
                                        {/*    <React.Fragment key={index}>*/}
                                        {/*        <KycContent text1={'LLPIN.'} text2={'10 KB'} url={url} />*/}
                                        {/*        {llpin_urls?.length !== index + 1 && <GrayBox />}*/}
                                        {/*    </React.Fragment>*/}
                                        {/*    ))}*/}
                                        {/*</>*/}
                                        {/*)}*/}
                                    </KycContentWrapper>)}
                                {(companyKycLinks?.cin_urls && companyKycLinks?.cin_urls === 0) && <KycContentNot/>}
                            </ContentBoxKyc>
                        </div>}
                        {companyKycLinks?.gstin_number && <div className={classes.bd}>
                            <div className={classes.bdHead}>
                                GSTIN Number
                            </div>
                            <div className={classes.bdCont}>
                                {companyKycLinks?.gstin_number}
                            </div>
                            <ContentBoxKyc>
                                <KycHeader text1={'B'} text2={'GSTIN - GST Registration'}/>
                                {companyKycLinks?.gstin_urls && companyKycLinks?.gstin_urls?.length !== 0 && (
                                    <KycContentWrapper>
                                        {companyKycLinks?.gstin_urls?.map((url, index) => (
                                            <React.Fragment key={index}>
                                                <KycContent text1={'GSTIN.'} text2={'10 KB'} url={url}/>
                                                {companyKycLinks?.gstin_urls?.length !== index + 1 && <GrayBox/>}
                                            </React.Fragment>
                                        ))}
                                    </KycContentWrapper>)}
                                {companyKycLinks?.gstin_urls?.length === 0 && <KycContentNot/>}
                            </ContentBoxKyc>
                        </div>}
                    </div>
                </div>}
                { businessDirectorDetails.length > 0 && <div className={classes.basicDetailsBox}>
                    <div className={classes.basicDetailsHead}>
                        Business Directors
                        <p className={classes.subHeading}>{`Directors with > 10% shareholding.`}</p>
                    </div>
                    <div className={classes.basicDetailsCont}>
                        {businessDirectorDetails?.map((director, index) => {
                            return <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Director {index + 1}
                                </div>
                                <div className={classes.bdCont}>
                                    {`${director?.director_first_name} ${director?.director_last_name}`}
                                </div>
                                <ContentBoxKyc>
                                    <KycHeader text1={''}
                                               text2={`PAN for ${director?.director_first_name} ${director?.director_last_name}`}
                                               text3={``}/>
                                    {director?.director_pan_urls?.length !== 0 && (
                                        <KycContentWrapper>
                                            {director?.director_pan_urls?.map((url, innerIndex) => (
                                                <React.Fragment key={index}>
                                                    <KycContent text1={'PAN.'} text2={'10 KB'} url={url}/>
                                                    {director?.director_pan_urls?.length !== innerIndex + 1 &&
                                                        <GrayBox/>}
                                                </React.Fragment>
                                            ))}
                                        </KycContentWrapper>
                                    )}
                                    {director?.director_pan_urls?.length === 0 && <KycContentNot/>}
                                </ContentBoxKyc>
                            </div>
                        })}

                    </div>
                </div> }
                { businessOwnerDetails.length > 0 && <div className={classes.basicDetailsBox}>
                    <div className={classes.basicDetailsHead}>
                        Business Owners
                        <p className={classes.subHeading}>{`Owners with >10% shareholding.`}</p>
                    </div>
                    <div className={classes.basicDetailsCont}>
                        {businessOwnerDetails.map((owner, index) => {
                            return <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    Owner {index + 1}
                                </div>
                                <div className={classes.bdCont}>
                                    {`${owner?.owner_first_name} ${owner?.owner_last_name}`}
                                </div>
                                <ContentBoxKyc>
                                    <KycHeader
                                        // text1={String.fromCharCode(alphabet + index)}
                                        text2={`PAN for ${owner?.owner_first_name} ${owner?.owner_last_name}`}
                                        text3={``}
                                    />
                                    {owner?.owner_pan_url?.length !== 0 && (
                                        <KycContentWrapper>
                                            {owner?.owner_pan_url?.map((url, innerIndex) => (
                                                <React.Fragment key={innerIndex}>
                                                    <KycContent text1={'PAN.'} text2={'10 KB'} url={url}/>
                                                    {owner?.owner_pan_url?.length !== innerIndex + 1 && <GrayBox/>}
                                                </React.Fragment>
                                            ))}
                                        </KycContentWrapper>
                                    )}
                                    {owner?.owner_pan_url?.length === 0 && <KycContentNot/>}
                                    {/*{true && <KycContentNot/>}*/}
                                </ContentBoxKyc>
                            </div>
                        })}
                    </div>
                </div> }
                {userDetails.length >0 && <div className={classes.basicDetailsBox}>
                    <div className={classes.basicDetailsHead}>
                        Fideo Account Users
                    </div>
                    <div className={classes.basicDetailsCont}>
                        {userDetails.map((user, index) => {
                            return <div className={classes.bd}>
                                <div className={classes.bdHead}>
                                    User {index + 1}
                                </div>
                                <div className={classes.bdCont}>
                                    {`${user?.user_first_name} ${user?.user_last_name}`}
                                </div>
                                <ContentBoxKyc>
                                    <KycHeader text1={''}
                                               text2={`PAN for ${user?.user_first_name} ${user?.user_last_name}`}
                                               text3={``}/>
                                    {user?.user_pan_urls?.length !== 0 && (
                                        <KycContentWrapper>
                                            {user?.user_pan_urls?.map((url, innerIndex) => (
                                                <React.Fragment key={innerIndex}>
                                                    <KycContent text1={'PAN.'} text2={'10 KB'} url={url}/>
                                                    {user?.user_pan_urls?.length !== innerIndex + 1 && <GrayBox/>}
                                                </React.Fragment>
                                            ))}
                                        </KycContentWrapper>
                                    )}
                                    {user?.user_pan_urls?.length === 0 && <KycContentNot/>}
                                </ContentBoxKyc>
                            </div>
                        })}
                    </div>
                </div>}

                <div className="flex flex-col gap-[20px]">
                    {props?.bankAccounts && props?.bankAccounts.map((bank,index)=> {
                    return(
                         <BankAccountDetails bank = {bank} />
                    )
                    })}
                </div>

                {/*<div>*/}
                {/*    /!* <VirtualBankData active = {true} virtualMap = {virtualMap?.USD} data={virtualBankDetails?.balances?.USD}  img={usaFlag} /> *!/*/}
                {/*    <VirtualBankDetails selectedAcc = {selectedAcc} data={virtualMap?.USD} sign={virtualBankDetails?.balances?.USD?.currencyDTO?.sign} amount={'10,000'} country={'USA'}/>*/}
                {/*</div>*/}
                
            </div>
        </>
    )
}

export default ExporterProfile