import classes from "./LeadsTable.module.scss"
import clsx from "clsx"
import ReportTableLayout from "../Layout/ReportTableLayout"
import ContactInfo from "../Layout/ContactInfo"
import {useRef, useEffect, useState} from "react"
import PaymentStatusTag from "../Components/atom/PaymentStatusTag"
import {modalActions} from "../Store/modal-redux"
import {useDispatch, useSelector} from "react-redux"
import ReactDOM from 'react-dom'
import dayjs from "dayjs"
import {Pagination} from "antd";
import Shimmer from "../Layout/Shimmer";
import LeadsProfileInfo from "../Components/atom/LeadsProfileInfo";

const tableHeaderData = [
    {
        header: "Creation Date",
        width: 20,
        maxWidth: 200
    },
    {
        header: "Lead Id",
        width: 20,
        maxWidth: 150
    },
    {
        header: "Lead Details",
        width: 25,
        maxWidth: 227
    },
    {
        header: "Contact Details",
        width: 25,
        maxWidth: 227
    },
    {
        header: "Status",
        width: 20,
        maxWidth: 200
    },
    {
        header: "Action",
        width: 10,
        maxWidth: 100
    },
]

const LeadsTable = (props) => {
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);
    const [exporter, setExporter] = useState({});

    const dispatch = useDispatch();
    const profileInfo = useSelector(state => state.modal.exporterProfileInfo);
    const [lead, setLead] = useState({});

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>
        }
        if (type === 'next') {
            return <a>Next</a>
        }
        return originalElement
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            } else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // console.log('props.resData',props?.totalElements)
    // console.log('props.curentPage',props.curentPage)
    // console.log('props.pageSize',props.pageSize)

    return (
        <>
            {
                profileInfo &&
                ReactDOM.createPortal(
                    <LeadsProfileInfo
                        lead={lead}
                        getLeads = {props?.getLeadsTable}
                        currentPage={props?.currentPage}
                        pageSize={props?.pageSize}
                    />,
                    document.getElementById('modal-root')
                )
            }
            <div className={classes.allWrap}>
                <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)}
                     id="thead" ref={theadRef}>
                    <thead>
                    {tableHeaderData.map((header, index) => (
                        <th key={`${header}-${index}`} className={classes.cell_header1}
                            style={{
                                width: `${header.width}%`,
                                minWidth: `${header.maxWidth}px`,
                                maxWidth: `${header.maxWidth}px`
                            }}
                            valign="top"
                        >
                            <div className={classes.cell_header2}>
                                <div className={classes.head}>{header.header}</div>
                            </div>
                        </th>
                    ))}
                    </thead>
                </div>

                <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef}>
                    <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
                        {
                            <tbody>
                            {
                                props?.loading
                                    ?
                                    <td colSpan={5}>
                                        {[1, 2, 3]?.map((item, index) => (
                                            <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                                        ))}
                                    </td>
                                    :
                                    props?.tableData?.map((data, index) =>
                                        //  data.status === "PENDING" &&
                                        (
                                            <tr className={classes.row}>
                                                <td className={classes.cell}
                                                    style={{
                                                        width: '20%',
                                                        minWidth: '200px',
                                                        maxWidth: '200px',
                                                        overflowWrap: "anywhere"
                                                    }}
                                                    valign="top"
                                                >
                                                    {/*{data?.creationDate ? dayjs(data?.creationDate).format('D MMM YYYY') : '--'}*/}
                                                    {data?.creationDate ? dayjs(data?.creationDate).format("D MMM 'YY") : '--'}
                                                </td>
                                                <td className={classes.cell}
                                                    style={{
                                                        width: '20%',
                                                        minWidth: '150px',
                                                        maxWidth: '150px',
                                                        overflowWrap: "anywhere"
                                                    }}
                                                    valign="top"
                                                >
                                                    <div className="flex flex-col items-start">
                                                        <p
                                                            className={clsx(
                                                                classes.invoice,
                                                                'truncate max-w-[15rem] w-full'
                                                            )}
                                                            style={{
                                                                cursor: 'pointer',
                                                                userSelect: 'none',
                                                            }}
                                                        >
                                                            {data?.leadId}
                                                        </p>
                                                        {/* <div className="h-[20px] mt-1">
                                            {data?.customer_detail?.verificationStatus!=='VERIFIED' && (
                                                <PaymentStatusTag status={data?.customer_detail?.verificationStatus} type="light" />
                                            )}
                                            </div> */}
                                                    </div>
                                                </td>
                                                <td className={classes.cell}
                                                    style={{
                                                        width: '25%',
                                                        minWidth: '227px',
                                                        maxWidth: '227px',
                                                        overflowWrap: "anywhere"
                                                    }}
                                                    valign="top"
                                                >
                                                    <ContactInfo
                                                        //   tick={true}
                                                        name={data?.organizationName}
                                                        email={data?.userName}
                                                    />
                                                    {/* {data.status === "UNDER REVIEW" && <PaymentStatusTag status = {data?.status} type="light" />} */}
                                                </td>
                                                <td className={classes.cell}
                                                    style={{
                                                        width: '25%',
                                                        minWidth: '227px',
                                                        maxWidth: '227px',
                                                        overflowWrap: "anywhere"
                                                    }}
                                                    valign="top"
                                                >
                                                    <ContactInfo
                                                        // name = {data?.exporter?.name}
                                                        //   tick={true}
                                                        name={data?.email}
                                                        email={data?.phone}
                                                    />
                                                    {/* {data.status === "UNDER REVIEW" && <PaymentStatusTag status = {data?.status} type="light" />} */}
                                                </td>
                                                <td className={classes.cell}
                                                    style={{
                                                        width: '20%',
                                                        minWidth: '200px',
                                                        maxWidth: '200px',
                                                        overflowWrap: "anywhere"
                                                    }}
                                                    valign="top"
                                                >
                                                    {data?.status && <PaymentStatusTag
                                                        status={data?.status?.split('_').join('').toLowerCase()}
                                                        type="light"/>}
                                                </td>
                                                <td className={classes.cell}
                                                    style={{
                                                        width: '10%',
                                                        minWidth: '100px',
                                                        maxWidth: '100px',
                                                        overflowWrap: "anywhere"
                                                    }}
                                                    valign="top"
                                                >
                                                    <div className="flex flex-col gap-[4px]">
                                                        <button
                                                            className="p-[4px] text-[#fff] bg-black rounded-[4px]"
                                                            onClick={() => {
                                                                setLead(data)
                                                                dispatch(modalActions.exporterProfileInfo(true))
                                                            }}
                                                        >
                                                            Details
                                                        </button>
                                                        {data?.status === 'UNDER REVIEW' && <button
                                                            className="p-[4px] text-[#fff] bg-black rounded-[4px] opacity-25 cursor-not-allowed"
                                                        >
                                                            Verify
                                                        </button>}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }
                            </tbody>
                        }
                    </ReportTableLayout>
                    {
                        props?.tableData?.length > 0 &&
                        <div className={classes.paginationWrap}>
                            <Pagination
                                showSizeChanger={false}
                                current={props?.currentPage + 1}
                                total={props?.totalElements}
                                pageSize={props?.pageSize}
                                showLessItems={false}
                                // defaultPageSize={30}
                                itemRender={itemRender}
                                onChange={props.handlePaginate}
                                // onShowSizeChange={props.handlePageSize}
                                // pageSizeOptions={[10,20,30,50,100]}
                            />
                        </div>
                    }
                </div>
            </div>
            {(props?.tableData?.length === 0 && !props?.loading) &&
                (<div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '4rem',
                        fontSize: '2.5rem'
                    }}
                    className={classes.table}
                >
                    {props?.isSearch?.length === 0 ? 'No Leads Yet.' : 'No leads by this lead id / name.'}
                </div>)
            }
        </>
    )
}

export default LeadsTable