import { useState, useEffect, memo, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import ReactDOM from "react-dom";
import styles from './FileViewer.module.css'
import DownloadIcon from '../../../assets/Icons/download-cloud.svg'
import BgIcon from '../../../assets/Icons/bg-file-uploader.png'
import FideoLogo from '../../../assets/Icons/fideo-logo-faded.svg'
import otpLock from '../../../assets/Icons/otpLock.svg'
import clsx from 'clsx'
import { Document, Page, pdfjs } from 'react-pdf'
// import EnterOtp from 'Components/otpScreen/EnterOtp'
// import EnterOtpInner from 'Components/otpInnerScreen/EnterOtp'
// import TypeOtp from 'Components/otpScreen/TypeOtp'
import invoice1 from '../../../assets/Images/invoice1.png';
import invoice2 from '../../../assets/Images/invoice2.png';
import invoice3 from '../../../assets/Images/invoice3.png';
import ZoomButtonsModal from '../../atom/FileViewer/ZoomFile'
// import Backdrop from 'Layout/Backdrop'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'
// import { handleDecrypt } from "hooks/handleDecryption";
// import { doEncryption } from 'constants';
 
const validTypes = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const FileViewer = forwardRef((props, ref) => {
  const {
    format = [],
    displayUrls = [],
    mobileView = false,
    downloadPrefix = 'invoice',
    otpViewNowBox,
    setOtpViewNowBox,
    setPaymentLinkOtpRequired,
    paymentLinkOtpRequired,
    showInnerTrue,
    handleResendOTPView,otpErrorState,presignedUrlErrorState,invoiceData,otp,setOtp,handleOTPView,handleOTPViewNow,
    whereCalled,setWhereCalled,setInvoicetoBigScreen,
    invoicetoBigScreen,
  } = props
  const [providedUrl, setProvidedUrl] = useState(displayUrls)
  const [providedUrlDumy, setProvidedUrlDumy] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [zoomIndex, setZoomIndex] = useState(1)
  const [key, setKey] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)

  const [showPdf, setShowPdf] = useState(false);


  useEffect(() => {
    // Reset showPdf state when url or type changes
    setShowPdf(true);
  }, [providedUrl]);

  const handlePdfLoad = () => {
    setShowPdf(true);
  };





  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    setSelectedIndex(0)
  }, [providedUrl,providedUrlDumy])

  useEffect(() => {
    setProvidedUrl(displayUrls)
    setZoomIndex(0)
  }, [displayUrls,providedUrlDumy])



  const renderBigFile = (file) => {
    const { type, url } = file
    // if (paymentLinkOtpRequired || otpViewNowBox) {
    //   return <EnterOtpInner handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />
    // }
    switch (true) {
      case type.includes('image'):
        return <img src={providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0].url} alt="invoice" 
        className='w-[350px] h-[440px] mb-[10px]'
        onClick={() => {
          openZoomModal()
        }}
        style={{ cursor: "zoom-in" }}
        />

        case type.includes('application'):
          return (
            <div className="w-[100%] h-[90%] max-w-[350px] max-h[450px] mb-[10px] " 
            style={{ cursor: "zoom-in" }}
            >
              {showPdf ? (
              <Document
                file={url}
                className={clsx('w-full h-full', styles.documentFull)}
                onLoadSuccess={handlePdfLoad}
                loading={""}
              >
                <Page
                  pageNumber={1}
                  className={clsx('w-full h-full', styles.pdfViewerSmall)}
                  style={{
                    minWidth: '100% !important',
                    minHeight: '100% !important',
                  }}
                  onClick={() => {
                    openZoomModal()
                  }}
                  renderTextLayer={false}
                  loading={""}
                />
              </Document>
              ) : (
                <div className="w-[350px] h-[440px] mb-[10px]  text-sm rounded-xl flex justify-center items-center "></div>
              )} 
            </div>
          )
      default:
        return <img src={url} alt="invoice" className='w-[350px] h-[440px] mb-[10px]' />
    }
  }

  const renderSmallFile = (file) => {
    const { type, url } = file
    
    switch (true) {
      case type.includes('image'):
        return (
          <img
            src={url}
            alt="invoice"
            className="w-[50px] rounded-xl h-[64px]"
          />
        )
      case type.includes('application'):
        return (
            <div className="w-[50px] h-[64px]">
              <div>
                {showPdf ? (
                <Document
                  file={url}
                  className={clsx('w-[50px] h-[64px]', styles.documentFull)}
                  onLoadSuccess={handlePdfLoad}
                  loading={""}
                >
                  <Page
                    pageNumber={1}
                    className={clsx('w-[50px] h-[64px]', styles.pdfViewerSmall)}
                    style={{
                      minWidth: '100% !important',
                      minHeight: '100% !important',
                    }}
                    renderTextLayer={false}
                    loading={""}
                  />
                </Document>
                ) : (
                  <div className="w-[50px] h-[64px]  text-sm rounded-xl flex justify-center items-center "></div>
                )} 
              </div>
            </div>
        )
      default:
        return <img src={url} alt="invoice" className="w-[50px] h-[64px]" />
    }
  }

  const openZoomModal = () => {
      dispatch(modalActions.showFileDisplay())
  };

  return (
    <>
      <div className={styles.container}>
        <div className="w-full relative backdrop-blur-lg">
          {showFileDisplay && providedUrl[zoomIndex] &&
            ReactDOM.createPortal(
              <ZoomButtonsModal
                fileUrl={invoicetoBigScreen[parseInt(localStorage.getItem('zoomkaindex')) || 0]}
              />
              ,document.getElementById("modal-root")
          )
          }
          {!mobileView && 
          (whereCalled === "bigScreen" || whereCalled === "bigSmall") && 
          (
            <div
              className={clsx(styles.dropContainer, 'relative h-[60vh] backdrop-blur-lg justify-center items-center')}
              style={{
                backgroundImage: `url(${BgIcon})`,
                backgroundRepeat: 'repeat',
              }}
              key={key}
            >
              {providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0] && (
                <>
                  <a
                    download={`${downloadPrefix}_${
                      parseInt(localStorage.getItem('zoomkaindex')) || 0 + 1
                    }_downloads.${
                      providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0].type.split('/')[1]
                    }`}
                    href={providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0].url}
                    className="absolute top-5 right-5 z-2000"
                    style={paymentLinkOtpRequired ? { filter: 'blur(4px)' } : { zIndex: "2000" }}
                  >
                    <img src={DownloadIcon} alt="download" style={{ zIndex: "3000" }} />
                  </a>
                  {renderBigFile(providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0])}
                  <div className="w-[100%] min-h-[300px]  justify-center items-center absolute t-[20px] l-[0px] flex  z-10"
                    style={paymentLinkOtpRequired ? { filter: 'blur(0px)' } : { zIndex: '-10' }}
                  >
                    {/* {otpViewNowBox && showInnerTrue && console.log("jmd")&&<TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/>} */}
                    {/* {paymentLinkOtpRequired && showInnerTrue && <EnterOtp handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />} */}
                  </div>
                  <div className="absolute z-1 bottom-3 flex flex-row items-center gap-2">
                    <p className="text-2xl font-medium text-[#1E333F4D]">
                      Powered by
                    </p>
                    <img src={FideoLogo} className="w-18 h-6" alt="Fideo" />
                  </div>
                </>
              )}
            </div>
          )}
          {
          (whereCalled === "smallScreen" || whereCalled === "bigSmall") && 
          (<div
            className={clsx(styles.docContainer,
              'rounded-[8px] min-h-[60px] flex flex-row  gap-5 rounded-t-none  bg-transparent  ',
              mobileView || whereCalled === "smallScreen" 
                ? ''
                : 'px-5 py-5 border-dashed border border-t-0 border-[#D2D6D9] overflow-scroll'
            )}
            // key={key}
          >
          
            {providedUrl?.map((file, index) => (
              <>
                <div
                key={index}
                  onClick={() => {
                    setInvoicetoBigScreen(Array(providedUrl?.length)?.fill(file))
                    setKey(!key)
                    setSelectedIndex(index)
                    setZoomIndex(index)
                    // console.log(index, "degg")
                    localStorage.setItem('zoomkaindex', index.toString());
                    dispatch(modalActions.showFileDisplay(false))
                  }}
                  className={clsx(
                    `flex relative min-w-[80px] h-[80px]  gap-2  rounded-xl flex-col border-solid border border-[#060A0D33] justify-center items-center py-1 px-2 cursor-pointer`,
                    // (((parseInt(localStorage.getItem('zoomkaindex')) || 0) === index)) ? 'border-[#1E333F]' : 'border-[#060A0D33]'
                    invoicetoBigScreen[0] === file ? 'border-[#1E333F]' : 'border-[#060A0D33]'
                  )}
                  style={paymentLinkOtpRequired ? { backdropFilter : 'blur(4px)' } : {}}
                >
                  {/* <div className={styles.smallFileCon}> */}
                    {renderSmallFile(file)}
                  {/* </div> */}

                  <div className="w-[40px] h-[65px] justify-center items-center absolute t-0 l-0 flex  z-10 "
                    // style={paymentLinkOtpRequired ? { backdropFilter: 'blur(4px)' } : {}}
                  >
                    {paymentLinkOtpRequired && <img src={otpLock} alt="lock" className="w-10 h-10" />}
                    {/* {paymentLinkOtpRequired && <EnterOtp setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/> } */}
                  </div>
                </div>
              </>
            ))}
          </div>)}
        </div>

        {/* <div className={styles.fileDisplayContainer}>
          {providedUrl
            ? fileDetailsFromUrl(providedUrl)
            : selectedFiles.map((data) => fileDetails(data))}
        </div> */}
      </div>
    </>
  )
})

export default memo(FileViewer)
