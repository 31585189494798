import ReactDOM from "react-dom";
import classes from '../../Components/Payment/ReceivePayment.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../../Layout/Modal';
import {modalActions} from '../../Store/modal-redux';
import ListOfOrganization from '../../Components/General/ListOfOrganization';
import {useEffect, useState} from 'react';
import {ContactsActions} from '../../Store/customer-redux';
import UseApiCall from '../../hooks/useApiCall';
import { apiGetOrganizationData } from '../../services';
import { baseUrl } from '../../constants/Network';
import { OrganizationActions } from "../../Store/customer-redux";
import ListOfContact from 'Components/General/ListOfContact';
import Backdrop from "Layout/Backdrop";

const ReceivePayment = (props) => {
    // const contactList = useSelector(state => state.customerContacts.contacts)
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState();
    const [customers, setCustomers] = useState();
    const addContactDisplay = useSelector(
        (state) => state.modal.addContactDisplay
      );
    const receivePaymentBulkDisplay = useSelector((state) => state.modal.receivePaymentBulkDisplay);

    const [getOrganizationData, fetchingGetOrganizationData, organizationData, organizationDataerror] = UseApiCall(apiGetOrganizationData, 
        (res) => {
            setFilteredData(res?.data)

            const uniqueOrganizationsMap = new Map();

            res?.data?.forEach((organization) => {
                const organizationName = organization.organizationName;
                if (!uniqueOrganizationsMap.has(organizationName)) {
                    uniqueOrganizationsMap.set(organizationName, organization);
                    return true
                }else {
                    return false
                }
            });

            const uniqueOrganizations = Array.from(uniqueOrganizationsMap.values());
            setCustomers(uniqueOrganizations);
        dispatch(OrganizationActions.setOrganizationTableData(res?.data));
      }, (err) => {
        console.log(err)
        // dispatch(DashboardSummaryActions.setShowBackendError(err));
      });
      
      useEffect(() => {
        getOrganizationData();
      }, [])

    return (
        <Modal className={classes.modal}>
            <ListOfOrganization
                contactType={'Organization'}
                contactFunctionality={'receive'}
                contact={customers}
                btnFunction={(type) => {
                    // if (type === "addContact") {
                        dispatch(modalActions.addContactOrganization())
                    // } else {
                    //     dispatch(modalActions.receivePayment())
                    // }
                    // dispatch(modalActions.receivePayment())
                    // dispatch(modalActions.addContact())
                }}
                btnText={'Add Organization'}
                addContactURL={''}
                nextPageFunctionality={() => {
                    // props.modal === "createInvoice" ?
                    //     dispatch(modalActions.addContact())
                    //     :
                    // // props.modal === "createInvoiceRec" ?
                    // //     dispatch(modalActions.setCreateInvoiceModalRec(true))
                    // //     :
                    // // props.modal === "createInvoiceRecDraft" ?
                    // //     dispatch(modalActions.setCreateInvoiceModalRecDraft(true))
                    // //     :
                    // dispatch(modalActions.createInvoice())
                    if (receivePaymentBulkDisplay) dispatch(modalActions.bulkUploaderDisplay(true))
                    else dispatch(modalActions.addContact())
                }}
                onClose={
                    props.modal === "createInvoice" ?
                    () => {
                    dispatch(modalActions.setCreateInvoiceReceivePayment(false))
                    } :
                        () => {
                            dispatch(modalActions.bulkUploaderDisplay(false))
                            dispatch(modalActions.receivePaymentBulk(false))
                            dispatch(modalActions.receivePayment(true))
                        }
                }
                loading = {fetchingGetOrganizationData}
                modal={props.modal}
            />
        </Modal>
    )
}
export default ReceivePayment;