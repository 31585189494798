import { useRef, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { SOMETHING_WRONG } from '../constants';
import { useNavigate } from 'react-router-dom';
import { apiSignOut } from '../services';
import { SIGN_OUT } from '../constants';
import { useDispatch } from "react-redux";


// interface ApiConfigs {
//   successMsg?: string;
//   errorMsg?: string;
//   callBack?: ({
//     isError,
//     response,
//   }: {
//     isError: boolean;
//     response: Record<string, any>;
//   }) => void;
// }

// type ReturnProps<T> = [
//   callApi: (params?: T, configs?: ApiConfigs) => void,
//   loading: boolean,
//   data: any,
//   error: any
// ];

/**
 * ## UseApiCall - is a custom hook, use this to make HTTP API calls
 * ----
 * ### Returns - [makeCallApiFunction, loadingState, successData, errorData]
 * ----
 * @param apiCallService API call service function, that must be defined in service file
 * @param onSuccess optional callback, called on api success
 * @param onFail optional callback, called on api failure
 */
function UseApiCall(apiCallService, onSuccess, onFail) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const retryCount = useRef(0);
  const dispatch = useDispatch();

  const currentTime = Date.now() / 1000;
  const exp = localStorage.getItem('exp');

  if (exp === null || isNaN(Number(exp)) || exp.trim() === '' || Number(exp) < currentTime) {
    dispatch({ type: 'CLEAR_STATE' });
    localStorage.removeItem('username')
    localStorage.removeItem('userid')
    localStorage.removeItem('exp')
    navigate("/login?mode=email");
  }

  // const [userData, setUserData] = useRecoilState(userAuthDetails);

  // adding log event to make a api call on logs whenever it receives a logData

  // const logEvent = (changedData: any) => {
  //   if (logData && userData) {
  //     const fullLogData = {
  //       ...logData,
  //       change_data: { type: "raw", change: changedData },
  //       created_by: userData.id,
  //       email: userData.email || ''
  //     }
  //     apiCreateLog(fullLogData)
  //   }
  // }

  /**
   *
   * @param params - directly passed to api service function
   * @param configs - optional configs
   *  - successMsg - optional success message
   *  - errorMsg - optional error message
   *  - hideDefaultError - optional, if true, default error message will not be shown
   *  - callBack - optional, if true, callback will be called on success
   * */
  const callApi = async (params, configs = {}) => {
    setLoading(true);
    apiCallService(params || {})
      .then((response) => {
        if (configs.successMsg)
          enqueueSnackbar(configs.successMsg, { variant: 'success' });
        setData(response.data);
        if (onSuccess) {
          onSuccess(response.data, response.headers)
          // logEvent(params)
        };

        if (configs.callBack)
          configs.callBack({ isError: false, response: response.data });
      })
      .catch((err) => {
        // console.log('<<<<api Err:', err);
        // const apiErrorMessage = err?.data?.error_response?.message;
        const apiErrorMessage = '';

        // if (configs.callBack)
        //   configs.callBack({ isError: true, response: err });

        let formatMessage;
        //  Not Found and Expired
        // if ((Number(err?.status) === 400 && [601, 603].includes(err?.data?.status_code)) || (Number(err?.status) === 401 && [602, 604].includes(err?.data?.status_code))) {
        if ((Number(err?.status) === 400  || Number(err?.status) === 401 )) {
          //retryCount.current < 2
          // onUnauthenticated(params, configs);
          // dispatch({type: 'CLEAR_STATE'})
          // navigate("/login?mode=email");
        } else {
          if (!err?.options?.hideDefaultError) {
            // enqueueSnackbar(apiErrorMessage || configs.errorMsg || err.data || formatMessage || { variant: 'error',});
          }
          // setError(err);
          // if (onFail) onFail(err);
        }
        setError(err);
        if (onFail) onFail(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signOut = useCallback(() => {
    enqueueSnackbar(SIGN_OUT, { variant: 'error' });
    // apiSignOut().then().catch((err) => console.log(err))
    // if(navigate) navigate('/login?mode=email')
    // setUserData(null);
  }, [enqueueSnackbar]);
  //getAuth, setUserData, history

  // const getAuthentication = useCallback(
  //   (params: any, configs: ApiConfigs) => {
  //     apiAuthToken()
  //       .then((res) => {
  //         if (res.data?.data?.access_token) {
  //           utils.setDataToLocalStorage(TOKEN, res.data?.data?.access_token);
  //           retryCount.current += 1;
  //           callApi(params, configs);
  //         } else signOut();
  //       })
  //       .catch((err) => {
  //         console.log('<<<<err 401: ', err);
  //         signOut();
  //       });
  //   },
  //   [apiAuthToken, callApi, signOut]
  // );

  const onUnauthenticated = useCallback(
    (params, configs) => {
      // const refreshToken = utils.getDataFromLocalStorage(REFRESH_TOKEN);
      // if (refreshToken) getAuthentication(params, configs);
      // else
      signOut();
    },
    [ signOut]
    // getAuthentication
  );

  return [callApi, loading, data, error];
}

export default UseApiCall