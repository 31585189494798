import classes from "./LeadComplianceDetails.module.scss";
import Modal from "../../Layout/Modal";
import FullModal from "../../Layout/FullModal";
import {modalActions} from "../../Store/modal-redux";
import Close from "../../Layout/Close";
import {useDispatch} from "react-redux";
import FileViewer from "./FileViewer/FileViewer";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import chevronLeft from "../../assets/Icons/chevron-rightNew.svg";
import infoIcon from "assets/Icons/infoIcon.svg";
import {Tooltip} from "antd";
import UseApiCall from "../../hooks/useApiCall";
import {fetchFileFromS3} from "../../services";
import CustomModal from "Components/atom/CustomModal";
import {showVerificationModal} from "../../utils/layout";


const LeadComplianceDetails = (props) => {
    const {detailsData, detailsKey, companyDetails, preSignedUrls} = props;

    const dispatch = useDispatch();
    const [documents, setDocuments] = useState([]);
    const [modalRef, setModalRef] = useState(null);



    const [getFilesFromS3, fetchingFiles] = UseApiCall(
        fetchFileFromS3,
        (res, headers) => {
            try {
                var blob = new Blob([res], {type: headers['content-type']})
                let link = window.URL.createObjectURL(blob)
                setDocuments((pre) => [
                    ...pre,
                    {
                        url: link,
                        type: headers['content-type'],
                    },
                ])
            } catch (error) {
                console.log(error)
            }
        },
        (err) => {
        },
        'direct'
    )

    const mappingUrls = () => {
        preSignedUrls.map((item) => {
            getFilesFromS3(item)
        })
    }

    useEffect(() => {
        if (preSignedUrls.length > 0) {
            mappingUrls();
        }
    }, [preSignedUrls])

    useEffect(()=>{

    })

    const [activeTab, setActiveTab] = useState("invoice")

    const jsonResponse = detailsData?.verification_data && detailsData?.verification_data.length > 0 ? JSON.parse(detailsData?.verification_data[0].response) : {};

    const handleVerificationClick = () => {

        showVerificationModal(modalRef, {
            action: "doc-verification",
            leadId : detailsData?.verification_data[0].id,
            getLeadDetails : props?.getLeadDetails,
            callId : props?.leadId
        })
    }



    return (
        <FullModal>
            <CustomModal ref={setModalRef}/>
            <div className="absolute right-0 top-0 bg-gray-300 rounded-bl-2xl">
                <Close onClick={() => {
                    dispatch(modalActions.setSearchDetails(false))
                }}/>
            </div>
            <div className={classes.firstHeader}>
                <div className="flex items-center gap-[20px]">
                    <div
                        className="w-[48px] h-[48px] rounded-[50%] bg-[#eceef0] flex items-center justify-center cursor-pointer"
                        onClick={() => {
                            props?.setDetailsData({});
                            props?.setDetailsKey("");
                            props?.setPreSignedUrls([]);
                            dispatch(modalActions.setSearchDetails(false))
                        }}
                    >
                        <img src={chevronLeft} alt={'back'} className={classes.backImg}/>
                    </div>
                    <div className={classes.firstHeaderLeftCont}>
                        <p className={classes.firstHead}>Compliance checks</p>
                        <div className="text-[12px] leading-[16px] font-[500] flex items-center gap-[5px]">
                            <span className={classes.greyHead}>Lead name :</span> <span>Fideo Tech</span>
                            <Tooltip
                                placement="bottom"
                                color="#060A0D"
                                title={
                                    <div className="flex flex-col gap-[5px]">
                                        <p className={classes.pTag}>Brand name
                                            : <span></span> {companyDetails?.brand_name}</p>
                                        <p className={classes.pTag}>Website : <span>{companyDetails?.website}</span></p>
                                        <p className={classes.pTag}>Business type : {companyDetails?.business_type}</p>
                                        <p className={classes.pTag}>Description
                                            : {companyDetails?.product_description}</p>
                                    </div>
                                }
                            >
                                <img src={infoIcon} alt={"info"} className="w-[14px] h-[14px]"/>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {detailsData?.verification_data.length > 0 && detailsData?.verification_data[0].verificationStatus !== "SUCCESS" && <button
                    className="w-[142.2px] px-[1.6rem] py-[1.2rem] rounded-[1rem] bg-[#181C30] text-white text-[1.6rem] leading-[2.4rem] font-[600]"
                    onClick={handleVerificationClick}
                >
                    Mark as verified
                </button>}
            </div>
            <div
                className="mt-[0px] flex gap-[30px] p-[40px] pt-[10px]"
            >
                {preSignedUrls.length > 0 && <div className="">
                    {<div className={classes.fileViewSection}>
                        {
                            // window.innerWidth >= 600 &&
                            <FileViewer
                                format={['document', 'image']}
                                displayUrls={documents}

                                downloadPrefix={"document"}

                                // customerEmail={viewInvoice?.reconcile_customer_details?.customer_email}

                                whereCalled="bigScreen"
                                setInvoicetoBigScreen={setDocuments}
                                invoicetoBigScreen={documents}
                            />
                        }
                        <div className={clsx(classes.invoiceDetailCon)}>
                            <div className={clsx(classes.invoiceTabCon)}>
                                <div className={clsx(`${classes.invoiceTabLocked} `)}
                                     style={{borderBottom: activeTab === "invoice" ? "2px solid #181C30" : "none"}}
                                     onClick={() => setActiveTab("invoice")}>
                                    <p className={clsx(`${classes.invoiceTabLockedText} `)}
                                       style={{
                                           color: activeTab === "invoice" ? "#1E333F" : "rgba(30, 51, 63, 0.50)",
                                           fontSize: "1.6rem",
                                           lineHeight: "2.4rem",
                                           fontWeight: "500"
                                       }}
                                    >Attachments</p>
                                </div>
                            </div>
                            {activeTab === "invoice" &&
                                (
                                    <div className={classes.invoiceCon}>
                                        {
                                            (
                                                <FileViewer
                                                    format={['document', 'image']}
                                                    displayUrls={documents}
                                                    downloadPrefix={"document"}
                                                    whereCalled="smallScreen"
                                                    invoiceData={documents}
                                                    setInvoicetoBigScreen={setDocuments}
                                                    //   setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                                                    //   paymentLinkOtpRequired={paymentLinkOtpRequired}
                                                    invoicetoBigScreen={documents}
                                                />
                                            )
                                        }

                                    </div>
                                )}
                        </div>
                    </div>}
                </div>}
                <div className="flex flex-col gap-[20px] w-[100%]">
                    <div className={classes.upperDiv}>
                        <div className={classes.upperLeftCont}>
                            <div className={classes.headDetail}>
                                Details to be verified
                            </div>
                            {
                                detailsKey === "ORG_PAN" ?
                                    <div className={classes.contDetails}>
                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Organization Name</span> : <span>{companyDetails?.brand_name}</span>
                                        </div>
                                        <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>{detailsData?.company_pan}</span>
                                        </div>
                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>Company</span>
                                        </div>
                                    </div>
                                    :
                                    detailsKey === "GST" ?
                                        <div className={classes.contDetails}>
                                            <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Organization Name</span> : <span>{companyDetails?.brand_name}</span>
                                            </div>
                                            <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>GSTIN Number</span> : <span>{detailsData?.gstin_number}</span>
                                            </div>
                                        </div>
                                        :
                                        detailsKey === "CIN" ?
                                            <div className={classes.contDetails}>
                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Organization Name</span> : <span>{companyDetails?.brand_name}</span>
                                                </div>
                                                <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>CIN number</span> : <span>{detailsData?.cin_number}</span>
                                                </div>
                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Business Type</span> : <span>{companyDetails?.business_type}</span>
                                                </div>
                                            </div>
                                            :
                                            detailsKey === "DIRECTOR" ?
                                                <div className={classes.contDetails}>
                                                    <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Name</span> : <span>{detailsData?.director_first_name} {detailsData?.director_last_name}</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>{detailsData?.director_pan}</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>Individual or Person</span>
                                                    </div>
                                                </div>
                                                :
                                                detailsKey === "OWNER" ?
                                                    <div className={classes.contDetails}>
                                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Name</span> : <span>{detailsData?.owner_first_name} {detailsData?.owner_last_name}</span>
                                                        </div>
                                                        <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>{detailsData?.owner_pan}</span>
                                                        </div>
                                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>Individual or Person</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    detailsKey === "USER" ?
                                                        <div className={classes.contDetails}>
                                                            <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Name</span> : <span>{detailsData?.first_name} {detailsData?.last_name}</span>
                                                            </div>
                                                            <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>{detailsData?.pan}</span>
                                                            </div>
                                                            <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>Individual or Person</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        detailsKey === "BANK" ?
                                                            <div className={classes.contDetails}>
                                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Bank Name</span> : <span>{detailsData?.bankAccountDetails?.bankName}</span>
                                                                </div>
                                                                <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Beneficiary Name</span> : <span>{detailsData?.bankAccountDetails?.accountName}</span>
                                                                </div>
                                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Account Number</span> : <span>{detailsData?.bankAccountDetails?.accountNumber}</span>
                                                                </div>
                                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>IFSC Code</span> : <span>{detailsData?.bankAccountDetails?.ifscCode}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={classes.contDetails}>
                                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Name</span> : <span>--</span>
                                                                </div>
                                                                <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>--</span>
                                                                </div>
                                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>--</span>
                                                                </div>
                                                            </div>
                            }
                        </div>
                        <div className={classes.upperLeftCont}>
                            <div className={classes.headDetail}>
                                Response details
                            </div>
                            {
                                detailsKey === "ORG_PAN" ?
                                    <div className={classes.contDetails}>
                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Organization Name</span> : <span>{jsonResponse?.result?.name}</span>
                                        </div>
                                        <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>{jsonResponse?.result?.number}</span>
                                        </div>
                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>{jsonResponse?.result?.typeOfHolder}</span>
                                        </div>
                                        <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Valid</span> : <span>{jsonResponse?.result?.isValid}</span>
                                        </div>
                                        <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan Status</span> : <span>{jsonResponse?.result?.panStatus}</span>
                                        </div>
                                    </div>
                                    :
                                    detailsKey === "GST" ?
                                        <div className={classes.contDetails}>
                                            <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Organization Name</span> : <span>{jsonResponse?.result?.gstnRecords[0]?.registrationName}</span>
                                            </div>
                                            <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>GSTIN number</span> : <span>{jsonResponse?.result?.gstin}</span>
                                            </div>
                                        </div>
                                        :
                                        detailsKey === "CIN" ?
                                            <div className={classes.contDetails}>
                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Organization Name</span> : <span>{jsonResponse?.result?.companyName}</span>
                                                </div>
                                                <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>CIN number</span> : <span>{jsonResponse?.result?.cin}</span>
                                                </div>
                                                <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Business Type</span> : <span>{jsonResponse?.result?.category}</span>
                                                </div>
                                            </div>
                                            :
                                            (detailsKey === "DIRECTOR" || detailsKey === "OWNER" || detailsKey === "USER") ?
                                                <div className={classes.contDetails}>
                                                    <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Name</span> : <span>{jsonResponse?.result?.name}</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>{jsonResponse?.result?.number}</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>{jsonResponse?.result?.typeOfHolder}</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                                        <span
                                                            className={classes.greyHead}>Valid</span> : <span>{jsonResponse?.result?.isValid}</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                                <span
                                                    className={classes.greyHead}>Pan Status</span> : <span>{jsonResponse?.result?.panStatus}</span>
                                                    </div>
                                                </div>
                                                :
                                                detailsKey === "BANK" ?
                                                    <div className={classes.contDetails}>
                                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Beneficiary Name</span> : <span>{jsonResponse?.result?.bankTransfer?.beneName}</span>
                                                        </div>
                                                        <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>IFSC Code</span> : <span>{jsonResponse?.result?.bankTransfer?.beneIFSC}</span>
                                                        </div>
                                                        <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Active: </span>  <span>{jsonResponse?.result?.active}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                <div className={classes.contDetails}>
                                                    <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Name</span> : <span>--</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                            <span
                                                className={classes.greyHead}>Pan number</span> : <span>--</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                    <span
                                        className={classes.greyHead}>Holder Type</span> : <span>--</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                                        <span
                                                            className={classes.greyHead}>Valid</span> : <span>--</span>
                                                    </div>
                                                    <div className={classes.valueSection}>
                                                <span
                                                    className={classes.greyHead}>Pan Status</span> : <span>--</span>
                                                    </div>
                                                </div>

                            }
                        </div>
                    </div>
                    <div className={classes.lowerDiv}>
                        <div className={classes.headDetail}>
                            Json Response
                        </div>
                        <pre className={classes.preDiv}>
                            {detailsData?.verification_data ? JSON.stringify(JSON.parse(detailsData?.verification_data[0]?.response), null, 2) : "--"}
                        </pre>
                    </div>

                </div>
            </div>
        </FullModal>
    )
}

export default LeadComplianceDetails;