import Backdrop from '../../../Layout/Backdrop'
import Modal from '../../../Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'
import Close from '../../../Layout/Close'

import classes from './CustomerDetailsModal.module.scss'
import leftPointer from '../../../assets/Icons/arrow-left.svg'
import { useSnackbar } from 'notistack'
import CustomerDetailsModalHeader from './CustomerDetailsModalHeader'
import arrowDown from '../../../assets/Images/arrow_down.svg'
import { Tabs } from 'antd'
// import UseApiCall from 'hooks/useApiCall'
// import { apiSendPaymentLink } from 'services'
import ButtonSet from '../../atom/ButtonSet/ButtonSet'
import { useEffect, useRef, useState } from 'react'
import CustomerDetailsTabContent from './CustomerDetailsTabContent'
import InvoicesTabContent from './InvoicesTabContent'
// import CreateInvoice from 'Components/Invoice/CreateInvoice'
import { currencyCodeByCountry } from '../../../constants/codeByCurrency'
import LinkIcon from '../../../assets/Icons/send-link.svg'
import {
    showSinglePaymentLinkModal,
} from '../../../utils/layout'
import { modalRefState } from '../../../recoil/layoutRef'
import { useRecoilValue } from 'recoil'
import modal from "../../../Layout/Modal";
import CustomModal from "../../atom/CustomModal";
import ComplianceChecks from "../ComplianceChecks/ComplianceChecks";

const copyTextToClipboard = (text) => {
    if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
}

const CustomerDetailsModal = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const [modalRef, setModalRef] = useState(null);
    const [activeTab,setActiveTab] = useState(props.whereCalled === 'compliance' ? 'complianceChecks' : 'invoices' )
    // const customer = useSelector((state) => state.customer.selected_customer)
    const customer = props.customerData;
    const { customer_name, email_address, country, verification_status } = props?.customerData?.details;
    const [selectedCustomerDetails, setSelectedCustomerDetails] = useState({
        id: props.customerId,
        customerName: customer_name,
        emailAddress: email_address,
        country: currencyCodeByCountry[country],
        displayId: props.selectedCustomerId,
        verificationStatus: verification_status,
    })
    const [transactionData, setTransactionData] = useState([])
    const showCustomerDisplay = useSelector(
        (state) => state.modal.showCustomerDisplay
    )

    const createInvoiceDisplay = useSelector(
        (state) => state.modal.createInvoiceDisplay
    )

    const customerDetailModalRef = useRef(null)

    const handleCopy = (paymentLink) => {
        try {
            copyTextToClipboard(paymentLink)
            enqueueSnackbar('Payment link copied!', { variant: 'success' })
        } catch (error) {}
    }
    // const [sendPaymentLink] = UseApiCall(apiSendPaymentLink, () => {
    //     enqueueSnackbar('Payment link sent Successfully', { variant: 'success' })
    // })
    const handleSendPaymentLink = () => {
        // const { invoice_id, payment_link } = invoice;
        // sendPaymentLink({invoiceId: invoice_id, paymentLink: encodeURIComponent(payment_link)})
    }
    const arrBtn = [
        // {
        //     text: 'Receive Payment',
        //     icon: arrowDown,
        //     active: true,
        //     isVisible: true, // show only if, this condition is true
        //     onClick: () => {
        //         // dispatch(modalActions.showCustomerDisplay())
        //         const { customer_name, email_address, country, verification_status } =
        //             props.customerData.details
        //         setSelectedCustomerDetails({
        //             id: props.customerId,
        //             customerName: customer_name,
        //             emailAddress: email_address,
        //             country: currencyCodeByCountry[country],
        //             displayId: props.selectedCustomerId,
        //             verificationStatus: verification_status,
        //         })
        //         // dispatch(modalActions.showCustomerDisplay)
        //         dispatch(modalActions.createInvoice())
        //     },
        // },
        {
            text: 'Payment Link',
            icon: LinkIcon,
            active: props?.customerData?.show_single_payment_link,
            disabled: !props?.customerData?.show_single_payment_link,
            isVisible: true,
            className: '!bg-[#FFFFFF] border border-solid border-[1px] border-[#060a0d1f]',
            btnClassText: 'text-[#000]',
            onClick: () => {
                const cellData = {
                    customer_detail: {
                        displayId: props.selectedCustomerId,
                    },
                    customer_id: props.customerId,
                };
                showSinglePaymentLinkModal(modalRef, {
                    cellData: cellData,
                    organizationId:props.organizationId
                })
            },
        },
    ]

    const onChange = (key) => {
        // console.log('key',key)
        setActiveTab(key)
    }

    const items = [
        {
            key: 'invoices',
            label: `Invoices`,
            children: <InvoicesTabContent summary={customer?.invoice_summary} customerId={props?.customerId} details={customer?.details} organizationId = {props.organizationId} />,
        },
        {
            key: 'customerDetails',
            label: `Customer Details`,
            children: <CustomerDetailsTabContent details={customer?.details} />,
        },
        {
            key: 'complianceChecks',
            label: `Compliance Checks`,
            children: <ComplianceChecks
                details={customer?.details}
                selectedCustomerId={props.selectedCustomerId}
                customerId={props.customerId}
            />,
        },
    ]

    return (
        <>
            <CustomModal ref={setModalRef} />
            {/*{createInvoiceDisplay &&*/}
            {/*    ReactDOM.createPortal(*/}
            {/*        <CreateInvoice*/}
            {/*            contact={selectedCustomerDetails}*/}
            {/*            nextPageFunctionality={() => dispatch(modalActions.removeAll())}*/}
            {/*        />,*/}
            {/*        document.getElementById('modal-root')*/}
            {/*    )}*/}
            <Modal
                className={clsx(
                    classes.modal,
                    `!bg-[#F4F6FA] px-0 sm:px-[48px] sm:pb-[96px] customModal invModal`
                )}
            >
                {ReactDOM.createPortal(
                    <Backdrop
                        onClick={() => {
                            dispatch(modalActions.showCustomerDisplay())
                        }}
                        className={classes.backdrop}
                    />,
                    document.getElementById('backdrop-root')
                )}
                <div
                    ref={customerDetailModalRef}
                    className={clsx(classes.invoiceModalContainer)}
                >
                    <div className="flex z-10 flex-row items-center justify-center sm:justify-between bg-[#F4F6FA] sm:pt-[48px] sticky top-0">
                        <img
                            src={leftPointer}
                            alt="left-pointer"
                            className="block sm:hidden absolute left-8 bg-white"
                            onClick={() => {
                                dispatch(modalActions.showCustomerDisplay())
                            }}
                        />
                        <CustomerDetailsModalHeader
                            pageName={`Customer: ${customer.details.customer_name}`}
                            arrBtn={arrBtn}
                            customerName={customer.details.customer_name}
                            status={customer.details.verification_status}
                            selectedCustomerId={props.selectedCustomerId}
                            customerId={props.customerId}
                            className={`w-full`}
                        />
                        <Close
                            className="w-[32px] h-[32px] -sm:hidden absolute top-0 right-[-36px] rounded-bl-2xl bg-gray-300"
                            onClick={() => {
                                dispatch(modalActions.showCustomerDisplay())
                            }}
                        />
                    </div>
                    {/* <div className="-sm:px-[16px]">
            <TransactionSummary clickable={false} invoice_summary={customer.invoice_summary} />
          </div> */}
                    <div
                        className="flex flex-col gap-[48px] -sm:p-8"
                        style={{
                            background: '#F3F6FA',
                        }}
                    >
                        {/*<Tabs defaultActiveKey="1" items={items} onChange={onChange} />*/}
                        <Tabs activeKey={activeTab} items={items} onChange={(key)=>onChange(key)} />
                    </div>
                </div>
                <ButtonSet arrBtn={arrBtn} />
            </Modal>
        </>
    )
}

export default CustomerDetailsModal
