import React from 'react';
import classes from "./CommercialsInfo.module.scss";
import percentageIcon from "../../assets/Icons/percentageIcon.png"

const CommercialsInfo = () => {
  return (
        <div className={classes.exporterProfile}>
            <div className={classes.basicDetailsBox}>
                <div className={classes.basicDetailsHead}>
                    Fees
                </div>
                <div className={classes.basicDetailsCont} style={{marginTop: "10px"}}>
                    <div className={classes.bd}>
                        <div className={classes.bdHead}>
                            <div className='flex items-center justify-center gap-[10px] '> 
                            <img src={percentageIcon} alt='percentageIcon'  className={classes.ImgIcon}/>
                            FX Fee
                            </div>
                            <div className='text-[#3E8DFC] cursor-pointer'>Edit</div>
                        </div>
                        <div className={classes.bdCont}>
                            <p className={classes.bdContGray}>0.25% above the mid-market rate</p>
                        </div>
                    </div>
                </div>
                <div className={classes.basicDetailsCont} style={{marginTop: "20px"}}>
                    <div className={classes.bd}>
                        <div className={classes.bdHead}>
                            <div className='flex items-center justify-center gap-[10px] '> 
                            <img src={percentageIcon} alt='percentageIcon'  className={classes.ImgIcon}/>
                            Payout Fees
                            </div>
                            <div className='text-[#3E8DFC] cursor-pointer'>Edit</div>
                        </div>
                        <div className={classes.bdCont}>
                            <p className={classes.bdContGray}>Fixed: US$ 20.00</p>
                            <p className={classes.bdContGray}>Variable: 00.00</p>
                            <p className={classes.bdContGray}>Minimum: US$ 0.00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default CommercialsInfo