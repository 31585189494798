import React, { useRef, useState } from 'react';
import classes from "./SettingsInfo.module.scss";
import { Switch } from 'antd';
import { Radio } from 'antd';
import { Dropdown } from 'antd';
// import useClickOutside from '../../hooks/useClickOutside';
// import { modalActions } from '../../Store/modal-redux';
import { useDispatch } from 'react-redux';
import ContactInfo from '../../Layout/ContactInfo';
import INRFlag from '../../assets/Images/INR_Flag.svg';
import USDFlag from '../../assets/Images/USD_Flag.svg';
import arrowDownIcon from '../../assets/Icons/arrow-DownIcon.svg';
import upArrow from "../../assets/Icons/arrow-up.svg";
import down from "../../assets/Icons/chevron-down.svg";
import clsx from 'clsx';

const SettingsInfo = () => {
  const [more, setMore] = useState('Thousands')
  const [moreFx, setMoreFx] = useState('2 times a day')
  // const optRef = useClickOutside(() => {
  //   dispatch(modalActions.showBankDropDown(false))
  // });
  // const dispatch = useDispatch();
  // const dropdownRef = useRef(null);

  const items = [
    {
      key: 'Thousands',
      label: <div className='text-[16px] font-inter'>Thousands</div>,
    },
    {
      key: 'Lacs',
      label: <div className='text-[16px] font-inter '>Lacs</div>,
    },
  ];

  const itemsFx = [
    {
      key: '2 times a day',
      label: <div className='text-[16px] font-inter'>2 times a day</div>,
    },
    {
      key: '3 times upto 24 times a day',
      label: <div className='text-[16px] font-inter '>3 times upto 24 times a day</div>,
    },
  ];

  const handleDropdownItemClick = (e) => {
    setMore(e.key);
  };

  const handleDropdownItemClickFx = (e) => {
    // console.log(e.key);
    setMoreFx(e.key);
  };

  return (
    <div className={classes.settingsWrap}>
      <div className={classes.settingsCon}>
        <div className={classes.basicDetailsHead}>
            Security
        </div>
        <div className={classes.basicDetailsCont} style={{marginTop: "10px"}}>
              <div className={classes.bd}>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Enable OTP to view invoices on FIDEO platform
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Enable OTP for all payment links
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
              </div>
        </div>
        <div className={classes.basicDetailsHead} style={{marginTop: "20px"}}>
            Communications
        </div>
        <div className={classes.basicDetailsCont} style={{marginTop: "10px"}}>
              <div className={classes.bd}>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Receive emails when money gets credited into Virtual bank account  
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Receive emails when money is reconciled
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Receive emails when money is converted
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Receive emails when money is paid to your bank account
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
                  <div className={classes.bdHead}>
                      <div className='flex items-center justify-center gap-[10px] text-[rgba(30, 51, 63, 0.8)] text-[14px]'> 
                      Receive emails when payment advice is generated
                      </div>
                      {<Switch checked style={{ backgroundColor: 'grey', borderColor: 'grey' }} />}
                  </div>
              </div>
          </div>

        <div className={classes.basicDetailsHead} style={{marginTop: "20px"}}>
            General
        </div>
        {true && (
              <div className="flex items-start justify-between mt-5 ml-[20px] w-[98%]" >
                <p className={clsx(`${classes.bdHead} `)} >Primary bank account to receive money in</p>
                <div className="w-[50%] bg-[#F7F8FB] flex flex-row items-center justify-between px-[20px] py-[16px] gap-8 rounded-[12px]" style={{cursor: true ? "pointer" : "default"}}  >
                  <div className={classes.pbankWrap}>
                  <img
                    src={
                       INRFlag
                    }
                    alt="country-flag"
                    className="w-[48px] h-[48px] rounded-full"
                  />

                  <ContactInfo
                    name={`ICICI Bank`}
                    email={"XXXXXXXX1290"}
                    className={classes.ctinfo}/>
                  </div>
                  
                  <div className={classes.rightDown}>
                    {<p className={classes.eefc} style={{backgroundColor:"#FFF"}}>{"INR"}</p>}
                    {true && (
                    <img
                      src={true?upArrow:arrowDownIcon}
                      alt="arrow-down-icon"
                      className="w-[24px] h-[24px] me-10px"
                      style={{ cursor: "pointer" }}
                  />)}
                  </div>
                </div>
              </div>
            )}

            {/* denomination */}
            <div className='flex items-start justify-between gap-[10px] ml-[20px] w-[98%]'>
              <div className={classes.bdHead} style={{marginTop: "10px", marginRight: "10px"}}>Show customer aging report in one of the 2 denominations</div>
              <Dropdown
                menu={{
                  onClick: handleDropdownItemClick,
                  items: items,
                }}
                placement="bottom"
                overlayStyle={{width:"110px", fontSize:"14px !important" }}
                arrow={true}
                // open={true}
              >
                <div className={clsx((true) ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}><>{more}</><img src={down} className="w-[20px] h-[20px]" /></div>
              </Dropdown>
            </div>

            {/* fx rate frequency */}
            <div className='flex items-start justify-between gap-[10px] ml-[20px]  w-[98%]'>
              <div className={classes.bdHead} style={{marginTop: "10px", marginRight: "10px"}}>FX rates update frequency daily</div>
              <Dropdown
                menu={{
                  onClick: handleDropdownItemClickFx,
                  items: itemsFx,
                }}
                placement="bottomRight"
                overlayStyle={{width:"243px", fontSize:"14px !important" }}
                arrow={true}
                // open={true}
              >
                <div className={clsx((true) ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}><>{moreFx}</><img src={down} className="w-[20px] h-[20px]" /></div>
              </Dropdown>
            </div>
      </div>
    </div>
  )
}

export default SettingsInfo