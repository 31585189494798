import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Authentication from "./Layout/Authentication";
import Error from "./Components/Global/Error";
import Login, { action as loginAction }  from "./Pages/Login";
import { RecoilRoot } from "recoil";
import Admin from "./Layout/Admin/Admin";
import Dashboard from "./Pages/Dashboard";
import Exporters from "./Pages/Exporters";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ConfigProvider } from 'antd';
import {createRef, useState} from "react";
import CloseIcon from './assets/Icons/close-white.svg';
import './Scss/global.scss';
import Receivables from "./Pages/Receivables";
import Payouts from "./Pages/Payouts";
import Customers from "./Pages/Customers";
import Leads from "./Pages/Leads";
import {useSelector} from "react-redux";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import dayjs from 'dayjs'
// import ChatBot from "Components/Bot/ChatBot";

dayjs.extend(dayjsPluginUTC)

function App() {
  const notistackRef = createRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isLogin,setIsLogin] = useState(false);

  // console.log('isLogin',isLogin)
  const onClickDismiss = (key) => {
    notistackRef?.current?.closeSnackbar(key);
  };
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Authentication />,
      errorElement: <Error />,
      children: [
        { path: '/', element: <Navigate to="login?mode=email" replace /> },
        { path: 'login', element: <Login setIsLogin={setIsLogin}/> , action: loginAction },
      ]
    },
    {
      path: '/',
      element: <Admin isLogin={isLogin} setIsLogin={setIsLogin}/>,
      // element: (
      //   <>
      //     <Admin isLogin={isLogin} setIsLogin={setIsLogin} />
      //     <ChatBot />
      //   </>
      // ),
      errorElement: <Error />,
      children: [
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/exporters', element: <Exporters /> },
        { path: '/leads', element: <Leads /> },
        { path: '/receivables', element: <Receivables /> },
        { path: '/payouts', element: <Payouts /> },
        { path: '/customers', element: <Customers /> },
      ]
    }
  ])

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorTextPlaceholder: "rgba(30, 51, 63, 0.5)",
            fontFamily: 'Inter',
            fontSizeLG: '1.6rem',
            fontWeightStrong: '500',
            colorText: '#1E333F',
            colorInfoBorderHover: '#1E333F',
            colorInfoBorder: '#1E333F',
            colorSuccessBorder: '#1E333F'
          },
        },
      }}
    >
      <SnackbarProvider
        ref={notistackRef}
        autoHideDuration={2000}
        action={(key) => (
          <img
            width={20}
            height={20}
            src={CloseIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              onClickDismiss(key);
            }}
          />
        )}
        maxSnack={1}
        className="text-xl"
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <RecoilRoot>
          <RouterProvider router={router} />
        </RecoilRoot>
      </SnackbarProvider>
    </ConfigProvider>
  );
}

export default App;
