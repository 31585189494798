// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput_box__nlc6q {
  width: 100%;
  height: max-content;
}
.PasswordInput_box__nlc6q .PasswordInput_password__-2Agp {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 45px;
  width: 100%;
}
.PasswordInput_box__nlc6q .PasswordInput_password_error__9E1sY {
  height: 63px;
}
.PasswordInput_box__nlc6q .PasswordInput_password_input__Jev4j {
  width: 100%;
  position: absolute;
}
.PasswordInput_box__nlc6q .PasswordInput_password__-2Agp .PasswordInput_eye__jrxDa {
  position: absolute;
  margin-right: 14.4px;
  cursor: pointer;
  height: 21.6px;
  width: 21.6px;
  top: 10.8px;
  background-color: white;
}
.PasswordInput_box__nlc6q .PasswordInput_tooltip__inKJC {
  position: absolute;
  z-index: var(--level_2);
  top: 69.3px;
}`, "",{"version":3,"sources":["webpack://./src/Layout/PasswordInput.module.scss"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,mBAAA;AAAJ;AAEI;EAEI,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;AADR;AAGQ;EACI,YAAA;AADZ;AAIQ;EACI,WAAA;EACA,kBAAA;AAFZ;AAKQ;EACI,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,WAAA;EACA,uBAAA;AAHZ;AAOI;EACI,kBAAA;EACA,uBAAA;EACA,WAAA;AALR","sourcesContent":[".box{\n\n    width: 100%;\n    height: max-content;\n\n    .password{\n\n        position: relative;\n        display: flex;\n        justify-content: flex-end;\n        height: 45px;\n        width: 100%;\n\n        &_error{\n            height: 63px;\n        }\n    \n        &_input{\n            width: 100%;\n            position: absolute;\n        }\n    \n        .eye{\n            position: absolute;\n            margin-right: 14.4px;\n            cursor: pointer;\n            height: 21.6px;\n            width: 21.6px;\n            top: 10.8px;\n            background-color: white;\n        }\n    }\n\n    .tooltip{\n        position: absolute;\n        z-index: var(--level_2);\n        top: 69.3px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `PasswordInput_box__nlc6q`,
	"password": `PasswordInput_password__-2Agp`,
	"password_error": `PasswordInput_password_error__9E1sY`,
	"password_input": `PasswordInput_password_input__Jev4j`,
	"eye": `PasswordInput_eye__jrxDa`,
	"tooltip": `PasswordInput_tooltip__inKJC`
};
export default ___CSS_LOADER_EXPORT___;
