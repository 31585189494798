import React, {useEffect, useState} from 'react';
import ComplianceTable from "./ComplianceChecksTable";
import threeBlackDots from '../../../assets/Icons/threeBlackDots.svg'
import quickSearch from '../../../assets/Icons/quickSearch.svg'
import customSearch from '../../../assets/Icons/search-icon.svg'
import {useDispatch, useSelector} from "react-redux";
import ReactDOM from "react-dom";
import InvoiceDetailsModal from "../InvoiceDetailsModal/InvoiceDetailsModal";
import CustomSearch from "./CustomSearch";
import {modalActions} from "../../../Store/modal-redux";
import useClickOutside from "../../../hooks/useClickOutside";
import UseApiCall from "../../../hooks/useApiCall";
import {
    apiGetComplianceCustom,
    apiGetComplianceDetails,
    apiGetComplianceDetailsQuick,
    apiGetCustomerDetails,
    apiUpdateComp,
    apiUpdateCompRiskLevel
} from "../../../services";
import {CustomersActions} from "../../../Store/customer-redux";
import {useSnackbar} from "notistack";


const ComplianceChecks = (props) =>{
    const { enqueueSnackbar } = useSnackbar();
    const [checkAgainOpen,setCheckAgainOpen] = useState(false);
    const [riskLevel, setRiskLevel] = useState(null);
    const [matchStatus, setMatchStatus] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [complianceData, setComplianceData] = useState(null);

    const [customerName, setCustomerName] = useState('');
    const [isCustomerNameValid, setIsCustomerNameValid] = useState(false)

    const [otherRefrences, setOtherRefrences] = useState('');
    const [isOtherRefrencesValid, setIsOtherRefrencesValid] = useState(false)

    const [allSanctions, setAllSanctions] = useState(false)
    const [allPeps, setAllPeps] = useState(false)
    const [allAdverse, setAllAdverse] = useState(false)
    const [yofB , setYofB] = useState();
    const [fuzinessValue, setFuzinessValue] = useState(50);
    const [allSelected , setAllSelected] = useState(false);
    const [countryList , setCountryList] = useState([]);
    const [sourcesList , setSourcesList] = useState([]);
    const [pepsList , setPepsList] = useState([]);
    const [adverseList , setAdverseList] = useState([]);


    const dispatch = useDispatch();
    const showComplianceCustomSearch = useSelector((state) => state.modal.showComplianceCustomSearch);
    const optRefCheck = useClickOutside(()=>setCheckAgainOpen(false));

    const tableHeader = [
        // 'created on', 'invoice id', 'amount', 'status',
        {
            id:1,
            title:"Search Id",
            width:  2,
            minWidth:80,
        },
        {
            id:2,
            title:"Search Date",
            width:  2,
            minWidth:80,
        },
        {
            id:3,
            title:"Search Term",
            width:  1.5,
            minWidth:100,
        },
        {
            id:4,
            title:"References",
            title2:"Found",
            width: 1.5,
            minWidth:60,
        },
        {
            id:5,
            title:"status",
            width:  4,
            minWidth:50,
        },
        {
            id:6,
            title:"Risk Level",
            width: 2,
            minWidth:60,
        },
        // {
        //     id:6,
        //     title:"Monitor",
        //     width: 14,
        //     minWidth:100,
        // },
        {
            id:7,
            title:"Action",
            width: 2,
            minWidth:20,
        },
    ]

    const [getComplianceDetails, fetchingCom,ComplianceData] = UseApiCall(apiGetComplianceDetails, (res) => {
        const sortedData = res?.data?.sort((a, b) => b?.searchDate - a?.searchDate);
        setComplianceData(sortedData)
    })

    const [getComplianceDetailsQuick, fetchingComQuick,ComplianceDataQuick] = UseApiCall(apiGetComplianceDetailsQuick, (res) => {
        // console.log('res',res?.data)
        enqueueSnackbar('Quick search done successfully.',
            {
                variant: 'success' ,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                autoHideDuration: 3000, // Specify the duration in milliseconds
                style: { fontSize: '10px' }
            }
        )
        getComplianceDetails({ customerId: props?.customerId})
    }, ()=>{
        enqueueSnackbar("Error while quick search , try again.",
            {
                variant: 'error',
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                autoHideDuration: 3000, // Specify the duration in milliseconds
                style: { fontSize: '10px' }
            })
    })

    const [getComplianceCustom, fetchingCustom,ComplianceCustomData] = UseApiCall(apiGetComplianceCustom, (res) => {
        // console.log('res',res)

            enqueueSnackbar('Custom search done successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )

        getComplianceDetails({ customerId: props?.customerId});
    }, (err)=>{
            enqueueSnackbar("Error while custom search , try again.",
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
        )

    useEffect(() => {
        getComplianceDetails({ customerId: props?.customerId})
    }, []);

    const handleQuickSearch = () => {
        getComplianceDetailsQuick({ customerId: props?.customerId})
    }

    const handleCustomSearchSubmit = () => {
        const countryCodes = countryList?.map(country => country.code);
        // console.log('countryArr',countryCodes)
        getComplianceCustom(
            {
                search_term: customerName || null,
                client_ref: otherRefrences || null,
                fuzziness: fuzinessValue / 100 || null,
                filters: {
                    country_codes: countryCodes || null,
                    types: [...sourcesList, ...pepsList, ...adverseList],
                    birth_year: yofB || null
                },
                customer_id: props?.customerId || null
            }
        )
        dispatch(modalActions.setShowComplianceCustomSearch(false))
        setCustomerName(null)
        setOtherRefrences(null)
        setYofB(null)
        setCountryList([])
        setSourcesList([])
        setPepsList([])
        setAdverseList([])

        setAllSanctions(false)
        setAllPeps(false)
        setAllAdverse(false)
        setFuzinessValue(50)
    }

    const handleCustomSearch = () => {
        dispatch(modalActions.setShowComplianceCustomSearch(true))
        // getComplianceDetailsQuick({ customerId: props?.customerId})
    }

    const handleCountrySelection = (e, country) => {
        // console.log('country', country);
        setCountryList((prev) => {
            const index = prev.findIndex(item => item.name === country.name);
            if (index !== -1) {
                // If the country is already included, remove it
                return prev.filter((item, i) => i !== index);
            } else {
                // If the country is not included, add it
                return [...prev, country];
            }
        });
        // Other actions can be added here if needed
    }

    const handleAllCountrySelection = (e, allCountries, x) => {
        // console.log('allCountries', allCountries);
        if(!x){
            setAllSelected(true)
            setCountryList(allCountries)
        }else {
            setAllSelected(false)
            setCountryList([])
        }
    }

    const handleSourcesSelection = async (e, source, sanctions) => {
        // console.log('source', source);
        // console.log('sanctions', sanctions);
        await setSourcesList((prevSourcesList) => {
            const updatedSourcesList = prevSourcesList.includes(source.key)
                ? prevSourcesList.filter((item) => item !== source.key)
                : [...prevSourcesList, source?.key];
            const allInSourcesList = sanctions?.every((sanction) => updatedSourcesList.includes(sanction.key));
            // console.log('allInSourcesList', allInSourcesList);
            setAllSanctions(allInSourcesList);
            return updatedSourcesList;
        });
    };

    const handleAllSanctions = (e, sanctions) => {
        e.stopPropagation();
        setAllSanctions(prevAllSanctions => {
            const updatedAllSanctions = !prevAllSanctions;
            console.log('allSanctions', updatedAllSanctions);
            return updatedAllSanctions;
        });

        if (!allSanctions) {
            const allSanctionKeys = sanctions.map(sanction => sanction.key);
            setSourcesList(allSanctionKeys);
        } else {
            setSourcesList([]);
        }
    };

    const handlePepSelection = async (e, pep, peps) => {
        // console.log('pep', pep);
        // console.log('peps', peps);
        await setPepsList((prevPepsList) => {
            const updatedPrevPepsList = prevPepsList.includes(pep.key)
                ? prevPepsList.filter((item) => item !== pep.key)
                : [...prevPepsList, pep?.key];
            const allInPepsList = peps?.every((pep) => updatedPrevPepsList.includes(pep.key));
            // console.log('allInPepsList', allInPepsList);
            setAllPeps(allInPepsList);
            return updatedPrevPepsList;
        });
    };

    const handleAllPeps = (e, peps) => {
        e.stopPropagation();
        setAllPeps((prevAllPeps) => {
            const updatedAllPeps = !prevAllPeps;
            // console.log('updatedAllPeps', updatedAllPeps);
            return updatedAllPeps;
        });

        if (!allPeps) {
            const allPepsKeys = peps?.map(pep => pep.key);
            setPepsList(allPepsKeys);
        } else {
            setPepsList([]);
        }
    };

    const handleAdverseSelection = async (e, adverse, adverses) => {
        // console.log('adverse', adverse);
        // console.log('adverses', adverses);
        await setAdverseList((prevAdverseList) => {
            const updatedPrevAdverseList = prevAdverseList.includes(adverse.key)
                ? prevAdverseList.filter((item) => item !== adverse.key)
                : [...prevAdverseList, adverse?.key];
            const allInAdverseList = adverses?.every((adverse) => updatedPrevAdverseList.includes(adverse.key));
            // console.log('allInAdverseList', allInAdverseList);
            setAllAdverse(allInAdverseList);
            return updatedPrevAdverseList;
        });
    };

    const handleAllAdverses = (e, adverses) => {
        e.stopPropagation();
        setAllAdverse((prevAllAdverse) => {
            const updatedAllAdverse = !prevAllAdverse;
            // console.log('updatedAllAdverse', updatedAllAdverse);
            return updatedAllAdverse;
        });

        if (!allAdverse) {
            const allAdverseKeys = adverses?.map(adverse => adverse.key);
            setAdverseList(allAdverseKeys);
        } else {
            setAdverseList([]);
        }
    };



    // console.log(totalHits); // Output: 500
    // console.log('propsComp',props)
    // const countryCodes = countryList?.map(country => country.code);
    // console.log('countryCodes',countryCodes)
    //
    // console.log('customerName',customerName)
    // console.log('otherRefrences',otherRefrences)
    // console.log('countryList',countryList)
    // console.log('sourcesList',sourcesList)
    // console.log('pepsList',pepsList)
    // console.log('adverseList',adverseList)
    // console.log('allSanctions',allSanctions)
    // console.log('fuzinessValue',(fuzinessValue / 100))
    // console.log('yofB',yofB)

    return(
        <>
            {
                showComplianceCustomSearch &&
                ReactDOM.createPortal(
                    <CustomSearch
                        customerName={customerName}
                        setCustomerName={setCustomerName}
                        isCustomerNameValid={isCustomerNameValid}
                        setIsCustomerNameValid={setIsCustomerNameValid}
                        otherRefrences={otherRefrences}
                        setOtherRefrences={setOtherRefrences}
                        isOtherRefrencesValid={isOtherRefrencesValid}
                        setIsOtherRefrencesValid={setIsOtherRefrencesValid}
                        yofB={yofB}
                        setYofB={setYofB}
                        countryList={countryList}
                        setCountryList={setCountryList}
                        handleCountrySelection={handleCountrySelection}
                        handleSourcesSelection={handleSourcesSelection}
                        handleAllSanctions={handleAllSanctions}
                        sourcesList={sourcesList}
                        allSanctions={allSanctions}
                        setAllSanctions={setAllSanctions}
                        handlePepSelection={handlePepSelection}
                        handleAllPeps={handleAllPeps}
                        allPeps={allPeps}
                        pepsList={pepsList}
                        handleAdverseSelection={handleAdverseSelection}
                        handleAllAdverses={handleAllAdverses}
                        adverseList={adverseList}
                        allAdverse={allAdverse}
                        handleCustomSearchSubmit={handleCustomSearchSubmit}
                        setFuzinessValue={setFuzinessValue}
                        fuzinessValue={fuzinessValue}
                        handleAllCountrySelection={handleAllCountrySelection}
                        setAllSelected={setAllSelected}
                        allSelected={allSelected}
                    />,
                    document.getElementById('modal-root')
                )
            }

            <div className="" ref={optRefCheck}>
                <div className="flex w-full items-center justify-end mb-[20px] sticky  rounded-[1rem] ">
                    <div className="flex rounded-[1rem] relative cursor-pointer"
                         onClick={()=>setCheckAgainOpen(!checkAgainOpen)}
                    >
                        <div className="flex items-center justify-center rounded-tl-[1rem] rounded-bl-[1rem] border border-solid border-[#060A0D1F] px-[12px] py-[12px] bg-[#FFFFFF]">
                            <p className="font-inter text-[14px] font-[600] leading-6 text-center text-[#1E333F]">Check Again</p>
                        </div>
                        <div
                            className="flex items-center justify-center rounded-tr-[1rem] rounded-br-[1rem] border-t border-r
                                    border-b border-solid border-[#060A0D1F] px-[12px] py-[12px] bg-[#FFFFFF]"

                        >
                            <img src={threeBlackDots} />
                        </div>
                    </div>
                </div>
                {checkAgainOpen &&
                    <div
                        className="flex flex-col    py-[12px] border-b-[1px] border-r-[1px] rounded-[12px]
                    bg-[#FFFFFF] shadow-lg absolute top-[52px] right-0 z-[50] "
                    >
                        <div className="flex w-[240px] h-[48px]  px-[12px] py-[16px] hover:bg-[#F4F6FA] cursor-pointer "
                             onClick={handleQuickSearch}
                        >
                            <div className="flex items-center gap-[16px]"
                            >
                                <img src={quickSearch} className="w-[24px] h-[24px]"/>
                                <p className="font-inter text-[14px] font-[400] leading-6 text-center text-[#1E333F]">Quick Search</p>
                            </div>
                        </div>

                        <div className="flex w-[240px] h-[48px]  px-[12px] py-[16px] hover:bg-[#F4F6FA] cursor-pointer "
                             onClick={handleCustomSearch}
                        >
                            <div className="flex items-center gap-[16px]"
                            >
                                <img src={customSearch} className="w-[24px] h-[24px]"/>
                                <p className="font-inter text-[14px] font-[400] leading-6 text-center text-[#1E333F]">Custom Search</p>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <ComplianceTable
                tableHeader={tableHeader}
                tableData={complianceData}
                loading={fetchingCom}
                riskLevel={riskLevel}
                setRiskLevel={setRiskLevel}
                matchStatus={matchStatus}
                setMatchStatus={setMatchStatus}
                searchId={searchId}
                setSearchId={setSearchId}
                customerId={props?.customerId}
                getComplianceDetails={getComplianceDetails}
                // fetchingUpdateCom={fetchingUpdateCom}
                // fetchingUpdateComMatchStatus={fetchingUpdateComMatchStatus}
            />
        </>
    )
}

export default ComplianceChecks;