import { useNavigate } from 'react-router-dom'
import Close from 'Layout/Close'
import Modal from 'Layout/Modal'
import classes from './CreateInvoice.module.scss'
import ReactDOM from 'react-dom'
import Backdrop from 'Layout/Backdrop'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import clsx from 'clsx'
import { useEffect, useState, useRef} from 'react'
import UseApiCall from 'hooks/useApiCall'
import arrowDownIcon from 'assets/Icons/arrow-DownIcon.svg'
import lock from "assets/Icons/lockTala.svg"
import rightArrow from "../../assets/Icons/chevron-rightNew.svg"
import crossImg from "../../assets/Icons/crossImg.svg"
import RightIcon from 'assets/Icons/chevron-right-white.svg'
// import EEFCTag from "assets/Icons/EEFC-tag.svg"
import {
  apiToCreateInvoice,
  apiGetReceivables,
  apiGetDashboardInvoice,
  apiGetSummary,
  // apiGetPurposeCodeData
  apiGetCreateInvoiceData,
  apiGetCustomerContactList,
  fetchFileFromS3
} from 'services'
import TextInput from 'Layout/TextInput'
import Button from 'Layout/Button'
import ContactInfo from 'Components/Global/ContactInfo'
import leftPointer from 'assets/Icons/arrow-left.svg'
import InfoIcon from 'assets/Icons/info_black_circle.svg'
import { useRecoilValue } from 'recoil'
import { modalRefState } from 'recoil/layoutRef'
import { DatePicker, Select } from 'antd'
import INRFlag from 'assets/Images/INR_Flag.svg'
import { showInvoiceVerificationPrompt } from 'utils/layout'
import dayjs from 'dayjs'
import EditFileBrowser from 'Components/atom/FileBrowser/EditFileBrowser'
import { enqueueSnackbar } from 'notistack'
import { ReceivablesActions, CustomersActions, DashboardSummaryActions } from 'Store/customer-redux'
import HelpCircle from "assets/Icons/help-circle.svg"
import { Tooltip } from 'antd'
import BankDropDown from 'Components/ReportTable/BankDropDown'
import PurposeCodeDropdown from 'Components/ReportTable/PurposeCodeDropdown'
import CustomSelectorValuesCodes from "Components/atom/CustomerSelecterValues/CustomSelectorValuesCodes";
import useClickOutside from "hooks/useClickOutside";
import { useSelector } from "react-redux";
import NormalAction from 'Components/ReportTable/NormalAction'
import checkIcon from "../../assets/Icons/check.svg"
import upArrow from "../../assets/Icons/arrow-up.svg"
import EditFileBrowserNew from 'Components/atom/FileBrowserForUploader/EditFileBrowserNew'
import EnterOtp from 'Components/otpScreen/EnterOtp'
import EnterOtpInner from 'Components/otpInnerScreen/EnterOtp'
import TypeOtp from 'Components/otpInnerScreen/TypeOtp'
// import DocumentSheet from 'Pages/Other/MakePayment/DocumentSheet'
import {ContactsActions} from 'Store/customer-redux';
import CustomModal from 'Components/atom/CustomModal'

const customStyles = {
  error: {
    backgroundColor: 'orange',
    border: '1px solid orange',
    color: 'white',
  },
  info: {
    backgroundColor: 'orange',
    border: '1px solid orange',
    color: 'white',
  },
};

const EditInvoice = (props) => {
  const { selectedInvoiceData, organizationId} = props;
  const dispatch = useDispatch()
  const showBankDropDown = useSelector((state) => state.modal.showBankDropDown);
  const invoiceTooltipDisplay = useSelector((state) => state.modal.invoiceTooltipDisplay);
  const invoice = useSelector((state) => state.receivable.selected_invoice);
  const userId = useSelector(state => state?.login?.userId);
  const optRef = useClickOutside(() => {
    screenWidth > 600
    && dispatch(modalActions.showBankDropDown(false))
    // : dispatch(modalActions.showBankDropDown(true))
  });
  const badRef = useClickOutside(()=>{

  })
  const handleBankClick = () =>dispatch(modalActions.showBankDropDown())
  const purposeCodeDropDown = useSelector((state) => state.modal.purposeCodeDropDown);
  const navigate = useNavigate()
  const { selectedInvoiceId } = props
  // const modalRef = useRecoilValue(modalRefState)
  const [modalRef, setModalRef] = useState(null);
  const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return  window.innerWidth
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenWidth]);
  const [remarks, setRemarks] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const [transactionId, setTransactionId] = useState(null)
  const [primaryBank, setPrimaryBank] = useState(null)
  const [forexDetails, setForexDetails] = useState(null)
  const [allBanks, setAllBanks] = useState([])
  const [invoiceId, setInvoiceId] = useState('')
  const [purposeCode, setPurposeCode] = useState('')
  const [issueDate, setIssueDate] = useState(null)
  const [dueDate, setDueDate] = useState()
  const [blDate, setBLDate] = useState()
  const [invoiceAmount, setInvoiceAmount] = useState('')
  const [receivableAmount, setReceivableAmount] = useState('')
  const [invoiceDescription, setInvoiceDescription] = useState('')
  const [invoiceCurrency, setInvoiceCurrency] = useState('USD')
  const [createDataApi, setCreateDataApi] = useState(false)
  const [invoices, setInvoices] = useState({})
  const [invoices1, setInvoices1] = useState([])
  const [changedDueDate, setChangedDueDate] = useState(false)
  const [changedIssueDate, setChangedIssueDate] = useState(false)
  const [key, setKey] = useState(false)
  const [purposeCodeArray, setPurposeCodeArray] = useState([])
  const [isInvoiceIdValid, setIsInvoiceIdValid] = useState(false)
  const [ispurposeCodeValid, setIsPurposeCodeValid] = useState(false)
  const [isInvoiceAmountValid, setIsInvoiceAmountValid] = useState(false)
  const [isReceivableAmountValid, setIsReceivableAmountValid] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [draftClicked, setDraftClicked] = useState(false)
  const [hasInvoiceError, setHasInvoiceError] = useState(false)
  const [customerCurrencies, setCustomerCurrencies] = useState({})
  const [primaryID,setPrimaryID] = useState(null);
  const [invoiceCheckbox, setInvoiceCheckbox] = useState(false);
  const [activeTab,setActiveTab] = useState('invoice');
  // const [lockInvoices, setLockInvoices] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState(false);
  const [activeInvoiceLock, setActiveInvoiceLock] = useState(false);
  const [willUpdate,setWillUpdate] = useState(false);
  const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(invoice?.is_otp_required || false);
  // const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(invoice?.is_otp_required || false);
  const [invoicesLocked, setInvoicesLocked] = useState([
    {
      type : "image/png",
      url : RightIcon
    }
  ]);
  const [otpViewNowBox,setOtpViewNowBox] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const [otpErrorState, setOtpErrorState] = useState({ error: false })
  const [otp, setOtp] = useState(['', '', '', '','','']); 
  const [otpSuccess,setOtpSuccess] = useState(true);
  const [presignedUrlErrorState, setPresignedUrlErrorState] = useState({ error: false })
  const [invoicesData, setInvoicesData] = useState(invoice?.invoice_details_summary?.presigned_urls || [])
  const [invoiceData,setInvoiceData] = useState(props.selectedInvoiceData?.invoice_details_summary?.presigned_urls)
  const [lockInvoices, setLockInvoices] = useState([])
  const [unlockInvoices, setunLockInvoices] = useState([])
  const [invoicetoBigScreen, setInvoicetoBigScreen] = useState([])
  const [invoiceError , setInvoiceError] = useState(false);
  const inputErrorScroll = useRef(null);
  const focusRef = useRef(null);
  const noneRef = useRef(null);
 
  const handleInputError = () =>{
    if(inputErrorScroll.current) {
      inputErrorScroll.current.scrollIntoView({
        behavior: 'smooth' , 
        block: 'start' ,
        inline: 'start',
      });
    }
  }

  const handleFocus = () =>{
    if(focusRef.current) {
      setInvoiceError(false)
      focusRef.current.focus()
    }
  }

  const handleOTPView = () => {
    setOtpViewNowBox((prev) => !prev);
    setPresignedUrlErrorState({
      error: false
    })
    // setPaymentLinkOtpRequired((prev) => !prev);

    const params = {invoiceId: invoice?.invoice_id}

    // const currentTime = new Date().getTime();
    // const expirationTime = parseInt(localStorage.getItem(`timerExpiration${invoice?.invoice_id}`), 10) || 0;
  
    // if (expirationTime - currentTime <= 0) {
    //   fetchOtpData(params);
    // }
    fetchOtpData(params)
  }

  const handleResendOTPView = () => {
    const params = {invoiceId: invoice?.invoice_id}
    fetchOtpData(params)
  }


  const handleOTPViewNow = () => {
    // setOtpViewNowBox(false);
    // setPaymentLinkOtpRequired(false);
    fetchPresignedUrl({ invoiceId: invoice?.invoice_id, otp: otp.join('')})
  }

  const [fetchOtpData] = UseApiCall(
    // apiGetInvoiceLinkOTP,
    async (res) => {
      try {
        // setOtpViewNowBox((prev) => !prev);
        setOtpErrorState({
          error: false
        })
        setPresignedUrlErrorState({
          error: false
        })
        setOtpData(res?.data);
      } catch (error) {
        console.error('Data processing error:', error);
      }
    },
    async (err) => {
      try {
        setOtpErrorState({
          error: true,
          title: err?.data?.error_response?.message || '',
          subtext: 'Please check your emailId.',
        });
        setPresignedUrlErrorState({
          error: false
        })
      } catch (error) {
        console.error('Error handling error:', error);
      }
    }
  );
  


  const [fetchPresignedUrl, fetchingPresignedUrl, fetchInvoiceDataOtp] = UseApiCall(
    // apiGetInvoicesPresignedUrlsWithOtp,
    (res) => {
      setOtpViewNowBox(false);
      setPaymentLinkOtpRequired(false);
      setPresignedUrlErrorState({
        error: false
      })
      localStorage.removeItem(`timerStart${invoice?.invoice_id}`);
      localStorage.removeItem(`timerExpiration${invoice?.invoice_id}`);
      setOtpErrorState({
        error: false
      })
      setInvoices([])
      setOtpSuccess(false)
      // invoiceData = res?.data;
      // setInvoicesData(res?.data)
      setInvoiceData(res?.data)
      // res?.data.map(
      //   (item) => getFilesFromS3(item.url)
      // )
    },
    (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message}`, { variant: 'error' })
      setOtpViewNowBox(true)
      setOtpErrorState({
        error: false
      })
      setPresignedUrlErrorState({
        error: true,
        title: err?.data?.error_response?.message || '',
        subtext: 'Not able to fetch Invoices',
      })
    }
  )

  const handlePageUpdate = () => {
    // const url = window.location.pathname;
    // const parts = url.split('/');
    // if (parts[parts?.length - 1] === "receivables") {
    //   dispatch(ReceivablesActions.setUpdateReceivables())
    // } else if (parts[parts?.length - 1] === "dashboard") {
    //   dispatch(DashboardSummaryActions.setUpdateDashboard())
    // } else if (parts[parts?.length - 1] === "customers") {
    //   dispatch(CustomersActions.setUpdateCustomers())
    // }
  }

  // const [getCustomerCurrencies] = UseApiCall(
  //   apiGetCustomerCurrencies,
  //   (res) => {
  //     setCustomerCurrencies(res?.data)
  //   }
  // )

  // const [getBankAccounts, fetchingBanks] = UseApiCall(apiGetBankList, (res) => {
  //   setAllBanks(res?.data);
  // })

  // useEffect(() => {
  //   allBanks?.forEach((item) => {
  //     if (item?.accountId === primaryID && allBanks?.length > 0) {
  //       setPrimaryBank(item)
  //     }
  //   })
  // }, [allBanks, primaryID])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const [getForexRate, fetchingForexRate] = UseApiCall(
  //   apiGetForexRate,
  //   (res) => {
  //     setForexDetails(res?.data)
  //   }
  // )

  const [contact, setContact] = useState({
    "customer_name": "NA",
    "customer_country": "NA",
    "customer_email": "NA",
    "customer_display_id": "NA",
    "customer_id": null
  });

  // const [getSelectedInvoice, selectedInvoiceDataLoading, selectedInvoiceData, selectedInvoiceError] = UseApiCall(apiGetInvoiceDetails, (res) => {
  //   dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
  //   dispatch(CustomersActions.setSelectedInvoice(res?.data))
  //   dispatch(CustomersActions.setActiveInvoiceTab('details'))
  //   setPrimaryID(res?.data?.invoice_details_summary?.invoice_attributes?.bank_account_id);
  //   setWillUpdate(res?.data?.invoice_status !== "DRAFT")
  //   setPaymentLinkOtpRequired(res?.data?.is_otp_required)
  //   setInvoiceData(res?.data?.invoice_details_summary?.presigned_urls)
  //   setRemarks(res?.data?.invoice_details_summary?.invoice_attributes?.remarks)
  //   setPaymentTerms(res?.data?.invoice_details_summary?.invoice_attributes?.net_terms)
  // }, (err) => {
  //   enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  // })
  
  // const invoiceDataRaw = useSelector((state)=>state.receivable.selected_invoice)


  // const [getPurposeCode, fetchingPurposeCode, purposeCodeData] = UseApiCall(
  //   apiGetPurposeCodeData,
  //   (res) => {
  //     setPurposeCodeArray(res?.data)
  //     setKey(!key)
  //   }
  // )

  const [getCreateInvoiceData] = UseApiCall(
    apiGetCreateInvoiceData,
    (res) => {
      setForexDetails(res?.estimatedForexRateResponse)
      setAllBanks(res?.bankAccountDto);
      setPrimaryBank(res?.bankAccountDto[0]);
      res?.bankAccountDto?.forEach((item) => {
        if (item.isPrimary) {
          setPrimaryBank(item)
        }
      })
      // setForexDetails(res?.estimatedForexRateResponse)
      const purposeCodeDefault = res?.invoicePurposeCodeDto?.find(p => p?.default)?.purposeCode;
      if (purposeCodeDefault) {
        setPurposeCode(purposeCodeDefault)
        setIsPurposeCodeValid(true);
      };
      setPurposeCodeArray(res?.invoicePurposeCodeDto)
      setCustomerCurrencies(res?.defaultCurrencyResponse)
      setKey(!key)
    }
  )

  const [draftEditInvoiceCall, draftEditInvoiceCallLoader] = UseApiCall(
    apiToCreateInvoice,
    (res) => {
      if (props.nextPageFunctionality) props.nextPageFunctionality()
      if (props?.onSubmit) props?.onSubmit()
      handlePageUpdate();
      enqueueSnackbar('Invoice details updated successfully.', {
        variant: 'success',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
    }, (err) => {
      // enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    }
  )

  // useEffect(() => {
  //   if (modalRef)
  //     showInvoiceVerificationPrompt(modalRef, {
  //       invoiceId: invoiceId,
  //       whereCalled: "single"
  //     })
  // }, [modalRef])

  const [draftCreateInvoiceCall, draftCreateInvoiceCallLoader] = UseApiCall(
    apiToCreateInvoice,
    (res) => {
      if (props.nextPageFunctionality) props.nextPageFunctionality()
      // if (props?.onSubmit) props?.onSubmit()
      showInvoiceVerificationPrompt(modalRef, {
        invoiceId: invoiceId,
        whereCalled: "single"
      })
      // handlePageUpdate();
      enqueueSnackbar('Invoice created successfully.', {
        variant: 'success',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
    }, (err) => {
      enqueueSnackbar(`Failed to create invoice`, { variant: 'error' })
    }
  )

  const [updateInvoiceCall, updateInvoiceCallLoader] = UseApiCall(
    apiToCreateInvoice,
    (res) => {
      if (props.nextPageFunctionality) props.nextPageFunctionality()
      // if (props?.onSubmit) props?.onSubmit()
      // showInvoiceVerificationPrompt(modalRef, {
      //   invoiceId: invoiceId,
      // })
      handlePageUpdate();
      enqueueSnackbar('Invoice updated successfully.', {
        variant: 'success',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
    }, (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    }
  )

  const isValidDraft = () => {
    if (!contact?.customer_id) {
      enqueueSnackbar('Please select a customer.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!isInvoiceIdValid) {
      enqueueSnackbar('Please fill valid invoice id.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!ispurposeCodeValid) {
      enqueueSnackbar('Please fill valid purpose code.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!issueDate) {
      enqueueSnackbar('Please fill issue date.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!isInvoiceAmountValid) {
      enqueueSnackbar('Please fill valid invoice amount.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if ((!isReceivableAmountValid && !invoiceCheckbox)) {
      enqueueSnackbar('Please fill valid receivable amount or check the invoice checkbox.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!primaryBank?.accountId) {
      enqueueSnackbar('Please select a bank.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    }
  
    return true;
  }

  const isValidInvoice = () => {
    if (!isValidDraft()) {
      return false;
    } else if (Object.keys(invoices || {}).length === 0) {
      enqueueSnackbar('Please upload invoice attachment(s) in order to create this invoice. Else click on ‘Save as Draft’ and continue later.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    }
    
    return true;
  }

  const isValidDraftButton = () => {
    if (
      !isInvoiceIdValid ||
      !ispurposeCodeValid ||
      !issueDate ||
      !isInvoiceAmountValid ||
      (!isReceivableAmountValid && !invoiceCheckbox) ||
      !primaryBank?.accountId ||
      !contact?.customer_id
    )
      return false
    return true
  }

  const isValidInvoiceButton = () => {
    if (
      !isInvoiceIdValid ||
      !ispurposeCodeValid ||
      !issueDate ||
      !isInvoiceAmountValid ||
      (!isReceivableAmountValid && !invoiceCheckbox) ||
      !primaryBank?.accountId ||
      !contact?.customer_id ||
      (Object.keys(invoices || {})?.length  === 0)
    )
      return false
    return true
  }

  const isValidAmount = () => {
    if (!invoiceCheckbox) {
      if (Number(invoiceAmount) < Number(receivableAmount)) return false;
    }
    return true
  }

  const handleSubmit = () => {
    setSubmitClicked(true)
    setDraftClicked(false)
    if (isValidInvoice() && isValidAmount())
      draftCreateInvoiceCall({
        transactionId,
        organizationId: props.organizationId,
        userId: userId,
        invoiceId: invoiceId,
        purposeCode: purposeCode,
        issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
        blDate: blDate ? new Date(blDate)?.getTime() : null,
        dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
        invoiceAmount,
        receivingAmount: invoiceCheckbox ? invoiceAmount : receivableAmount,
        description: invoiceDescription,
        customerId: contact?.customer_id,
        invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        receiveMoneyAccountId: primaryBank?.accountId,
        mode: "CREATE",
        editInvoiceId: selectedInvoiceData?.id
      })
    else {
      setHasInvoiceError(true)
      setInvoiceError(true)
      handleInputError()
      handleFocus()
      if (!isValidDraft()) {
        // enqueueSnackbar('Please fill all mandatory fields.', {
        //   variant: 'error',
        //   anchorOrigin: { horizontal: "center", vertical: "top" }
        // });
      } else if (isValidDraft() && !isValidAmount()) {
        enqueueSnackbar('Invoice amount cannot be greater than receivable amount.', {
          variant: 'error',
          anchorOrigin: { horizontal: "center", vertical: "top" }
        });
      }
    }
  }

  const handleEditDraft = () => {
    setSubmitClicked(false)
    setDraftClicked(true)
    if (isValidDraftButton() && isValidAmount()) {
      let body = {
        transactionId,
        organizationId: props.organizationId,
        userId: userId,
        invoiceId: invoiceId,
        purposeCode: purposeCode,
        issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
        blDate: blDate ? new Date(blDate)?.getTime() : null,
        dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
        invoiceAmount,
        receivingAmount: invoiceCheckbox ? invoiceAmount : receivableAmount,
        description: invoiceDescription,
        customerId: contact?.customer_id,
        invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        receiveMoneyAccountId: primaryBank?.accountId,
        mode: "DRAFT",
        editInvoiceId: selectedInvoiceData?.id
      }
      draftEditInvoiceCall(body)
    }
    else {
      if (!isValidDraft()){
        // enqueueSnackbar('Please check mandatory fields before continuing.', {
        //   variant: 'error',
        //   anchorOrigin: { horizontal: "center", vertical: "top" }
        // });
      } else if (isValidDraft() && !isValidAmount()) {
        enqueueSnackbar('Invoice amount cannot be greater than receivable amount.', {
          variant: 'error',
          anchorOrigin: { horizontal: "center", vertical: "top" }
        });
      }
    }
  }

  const handleUpdateInvoice = () => {

      let body = {
        transactionId,
        invoiceId: invoiceId,
        purposeCode: purposeCode,
        issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
        blDate: blDate ? new Date(blDate)?.getTime() : null,
        dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
        invoiceAmount,
        receivingAmount: invoiceCheckbox ? invoiceAmount : receivableAmount,
        description: invoiceDescription,
        customerId: contact?.customer_id,
        invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        receiveMoneyAccountId: primaryBank?.accountId,
        mode: "EDIT",
        editInvoiceId: selectedInvoiceData?.id,
        remarks:remarks,
        netTerms:paymentTerms
      }
      updateInvoiceCall(body)
  }

  useEffect(() => {
    localStorage.removeItem("active")
    localStorage.removeItem("locked")
    localStorage.removeItem("isLocked")
  }, [])

  useEffect(() => {
    if (selectedInvoiceData) {
      setContact(selectedInvoiceData?.invoice_details_summary?.customer_attributes)
      setTransactionId(selectedInvoiceData?.transaction_id)
      setInvoiceId(selectedInvoiceData?.invoice_id)
      setPurposeCode(selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.purpose_code)
      setIsPurposeCodeValid(!(selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.purpose_code === ''))

      const dateString = selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.issue_date;
      if (dateString) {
        const dateParts = dateString?.split(/-| |:/);

        const issue = dayjs()
          .year(Number(dateParts[0]))
          .month(Number(dateParts[1]) - 1)
          .date(Number(dateParts[2]));

        setIssueDate(issue);
        setChangedIssueDate(true);
      }

      const dateString2 = selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.due_date;
      if (dateString2) {
        const dateParts = dateString2?.split(/-| |:/);

        const due = dayjs()
        .year(Number(dateParts[0]))
        .month(Number(dateParts[1]) - 1)
        .date(Number(dateParts[2]));

        setDueDate(due);
      }

      const dateString3 = selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.bl_date;
      if (dateString3) {
        const dateParts = dateString3.split(/-| |:/);

        const bl = dayjs()
        .year(Number(dateParts[0]))
        .month(Number(dateParts[1]) - 1)
        .date(Number(dateParts[2]));

        setBLDate(bl);
      }
      setInvoiceAmount(selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.invoice_amount)
      setReceivableAmount(selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.receiving_amount)
      setInvoiceDescription(selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.invoice_description)
      setInvoiceCurrency(selectedInvoiceData?.invoice_details_summary?.invoice_attributes?.invoice_amount_currency)
      setCreateDataApi(true)
    }
  }, [selectedInvoiceData])

  useEffect(() => {
    if (createDataApi) {
      let params = {
        inputCurrency: invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        mode: "SINGLE_INVOICE",
        organizationId: props.organizationId,
        firstTimePrerequisites: "true"
      };
      
      if (contact?.customer_id !== undefined) {
        params.customerId = contact.customer_id;
      }
      
      getCreateInvoiceData(params);
    }
  }, [createDataApi])

  const handleChange = (value) => {
    let params = {
      inputCurrency: value,
      outputCurrency: "INR" || customerCurrencies.customerCurrency,
      mode: "SINGLE_INVOICE",
      organizationId: props.organizationId,
      firstTimePrerequisites: "false"
    };
    
    if (contact?.customer_id !== undefined) {
      params.customerId = contact.customer_id;
    }
    
    getCreateInvoiceData(params);
    setInvoiceCurrency(value)
  };

  const handleCheckboxChange = () => {
    setInvoiceCheckbox((prevState) => !prevState)
  };

  const handlePurposeCodeChange = (prop) => {
    setPurposeCode(prop?.purposeCode)
    setIsPurposeCodeValid(!(prop?.purposeCode === ''))
  }

  const invoiceLockData = invoiceData?.filter((item)=> item.isLocked === true) ;
  const invoiceunLockData = invoiceData?.filter((item)=> item.isLocked === false);

  const [getLockFilesFromS3, fetchingLockFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setLockInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
            isLocked: true,
          },
        ])
      } catch (error) {
        // console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  const [getunLockFilesFromS3, fetchingunLockFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setunLockInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
            isLocked: false,
          },
        ])
      } catch (error) {
        // console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  const downloadLockInvoices = () => {
    invoiceLockData?.map((item, index) => {
      getLockFilesFromS3(item?.url)
      return item
    })
  }

  const downloadunLockInvoices = () => {
    invoiceunLockData?.map((item, index) => {
      getunLockFilesFromS3(item?.url)
      return item
    })
  }

  useEffect(()=>{
    const concatenateInvoices = unlockInvoices.concat(lockInvoices)
    setInvoices(concatenateInvoices)
    setInvoicetoBigScreen([concatenateInvoices[0]])
  },[lockInvoices,unlockInvoices])

  const contactList = useSelector(state => state.customerContacts.contacts)
  const [getContactList, fetching] = UseApiCall(
    apiGetCustomerContactList, (res) => { 
      dispatch(ContactsActions.setcontacts(res.data))
  })

  useEffect(() => {
    getContactList({organizationId: props.organizationId});
  }, [])

  const onChange = (value) => {
    setContact({
      "customer_name": "NA",
      "customer_country": "NA",
      "customer_email": "NA",
      "customer_display_id": "NA",
      "customer_id": value
    });
  };
  
  const onSearch = (value) => {};

  const filterOption = (input, option) => {
    const name = option.children.props.children[1].props.name;
    return (name && name.toLowerCase().includes(input.toLowerCase()));
  };

  useEffect(() => {
      downloadLockInvoices()
      downloadunLockInvoices()
      setunLockInvoices([])
      setLockInvoices([])
  }, [invoiceData])
  
  return (
    <>
    <CustomModal ref={setModalRef} />
    {
      selectedInvoiceData &&
        <Modal
          className={clsx(
            classes.modal,
            '-sm:h-[calc(100vh-7.2rem)] p-0 w-screen md:w-[100%] xl:w-auto'
          )}
        >
          {ReactDOM.createPortal(
            <Backdrop
              onClick={() => {
                dispatch(modalActions.editInvoice())
              }}
              className={classes.backdrop}
            />,
            document.getElementById('backdrop-root')
          )}
          {allBanks?.length>1 && showBankDropDown && 
            ReactDOM.createPortal(
              <BankDropDown
                option={allBanks}
                cellData={primaryBank}
                setPrimaryBank = {setPrimaryBank}
              />,
            document.getElementById("modal-root")
          )}
          {otpViewNowBox
            && <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoice} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />}
          {purposeCodeDropDown &&
            ReactDOM.createPortal(
              <PurposeCodeDropdown
                option={purposeCodeArray}
                heading={"Select Purpose Code"}
                onClick={handlePurposeCodeChange}
                purposeCode={purposeCode}
              />,
            document.getElementById("modal-root")
          )}
          {invoiceTooltipDisplay &&
            ReactDOM.createPortal(
              <NormalAction
                title={"What is this?"}
                heading={"Receivable amount is same as Invoice amount"}
                text={"The amount you want to collect against this Receivable. It must be less than or equal to the Invoice amount."}
                buttonText={"Okay"}
                buttonShow={true}
              />,
              document.getElementById("modal-root")
          )}
          <div className="flex flex-col items-center sm:flex-row -sm:relative h-[calc(100vh-7.2rem)] -sm:pb-12 -sm:overflow-auto">
            <div className="flex sm:hidden z-10 flex-row items-center justify-center sm:justify-between fixed top-0 right-0 left-0 px-16 py-[20px] bg-white">
              <img
                src={leftPointer}
                alt="left-pointer"
                className="block sm:hidden absolute left-8"
                onClick={() => {
                  dispatch(modalActions.editInvoice())
                }}
              />
              <p className="text-3xl font-bold">Add Invoice Details</p>
              <Close
                className="w-[32px] h-[32px] hidden sm:block"
                onClick={() => {
                  dispatch(modalActions.editInvoice())
                }}
              />
            </div>
            {!willUpdate ?(<div className="fixed z-10 flex sm:hidden left-0 right-0 bottom-0 px-8 sm:px-16 py-5 border-t bg-bg-white border-t-[#ECEFF2]">
              <Button
                className={clsx(
                  'flex-1 outline-none h-[48px] mr-[6px] bg-white rounded-[8px] border border-solid border-[#181C3033]',
                  // !isValid() && 
                  'text-[#181C30]'
                )}
                text="Save as Draft"
                onClick={handleEditDraft}
              />
              <Button
                className={clsx(
                  'flex-1 outline-none h-[48px] ml-[6px]',
                  !isValidInvoiceButton() && 'text-[#ffffff80]'
                )}
                text="Create Invoice"
                disabled={false}
                onClick={handleSubmit}
              />
            </div>) : 
            (
              <div className="fixed z-10 flex sm:hidden left-0 right-0 bottom-0 px-8 sm:px-16 py-5 border-t bg-bg-white border-t-[#ECEFF2]">
                  {/* <Button
                    className={clsx(
                      'flex-1 outline-none h-[48px] mr-[6px] bg-white rounded-[8px] border border-solid border-[#181C3033]',
                      // !isValid() && 
                      'text-[#181C30]'
                    )}
                    // disabled={draftEditInvoiceCallLoader}
                    disabled={willUpdate}
                    text="Update Invoice"
                    onClick={handleEditDraft}
                  /> */}
                  <Button
                    className={clsx(
                      'flex-1 outline-none h-[48px] ml-[6px]',
                      (updateInvoiceCallLoader ) && 'text-[#ffffff80]'
                    )}
                    disabled={updateInvoiceCallLoader}
                    text="Update Invoice"
                    onClick={handleUpdateInvoice}
                  />
                </div>
            )}
            {/* <div
              className="h-[calc(100vh-7.2rem)] sm:overflow-scroll w-full sm:max-w-[64rem] xl:min-w-[64rem] px-8 order-2 sm:order-1 sm:px-16 pt-10"
              style={{ boxShadow: '-10px 0px 24px rgba(30, 51, 63, 0.04)' }}
            >
              <div>
                <p className="font-bold text-[#1E333F] text-2xl sm:text-3xl mb-10">
                  Upload Invoice
                </p>
                <div className="sm:pb-12">
                  {transactionId && (
                    <EditFileBrowser
                      setFile={setInvoices}
                      previousInvoiceData={invoiceData}
                      txnId={transactionId}
                      validFormats={['document', 'image']}
                      hasError={
                        (submitClicked) && invoices.length === 0 && hasInvoiceError
                      }
                      errorMessage="Attachment is mandatory*"
                    />
                  )}
                </div> */}
                {/* <div className="h-[max-content] sm:overflow-scroll w-full sm:max-w-[64rem] xl:min-w-[64rem] -sm:hidden">
                  <div className="sm:pb-12">
                    <FileViewer
                      format={['document', 'image']}
                      displayUrls={invoices}
                      downloadPrefix={props?.invoice?.invoice_id}
                    />
                  </div>
                </div> */}
              {/* </div>
            </div> */}



            {
            // !invoiceData?
            //  ((paymentLinkOtpRequired || otpViewNowBox) ?
            //   <EnterOtpInner handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} /> :
            //   <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/> )
            //   :
            (window.innerWidth >= 640 &&
            (<div
              className="h-[calc(100vh-7.2rem)] sm:overflow-scroll w-full sm:max-w-[64rem] xl:min-w-[64rem] px-8 order-2 sm:order-1 sm:px-16 "
              style={{ boxShadow: '-10px 0px 24px rgba(30, 51, 63, 0.04)' }}
            >
              {
              

              (<div>
                  <p className={clsx(classes.mWebFor, `font-bold text-[#1E333F] text-20 sm:text-3xl  pt-16`)}>
                  Upload Documents
                  </p>
                  <div className={(clsx(classes.mWebFor, `flex gap-6 mb-14 border-b border-gray-300`))}>
                    <div className={`sm:text-2xl  pt-10 ${activeTab === 'invoice' ? 'border-b-2 border-solid border-black' : 'text-[gray]'} cursor-pointer`} onClick={()=>setActiveTab('invoice')} >
                      <p className={` ${activeTab === 'invoice' ? 'text-[#1E333F]' : 'text-[grey]'} font-inter  font-bold leading-48 pb-4`}>Upload Invoice</p>
                    </div>
                    <div className={`sm:text-2xl pt-10 ${activeTab === 'locked' ? 'border-b-2 border-solid border-black' : 'text-[gray]'} cursor-pointer`} onClick={()=>setActiveTab('locked')}>
                      <p className={` ${activeTab === 'locked' ? 'text-[#1E333F]' : 'text-[grey]'} font-inter  font-bold leading-48 pb-4`}>Locked Documents</p>
                    </div>
                  </div>
                    <div className="sm:pb-12" style={{ display: activeTab === 'invoice' ? 'block' : 'none' }}>
                    {
                      // !invoiceData?
                      false ?
                      ((paymentLinkOtpRequired && willUpdate) &&
                          <div className='mt-[200px]'>
                          <EnterOtpInner handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} /> 
                          </div>
                          // :
                          // <div>
                          //  <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/> 
                          // </div>
                          )
                        :
                      (<div>
                      {transactionId && (
                        <EditFileBrowser
                          setFile={setInvoices}
                          previousInvoiceData={(paymentLinkOtpRequired && willUpdate) ? invoicesLocked : invoiceData?.filter((item)=> item.isLocked === false)}
                          txnId={transactionId}
                          organizationId={props.organizationId}
                          validFormats={['document', 'image']}
                          // hasError={
                          //   (submitClicked) && invoices.length === 0 && hasInvoiceError
                          // }
                          errorMessage="Attachment is mandatory*"
                          willUpdate={willUpdate}
                          disabled={willUpdate}

                          otp={otp}
                          setOtp={setOtp}
                          handleOTPView={handleOTPView}
                          handleOTPViewNow={handleOTPViewNow}
                          setOtpViewNowBox={setOtpViewNowBox}
                          otpViewNowBox={otpViewNowBox}
                          setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                          paymentLinkOtpRequired={paymentLinkOtpRequired && willUpdate}
                          handleResendOTPView={handleResendOTPView}
                          otpErrorState={otpErrorState}
                          presignedUrlErrorState={presignedUrlErrorState}
                        />
                      )}
                      </div>)}
                    </div>
                    <div className="sm:pb-12" style={{ display: activeTab === 'locked' ? 'block' : 'none' }}>
                      <div className={classes.lockedTextCon}>
                        <div className={classes.lockedTextWrap}>
                          <div className={classes.lockedTextBox}>
                            <p className={classes.lockedText}>
                            These documents are currently secured and will only be unlocked 
                            in accordance with the terms agreed upon with your customer,
                            or if you choose to unlock them yourself.</p>
                            <div className={classes.imgBoc}>
                              <img src={lock} alt='lock' className={classes.imgLock} /> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.uploadCon}>
                      {
                      // !invoiceData?
                      false ?
                      ((paymentLinkOtpRequired && willUpdate) &&
                          <div className='mt-[60px]'>
                          <EnterOtpInner className={""} handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} /> 
                          </div>
                          // :
                          
                          // <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/> 
            
                          )
                        :
                      (<div>
                          {transactionId && (
                            <EditFileBrowserNew
                              setFile={setLockInvoices}
                              // files={lockInvoices}
                              previousInvoiceData={invoiceData?.filter((item)=> item.isLocked === false)}
                              // txnId={transactionId}
                              organizationId={props.organizationId}
                              transactionId={transactionId}
                              isMobile={isMobile}
                              validFormats={['document', 'image']}
                              hasError={false}
                              disabled={false}
                              // hasError={
                              //   (submitClicked) && invoices.length === 0 && hasInvoiceError
                              // }
                              errorMessage="Attachment is mandatory*"
                              componentName="locked"
                              isLocked={true}
                              willUpdate={willUpdate}
                            />
                          )}
                          </div>)}
                      </div>
                    </div>
              </div>)}
            </div>
            ))
            }
            <div
              className={clsx(
                classes.add_contact,
                'flex sm:relative w-full sm:max-w-[64rem] xl:min-w-[64rem] order-1 sm:order-2 flex-col pt-[72px] h-auto sm:h-[calc(100vh-7.2rem)]  '
              )}
              style={{ boxShadow: '-10px 0px 24px rgba(30, 51, 63, 0.04)' }}
            >
              <div className="hidden sm:flex z-10 flex-row items-center justify-center sm:justify-between absolute top-0 right-0 left-0 px-16 py-[20px] bg-white">
                <img
                  src={leftPointer}
                  alt="left-pointer"
                  className="block sm:hidden absolute left-8"
                  onClick={() => {
                    dispatch(modalActions.editInvoice())
                  }}
                />
                <p className="text-3xl font-bold">Add Invoice Details</p>
                <Close
                  className="w-[32px] h-[32px] hidden sm:block"
                  onClick={() => {
                    dispatch(modalActions.editInvoice())
                  }}
                />
              </div>
              <div className="sm:overflow-scroll px-8 sm:px-16 sm:pb-36 ">
                <div className="mt-6">
                  <p className="text-2xl font-bold">Bill to</p>
                  <div className="w-full mt-8 bg-[#F7F8FB] flex flex-col items-start p-[16px] rounded-[12px]">
                    {
                      selectedInvoiceData?.invoice_details_summary?.customer_attributes !== null
                        ?
                        <div className='flex flex-row gap-8 items-center w-full'>
                          <div className="w-[48px] h-[48px] rounded-full flex items-center justify-center bg-[#FFEBEB] text-2xl text-[#FFB2B2]">
                            {contact?.customer_name
                              ?.split(' ')
                              .map((char) => char.charAt(0).toUpperCase())
                              .join('')}
                          </div>
                          <ContactInfo
                            name={contact?.customer_name}
                            country={contact?.customer_country}
                            email={contact?.customer_email}
                          />
                        </div>
                        :
                          <>
                            <div className={clsx('w-[100%] h-[52px]', ((submitClicked || draftClicked) && !contact?.customer_id) ? 'border-solid border-[2px] border-[#FF6868] rounded-xl' : (!contact?.customer_id ? 'border-solid border-[1px] border-[#d9d9d9] rounded-xl' : ''))}>
                              <Select
                                showSearch
                                placeholder="Select a customer"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={filterOption}
                              >
                                {contactList?.length > 0 &&
                                  contactList.map((contact, index) => (
                                    <Select.Option key={index} value={contact.id}>
                                      <div className="flex items-center gap-4">
                                        <div className="w-[48px] h-[48px] rounded-full flex items-center justify-center bg-[#FFEBEB] text-2xl text-[#FFB2B2]">
                                          {contact.customerName
                                            ?.split(' ')
                                            .map((char) => char.charAt(0).toUpperCase())
                                            .join('')}
                                        </div>
                                        <ContactInfo
                                          name={contact.customerName}
                                          country={contact.countryName}
                                          email={contact.emailAddress}
                                        />
                                      </div>
                                    </Select.Option>
                                  ))
                                }
                              </Select>
                            </div>
                            <div>
                                {
                                  ((submitClicked || draftClicked) && !contact?.customer_id)
                                    ?
                                      <p className={clsx('text-xl text-[#FF6868] font-normal mt-3 flex')}>Please select a customer</p>
                                    :
                                      ""
                                }
                            </div>
                          </>
                    }
                  </div>
                </div>
                {primaryBank && (
                  <div className="mt-16" ref={optRef} >
                    <p className="text-2xl font-bold" onClick={()=> dispatch(modalActions.showBankDropDown(false))}>Receive money in</p>
                    <div className="w-full mt-8 bg-[#F7F8FB] flex flex-row items-center  justify-between px-[20px] py-[16px] gap-8 rounded-[12px]" style={{cursor: allBanks?.length > 1 && !willUpdate ? "pointer" : "default"}} onClick={handleBankClick} >
                      <div className={classes.pbankWrap}>
                      <img
                        src={
                          primaryBank?.countryFlag
                            ? `Images/${primaryBank.country}.svg`
                            : INRFlag
                        }
                        alt="country-flag"
                        className="w-[48px] h-[48px] rounded-full"
                      />

                      <ContactInfo
                        name={`${primaryBank?.bankName} Bank`}
                        email={primaryBank?.accountNumber
                          ?.slice(-4)
                          .padStart(primaryBank?.accountNumber?.length, 'X')}
                      className={classes.ctinfo}/>
                      </div>
                      
                      <div className={clsx(`${classes.rightDown} `)}>
                                    {<p className={classes.eefc} style={{backgroundColor:"#FFF"}}>{primaryBank.bankAccountType=="EEFC"?"EEFC":"INR"}</p>}
                                    {allBanks?.length> 1&& !willUpdate && (<img
                        src={showBankDropDown  ?upArrow:arrowDownIcon}
                        alt="arrow-down-icon"
                        className="w-[24px] h-[24px] me-10px"
                        // style={{ cursor: `${ willUpdate ? "pointer" : '' }` }}
                      />)}
                                  </div>
                    
                    </div>
                    {allBanks?.length>1 && showBankDropDown &&  !willUpdate &&(
              <div className={classes.webContent} >
                <div className={classes.options}>
                  {allBanks?.map((opt, index) => (
                    <div
                      className={clsx(
                        classes.opt,
                        !opt.onClick && 
                        'cursor-pointer',
                        index && 'border-t border-[#ECEFF2]',
                      )}
                      key={`${opt.text}-${index}`}>
                      <div key={index} className={classes.bankCard} style={{backgroundColor:`${index%2==0?"#FFF":"#F7F8FB"}`}} onClick={()=>{
                        setPrimaryBank(opt)
                        dispatch(modalActions.showBankDropDown(false))
                        }}  >
                            <div key={index} className={classes.bankContent} >
                                  <img
                                  src={
                                  INRFlag
                                  }
                                  alt="country-flag"
                                  className="w-[48px] h-[48px] rounded-full"
                                />
                                <ContactInfo 
                                name={`${opt?.bankName} Bank`}
                                email={opt?.accountNumber
                                  ?.slice(-4)
                                  .padStart(opt?.accountNumber?.length, 'X')}
                                />
                                </div>
                                
                                  <div className={classes.rightCheck}>
                                    <p className={classes.eefc} style={{backgroundColor:`${index%2==0?"#F7F8FB":"#FFF"}`}}>{opt.bankAccountType=="EEFC"?"EEFC":"INR"}</p>
                                    <div className={classes.checkImgWrap}>{opt.accountId==primaryBank.accountId &&<img src={checkIcon} alt="check-icon" className={classes.checkIcon} />}</div>
                                  </div>
                                
                              </div>  
                    </div>
                  ))}
                </div>
              </div>
            )}
                  </div>
                )}

                <div className="flex flex-col mt-16 items-start gap-4">
                  <p className="text-2xl font-bold">Invoice Details</p>
                  <div className={clsx(classes.rowGap, 'w-full grid mt-8 grid-cols-2 gap-x-6')} ref={!isInvoiceIdValid ? inputErrorScroll : noneRef}>
                    <TextInput
                      type="text"
                      text="Invoice ID*"
                      className="col-span-2"
                      validation={[(value) => value !== '']}
                      errorMessage="Please enter a valid Invoice Id"
                      inputValidity={(value) => {
                        setIsInvoiceIdValid(value)
                      }}
                      setInput={(value) => {
                        setInvoiceId(value)
                      }}
                      enteredInput={invoiceId}
                      hasError={(submitClicked || draftClicked) && !isInvoiceIdValid}
                      disabled={willUpdate}
                      initiateFocus = {!isInvoiceIdValid}
                      focusRef = {focusRef}
                      noneRef = {noneRef}
                    />
                    {/* <TextInput
                      type="text"
                      text="Purpose Code*"
                      className="col-span-2"
                      errorMessage="Please enter a valid Purpose code"
                      validation={[(value) => value !== '']}
                      inputValidity={(value) => {
                        setIsPurposeCodeValid(value)
                      }}
                      setInput={(value) => {
                        setPurposeCode(value)
                      }}
                      enteredInput={purposeCode}
                      // hasError = {isWrongInput && !isEmailValid}
                      hasError={(submitClicked || draftClicked) && !purposeCode}
                    /> */}
                    <div className="col-span-2"  ref={(isInvoiceIdValid && !ispurposeCodeValid) ? inputErrorScroll : noneRef}>
                      <CustomSelectorValuesCodes
                          onChange={handlePurposeCodeChange}
                          key={key}
                          selectedValue={purposeCode}
                          items={purposeCodeArray}
                          validation={[(value) => value !== '']}
                          placeholder="Purpose Code*"
                          searchText="Search a Purpose Code"
                          headingText="All Purpose Codes"
                          nothingFoundText="No Matching Purpose Code Found"
                          errorMessage="Please select a valid Purpose code"
                          // inputValidity={(value) => setBusinessTypeValid(!value)}
                          hasError={(submitClicked || draftClicked) && !purposeCode}
                          disabled={willUpdate}
                          initiateFocus = {isInvoiceIdValid && !ispurposeCodeValid}
                          focusRef = {focusRef}
                          noneRef = {noneRef}
                      />
                    </div>
                    {
                      issueDate !== undefined &&
                        <div className="date-picker-wrapper relative" ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate) ? inputErrorScroll : noneRef}>
                        {issueDate && <p className={classes.dateLabel}>Issue Date*</p>}
                          <DatePicker
                            status={(submitClicked || draftClicked) && !issueDate ? 'error' : undefined}
                            defaultValue={issueDate}
                            key={changedIssueDate}
                            onChange={(date, dateString) => {
                              setIssueDate(date)
                              setChangedIssueDate(true)
                              if (
                                date?.isSame(dayjs(dueDate)) ||
                                date?.isAfter(dayjs(dueDate)) ||
                                !date
                              )
                                {
                                  setDueDate();
                                }
                            }}
                            className={clsx(
                              ((submitClicked || draftClicked) && !issueDate) && `border-[2px]`,
                              `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                            )}
                            placeholder="Issue Date*"
                            size="large"
                            format="DD/MM/YYYY"
                            disabledDate={(d) => d && d > dayjs().endOf("day")}
                            disabled={willUpdate}
                            ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate)?focusRef:noneRef}
                            // format={["DD/MM/YYYY"]}
                          />
                          {(submitClicked || draftClicked) && !issueDate && (
                            <div className={classes.error} style={{ width: '100%' }}>
                              Please enter valid issue date.
                            </div>
                          )}
                        </div>
                    }
                    {
                      <div className="date-picker-wrapper relative">
                      {blDate && <p className={classes.dateLabel}>BL Date</p>}
                      <DatePicker
                        status={
                          (submitClicked || draftClicked) && issueDate && !blDate
                            // ? 'error'
                            ? undefined
                            : undefined
                        }
                        onChange={(date, dateString) => {
                          setBLDate(date)
                        }}
                        //  defaultPickerValue={issueDate}
                        disabled={!issueDate}
                        value={blDate}
                        className={clsx(
                          // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
                          `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                        )}
                        placeholder="BL Date"
                        size="large"
                        format="DD/MM/YYYY"
                        disabledDate={(d) => !d || d.isBefore(issueDate)}
                      />
                    </div>
                    }
                    { (dueDate || changedIssueDate) &&
                      <div className="col-span-2 relative">
                      {dueDate && <p className={classes.dateLabel}>Due Date</p>}
                        <DatePicker
                          status={
                            (submitClicked || draftClicked) && issueDate && !dueDate
                              ? undefined
                              : undefined
                          }
                          // defaultValue={dayjs(dueDate, "DD/MM/YYYY")}
                          onChange={(date, dateString) => {
                            setDueDate(date)
                            setChangedDueDate(true)
                          }}
                          // defaultPickerValue={issueDate}
                          disabled={!issueDate}
                          value={dueDate}
                          className={clsx(
                            // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
                            `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                          )}
                          placeholder="Due Date"
                          size="large"
                          format="DD/MM/YYYY"
                          // format={["DD/MM/YYYY"]}
                          disabledDate={(d) => !d || d.isBefore(issueDate)}
                        />
                      </div>
                    }
                    <div className="flex flex-row relative col-span-2" ref={(isInvoiceIdValid && ispurposeCodeValid && issueDate && !isInvoiceAmountValid) ? inputErrorScroll : noneRef}>
                      <TextInput
                        type="text"
                        text="Invoice Amount*"
                        errorMessage="Please enter a valid Invoice Amount"
                        validation={[
                          (value) =>
                            value !== '' &&
                            Number(value) !== 0 &&
                            /^(?!0\d)\d+(\.\d+)?$/.test(value),
                        ]}
                        inputValidity={(value) => {
                          setIsInvoiceAmountValid(value)
                        }}
                        setInput={(value) => {
                          setInvoiceAmount(value)
                        }}
                        enteredInput={invoiceAmount}
                        hasError={(submitClicked || draftClicked) && !isInvoiceAmountValid}
                        disabled={willUpdate}
                        initiateFocus = {isInvoiceIdValid && ispurposeCodeValid && issueDate && !isInvoiceAmountValid}
                        focusRef = {focusRef}
                        noneRef = {noneRef}
                      />
                      <span className={clsx(
                        // ((submitClicked || draftClicked) && !isInvoiceAmountValid) ? classes.customHeightError : classes.customHeight,
                        `px-4 py-2 absolute right-[2px] bottom-[2px] top-[2px] text-[#1E333F80] text-lg rounded bg-[#F4F4F4]`
                      )} style={{
                        borderRadius: 6
                      }}>
                      <Select
                        value={invoiceCurrency}
                        disabled={willUpdate}
                        style={{
                          width: 120,
                        }}
                        onChange={handleChange}
                        options={customerCurrencies?.supportedCurrency ? Object.keys(customerCurrencies?.supportedCurrency).map((item, index) => {
                          return {
                            label: customerCurrencies.supportedCurrency[item],
                            value: customerCurrencies.supportedCurrency[item],
                          }
                        }) : []}
                      />
                      </span>
                    </div>
                    <div className="flex flex-row relative col-span-2 justify-between">
                      <div className="flex gap-[8px] w-[100%] items-center">
                        <input
                            className={classes.receivableCheckbox}
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={invoiceCheckbox}
                            onChange={() => handleCheckboxChange()}
                            disabled={willUpdate}
                        />
                        <p className={classes.checkboxText}>Receivable amount is same as Invoice amount</p>
                        {
                          window.innerWidth > 600
                          ?
                            <Tooltip
                              placement="bottom"
                              color="#060A0D"
                              title={'The amount you want to collect against this Receivable. It must be less than or equal to the Invoice amount.'}
                            >
                              <img
                                src={HelpCircle}
                                alt="help-circle"
                                className={classes.tooltipImage}
                              />
                            </Tooltip>
                            :
                              <img
                                src={HelpCircle}
                                alt="help-circle"
                                className={classes.tooltipImage}
                                onClick={() => dispatch(modalActions.invoiceTooltipDisplay(true))}
                              />
                        }
                      </div>
                    </div>
                    { !invoiceCheckbox &&
                        <div className="flex flex-row relative col-span-2" ref={(isInvoiceIdValid && ispurposeCodeValid && issueDate && isInvoiceAmountValid && !isReceivableAmountValid) ? inputErrorScroll : noneRef} >
                          <TextInput
                            type="text"
                            text="Enter Receivable Amount"
                            errorMessage={Number(invoiceAmount || 0) < Number(receivableAmount || 0) ? "Receivable amount cannot be greater than invoice amount." : "Please enter a valid Receivable Amount"}
                            validation={[
                              (value) =>
                                value !== '' &&
                                Number(value) !== 0 &&
                                /^(?!0\d)\d+(\.\d+)?$/.test(value),
                            ]}
                            inputValidity={(value) => {
                              setIsReceivableAmountValid(value)
                            }}
                            setInput={(value) => {
                              setReceivableAmount(value)
                            }}
                            enteredInput={receivableAmount}
                            hasError={((submitClicked || draftClicked) && !isReceivableAmountValid) || Number(invoiceAmount || 0) < Number(receivableAmount || 0)}
                            disabled={willUpdate}
                            initiateFocus = {isInvoiceIdValid && ispurposeCodeValid && issueDate && isInvoiceAmountValid && !isReceivableAmountValid}
                            focusRef = {focusRef}
                            noneRef = {noneRef}
                          />
                          <span className={clsx(
                            // (((submitClicked || draftClicked) && !isReceivableAmountValid) || Number(invoiceAmount || 0) < Number(receivableAmount || 0)) ? classes.customHeightError : classes.customHeight,
                            `absolute right-[2px] bottom-[2px] top-[2px] text-[#1E333F80] text-lg rounded bg-[#F4F4F4] h-[41.19px]`
                          )} style={{
                            borderRadius: 6
                          }}>
                          <Select
                            value={invoiceCurrency}
                            className="flex items-center justify-center px-4 py-2"
                            disabled
                            suffixIcon={null}
                            style={{
                              width: 120,
                              height: 41.19,
                            }}
                          />
                          </span>
                        </div>
                    }
                    {forexDetails && (
                      <div className="col-span-2 rounded-xl flex flex-row items-center justify-between gap-3 bg-[#0C9C7A29] px-6 py-3">
                        <div className="rounded-full w-fit flex flex-row items-center justify-between gap-2 bg-[#1DAF8C] px-4 py-1 ">
                          {/* <img src={WalletIcon} alt='wallet' className="w-6 h-6" /> */}
                          <p className="text-lg font-bold text-center text-[#FFFFFF]">
                            @{forexDetails?.estimatedForexRate}{' '}
                            <span className="inline-block">
                              {forexDetails?.inputCurrency} {'< >'}{' '}
                              {forexDetails?.outputCurrency}
                            </span>
                          </p>
                        </div>
                        <p className="text-[#1DAF8C] text-xl font-normal flex-grow">
                          Estimated FX rate on this transaction
                        </p>
                        <img src={InfoIcon} alt="i" className="w-7 h-7" />
                      </div>
                    )}
                    <TextInput
                      type="text"
                      text="Invoice Description (optional)"
                      className="col-span-2"
                      multiline={true}
                      setInput={(value) => {
                        setInvoiceDescription(value)
                      }}
                      enteredInput={invoiceDescription}
                      disabled={willUpdate}
                    />
                  </div>
                  <p
                    className={clsx(
                      'text-xl font-medium text-[#1E333F80] col-span-2'
                    )}
                  >
                    This will be shown to the customer
                  </p>
                </div>
                <div className="flex flex-col mt-[3rem] items-start">
                  <p className="text-2xl font-bold">Additional Information</p>
                  <div className={clsx(classes.rowGap, 'w-full grid mt-8 grid-cols-2 gap-x-6')}>
                    <TextInput
                        type="text"
                        text="Payment Terms(optional)"
                        className="col-span-2"
                        multiline={true}
                        setInput={(value) => {
                          setPaymentTerms(value)
                        }}
                        enteredInput={paymentTerms}
                        setLimit = {true}
                    />
                  </div>
                  <div className={clsx(classes.rowGap, 'w-full grid mt-8 grid-cols-2 gap-x-6')}>
                    <TextInput
                        type="text"
                        text="Remarks (optional, for internal reference)"
                        className="col-span-2"
                        multiline={true}
                        setInput={(value) => {
                          setRemarks(value)
                        }}
                        enteredInput={remarks}
                        setLimit = {true}
                    />
                  </div>
                </div>
                {window.innerWidth < 640 && 
                  (willUpdate ? 
                    (
                      <div>
                        <div
                          className="sm:hidden flex flex-col items-start gap-2 pb-[16px] border-t border-[#ECEFF2] w-full -sm:order-2 mt-[16px] "
                          style={{
                          }}
                        >
                          <div className="flex text-left w-full">
                                <p className={classes.invoiceAttach}>{`Invoice Attachment (${unlockInvoices?.length})`}</p>
                              </div>
                          <div className={classes.docSheetCon}>
                            {/* {
                              // window.innerWidth < 644 &&
                                <DocumentSheet
                                  invoices={(paymentLinkOtpRequired && willUpdate) ? invoicesLocked : (unlockInvoices?.length ? unlockInvoices : invoicesLocked)}
                                  invoiceId={invoice?.invoice_id}
                                  customerEmail={selectedInvoiceData?.dat?.invoice_details_summary?.customer_attributes?.customer_email}
                                  setOtpViewNowBox={setOtpViewNowBox}
                                  otpViewNowBox={otpViewNowBox}
                                  setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                                  paymentLinkOtpRequired={paymentLinkOtpRequired && willUpdate}
                                  handleResendOTPView={handleResendOTPView}
                                  otpErrorState={otpErrorState}
                                  presignedUrlErrorState={presignedUrlErrorState}
                                  invoiceData={invoiceData}
                                  otp={otp}
                                  setOtp={setOtp}
                                  handleOTPView={handleOTPView}
                                  handleOTPViewNow={handleOTPViewNow}
                                  setInvoicetoBigScreen={setInvoicetoBigScreen}
                                  invoicetoBigScreen={invoicetoBigScreen}
                                /> 
                            } */}
                          </div>
                        </div>
                        <div
                        className="sm:hidden flex flex-col items-start gap-2  border-t border-[#ECEFF2] w-full -sm:order-2 mt-[0px]"
                      >
                        <div className="flex text-left w-full">
                          <p className={classes.invoiceAttach}>{`Locked Documents (${lockInvoices?.length})`}</p>
                            </div>
                        <div className={classes.docSheetCon}>
                          {/* {
                            // window.innerWidth < 644 &&
                              <DocumentSheet
                                invoices={(paymentLinkOtpRequired && willUpdate) ? invoicesLocked : (lockInvoices?.length ? lockInvoices : invoicesLocked)}
                                invoiceId={props?.invoice?.invoice_id}
                                customerEmail={selectedInvoiceData?.dat?.invoice_details_summary?.customer_attributes?.customer_email}
                                setOtpViewNowBox={setOtpViewNowBox}
                                otpViewNowBox={otpViewNowBox}
                                setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                                paymentLinkOtpRequired={paymentLinkOtpRequired && willUpdate}
                                handleResendOTPView={handleResendOTPView}
                                otpErrorState={otpErrorState}
                                presignedUrlErrorState={presignedUrlErrorState}
                                invoiceData={invoiceData}
                                otp={otp}
                                setOtp={setOtp}
                                handleOTPView={handleOTPView}
                                handleOTPViewNow={handleOTPViewNow}
                                setInvoicetoBigScreen={setInvoicetoBigScreen}
                                invoicetoBigScreen={invoicetoBigScreen}
                              /> 
                          } */}
                        </div>
                      </div>
                    </div>
                    )
                    :
                    (<div className={classes.attachments}>
                      <div className={classes.attachmentsTop}>
                        <p className={classes.attachText}>Attachments</p>
                      </div>
                      <div className={classes.attachmentsBottom}>
                        <div className={classes.attachmentsInvoice} onClick={() => {
                          // localStorage.removeItem("locked");
                          setActiveInvoice(true);
                          }}>
                          <p className={classes.attachSubText}>Upload Invoice</p>
                          <img src={rightArrow} alt='rightArrow' />
                        </div>
                        {/* {activeInvoice && 
                          ( */}
                            <div className={classes.mask}
                            style={{ display: activeInvoice ? 'block' : 'none'}}
                            >
                              <div className={classes.lockedCon}>
                                <div className={classes.lockedConCross} onClick={()=>setActiveInvoice(false)}>
                                  <p className={classes.lockedConCrossText}>Upload Invoices</p>
                                  <img src={crossImg} alt='rightArrow' className={classes.lockedConCrossImg}/>
                                </div>
                                <div className={classes.uploadCon}>
                                  {transactionId && (
                                    <EditFileBrowserNew
                                      setFile={setInvoices}
                                      previousInvoiceData={(paymentLinkOtpRequired && willUpdate) ? invoicesLocked : invoiceData?.filter((item)=> item.isLocked === false)}
                                      transactionId={transactionId}
                                      organizationId={props.organizationId}
                                      validFormats={['document', 'image']}
                                      isMobile={isMobile}
                                      hasError={false}
                                      disabled={false}
                                      errorMessage="Attachment is mandatory*"
                                      componentName="unLocked"
                                      isLocked={false}
                                      willUpdate={willUpdate}
                                    />
                                  )}
                                </div>
                                <div className={classes.button}>
                                  <Button
                                    className={classes.btn}
                                    text={"Save"}
                                    disabled={Object.keys(invoices || {})?.length === 0 ? true : false}
                                    // onClick={() => {
                                    //     getBulkTransactionSubmit({ transactionId: bulkTransactionsIdResult?.data});
                                    //     dispatch(modalActions.bulkUploaderDisplay(false));
                                    // }}
                                    onClick={()=>setActiveInvoice(false)}
                                  />
                                </div>
                              </div>
                            </div>
                        {/* //   )
                        // } */}
                        <hr className={classes.hr}></hr>
                        <div className={classes.attachmentsInvoice} onClick={() => {
                          // localStorage.removeItem("locked");
                          setActiveInvoiceLock(true);
                          }}>
                          <p className={classes.attachSubText}>Upload Locked Documents</p>
                          <img src={rightArrow} alt='rightArrow' />
                        </div>
                        {/* {activeInvoiceLock && 
                          ( */}
                            <div className={classes.mask} 
                            style={{ display: activeInvoiceLock ? 'block' : 'none'}}
                            >
                              <div className={classes.lockedCon}>
                                <div className={classes.lockedConCross} onClick={()=>setActiveInvoiceLock(false)}>
                                  <p className={classes.lockedConCrossText}>Upload Locked Documents</p>
                                  <img src={crossImg} alt='rightArrow' className={classes.lockedConCrossImg}/>
                                </div>
                                <div className={classes.lockedTextCon}>
                                  <div className={classes.lockedTextWrap}>
                                    <div className={classes.lockedTextBox}>
                                      <p className={classes.lockedText}>
                                      These documents are currently secured and will only be unlocked 
                                      in accordance with the terms agreed upon with your customer,
                                      or if you choose to unlock them yourself.</p>
                                      <div className={classes.imgBoc}>
                                        <img src={lock} alt='lock' className={classes.imgLock} /> 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.uploadCon}>
                                  {transactionId && (
                                    <EditFileBrowserNew
                                      setFile={setLockInvoices}
                                      // files={lockInvoices}
                                      previousInvoiceData={(paymentLinkOtpRequired && willUpdate) ? invoicesLocked : invoiceData?.filter((item)=> item.isLocked === true)}
                                      transactionId={transactionId}
                                      organizationId={props.organizationId}
                                      isMobile={isMobile}
                                      validFormats={['document', 'image']}
                                      hasError={false}
                                      disabled={false}
                                      // hasError={
                                      //   (submitClicked) && invoices.length === 0 && hasInvoiceError
                                      // }
                                      errorMessage="Attachment is mandatory*"
                                      componentName="locked"
                                      isLocked={true}
                                      willUpdate={willUpdate}
                                    />
                                  )}
                                </div>
                                <div className={classes.button}>
                                  <Button
                                    className={classes.btn}
                                    text={"Save"}
                                    onClick={()=>setActiveInvoiceLock(false)}
                                  />
                                </div>
                              </div>
                            </div>
                          {/* )
                        } */}
                      </div>
                    </div>)
                  )}
              </div>
              {!willUpdate ? 
              (<div
                className={clsx(
                  classes.buttonBox,
                  'absolute z-10 hidden sm:flex left-0 right-0 bottom-0 border-t bg-bg-white border-t-[#ECEFF2]'
                )}
              >
                <Button
                  className={clsx(
                    'flex-1 outline-none mr-[6px] bg-white rounded-[8px] border border-solid border-[#181C3033]',
                    // !isValid() && 
                    'text-[#181C30]'
                  )}
                  // disabled={draftEditInvoiceCallLoader}
                  disabled={willUpdate}
                  text="Save as Draft"
                  onClick={handleEditDraft}
                />
                <Button
                  className={clsx(
                    'flex-1 outline-none ml-[6px]',
                    (draftCreateInvoiceCallLoader || !isValidInvoiceButton()) && 'text-[#ffffff80]'
                  )}
                  disabled={draftCreateInvoiceCallLoader}
                  text="Create Invoice"
                  onClick={handleSubmit}
                />
              </div>) :
              (
                <div
                className={clsx(
                  classes.buttonBox,
                  'absolute z-10 hidden sm:flex left-0 right-0 bottom-0 border-t bg-bg-white border-t-[#ECEFF2]'
                )}
                >
                  {/* <Button
                    className={clsx(
                      'flex-1 outline-none h-[48px] mr-[6px] bg-white rounded-[8px] border border-solid border-[#181C3033]',
                      // !isValid() && 
                      'text-[#181C30]'
                    )}
                    // disabled={draftEditInvoiceCallLoader}
                    disabled={willUpdate}
                    text="Update Invoice"
                    onClick={handleEditDraft}
                  /> */}
                  <Button
                    className={clsx(
                      'flex-1 outline-none h-[48px] ml-[6px]',
                      (updateInvoiceCallLoader) && 'text-[#ffffff80]'
                    )}
                    disabled={draftCreateInvoiceCallLoader}
                    text="Update Invoice"
                    onClick={handleUpdateInvoice}
                  />
                </div>
              )
              }
            </div>
          </div>
        </Modal>
    }
    </>
  )
}

export default EditInvoice
