// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatabaseMatchTag_bigTag__vfV-d {
  padding: 0px 0px 0px 4px;
  border-radius: 100px;
  border: 1px solid rgb(255, 172, 192);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  width: -moz-fit-content;
  width: fit-content;
  gap: 6px;
  border-right: none;
  cursor: pointer;
}

.DatabaseMatchTag_smallTag__xpSDm {
  background-color: rgb(255, 172, 192);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/DatabaseMatchTag.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,oBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,QAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".bigTag{\n  padding: 0px 0px 0px 4px;\n  border-radius: 100px;\n  border: 1px solid rgba(255, 172, 192, 1);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: 500;\n  text-transform: uppercase;\n  width: fit-content;\n  gap: 6px;\n  border-right: none;\n  cursor: pointer;\n}\n\n.smallTag{\n  background-color: rgba(255, 172, 192, 1);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: 500;\n  border-radius: 50%;\n  width: 16px;\n  height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigTag": `DatabaseMatchTag_bigTag__vfV-d`,
	"smallTag": `DatabaseMatchTag_smallTag__xpSDm`
};
export default ___CSS_LOADER_EXPORT___;
