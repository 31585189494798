import { Outlet } from "react-router-dom";
import classes from "./Authentication.module.scss"
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AuthActions} from "../Store/auth-redux";
import moreInfo from "../assets/Images/moreInfo.svg";
import Close from "../Layout/Close";

const Authentication = () => {
    const dispatch = useDispatch();
    const timeRef = useRef(null);
    const showBackendError = useSelector((state) => state.auth.showBackendError);

    useEffect(() => {
        if (showBackendError) {
            clearTimeout(timeRef.current);
            timeRef.current = setTimeout(() => {
                dispatch(AuthActions.setShowBackendError(false));
            }, 5000);
        }else{
            clearTimeout(timeRef.current);
        }
    },[showBackendError]);

    useEffect(() => {
        return () => {
            clearTimeout(timeRef.current);
        };
    }, []);

    return (
        <>
            {showBackendError && (
                <div className={classes.errors}>
                    <img
                        src={moreInfo}
                        alt="more-info-icon"
                        className={classes.errors_moreinfo}
                    />
                    <div className={classes.errors_text}>
                        Invalid credentials! Email id and password do not match. Please
                        check and try again
                    </div>
                    <Close
                        onClick={() => dispatch(AuthActions.setShowBackendError(false))}
                        className={classes.errors_close}
                    />
                </div>
            )}
            <div className={classes.auth}>
                <div className={classes.box}>
                    <div className={classes.outlet}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Authentication