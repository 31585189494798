// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomerDetailsModal_modal__tHi8- {
  z-index: var(--level_8);
  height: calc(100vh - var(--admin_header_length));
  padding-bottom: 96px;
}

.CustomerDetailsModal_invoiceModalContainer__BEu6w {
  position: relative;
}

.CustomerDetailsModal_backdrop__mtyck {
  z-index: var(--level_7);
  height: calc(100vh - var(--admin_header_length));
}

.CustomerDetailsModal_create_invoice__LDGLh {
  height: 90vh;
}

.CustomerDetailsModal_dateField__oOGO5:invalid::-webkit-datetime-edit {
  color: #fff;
}

.CustomerDetailsModal_error__PNvDg {
  color: #ff6868;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-top: 0.8rem;
  top: 5.6rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/molecule/CustomerDetailsModal/CustomerDetailsModal.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,gDAAA;EACA,oBAAA;AACF;;AAQA;EACE,kBAAA;AALF;;AAQA;EACE,uBAAA;EACA,gDAAA;AALF;;AAQA;EACE,YAAA;AALF;;AAQA;EACE,WAAA;AALF;;AAQA;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AALF","sourcesContent":[".modal {\n  z-index: var(--level_8);\n  height: calc(100vh - var(--admin_header_length));\n  padding-bottom: 96px;\n}\n\n// @media (max-width: 540px) {\n//   .modal {\n//     width: 100%;\n//   }\n// }\n\n.invoiceModalContainer {\n  position: relative;\n}\n\n.backdrop {\n  z-index: var(--level_7);\n  height: calc(100vh - var(--admin_header_length));\n}\n\n.create_invoice {\n  height: 90vh;\n}\n\n.dateField:invalid::-webkit-datetime-edit {\n  color: #fff;\n}\n\n.error {\n  color: #ff6868;\n  font-weight: 400;\n  font-size: 1.2rem;\n  line-height: 1.8rem;\n  margin-top: 0.8rem;\n  top: 5.6rem;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `CustomerDetailsModal_modal__tHi8-`,
	"invoiceModalContainer": `CustomerDetailsModal_invoiceModalContainer__BEu6w`,
	"backdrop": `CustomerDetailsModal_backdrop__mtyck`,
	"create_invoice": `CustomerDetailsModal_create_invoice__LDGLh`,
	"dateField": `CustomerDetailsModal_dateField__oOGO5`,
	"error": `CustomerDetailsModal_error__PNvDg`
};
export default ___CSS_LOADER_EXPORT___;
