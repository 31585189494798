import Close from 'Layout/Close'
import Modal from 'Layout/Modal'
import classes from './CreateInvoice.module.scss'
import checkIcon from "../../assets/Icons/check.svg"
import upArrow from "../../assets/Icons/arrow-up.svg"
import rightArrow from "../../assets/Icons/chevron-rightNew.svg"
import crossImg from "../../assets/Icons/crossImg.svg"
import lock from "assets/Icons/lockTala.svg"
import ReactDOM from 'react-dom'
import Backdrop from 'Layout/Backdrop'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
// import EEFCTag from "assets/Icons/EEFC-tag.svg"
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import UseApiCall from 'hooks/useApiCall'
import clazz from 'Components/Dropdown/CountryDropdown.module.scss'
import {
  apiCreateInvoice,
  apiCreateTransaction,
  // apiGetBankList,
  // apiGetCustomerCurrencies,
  // apiGetForexRate,
  apiToCreateInvoice,
  apiGetReceivables,
  apiGetDashboardInvoice,
  apiGetSummary,
  // apiGetPurposeCodeData
  apiGetCreateInvoiceData
} from 'services'
import TextInput from 'Layout/TextInput'
import Button from 'Layout/Button'
import ContactInfo from 'Components/Global/ContactInfo'
import leftPointer from 'assets/Icons/arrow-left.svg'
import InfoIcon from 'assets/Icons/info_black_circle.svg'
import ArrowDown from 'assets/Icons/chevron-down.svg'
import { useRecoilValue } from 'recoil'
import { modalRefState } from 'recoil/layoutRef'
import { DatePicker, Select } from 'antd'
import INRFlag from 'assets/Images/INR_Flag.svg'
import { showInvoiceVerificationPrompt } from 'utils/layout'
import dayjs from 'dayjs'
import FileBrowser from 'Components/atom/FileBrowser/FileBrowser'
import { enqueueSnackbar } from 'notistack'
import Dropdown from 'Components/Dropdown/Dropdown'
import DropdownLayout from 'Components/Dropdown/DropdownLayout'
import DropdownOption from 'Components/Dropdown/DropdownOption'
import { ReceivablesActions, DashboardSummaryActions, CustomersActions } from 'Store/customer-redux'
import HelpCircle from "assets/Icons/help-circle.svg"
import { Tooltip } from 'antd'
import arrowDownIcon from 'assets/Icons/arrow-DownIcon.svg'
import BankDropDown from 'Components/ReportTable/BankDropDown'
import PurposeCodeDropdown from 'Components/ReportTable/PurposeCodeDropdown'
import CustomSelectorValuesCodes from "Components/atom/CustomerSelecterValues/CustomSelectorValuesCodes";
import useClickOutside from "hooks/useClickOutside";
import { useSelector } from "react-redux";
import FileBrowserNew from 'Components/atom/FileBrowserForUploader/FileBrowserNew'
import NormalAction from 'Components/ReportTable/NormalAction'
import FileViewer from 'Components/atom/FileViewer/FileViewer'
import CustomModal from 'Components/atom/CustomModal'
// import MobileInvoiceSuccessPrompt from 'Components/atom/MobileInvoiceSuccess/MobileInvoiceSuccessPrompt'
// import DatePicker from "Layout/DatePicker/DatePicker";
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString'

const customStyles = {
  error: {
    backgroundColor: 'orange', // Change the background color
    border: '1px solid orange', // Change the border color
    color: 'white', // Change the text color
  },
  info: {
    backgroundColor: 'orange', // Change the background color
    border: '1px solid orange', // Change the border color
    color: 'white', // Change the text color
  },
};

const CreateInvoice = (props) => {
  const dispatch = useDispatch()
  const showBankDropDown = useSelector((state) => state.modal.showBankDropDown);
  const purposeCodeDropDown = useSelector((state) => state.modal.purposeCodeDropDown);
  const invoiceTooltipDisplay = useSelector((state) => state.modal.invoiceTooltipDisplay);
  const mobileInvoiceSuccess = useSelector((state) => state.modal.mobileInvoiceSuccess);
  const userId = useSelector(state => state?.login?.userId);
  const optRef = useClickOutside(() => {
    screenWidth > 600
    && dispatch(modalActions.showBankDropDown(false))
    // : dispatch(modalActions.showBankDropDown(true))
  });
  const badRef = useClickOutside(()=>{

  })
  const handleBankClick = () =>dispatch(modalActions.showBankDropDown())
  const { contact } = props
  const [modalRef, setModalRef] = useState(null);
  const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return  window.innerWidth
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenWidth]);
  const [transactionId, setTransactionId] = useState(null)
  const [primaryBank, setPrimaryBank] = useState(null)
  const [allBanks, setAllBanks] = useState([])
  const [forexDetails, setForexDetails] = useState(null)
  const [key, setKey] = useState(false)
  const [purposeCodeArray, setPurposeCodeArray] = useState([])

  const [invoiceId, setInvoiceId] = useState('')
  const [purposeCode, setPurposeCode] = useState('')
  const [issueDate, setIssueDate] = useState()
  const [dueDate, setDueDate] = useState()
  const [blDate , setBLDate] = useState()
  const [invoiceAmount, setInvoiceAmount] = useState('')
  const [receivableAmount, setReceivableAmount] = useState('')
  const [invoiceDescription, setInvoiceDescription] = useState('')
  const [invoiceCurrency, setInvoiceCurrency] = useState('USD')
  const [invoices, setInvoices] = useState({})
  const [lockInvoices, setLockInvoices] = useState({})

  const [isInvoiceIdValid, setIsInvoiceIdValid] = useState(false)
  const [ispurposeCodeValid, setIsPurposeCodeValid] = useState(false)
  const [isInvoiceAmountValid, setIsInvoiceAmountValid] = useState(false)
  const [isReceivableAmountValid, setIsReceivableAmountValid] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [draftClicked, setDraftClicked] = useState(false)
  const [hasInvoiceError, setHasInvoiceError] = useState(false)
  const [customerCurrencies, setCustomerCurrencies] = useState({})
  const [changedIssueDate, setChangedIssueDate] = useState(false)

  const [activeTab,setActiveTab] = useState('invoice')
  const [isMobile, setIsMobile] = useState(false);
  const [invoiceCheckbox, setInvoiceCheckbox] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState(false);
  const [activeInvoiceLock, setActiveInvoiceLock] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const dropdownRef = useRef(null);
  const [invoiceError , setInvoiceError] = useState(false);
  const [ocrResponse , setOcrResponse] = useState([]);
  const [ocrLoading , setOcrLoading] = useState(1);
  const inputErrorScroll = useRef(null);
  const focusRef = useRef(null);
  const noneRef = useRef(null);
  const [more, setMore] = useState('USD');
  const [bgColors, setBgColors] = useState({
    invoiceId: false,
    issueDate: false,
    invoiceAmount: false,
    receivingAmount: false,
    description: false,
    netTerms: false,
    remarks: false,
    dueDate: false,
    blDate: false,
    invoiceCurrency: false,
  });
 
  const handleInputError = () =>{
    if(inputErrorScroll.current) {
      inputErrorScroll.current.scrollIntoView({
        behavior: 'smooth' , 
        block: 'start' ,
        inline: 'start',
      });
    }
  }

  const handleFocus = () =>{
    if(focusRef.current) {
      setInvoiceError(false)
      focusRef.current.focus()
    }
  }

  const handlePageUpdate = () => {
    // const url = window.location.pathname;
    // const parts = url.split('/');
    // if (parts[parts?.length - 1] === "receivables") {
    //   dispatch(ReceivablesActions.setUpdateReceivables())
    // } else if (parts[parts?.length - 1] === "dashboard") {
    //   dispatch(DashboardSummaryActions.setUpdateDashboard())
    // } else if (parts[parts?.length - 1] === "customers") {
    //   dispatch(CustomersActions.setUpdateCustomers())
    // }
  }

  // const [getCustomerCurrencies] = UseApiCall(
  //   // apiGetCustomerCurrencies,
  //   (res) => {
  //     setCustomerCurrencies(res?.data)
  //   }
  // )

  const [createTransaction, creatingTransactionLoader] = UseApiCall(
    apiCreateTransaction,
    (res) => {
      setTransactionId(res.data)
    }, (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    }
  )
  // const [getBankAccounts, fetchingBanks] = UseApiCall(
  //   // apiGetBankList,
  //   (res) => {
  //   setAllBanks(res?.data);
  //   setPrimaryBank(res?.data[0]);
  //   res?.data?.forEach((item) => {
  //     if (item.isPrimary) {
  //       setPrimaryBank(item)
  //     }
  //   })
  // })
  // const [getForexRate, fetchingForexRate] = UseApiCall(
  //   // apiGetForexRate,
  //   (res) => {
  //     setForexDetails(res?.data)
  //   }
  // )

  const [getCreateInvoiceData] = UseApiCall(
    apiGetCreateInvoiceData,
    (res) => {
      setForexDetails(res?.estimatedForexRateResponse)
      setAllBanks(res?.bankAccountDto);
      setPrimaryBank(res?.bankAccountDto[0]);
      res?.bankAccountDto?.forEach((item) => {
        if (item.isPrimary) {
          setPrimaryBank(item)
        }
      })
      // setForexDetails(res?.estimatedForexRateResponse)
      const purposeCodeDefault = res?.invoicePurposeCodeDto?.find(p => p?.default)?.purposeCode;
      if (purposeCodeDefault) {
        setPurposeCode(purposeCodeDefault)
        setIsPurposeCodeValid(true);
      };
      setPurposeCodeArray(res?.invoicePurposeCodeDto)
      setCustomerCurrencies(res?.defaultCurrencyResponse)
      setKey(!key)
    }
  )

  const [createInvoiceCall, creatingInvoice] = UseApiCall(
    apiToCreateInvoice,
    (res) => {
      // if (props.nextPageFunctionality) props.nextPageFunctionality()
      if (props?.onSubmit) props?.onSubmit()
      showInvoiceVerificationPrompt(modalRef, {
        invoiceId: invoiceId,
      })
      if (screenWidth > 640) {
        if (props.nextPageFunctionality) props.nextPageFunctionality()
        // showInvoiceVerificationPrompt(modalRef, {
        //   invoiceId: invoiceId,
        // })
        enqueueSnackbar('Invoice created successfully.', {
          variant: 'success',
          anchorOrigin: { horizontal: "center", vertical: "top" }
        });
        // handlePageUpdate();
      } else {
        dispatch(modalActions.mobileInvoiceSuccess(true))
      }
    }, (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    }
  )

  const [draftEditInvoiceCall, draftEditInvoiceCallLoader] = UseApiCall(
    apiToCreateInvoice,
    (res) => {
      if (props.nextPageFunctionality) props.nextPageFunctionality()
      if (props?.onSubmit) props?.onSubmit()
      handlePageUpdate();
      enqueueSnackbar('Invoice saved as draft successfully.', {
        variant: 'success',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
    }, (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    }
  )

  const isValidDraft = () => {
    if (!isInvoiceIdValid) {
      enqueueSnackbar('Please fill valid invoice id.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!ispurposeCodeValid) {
      enqueueSnackbar('Please fill valid purpose code.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!issueDate) {
      enqueueSnackbar('Please fill issue date.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!isInvoiceAmountValid) {
      enqueueSnackbar('Please fill valid invoice amount.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if ((!isReceivableAmountValid && !invoiceCheckbox)) {
      enqueueSnackbar('Please fill valid receivable amount or check the invoice checkbox.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!primaryBank?.accountId) {
      enqueueSnackbar('Please select a bank.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    } else if (!contact?.id) {
      enqueueSnackbar('Please select a customer.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    }
  
    return true;
  }

  const isValidInvoice = () => {
    if (!isValidDraft()) {
      return false;
    } else if (Object.keys(invoices || {}).length === 0) {
      enqueueSnackbar('Please upload invoice attachment(s) in order to create this invoice. Else click on ‘Save as Draft’ and continue later.', {
        variant: 'error',
        anchorOrigin: { horizontal: "center", vertical: "top" }
      });
      return false;
    }
    
    return true;
  }

  const isValidDraftButton = () => {
    if (
      !isInvoiceIdValid ||
      !ispurposeCodeValid ||
      !issueDate ||
      !isInvoiceAmountValid ||
      (!isReceivableAmountValid && !invoiceCheckbox) ||
      !primaryBank?.accountId ||
      !contact?.id
    )
      return false
    return true
  }

  const isValidInvoiceButton = () => {
    if (
      !isInvoiceIdValid ||
      !ispurposeCodeValid ||
      !issueDate ||
      !isInvoiceAmountValid ||
      (!isReceivableAmountValid && !invoiceCheckbox) ||
      !primaryBank?.accountId ||
      !contact?.id ||
      (Object.keys(invoices || {})?.length === 0)
    )
      return false
    return true
  }

  const isValidAmount = () => {
    if (!invoiceCheckbox) {
      if (Number(invoiceAmount) < Number(receivableAmount)) return false;
    }
    return true
  }

  const handleSubmit = () => {
    // showPaymentLinkModal(modalRef, { invoiceId: `${invoiceId}`, paymentLink: 'sasas' });
    setSubmitClicked(true)
    setDraftClicked(false)
    if (isValidInvoice() && isValidAmount())
      createInvoiceCall({
        transactionId,
        organizationId: props.organizationId,
        userId: userId,
        invoiceId: invoiceId,
        purposeCode: purposeCode,
        issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
        dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
        blDate: blDate? new Date(blDate)?.getTime() : null,
        invoiceAmount,
        receivingAmount: invoiceCheckbox ? invoiceAmount : receivableAmount,
        description: invoiceDescription,
        customerId: contact?.id,
        invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        receiveMoneyAccountId: primaryBank?.accountId,
        mode: "CREATE",
        remarks: remarks,
        netTerms: paymentTerms
      })
    else {
      setHasInvoiceError(true)
      setInvoiceError(true)
      handleInputError()
      handleFocus()
      if (!isValidDraft()) {
        // if (!primaryBank?.accountId) {
        //   enqueueSnackbar('Please add bank account.', {
        //     variant: 'error',
        //     anchorOrigin: { horizontal: "center", vertical: "top" }
        //   });
        // }
        // else {
        //   enqueueSnackbar('Please fill all mandatory fields.', {
        //     variant: 'error',
        //     anchorOrigin: { horizontal: "center", vertical: "top" }
        //   });
        // }
      } else if (isValidDraft() && !isValidAmount()) {
        enqueueSnackbar('Invoice amount cannot be greater than receivable amount.', {
          variant: 'error',
          anchorOrigin: { horizontal: "center", vertical: "top" }
        });
      }
    }
  }

  const handleDraft = () => {
    // showPaymentLinkModal(modalRef, { invoiceId: `${invoiceId}`, paymentLink: 'sasas' });
    setSubmitClicked(false)
    setDraftClicked(true)
    if (isValidDraftButton() && isValidAmount()) {
      draftEditInvoiceCall({
        transactionId,
        organizationId: props.organizationId,
        userId: userId,
        invoiceId: invoiceId,
        purposeCode: purposeCode,
        issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
        dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
        blDate: blDate? new Date(blDate)?.getTime() : null,
        invoiceAmount,
        receivingAmount: invoiceCheckbox ? invoiceAmount : receivableAmount,
        description: invoiceDescription,
        customerId: contact?.id,
        invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        receiveMoneyAccountId: primaryBank?.accountId,
        mode: "DRAFT"
      })
    }
    else {
      // setHasInvoiceError(true)
      if (!isValidDraft()) {
        // enqueueSnackbar('Please check mandatory fields before continuing.', {
        //   variant: 'error',
        //   anchorOrigin: { horizontal: "center", vertical: "top" }
        // });
      } else if (isValidDraft() && !isValidAmount()) {
        enqueueSnackbar('Invoice amount cannot be greater than receivable amount.', {
          variant: 'error',
          anchorOrigin: { horizontal: "center", vertical: "top" }
        });
      }
    }
  }

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 640);
      };
  
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  useEffect(() => {
    createTransaction({ customerId: contact?.id, organizationId: props.organizationId, userId: userId })
  }, [])

  useEffect(() => {
    // if (customerCurrencies?.customerCurrency) {
      getCreateInvoiceData({
        inputCurrency: invoiceCurrency,
        outputCurrency: "INR" || customerCurrencies.customerCurrency,
        mode: "SINGLE_INVOICE",
        customerId: contact?.id,
        organizationId: props.organizationId,
        firstTimePrerequisites: "true"
      })
    // }
  }, [])

  const handleChange = (value) => {
    getCreateInvoiceData({
      inputCurrency: value,
      outputCurrency: "INR" || customerCurrencies.customerCurrency,
      mode: "SINGLE_INVOICE",
      customerId: contact?.id,
      organizationId: props.organizationId,
      firstTimePrerequisites: "false"
    })
    setInvoiceCurrency(value)
  };

  const handleCheckboxChange = () => {
    setInvoiceCheckbox((prevState) => !prevState)
  };

  const handlePurposeCodeChange = (prop) => {
    setPurposeCode(prop?.purposeCode)
    setIsPurposeCodeValid(!(prop?.purposeCode === ''))
  }

  const handleDropdownClick = () => {
    if (dropdownRef.current) {
      dropdownRef.current.scrollIntoView({
        behavior: 'smooth', 
        block: 'start',    
        inline: 'start',  
      });
    }
  };

  function extractNumericValue(str) {
    if (typeof str === 'number') {
        str = str.toString();
    } else if (!str || typeof str !== 'string') {
        return '0.00';
    }

    const match = str.replace(/[^0-9.$]/g, '').match(/(\d+(\.\d+)?)$/);

    if (match) {
        const numericValue = parseFloat(match[1]);
        return numericValue;
    }

    return '0.00';
  }

  function parseDate(inputDate) {
    const [day, month, year] = inputDate.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
  
    if (isNaN(dateObject.getTime())) {
        return null;
    }
  
    const formattedYear = dateObject.getFullYear().toString();
    const formattedMonth = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const formattedDay = dateObject.getDate().toString().padStart(2, '0');
  
    return [formattedYear, formattedMonth, formattedDay];
  }

  useEffect(() => {
    console.log(ocrResponse, "ocr_response")
    if (ocrResponse?.length > 0) {
      const newBgColors = { ...bgColors };
      if (ocrResponse[0]?.invoiceId) {
        setInvoiceId(ocrResponse[0]?.invoiceId?.trim());
        newBgColors.invoiceId = true;
      }
      if (ocrResponse[0]?.invoiceAmount) {
        const stringWithCurrency = ocrResponse[0]?.invoiceAmount;
        const numericValue = extractNumericValue(stringWithCurrency);
        setInvoiceAmount(numericValue);
        setReceivableAmount(numericValue);
        newBgColors.invoiceAmount = true;
        newBgColors.receivingAmount = true;
      }
      if (ocrResponse[0]?.receivingAmount) {
        const stringWithCurrency = ocrResponse[0]?.receivingAmount;
        const numericValue = extractNumericValue(stringWithCurrency);
        setReceivableAmount(numericValue);
        newBgColors.invoiceAmount = true;
        newBgColors.receivingAmount = true;
      }
      // if (ocrResponse[0]?.description) {
      //   setInvoiceDescription(ocrResponse[0]?.description);
      //   newBgColors.description = true;
      // }
      if (ocrResponse[0]?.netTerms) {
        setPaymentTerms(ocrResponse[0]?.netTerms);
        newBgColors.netTerms = true;
      }
      if (ocrResponse[0]?.remarks) {
        setRemarks(ocrResponse[0]?.remarks);
        newBgColors.remarks = true;
      }

      if (ocrResponse && ocrResponse?.length > 0 && ocrResponse[0]?.issueDate) {
        const dateParts = parseDate(ocrResponse[0]?.issueDate);
        if (dateParts !== null) {
          const issue = dayjs()
            .year(Number(dateParts[0]))
            .month(Number(dateParts[1]) - 1)
            .date(Number(dateParts[2]));
          setIssueDate(issue);
          setChangedIssueDate(true);
          newBgColors.issueDate = true;
        }
      }

      if (ocrResponse[0]?.dueDate) {
        const dateParts = parseDate(ocrResponse[0]?.dueDate);
        if (dateParts !== null) {
          const due = dayjs()
            .year(Number(dateParts[0]))
            .month(Number(dateParts[1]) - 1)
            .date(Number(dateParts[2]));
          setDueDate(due);
        }
        newBgColors.dueDate = true;
      }

      if (ocrResponse[0]?.blDate) {
        const dateParts = parseDate(ocrResponse[0]?.blDate);
        if (dateParts !== null) {
          const bl = dayjs()
            .year(Number(dateParts[0]))
            .month(Number(dateParts[1]) - 1)
            .date(Number(dateParts[2]));
          setBLDate(bl);
          newBgColors.blDate = true;
        }
      }

      if (ocrResponse[0]?.invoiceCurrency) {
        setInvoiceCurrency(ocrResponse[0]?.invoiceCurrency);
        newBgColors.invoiceCurrency = true;
      }
      setBgColors(newBgColors);
    }
  }, [ocrResponse])

  useEffect(() => {
    if (Object.values(bgColors).every(value => !value)) {
      return;
    }
  
    const timeoutId = setTimeout(() => {
      setBgColors({
        invoiceId: false,
        issueDate: false,
        invoiceAmount: false,
        receivingAmount: false,
        description: false,
        netTerms: false,
        remarks: false,
        dueDate: false,
        blDate: false,
        invoiceCurrency: false,
      });
    }, 10000);
  
    return () => clearTimeout(timeoutId);
  }, [bgColors]);

  return (
    <Modal
      className={clsx(
        classes.modal,
        '-sm:h-[calc(100vh-7.2rem)] p-0 w-screen md:w-[100%] xl:w-auto'
      )}
    >
      <CustomModal ref={setModalRef} />
      {ReactDOM.createPortal(
        <Backdrop
          onClick={() => {
            dispatch(modalActions.createInvoice())
          }}
          className={classes.backdrop}
        />,
        document.getElementById('backdrop-root')
      )}
      {allBanks?.length>1 && showBankDropDown &&
        ReactDOM.createPortal(
          <BankDropDown
            option={allBanks}
            cellData={primaryBank}
            setPrimaryBank = {setPrimaryBank}
          />,
        document.getElementById("modal-root")
      )}
      {purposeCodeDropDown &&
        ReactDOM.createPortal(
          <PurposeCodeDropdown
            option={purposeCodeArray}
            heading={"Select Purpose Code"}
            onClick={handlePurposeCodeChange}
            purposeCode={purposeCode}
          />,
        document.getElementById("modal-root")
      )}
      {invoiceTooltipDisplay &&
        ReactDOM.createPortal(
          <NormalAction
            title={"What is this?"}
            heading={"Receivable amount is same as Invoice amount"}
            text={"The amount you want to collect against this Receivable. It must be less than or equal to the Invoice amount."}
            buttonText={"Okay"}
            buttonShow={true}
          />,
          document.getElementById("modal-root")
      )}
      {/* {
        mobileInvoiceSuccess && 
        ReactDOM.createPortal(
          <MobileInvoiceSuccessPrompt
           invoiceId = {invoiceId}
          />,
          document.getElementById("modal-root")
        )
      } */}
      <div className="flex flex-col items-center sm:flex-row -sm:relative h-[calc(100vh-7.2rem)] -sm:pb-12 -sm:overflow-auto">
        <div className="flex sm:hidden z-10 flex-row items-center justify-center sm:justify-between fixed top-0 right-0 left-0 px-16 py-[20px] bg-white">
          <img
            src={leftPointer}
            alt="left-pointer"
            className="block sm:hidden absolute left-8"
            onClick={() => {
              dispatch(modalActions.createInvoice())
            }}
          />
          <p className="text-3xl font-bold">Add Invoice Details</p>
          <Close
            className="w-[32px] h-[32px] hidden sm:block"
            onClick={() => {
              dispatch(modalActions.createInvoice())
            }}
          />
        </div>
        <div className="fixed z-10 flex sm:hidden left-0 right-0 bottom-0 px-8 sm:px-16 py-5 border-t bg-bg-white border-t-[#ECEFF2]">
          <Button
            className={clsx(
              'flex-1 outline-none h-[48px] mr-[6px] bg-white rounded-[8px] border border-solid border-[#181C3033]',
              // !isValid() && 
              'text-[#181C30]'
            )}
            text="Save as Draft"
            onClick={handleDraft}
          />
          <Button
            className={clsx(
              'flex-1 outline-none h-[48px] ml-[6px]',
              !isValidInvoiceButton() && 'text-[#ffffff80]'
            )}
            text="Create Invoice"
            disabled={false}
            onClick={handleSubmit}
          />
        </div>

        {window.innerWidth >= 640 && (<div
          className="h-[calc(100vh-7.2rem)] sm:overflow-scroll w-full sm:max-w-[64rem] xl:min-w-[64rem] px-8 order-2 sm:order-1 sm:px-16 "
          style={{ boxShadow: '-10px 0px 24px rgba(30, 51, 63, 0.04)' }}
         >
          <div>
              <p className={clsx(classes.mWebFor, `font-bold text-[#1E333F] text-20 sm:text-3xl  pt-16`)}>
              Upload Documents
              </p>
              <div className={(clsx(classes.mWebFor, `flex gap-6 mb-14 border-b border-gray-300`))}>
                <div className={`sm:text-2xl  pt-10 ${activeTab === 'invoice' ? 'border-b-2 border-solid border-black' : 'text-[gray]'} cursor-pointer`} onClick={()=>setActiveTab('invoice')} >
                  <p className={` ${activeTab === 'invoice' ? 'text-[#1E333F]' : 'text-[grey]'} font-inter  font-bold leading-48 pb-4`}>Upload Invoice</p>
                </div>
                <div className={`sm:text-2xl pt-10 ${activeTab === 'locked' ? 'border-b-2 border-solid border-black' : 'text-[gray]'} cursor-pointer`} onClick={()=>setActiveTab('locked')}>
                  <p className={` ${activeTab === 'locked' ? 'text-[#1E333F]' : 'text-[grey]'} font-inter  font-bold leading-48 pb-4`}>Locked Documents</p>
                </div>
              </div>

            {activeTab === 'invoice' &&
            (<div className="sm:pb-12">
              {transactionId && (
                <FileBrowser
                  setFile={setInvoices}
                  files={invoices}
                  organizationId={props.organizationId}
                  txnId={transactionId}
                  setOcrResponse={setOcrResponse}
                  setOcrLoading={setOcrLoading}
                  validFormats={['document', 'image']}
                  hasError={
                    (submitClicked) && Object.keys(invoices || {})?.length === 0 && hasInvoiceError
                  }
                  errorMessage="Attachment is mandatory*"
                />
              )}
            </div>)} 

            {activeTab === 'locked' && (
              <>
                <div className={classes.lockedTextCon}>
                  <div className={classes.lockedTextWrap}>
                    <div className={classes.lockedTextBox}>
                      <p className={classes.lockedText}>
                      These documents are currently secured and will only be unlocked 
                      in accordance with the terms agreed upon with your customer,
                      or if you choose to unlock them yourself.</p>
                      <div className={classes.imgBoc}>
                        <img src={lock} alt='lock' className={classes.imgLock} /> 
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.uploadCon}>
                  <FileBrowserNew
                      setFile={setLockInvoices}
                      files={lockInvoices}
                      organizationId={props.organizationId}
                      transactionId={transactionId}
                      isMobile={isMobile}
                      validFormats={['document', 'image']}
                      hasError={false}
                      disabled={creatingTransactionLoader}
                      // docNum={Object.keys(invoice || {})?.length + 1 || 0}
                      errorMessage={"Please upload a valid file"}
                      componentName="locked"
                  />
                </div>
              </>
            )}
          </div>
        </div>)}
        <div
          className={clsx(
            classes.add_contact,
            'flex sm:relative w-full sm:max-w-[64rem] xl:min-w-[64rem] order-1 sm:order-2 flex-col pt-[72px] h-auto sm:h-[calc(100vh-7.2rem)]  '
          )}
          style={{ boxShadow: '-10px 0px 24px rgba(30, 51, 63, 0.04)' }}
        >
          <div className="hidden sm:flex z-10 flex-row items-center justify-center sm:justify-between absolute top-0 right-0 left-0 px-16 py-[2rem] bg-white">
            <img
              src={leftPointer}
              alt="left-pointer"
              className="block sm:hidden absolute left-8"
              onClick={() => {
                dispatch(modalActions.createInvoice())
              }}
            />
            <p className="text-3xl font-bold">Add Invoice Details</p>
            <Close
              className="w-[3.2rem] h-[3.2rem] hidden sm:block"
              onClick={() => {
                dispatch(modalActions.createInvoice())
              }}
            />
          </div>
          <div className="sm:overflow-scroll px-8 sm:px-16 sm:pb-36 ">
            <div className="mt-6">
              <p className="text-2xl font-bold">Bill to</p>
              <div className="w-full mt-8 bg-[#F7F8FB] flex flex-row items-center p-[16px] gap-8 rounded-[12px]">
                <div className="w-[48px] h-[48px] rounded-full flex items-center justify-center bg-[#FFEBEB] text-2xl text-[#FFB2B2]">
                  {contact?.customerName
                    ?.split(' ')
                    .map((char) => char.charAt(0).toUpperCase())
                    .join('')}
                </div>
                <ContactInfo
                  name={contact?.customerName}
                  country={contact?.country}
                  email={contact?.emailAddress}
                />
              </div>
            </div>
            {primaryBank && (
              <div className="mt-16" ref={optRef} >
                <p className="text-2xl font-bold" onClick={()=> dispatch(modalActions.showBankDropDown(false))} ref={dropdownRef}>Receive money in</p>
                <div className="w-full mt-8 bg-[#F7F8FB] flex flex-row items-center justify-between px-[20px] py-[16px] gap-8 rounded-[12px]" style={{cursor: allBanks?.length > 1 ? "pointer" : "default"}} onClick={handleBankClick} >
                  <div className={classes.pbankWrap}>
                  <img
                    src={
                      primaryBank?.countryFlag
                        ? `Images/${primaryBank.country}.svg`
                        : INRFlag
                    }
                    alt="country-flag"
                    className="w-[48px] h-[48px] rounded-full"
                  />

                  <ContactInfo
                    name={`${primaryBank?.bankName} Bank`}
                    email={primaryBank?.accountNumber
                      ?.slice(-4)
                      .padStart(primaryBank?.accountNumber?.length, 'X')}
                  className={classes.ctinfo}/>
                  </div>
                  
                  <div className={classes.rightDown}>
                    {<p className={classes.eefc} style={{backgroundColor:"#FFF"}}>{primaryBank.bankAccountType=="EEFC"?"EEFC":"INR"}</p>}
                    {allBanks?.length>1 && (
                    <img
                      src={showBankDropDown?upArrow:arrowDownIcon}
                      alt="arrow-down-icon"
                      className="w-[24px] h-[24px] me-10px"
                      style={{ cursor: "pointer" }}
                  />)}
                  </div>
                 
                </div>
                {allBanks?.length>1 && showBankDropDown && (
            <div className={classes.webContent} >
              <div className={classes.options}>
              {allBanks?.map((opt, index) => (
                <div
                  className={clsx(
                    classes.opt,
                    !opt.onClick && 
                    'cursor-pointer',
                    index && 'border-t border-[#ECEFF2]',
                  )}
                  key={`${opt.text}-${index}`}>
                  <div key={index} className={classes.bankCard} style={{backgroundColor:`${index%2==0?"#FFF":"#F7F8FB"}`}} onClick={()=>{
                    setPrimaryBank(opt)
                    dispatch(modalActions.showBankDropDown(false))
                    }}  >
                        <div key={index} className={classes.bankContent} >
                              <img
                              src={
                              INRFlag
                              }
                              alt="country-flag"
                              className="w-[48px] h-[48px] rounded-full"
                            />
                             <ContactInfo 
                             name={`${opt?.bankName} Bank`}
                             email={opt?.accountNumber
                              ?.slice(-4)
                              .padStart(opt?.accountNumber?.length, 'X')}
                             />
                            </div>
                            
                               <div className={classes.rightCheck}>
                                <p className={classes.eefc} style={{backgroundColor:`${index%2==0?"#F7F8FB":"#FFF"}`}}>{opt.bankAccountType=="EEFC"?"EEFC":"INR"}</p>
                                <div className={classes.checkImgWrap}>{opt.accountId==primaryBank.accountId &&<img src={checkIcon} alt="check-icon" className={classes.checkIcon} />}</div>
                              </div>
                            
                          </div>  
                </div>
              ))}
            </div>
              </div>
            )}
              </div>
            )}

            <div className="flex flex-col mt-16 items-start gap-4">
              <p className="text-2xl font-bold">Invoice Details</p>
                {
                  ocrLoading === 2
                    ?
                      <div className="flex flex-col items-center my-[40px] w-[100%] h-[16vh] justify-center">
                        <>
                          <svg
                            class="animate-spin -ml-1 mr-3 h-8 w-8 text-black"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                                stroke-dasharray="64"
                                stroke-dashoffset="0"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </>
                        <div className='flex items-start flex-row justify-center text-[12px] leading-[16px] pt-[10px] font-[400]'>Please wait while we are fetching details.</div>
                      </div>
                    :
                    <>
                      <div className={clsx(classes.rowGap, 'w-full grid mt-8 grid-cols-2 gap-x-6')} ref={!isInvoiceIdValid ? inputErrorScroll : noneRef} >
                        <TextInput
                          type="text"
                          text="Invoice ID*"
                          className="col-span-2"
                          validation={[(value) => value !== '']}
                          errorMessage="Please enter a valid Invoice Id"
                          inputValidity={(value) => {
                            setIsInvoiceIdValid(value)
                          }}
                          setInput={(value) => {
                            setInvoiceId(value)
                          }}
                          enteredInput={invoiceId}
                          hasError={(submitClicked || draftClicked) && !isInvoiceIdValid}
                          initiateFocus = {!isInvoiceIdValid}
                          focusRef = {focusRef}
                          noneRef = {noneRef}
                          highlightTrue={bgColors.invoiceId}
                        />
                        {/* <TextInput
                          type="text"
                          text="Purpose Code*"
                          className="col-span-2"
                          errorMessage="Please enter a valid Purpose code"
                          validation={[(value) => value !== '']}
                          inputValidity={(value) => {
                            setIsPurposeCodeValid(value)
                          }}
                          setInput={(value) => {
                            setPurposeCode(value)
                          }}
                          enteredInput={purposeCode}
                          // hasError = {isWrongInput && !isEmailValid}
                          hasError={(submitClicked || draftClicked) && !purposeCode}
                        /> */}
                        <div className="date-picker-wrapper relative"  ref={(isInvoiceIdValid && !ispurposeCodeValid) ? inputErrorScroll : noneRef}>
                          <CustomSelectorValuesCodes
                              onChange={handlePurposeCodeChange}
                              key={key}
                              selectedValue={purposeCode}
                              items={purposeCodeArray}
                              validation={[(value) => value !== '']}
                              placeholder="Purpose Code*"
                              searchText="Purpose Code*"
                              headingText="All Purpose Codes"
                              nothingFoundText="No Matching Purpose Code Found"
                              errorMessage="Please select a valid Purpose code"
                              // inputValidity={(value) => setBusinessTypeValid(!value)}
                              hasError={(submitClicked || draftClicked) && !purposeCode}
                              handleDropdownClick={handleDropdownClick}
                              initiateFocus = {isInvoiceIdValid && !ispurposeCodeValid}
                              focusRef = {focusRef}
                              noneRef = {noneRef}
                          />
                        </div>
                        <div className="date-picker-wrapper relative" ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate) ? inputErrorScroll : noneRef}>
                        {issueDate && <p className={classes.dateLabel}>Issue Date*</p>}
                          <DatePicker
                            status={(submitClicked || draftClicked) && !issueDate ? 'error' : undefined}
                            defaultValue={issueDate}
                            key={changedIssueDate}
                            onChange={(date, dateString) => {
                              setIssueDate(date)
                              setChangedIssueDate(true)
                              if (
                                date?.isSame(dueDate) ||
                                date?.isAfter(dueDate) ||
                                !date
                              )
                                setDueDate()
                            }}
                            className={clsx(
                              ((submitClicked || draftClicked) && !issueDate) && `border-[2px]`,
                              bgColors.issueDate ? 'bg-[#fff9e7]' : '',
                              `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                            )}
                            placeholder="Issue Date*"
                            size="large"
                            format="DD/MM/YYYY"
                            disabledDate={(d) => d && d > dayjs().endOf("day")}
                            ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate)?focusRef:noneRef}
                          />
                          {(submitClicked || draftClicked) && !issueDate && (
                            <div className={classes.error} style={{ width: '100%' }}>
                              Please enter valid issue date.
                            </div>
                          )}
                        </div>
                        
                        
                        <div className="flex flex-row relative col-span-2" ref={(isInvoiceIdValid && ispurposeCodeValid && issueDate && !isInvoiceAmountValid) ? inputErrorScroll : noneRef} >
                          <TextInput
                            type="text"
                            text="Invoice Amount*"
                            errorMessage="Please enter a valid Invoice Amount"
                            validation={[
                              (value) =>
                                value !== '' &&
                                Number(value) !== 0 &&
                                /^(?!0\d)\d+(\.\d+)?$/.test(value),
                            ]}
                            inputValidity={(value) => {
                              setIsInvoiceAmountValid(value)
                            }}
                            setInput={(value) => {
                              setInvoiceAmount(value)
                            }}
                            enteredInput={invoiceAmount}
                            hasError={(submitClicked || draftClicked) && !isInvoiceAmountValid}
                            initiateFocus = {isInvoiceIdValid && ispurposeCodeValid && issueDate && !isInvoiceAmountValid}
                            focusRef = {focusRef}
                            noneRef = {noneRef}
                            highlightTrue={bgColors.invoiceAmount}
                          />
                          <span className={clsx(
                            ((submitClicked || draftClicked) && !isInvoiceAmountValid) ? classes.customHeightError : classes.customHeight,
                            `px-4 py-2 absolute right-[2px] bottom-[2px] top-[2px] text-[#1E333F80] text-lg rounded bg-[#F4F4F4]`
                          )} style={{
                            borderRadius: 6
                          }}>
                          <Select
                            defaultValue="USD"
                            style={{
                              width: 120,
                            }}
                            onChange={handleChange}
                            options={customerCurrencies?.supportedCurrency ? Object.keys(customerCurrencies?.supportedCurrency).map((item, index) => {
                              return {
                                label: customerCurrencies.supportedCurrency[item],
                                value: customerCurrencies.supportedCurrency[item],
                              }
                            }) : []}
                          />
                          </span>
                        </div>
                        <div className="flex flex-row relative col-span-2 justify-between">
                          <div className="flex gap-[8px] w-[100%] items-center">
                              <input
                                  className={classes.receivableCheckbox}
                                  type="checkbox"
                                  style={{ cursor: "pointer" }}
                                  checked={invoiceCheckbox}
                                  onChange={() => handleCheckboxChange()}
                              />
                              <p className={classes.checkboxText}>Receivable amount is same as Invoice amount</p>
                              {
                                window.innerWidth > 600
                                ?
                                  <Tooltip
                                    placement="bottom"
                                    color="#060A0D"
                                    title={'The amount you want to collect against this Receivable. It must be less than or equal to the Invoice amount.'}
                                  >
                                    <img
                                      src={HelpCircle}
                                      alt="help-circle"
                                      className={classes.tooltipImage}
                                    />
                                  </Tooltip>
                                  :
                                    <img
                                      src={HelpCircle}
                                      alt="help-circle"
                                      className={classes.tooltipImage}
                                      onClick={() => dispatch(modalActions.invoiceTooltipDisplay(true))}
                                    />
                              }
                            </div>
                          {/* <div className="flex gap-[8px] items-center">
                            <input
                                className={classes.receivableCheckbox}
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                checked={invoiceCheckbox}
                                onChange={() => handleCheckboxChange()}
                            />
                            <p className={clsx(classes.checkboxText, 'text-right')}>Same as Invoice amount</p>
                          </div> */}
                        </div>
                        { !invoiceCheckbox &&
                            <div className="flex flex-row relative col-span-2" ref={(isInvoiceIdValid && ispurposeCodeValid && issueDate && isInvoiceAmountValid && !isReceivableAmountValid) ? inputErrorScroll : noneRef} >
                              <TextInput
                                type="text"
                                text="Enter Receivable Amount"
                                errorMessage={Number(invoiceAmount || 0) < Number(receivableAmount || 0) ? "Receivable amount cannot be greater than invoice amount." : "Please enter a valid Receivable Amount"}
                                validation={[
                                  (value) =>
                                    value !== '' &&
                                    Number(value) !== 0 &&
                                    /^(?!0\d)\d+(\.\d+)?$/.test(value),
                                ]}
                                inputValidity={(value) => {
                                  setIsReceivableAmountValid(value)
                                }}
                                setInput={(value) => {
                                  setReceivableAmount(value)
                                }}
                                enteredInput={receivableAmount}
                                hasError={((submitClicked || draftClicked) && !isReceivableAmountValid) || Number(invoiceAmount || 0) < Number(receivableAmount || 0)}
                                initiateFocus = {isInvoiceIdValid && ispurposeCodeValid && issueDate && isInvoiceAmountValid && !isReceivableAmountValid}
                                focusRef = {focusRef}
                                noneRef = {noneRef}
                                highlightTrue={bgColors.receivingAmount}
                              />
                              <span className={clsx(
                                (((submitClicked || draftClicked) && !isReceivableAmountValid) || Number(invoiceAmount || 0) < Number(receivableAmount || 0)) ? classes.customHeightError : classes.customHeight,
                                `px-4 py-2 absolute right-[2px] bottom-[2px] top-[2px] text-[#1E333F80] text-lg rounded bg-[#F4F4F4]`
                              )} style={{
                                borderRadius: 6
                              }}>
                              <Select
                                value={invoiceCurrency}
                                disabled
                                suffixIcon={null}
                                style={{
                                  width: 120,
                                }}
                              />
                              </span>
                            </div>
                        }
                        {forexDetails && (
                          <div key={key} className="col-span-2 rounded-xl flex flex-row items-center justify-between gap-3 bg-[#0C9C7A29] px-6 py-3">
                            <div className="rounded-full w-fit flex flex-row items-center justify-between gap-2 bg-[#1DAF8C] px-4 py-1 ">
                              {/* <img src={WalletIcon} alt='wallet' className="w-6 h-6" /> */}
                              <p className="text-lg font-bold text-center text-[#FFFFFF]">
                                @{forexDetails?.estimatedForexRate}{' '}
                                <span className="inline-block">
                                  {forexDetails?.inputCurrency} {'< >'}{' '}
                                  {forexDetails?.outputCurrency}
                                </span>
                              </p>
                            </div>
                            <p className="text-[#1DAF8C] text-xl font-normal flex-grow">
                              Estimated FX rate on this transaction
                            </p>
                            <img src={InfoIcon} alt="i" className="w-7 h-7" />
                          </div>
                        )}
                        <div className='col-span-2'>
                        <TextInput
                            type="text"
                            text="Payment Terms(optional)"
                            className="col-span-2"
                            multiline={true}
                            setInput={(value) => {
                              setPaymentTerms(value)
                            }}
                            enteredInput={paymentTerms}
                            setLimit = {true}
                            highlightTrue={bgColors.netTerms}
                        />
                      </div>
                        <div className="date-picker-wrapper relative">
                          {blDate && <p className={classes.dateLabel}>BL Date</p>}
                          <DatePicker
                            status={
                              (submitClicked || draftClicked) && issueDate && !blDate
                                // ? 'error'
                                ? undefined
                                : undefined
                            }
                            onChange={(date, dateString) => {
                              setBLDate(date)
                            }}
                            defaultPickerValue={issueDate}
                            disabled={!issueDate}
                            value={blDate}
                            className={clsx(
                              // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
                              bgColors.blDate ? 'bg-[#fff9e7]' : '',
                              `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                            )}
                            placeholder="BL Date"
                            size="large"
                            format="DD/MM/YYYY"
                            disabledDate={(d) => !d || d.isBefore(issueDate)}
                          />
                        </div>
                        <div className="date-picker-wrapper relative">
                          {(dueDate || changedIssueDate) && <p className={classes.dateLabel}>Due Date</p>}
                          <DatePicker
                            status={
                              (submitClicked || draftClicked) && issueDate && !dueDate
                                // ? 'error'
                                ? undefined
                                : undefined
                            }
                            onChange={(date, dateString) => {
                              setDueDate(date)
                            }}
                            defaultPickerValue={issueDate}
                            disabled={!issueDate}
                            value={dueDate}
                            className={clsx(
                              // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
                              bgColors.dueDate ? 'bg-[#fff9e7]' : '',
                              `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                            )}
                            placeholder="Due Date"
                            size="large"
                            format="DD/MM/YYYY"
                            disabledDate={(d) => !d || d.isBefore(issueDate)}
                          />
                          {/* {(submitClicked || draftClicked) && issueDate && !dueDate && (
                            <div className={classes.error} style={{ width: '100%' }}>
                              Please enter valid due date.
                            </div>
                          )} */}
                        </div>
                        
                        
                        <TextInput
                          type="text"
                          text="Invoice Description (optional)"
                          className="col-span-2"
                          multiline={true}
                          setInput={(value) => {
                            setInvoiceDescription(value)
                          }}
                          enteredInput={invoiceDescription}
                          highlightTrue={bgColors.description}
                        />
                      </div>
                      <p
                        className={clsx(
                          'text-xl font-medium text-[#1E333F80] col-span-2'
                        )}
                      >
                        This will be shown to the customer
                      </p>
                    </>
                }
            </div>
            {
              ocrLoading === 2
                ?
                  ""
                :
                  <div className="flex flex-col mt-[3rem] items-start">
                    <p className="text-2xl font-bold">Additional Information</p>
                    {/* <div className={clsx(classes.rowGap, 'w-full grid mt-8 grid-cols-2 gap-x-6')}>
                      <TextInput
                          type="text"
                          text="Payment Terms(optional)"
                          className="col-span-2"
                          multiline={true}
                          setInput={(value) => {
                            setPaymentTerms(value)
                          }}
                          enteredInput={paymentTerms}
                          setLimit = {true}
                      />
                    </div> */}
                    <div className={clsx(classes.rowGap, 'w-full grid mt-8 grid-cols-2 gap-x-6')}>
                      <TextInput
                          type="text"
                          text="Remarks (optional, for internal reference)"
                          className="col-span-2"
                          multiline={true}
                          setInput={(value) => {
                            setRemarks(value)
                          }}
                          enteredInput={remarks}
                          setLimit = {true}
                          highlightTrue={bgColors.remarks}
                      />
                    </div>
                  </div>
            }

            {window.innerWidth < 640 && 
              (
                <div className={classes.attachments}>
                  <div className={classes.attachmentsTop}>
                    <p className={classes.attachText}>Attachments</p>
                  </div>
                  <div className={classes.attachmentsBottom}>
                    <div className={classes.attachmentsInvoice} onClick={()=>setActiveInvoice(true)}>
                      <p className={classes.attachSubText}>Upload Invoice</p>
                      <img src={rightArrow} alt='rightArrow' />
                    </div>
                    {activeInvoice && 
                      (
                        <div className={classes.mask}>
                          <div className={classes.lockedCon}>
                            <div className={classes.lockedConCross} onClick={()=>setActiveInvoice(false)}>
                              <p className={classes.lockedConCrossText}>Upload Invoices</p>
                              <img src={crossImg} alt='rightArrow' className={classes.lockedConCrossImg}/>
                            </div>
                            <div className={classes.uploadCon}>
                              <FileBrowserNew
                                  setFile={setInvoices}
                                  files={invoices}
                                  transactionId={transactionId}
                                  isMobile={isMobile}
                                  validFormats={['document', 'image']}
                                  hasError={false}
                                  disabled={creatingTransactionLoader}
                                  // docNum={Object.keys(invoice || {})?.length + 1 || 0}
                                  errorMessage={"Please upload a valid file"}
                                  componentName="unLocked"
                              />
                              {/* <FileBrowser
                                  setFile={setInvoices}
                                  files={invoices}
                                  txnId={transactionId}
                                  validFormats={['document', 'image']}
                                  hasError={
                                    (submitClicked) && invoices.length === 0 && hasInvoiceError
                                  }
                                  errorMessage="Attachment is mandatory*"
                                /> */}
                            </div>
                            <div className={classes.button}>
                              <Button
                                className={classes.btn}
                                text={"Save"}
                                // disabled={Object.keys(invoice || {})?.length === 0 ? true : false}
                                // onClick={() => {
                                //     getBulkTransactionSubmit({ transactionId: bulkTransactionsIdResult?.data});
                                //     dispatch(modalActions.bulkUploaderDisplay(false));
                                // }}
                                onClick={()=>setActiveInvoice(false)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <hr className={classes.hr}></hr>
                    <div className={classes.attachmentsInvoice} onClick={()=>setActiveInvoiceLock(true)}>
                      <p className={classes.attachSubText}>Upload Locked Documents</p>
                      <img src={rightArrow} alt='rightArrow' />
                    </div>
                    {/* { lockInvoices?.length >= 1 &&
                    (<div className={classes.invoiceCon}>
                      <FileViewer
                      format={['document', 'image']}
                      displayUrls={lockInvoices}
                      downloadPrefix={props?.invoice?.invoice_id} 
                      whereCalled="smallScreen"
                      // setWhereCalled={setWhereCalled}
                      // invoiceData={invoiceLockData}
                      // setInvoicetoBigScreen={setInvoicetoBigScreen}
                      // setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                      // paymentLinkOtpRequired={paymentLinkOtpRequired}
                      />
                    </div>)} */}
                    {activeInvoiceLock && 
                      (
                        <div className={classes.mask}>
                          <div className={classes.lockedCon}>
                            <div className={classes.lockedConCross} onClick={()=>setActiveInvoiceLock(false)}>
                              <p className={classes.lockedConCrossText}>Upload Locked Documents</p>
                              <img src={crossImg} alt='rightArrow' className={classes.lockedConCrossImg}/>
                            </div>
                            <div className={classes.lockedTextCon}>
                              <div className={classes.lockedTextWrap}>
                                <div className={classes.lockedTextBox}>
                                  <p className={classes.lockedText}>
                                  These documents are currently secured and will only be unlocked 
                                  in accordance with the terms agreed upon with your customer,
                                  or if you choose to unlock them yourself.</p>
                                  <div className={classes.imgBoc}>
                                    <img src={lock} alt='lock' className={classes.imgLock} /> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classes.uploadCon}>
                              <FileBrowserNew
                                  setFile={setLockInvoices}
                                  files={lockInvoices}
                                  transactionId={transactionId}
                                  isMobile={isMobile}
                                  validFormats={['document', 'image']}
                                  hasError={false}
                                  disabled={creatingTransactionLoader}
                                  // docNum={Object.keys(invoice || {})?.length + 1 || 0}
                                  errorMessage={"Please upload a valid file"}
                                  componentName="locked"
                              />
                            </div>
                            <div className={classes.button}>
                              <Button
                                className={classes.btn}
                                text={"Save"}
                                // disabled={Object.keys(invoice || {})?.length === 0 ? true : false}
                                // onClick={() => {
                                //     getBulkTransactionSubmit({ transactionId: bulkTransactionsIdResult?.data});
                                //     dispatch(modalActions.bulkUploaderDisplay(false));
                                // }}
                                onClick={()=>setActiveInvoiceLock(false)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              )}
          </div>
          <div
            className={clsx(
              classes.buttonBox,
              'absolute z-10 hidden sm:flex left-0 right-0 bottom-0 border-t bg-bg-white border-t-[#ECEFF2]'
            )}
          >
            <Button
              className={clsx(
                'flex-1 outline-none mr-[6px] bg-white rounded-[8px] border border-solid border-[#181C3033]',
                // !isValid() && 
                'text-[#181C30]'
              )}
              disabled={draftEditInvoiceCallLoader}
              text="Save as Draft"
              onClick={handleDraft}
            />
            <Button
              className={clsx(
                'flex-1 outline-none ml-[6px]',
                (creatingInvoice || !isValidInvoiceButton()) && 'text-[#ffffff80]'
              )}
              disabled={creatingInvoice}
              text="Create Invoice"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateInvoice
