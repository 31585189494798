// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoInvoicesView_button__MMjq2 {
  align-self: flex-start;
  display: flex;
}
.NoInvoicesView_button__MMjq2 .NoInvoicesView_btnClass__vdz2n {
  border-radius: 1rem;
  padding: 1.2rem 1.6rem;
}
.NoInvoicesView_button__MMjq2 .NoInvoicesView_btnClass_icon__fEqcg {
  margin-right: 0.8rem;
}
.NoInvoicesView_button__MMjq2 .NoInvoicesView_btnClass_text__qKb9U {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.NoInvoicesView_circle__dPVWI::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 10px solid black;
  left: 293px;
  top: 24px;
}

.NoInvoicesView_littleDot__wFQb0 {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: #1E333F;
  color: white;
  transform: translate(10px, 10px);
}`, "",{"version":3,"sources":["webpack://./src/Components/molecule/CustomerDetailsModal/NoInvoicesView.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;AACF;AAEE;EACE,mBAAA;EACA,sBAAA;AAAJ;AAEI;EACE,oBAAA;AAAN;AAGI;EACE,iBAAA;EACA,mBAAA;AADN;;AAKA;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,wBAAA;EACA,WAAA;EACA,SAAA;AAFF;;AAIA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,gCAAA;AADF","sourcesContent":[".button {\n  align-self: flex-start;\n  display: flex;\n\n\n  .btnClass {\n    border-radius: 1rem;\n    padding: 1.2rem 1.6rem;\n\n    &_icon {\n      margin-right: 0.8rem;\n    }\n\n    &_text {\n      font-size: 1.6rem;\n      line-height: 2.4rem;\n    }\n  }\n}\n.circle::after {\n  content: \"\";\n  position: absolute;\n  border-radius: 50%;\n  border: 10px solid black;\n  left: 293px;\n  top: 24px;\n}\n.littleDot{\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 24px;\n  height: 24px;\n  background: #1E333F;\n  color: #ffff;\n  transform: translate(10px, 10px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `NoInvoicesView_button__MMjq2`,
	"btnClass": `NoInvoicesView_btnClass__vdz2n`,
	"btnClass_icon": `NoInvoicesView_btnClass_icon__fEqcg`,
	"btnClass_text": `NoInvoicesView_btnClass_text__qKb9U`,
	"circle": `NoInvoicesView_circle__dPVWI`,
	"littleDot": `NoInvoicesView_littleDot__wFQb0`
};
export default ___CSS_LOADER_EXPORT___;
