import ReactDOM from "react-dom";
// import Close from '../../.Layout/Close'
import classes from './InvoiceDetailsTabContent.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import up from "../../../assets/Icons/chevron-up.svg"
import down from "../../../assets/Icons/chevron-down.svg"
import ContactInfo from '../../../Components/Global/ContactInfo'
// import leftPointer from 'assets/Icons/arrow-left.svg'
import InfoIcon from '../../../assets/Icons/info_black_circle.svg'
import WalletWhite from '../../../assets/Icons/wallet-white.svg'
// import unlock from 'assets/Icons/unlock.svg'
import lockBlack2 from '../../../assets/Icons/lockBlack2.svg'
import dummyInvoice from "../../../assets/Images/dummyInvoice.png"
import dayjs from 'dayjs'
// import FileBrowser from 'Components/atom/FileBrowser/FileBrowser'
import FileViewer from '../../../Components/atom/FileViewer/FileViewer'
// import {
//   fetchFileFromS3,
//   apiGetNewPaymentDetails,
//   apiGetOTP,
//   apiGetPaymentDetails,
//   apiGetPaymentInvoices,
//  } from 'services'
// import UseApiCall from 'hooks/useApiCall'
// import { useSize } from 'ahooks'
// import DocumentSheet from 'Pages/Other/MakePayment/DocumentSheet'
// import ZoomButtonsModal from '../../atom/FileViewer/ZoomFile'
import { formatNumberToLocaleString } from '../../../hooks/formatNumberToLocaleString';
// import RightIcon from 'assets/Icons/chevron-right-white.svg'

const InvoiceDetailsTabContent = (props) => {
  const dispatch = useDispatch()

  // const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(true);  // for now i use a state , once new API deployed i will fetch it from backend response
  // const [otpViewNowBox,setOtpViewNowBox] = useState(false);
  // const transId = useRef(0);
  // const transIdpaymentLinkOtpRequired = useRef(false);
  // const [otpData, setOtpData] = useState(null)
  // const [errorState, setErrorState] = useState({ error: false })
  // const [otpErrorState, setOtpErrorState] = useState({ error: false })
  // const [presignedUrlErrorState, setPresignedUrlErrorState] = useState({ error: false })
  // const [otp, setOtp] = useState(['', '', '', '','','']); 

  // const [invoicesLocked, setInvoicesLocked] = useState([
  //   {
  //     type : "image/png",
  //     url : RightIcon
  //   }
  // ])

  // const handleOTPView = () => {
  //   // setOtpViewNowBox((prev) => !prev);
  //   // setPaymentLinkOtpRequired((prev) => !prev);

  //   const params = {token : "340", invoiceId: "TestXflow"}
  //      fetchOtpData(params)
  // }

  // console.log(otpViewNowBox, "otpViewNowBox")


  // const handleResendOTPView = () => {
  //   const params = "MILEGA"
  //      fetchOtpData(params)
  // }

  // // console.log(invoices)



  // const handleOTPViewNow = () => {
  //   // setOtpViewNowBox(false);
  //   // setPaymentLinkOtpRequired(false);
  //   fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  // }


  // const [fetchInvoiceData, fetchingInvoiceLoader] = UseApiCall(
  //   apiGetNewPaymentDetails,
  //   (res) => {
  //       setPaymentLinkOtpRequired(res?.data?.paymentLinkOtpRequired)
  //       // console.log("transactionId",res?.data.transactionId)
  //       transId.current = res?.data?.transactionId
  //       transIdpaymentLinkOtpRequired.current = res?.data?.paymentLinkOtpRequired
  //       // !paymentLinkOtpRequired && fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  //       if(!transIdpaymentLinkOtpRequired.current){
  //         fetchPresignedUrl({ transactionId: transId.current , otp : otp.join('')})
  //       }
  //   },
  //   (err) => {
  //     setErrorState({
  //       error: true,
  //       title: JSON.stringify(err?.data?.error_response?.message) || '',
  //       subtext: 'Please generate a new link.',
  //     })
  //   }
  // )
  

  // const [fetchOtpData] = UseApiCall(
  //   apiGetOTP,
  //   async (res) => {
  //     try {
  //       // Assuming res.data is an object
  //       setOtpViewNowBox((prev) => !prev);
  //       setOtpData(res?.data);
  //       // Perform other actions if needed
  //     } catch (error) {
  //       // Handle any errors that occur during data processing
  //       console.error('Data processing error:', error);
  //     }
  //   },
  //   async (err) => {
  //     try {
  //       // Assuming err.data is an object
  //       console.error(err?.data?.detail);
  //       setOtpErrorState({
  //         error: true,
  //         title: err?.data?.detail || '',
  //         subtext: 'Please check your emailId.',
  //       });
  //     } catch (error) {
  //       // Handle any errors that occur during error handling
  //       console.error('Error handling error:', error);
  //     }
  //   }
  // );
  


  // const [fetchPresignedUrl, fetchingPresignedUrl] = UseApiCall(
  //   apiGetPaymentInvoices,
  //   (res) => {
  //     setOtpViewNowBox(false);
  //     setPaymentLinkOtpRequired(false);
  //     setInvoices([])
  //     // console.log(res.data)
  //     res.data.map(
  //       (item) => item.createdBy === 'fideo-backend' && getFilesFromS3(item.url)
  //     )
  //   },
  //   (err) => {
  //     // console.log(err)
  //     setOtpViewNowBox(true)
  //     setPresignedUrlErrorState({
  //       error: true,
  //       title: err?.data?.detail || '',
  //       subtext: 'Not able to fetch Invoices',
  //     })
  //   }
  // )

  // const [getFilesFromS3, fetchingFiles] = UseApiCall(
  //   fetchFileFromS3,
  //   (res, headers) => {
  //     try {
  //       var blob = new Blob([res], { type: headers['content-type'] })
  //       let link = window.URL.createObjectURL(blob)
  //       setInvoices((pre) => [
  //         ...pre,
  //         {
  //           url: link,
  //           type: headers['content-type'],
  //         },
  //       ])
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // )

  // localStorage.setItem('zoomkaindex', "0");
  // const invoiceData = props.invoice?.invoice_details_summary?.presigned_urls;
  // const invoiceData = props.invoiceData || props.invoice?.invoice_details_summary?.presigned_urls;
  // const invoiceLockData = invoiceData?.filter((item)=> item.isLocked === true) || [];
  // const invoiceunLockData = invoiceData?.filter((item)=> item.isLocked === false) || [];


  const {
    setOtpViewNowBox,
    otpViewNowBox,
    setPaymentLinkOtpRequired,
    paymentLinkOtpRequired,
    handleResendOTPView,
    otpErrorState,
    presignedUrlErrorState,
    invoiceData,
    invoiceLockData,
    invoiceunLockData,
    otp,
    setOtp,
    handleOTPView,
    handleOTPViewNow,
    invoicesLocked,
    invoices,
    setInvoices,
    withOutOtpDownload,
    userEmail,
    invoicetoBigScreen,
    lockInvoices,
    unlockInvoices,
    setInvoicetoBigScreen
  } = props;
  const invoiceStatusRed = (props.invoice?.invoice_status === "SETTLED_OUTSIDE" || props.invoice?.invoice_status === "CANCELLED") ? true : false;
  // const [invoicetoBigScreen, setInvoicetoBigScreen] = useState([])
  const { customer_attributes, invoice_attributes, savings_details } =
    props.invoice.invoice_details_summary
  const invoiceDetailsRef = useRef(null)
  // const [lockInvoices, setLockInvoices] = useState([])
  // const [unlockInvoices, setunLockInvoices] = useState([])
  const [activeTab,setActiveTab] = useState("invoice")
  const [descOpen, setDescOpen] = useState(false)
  // const size = useSize(invoiceDetailsRef)
  const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)
  const fileDisplayUrl = useSelector((state) => state.modal.fileDisplayUrl)
  const [whereCalled,setWhereCalled] = useState()
  // const [getFilesFromS3, fetchingFiles] = UseApiCall(
  //   fetchFileFromS3,
  //   (res, headers) => {
  //     try {
  //       var blob = new Blob([res], { type: headers['content-type'] })
  //       let link = window.URL.createObjectURL(blob)
  //       // console.log(link, "link")
  //       setInvoices((pre) => [
  //         ...pre,
  //         {
  //           url: link,
  //           type: headers['content-type'],
  //           // isLocked:
  //         },
  //       ])
  //     } catch (error) {
  //       // console.log(error)
  //     }
  //   }
  // )

  // const [getLockFilesFromS3, fetchingLockFiles] = UseApiCall(
  //   fetchFileFromS3,
  //   (res, headers) => {
  //     try {
  //       var blob = new Blob([res], { type: headers['content-type'] })
  //       let link = window.URL.createObjectURL(blob)
  //       console.log('linkgetLockFilesFromS3', link)
  //       setLockInvoices((pre) => [
  //         ...pre,
  //         {
  //           url: link,
  //           type: headers['content-type'],
  //           isLocked: true,
  //         },
  //       ])
  //     } catch (error) {
  //       // console.log(error)
  //     }
  //   }
  // )

  // const [getunLockFilesFromS3, fetchingunLockFiles] = UseApiCall(
  //   fetchFileFromS3,
  //   (res, headers) => {
  //     try {
  //       var blob = new Blob([res], { type: headers['content-type'] })
  //       let link = window.URL.createObjectURL(blob)
  //       console.log('linkgetunLockFilesFromS3', link)
  //       setunLockInvoices((pre) => [
  //         ...pre,
  //         {
  //           url: link,
  //           type: headers['content-type'],
  //           isLocked: false,
  //         },
  //       ])
  //     } catch (error) {
  //       // console.log(error)
  //     }
  //   }
  // )

  // const downloadInvoices = () => {
  //   // console.log('invoiceData', invoiceData)
  //   invoiceData?.map((item, index) => {
  //     getFilesFromS3(item?.url)
  //     return item
  //   })
  // }

  // const downloadLockInvoices = () => {
  //   invoiceLockData?.map((item, index) => {
  //     getLockFilesFromS3(item?.url)
  //     return item
  //   })
  // }

  // const downloadunLockInvoices = () => {
  //   invoiceunLockData?.map((item, index) => {
  //     getunLockFilesFromS3(item?.url)
  //     return item
  //   })
  // }

  // useEffect(()=>{
  //   const concatenateInvoices = unlockInvoices.concat(lockInvoices)
  //   // console.log('concatenateInvoices',concatenateInvoices)
  //   setInvoices(concatenateInvoices)
  //   setInvoicetoBigScreen([concatenateInvoices[0]])
  // },[lockInvoices,unlockInvoices])

  // useEffect(() => {
  //   // if (!withOutOtpDownload) {
  //     // downloadInvoices()
  //     downloadLockInvoices()
  //     downloadunLockInvoices()
  //   // }
  // }, [])

  useEffect(() => {
    if (paymentLinkOtpRequired) {
      localStorage.setItem('zoomkaindex', '0')
    }
  }, [paymentLinkOtpRequired])

// console.log('man',invoices)
// console.log('invoiceData',invoiceData)
// console.log('invoiceLockData',invoiceLockData)
// console.log('invoiceunLockData',invoiceunLockData)
// console.log('lockInvoice',lockInvoices)
// console.log('unlockInvoice',unlockInvoices)
// console.log('invoicetoBigScreen',invoicetoBigScreen)
// console.log('activeTab',activeTab)
// console.log('paymentLinkOtpRequired',paymentLinkOtpRequired)
// console.log('otpViewNowBox',otpViewNowBox)

  return (
    <div className="flex flex-col items-center sm:flex-row -sm:relative h-[max-content] -sm:pb-12 -sm:overflow-auto gap-x-8 bg-transparent invoiceDetailsTabPaneContent">
      <div className="h-[max-content] sm:overflow-x-scroll sm:overflow-y-hidden w-full xl:min-w-[50rem] -sm:hidden">
        <div className="">
          {/* {showFileDisplay &&
            ReactDOM.createPortal(
              <ZoomButtonsModal
                fileUrl={fileDisplayUrl}
              />,
              document.getElementById("modal-root")
          )} */}
          {/* {otpViewNowBox && <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/>} */}
                              {/* {paymentLinkOtpRequired && <EnterOtp handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />} */}

          {
            window.innerWidth >= 644 &&
              <FileViewer
                // format={['document', 'image']}
                displayUrls={paymentLinkOtpRequired ? invoicesLocked : invoicetoBigScreen}
                // displayUrls={invoicetoBigScreen}
                downloadPrefix={props?.invoice?.invoice_id}

                customerEmail={customer_attributes?.customer_email}
                setOtpViewNowBox={setOtpViewNowBox}
                otpViewNowBox={otpViewNowBox}
                setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                paymentLinkOtpRequired={paymentLinkOtpRequired}
                handleResendOTPView={handleResendOTPView}
                otpErrorState={otpErrorState}
                presignedUrlErrorState={presignedUrlErrorState}
                // invoiceData={invoiceData}
                otp={otp}
                setOtp={setOtp}
                handleOTPView={handleOTPView}
                handleOTPViewNow={handleOTPViewNow}
                whereCalled="bigScreen"
                invoicetoBigScreen={invoicetoBigScreen}
              />
          }
        </div>
        <div className={clsx(classes.invoiceDetailCon)}>
          <div className={clsx(classes.invoiceTabCon)}>
            <div className={clsx(`${classes.invoiceTabLocked} `)}
              style={{ borderBottom: activeTab === "invoice" ? "2px solid #181C30" : "none" }}
              onClick={()=>setActiveTab("invoice")}>
              <p className={clsx(`${classes.invoiceTabLockedText} `)}
              style={{ color: activeTab === "invoice" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
              >Invoice Attachments</p>
            </div>
            <div className={clsx(`${classes.invoiceTabLocked} `)} 
              style={{ borderBottom: activeTab === "locked" ? "2px solid #181C30" : "none" }}
              onClick={()=>setActiveTab("locked")}>
              <p className={clsx(`${classes.invoiceTabLockedText} `)}
              style={{ color: activeTab === "locked" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
              >Locked Documents</p>
            </div>
          </div>
          {activeTab === "invoice" && 
          (
            <div className={classes.invoiceCon}>
            {unlockInvoices?.length > 0 ?
              (
                <FileViewer
                  format={['document', 'image']}
                  displayUrls={unlockInvoices}
                  downloadPrefix={props?.invoice?.invoice_id}
                  whereCalled="smallScreen"
                  setWhereCalled={setWhereCalled}
                  invoiceData={invoiceunLockData}
                  setInvoicetoBigScreen={setInvoicetoBigScreen}
                  setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                  paymentLinkOtpRequired={paymentLinkOtpRequired}
                  invoicetoBigScreen={invoicetoBigScreen}
                />
              ) : 
              ( (paymentLinkOtpRequired || otpViewNowBox) ? <>
            <div className={classes.invoiceBox} 

            onClick={handleOTPView}>
              <img src={dummyInvoice} alt="invoice" className={classes.invoiceImg}/>
              <img src={lockBlack2} alt="invoice" className={classes.lockBlack2Img}/>
            </div>
            </> : 
            <p className={clsx(`${classes.NoinvoiceTabLockedText} `)}
            style={{ color: activeTab === "lockedd" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
            >No invoice uploaded</p>
            )

            }
          </div>
          )}

          {activeTab === "locked" && 
          (
            <div className={classes.invoiceCon}>
              {lockInvoices?.length > 0 ?
                  (
                    <FileViewer
                      format={['document', 'image']}
                      displayUrls={lockInvoices}
                      downloadPrefix={props?.invoice?.invoice_id} 
                      whereCalled="smallScreen"
                      setWhereCalled={setWhereCalled}
                      invoiceData={invoiceLockData}
                      setInvoicetoBigScreen={setInvoicetoBigScreen}
                      setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                      paymentLinkOtpRequired={paymentLinkOtpRequired}
                      invoicetoBigScreen={invoicetoBigScreen}
                    />
                  ) 
                  : 
                  ( (paymentLinkOtpRequired || otpViewNowBox) ? <>
                <div className={classes.invoiceBox} 

                onClick={handleOTPView}>
                  <img src={dummyInvoice} alt="invoice" className={classes.invoiceImg}/>
                  <img src={lockBlack2} alt="invoice" className={classes.lockBlack2Img}/>
                </div>
                </> : 
                <p className={clsx(`${classes.NoinvoiceTabLockedText} `)}
                style={{ color: activeTab === "lockedd" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
                >No locked document uploaded</p>
                )
                }
              </div>
          )}
        </div>
      </div>
      <div
        ref={invoiceDetailsRef}
        className={clsx(
          classes.add_contact,
          'flex sm:relative w-full order-1 sm:order-2 flex-col p-[24px] h-auto sm:h-[max-content] -sm:p-[16px] sm:overflow-scroll -sm:order-1'
        )}
        style={{
          borderRadius: '12px',
          background: '#FFF',
          boxShadow: '0px 4px 20px 0px rgba(76, 87, 125, 0.02)',
        }}
      >
        <div className="sm:flex flex-row items-center justify-center sm:justify-between bg-white">
          <p className="text-2xl" style={{fontWeight: 500}}>Bill to</p>
        </div>
        <div className="">
          <div className="mt-6">
            <div className="w-full mt-8 bg-[#F7F8FB] flex flex-row items-center p-[16px] gap-8 rounded-[12px]">
              <div className="w-[48px] h-[48px] rounded-full flex items-center justify-center bg-[#FFEBEB] text-2xl text-[#FFB2B2]">
                {customer_attributes?.customer_name
                  ?.split(' ')
                  .map((char) => char.charAt(0).toUpperCase())
                  .join('')}
              </div>
              <ContactInfo
                name={customer_attributes?.customer_name}
                country={customer_attributes?.customer_country}
                email={customer_attributes?.customer_email}
              />
            </div>
            <ul className={classes.all_list}>
              <li className={`${classes.list} ${classes.border_bottom}`}>
                <div className={classes.name_id}>
                  <div
                    className={classes.name}
                  >{`${invoice_attributes.invoice_id}`}</div>
                  <div className={classes.invoiceId}>
                    <div>Invoice ID</div>
                  </div>
                </div>
                
              </li>
              <li className={`${classes.list} ${classes.border_bottom}`}>
              <div className={classes.name_id}>
                  <div className={classes.amount}>
                    {invoice_attributes.purpose_code}
                  </div>
                  <div className={classes.invoiceId}>
                    <div>Purpose Code</div>
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.name}>
                    {dayjs(invoice_attributes.issue_date).format('DD/MM/YYYY')}
                  </div>
                  <div className={classes.invoiceId}>
                    <div>Issue Date</div>
                  </div>
                </div>
                
                {/* <div className={classes.amount_status}>
                    <div className={classes.amount}>
                    { invoice_attributes?.bl_date ? dayjs(invoice_attributes?.bl_date).format('DD/MM/YYYY') : "--"}
                    </div>
                    <div className={classes.invoiceId}>
                      <div>BL Date</div>
                    </div>
                </div> */}
              </li>
              <li className={`${classes.list} justify-between`}>
                {/* <div className={classes.name_id}>
                  <div className={''}>
                    <div>Receivable Amount</div>
                  </div>
                </div> */}
                <div className={`${classes.amount_status} !items-start`}>
                  <div className={clsx(classes.amount, classes.amountVal)}>
                    {`${invoice_attributes?.invoice_amount_currency_sign}${formatNumberToLocaleString(Number(invoice_attributes?.receiving_amount), 2, 2)}`}
                  </div>
                  <div className={classes.invoiceId}>
                    <div>Receivable Amount</div>
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={clsx(classes.amount, classes.amountVal)}>
                    {`${invoice_attributes?.invoice_amount_currency_sign}${formatNumberToLocaleString(Number(invoice_attributes?.invoice_amount), 2, 2)}`}
                  </div>
                  <div className={classes.invoiceId}>
                    <div>Invoice Amount</div>
                  </div>
                </div>
              </li>
              {
                (savings_details?.saving_amount !== null)
                ?
                  <li className={`${classes.list} ${classes.border_bottom}`}>
                    <div className={clsx(
                      `col-span-2 rounded-xl flex flex-row items-center justify-between gap-3 px-[16px] py-[8px] w-full`,
                      invoiceStatusRed ? 'bg-[#F6DFDF]' : 'bg-[#0C9C7A29]'
                    )}>
                      <div className={clsx(
                        invoiceStatusRed ? 'bg-[#d34646]' : 'bg-[#1DAF8C]',
                        `rounded-full w-fit flex flex-row items-center justify-between gap-2 px-4 py-1`
                      )}>
                        <img src={WalletWhite} alt="wallet" className="w-6 h-6" />{' '}
                        <p className="text-lg font-bold text-center text-[#FFFFFF]">
                          <span className="inline-block">
                            {savings_details?.saving_amount_currency_sign}
                            {savings_details?.saving_amount}
                          </span>
                        </p>
                      </div>
                      <p className={clsx(
                        `text-xl font-normal flex-grow`,
                        invoiceStatusRed ? 'text-[#D34646] ' : 'text-[#1DAF8C]',
                      )}>
                        {
                          invoiceStatusRed ?
                            "Savings lost on this transaction"
                            :
                            "Estimated savings on this transaction"
                        }
                      </p>
                      <img src={InfoIcon} alt="i" className="w-7 h-7" />
                    </div>
                  </li>
                :
                  <li className={`${classes.list} p-[0px] mb-[1.6rem] ${classes.border_bottom}`}></li>
              }
              <li className={`${classes.list} ${classes.border_bottom}`}>
                {/* <div className={clsx('flex flex-col items-start gap-4')}>
                    <h3>Payment Terms</h3>
                  <p
                    className={clsx(
                      'text-xl font-[14px] text-[#1e333f80] col-span-2'
                    )}
                  >
                    { invoice_attributes?.net_terms ? invoice_attributes?.net_terms : "No invoice terms added" }
                  </p>
                </div> */}
                <div className={classes.name_id}>
                  <div className={classes.name}>
                    Payment Terms
                  </div>
                  <div className={classes.invoiceId}>
                    <div>{ invoice_attributes?.net_terms ? invoice_attributes?.net_terms : "No invoice terms added" }</div>
                  </div>
                </div>
              </li>
              <li className={`${classes.list} ${classes.border_bottom}`}>
              <div className={classes.name_id}>
                    <div className={classes.amount}>
                    { invoice_attributes?.bl_date ? dayjs(invoice_attributes?.bl_date).format('DD/MM/YYYY') : "--"}
                    </div>
                    <div className={classes.invoiceId}>
                      <div>BL Date</div>
                    </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.name}>
                    { invoice_attributes?.due_date ? dayjs(invoice_attributes?.due_date).format('DD/MM/YYYY') : "--"}
                  </div>
                  <div className={classes.invoiceId}>
                    <div>Due Date</div>
                  </div>
                </div>
                
              </li>
              {/* <li className={`${classes.list}`}>
                <div className={classes.name_id}>
                  <div className={classes.invoiceCurrency}>
                    <div>{invoice_attributes.invoice_amount_currency}</div>
                  </div>  
                  </div>  
              </li> */}
              
              
            </ul>
          </div>

          {/* {window.innerWidth >= 644 && <div className="flex flex-col items-center gap-0 border-b "> 
              <div className="flex text-left w-full">
                <p className={classes.invoiceAttach}>{`Invoice Attachment (${unlockInvoices?.length})`}</p>
              </div>
              <div className={classes.invoiceCon}>
                {unlockInvoices?.length > 0 ?
                  (
                    <FileViewer
                      format={['document', 'image']}
                      displayUrls={unlockInvoices}
                      downloadPrefix={props?.invoice?.invoice_id}
                      whereCalled="smallScreen"
                      setWhereCalled={setWhereCalled}
                      invoiceData={invoiceunLockData}
                      setInvoicetoBigScreen={setInvoicetoBigScreen}
                      setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                      paymentLinkOtpRequired={paymentLinkOtpRequired}
                      invoicetoBigScreen={invoicetoBigScreen}
                    />
                  ) : 
                  (<>
                    <div className={classes.invoiceBox}>
                      <img src={dummyInvoice} alt="invoice" className={classes.invoiceImg}/>
                    </div>
                    </>)
                }
              </div>
          </div>} */}
          {/* {window.innerWidth >= 644 && <div className="flex flex-col items-center gap-2 border-b"> 
              <div className="flex justify-between items-center w-full">
                <p className={classes.invoiceAttach}>{`Locked Documents (${lockInvoices?.length})`}</p>
                <div className={classes.lockBox}>
                  <p className={classes.lockTextBox}>Unlock all</p>
                  <img src={unlock} alt="invoice" className="w-[16px] h-[16px]"/>
                </div>
              </div>
              <div className={classes.invoiceCon}>
              {lockInvoices?.length > 0 ?
                  (
                    <FileViewer
                      format={['document', 'image']}
                      displayUrls={lockInvoices}
                      downloadPrefix={props?.invoice?.invoice_id} 
                      whereCalled="smallScreen"
                      setWhereCalled={setWhereCalled}
                      invoiceData={invoiceLockData}
                      setInvoicetoBigScreen={setInvoicetoBigScreen}
                      setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                      paymentLinkOtpRequired={paymentLinkOtpRequired}
                      invoicetoBigScreen={invoicetoBigScreen}
                    />
                  ) 
                  : 
                  (<>
                    <div className={classes.invoiceBox}>
                      <img src={dummyInvoice} alt="invoice" className={classes.invoiceImg}/>
                      <img src={lockBlack2} alt="invoice" className={classes.lockBlack2Img}/>
                    </div>
                    </>)
                }
              </div>
          </div>} */}

          {/* <div className="flex flex-col mt-[16px] items-start gap-4"> */}
          {/* <div className={clsx('flex flex-col items-start gap-4', (savings_details?.saving_amount !== null && 'mt-[16px]'))}>
            <h3>Descriptionss</h3>
            <p
              className={clsx(
                'text-xl font-medium text-[#1E333F80] col-span-2'
              )}
            >
              {invoice_attributes?.invoice_description ||
                `Description not available`}
            </p>
          </div> */}
        </div>
        <div
          className="sm:hidden flex flex-col items-start gap-2 pb-[16px] border-t border-[#ECEFF2] w-full -sm:order-2 mt-[16px] "
          style={{
            // borderRadius: '12px',
            // background: 'rgb(255, 255, 255)',
            // boxShadow: 'rgba(76, 87, 125, 0.02) 0px 4px 20px 0px',
            // padding: 16,
          }}
        >
          {/* <p className="text-[rgba(0, 0, 0, 0.88)] text-2xl" style={{fontWeight: 500}}>
            Attachments
          </p> */}
          <div className="flex text-left w-full">
                <p className={classes.invoiceAttach}>{`Invoice Attachment (${unlockInvoices?.length})`}</p>
              </div>
          <div className={classes.docSheetCon}>
            {/* {
              window.innerWidth < 644 &&
              // invoiceunLockData?.length > 0 ?
                <DocumentSheet
                  invoices={paymentLinkOtpRequired ? invoicesLocked : (unlockInvoices?.length ? unlockInvoices : invoicesLocked)}
                  invoiceId={props?.invoice?.invoice_id}
                  customerEmail={customer_attributes?.customer_email}
                  setOtpViewNowBox={setOtpViewNowBox}
                  otpViewNowBox={otpViewNowBox}
                  setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                  paymentLinkOtpRequired={paymentLinkOtpRequired}
                  handleResendOTPView={handleResendOTPView}
                  otpErrorState={otpErrorState}
                  presignedUrlErrorState={presignedUrlErrorState}
                  invoiceData={invoiceData}
                  otp={otp}
                  setOtp={setOtp}
                  handleOTPView={handleOTPView}
                  handleOTPViewNow={handleOTPViewNow}
                  setInvoicetoBigScreen={setInvoicetoBigScreen}
                  invoicetoBigScreen={invoicetoBigScreen}
                /> 
                // : 
                // (<>
                //   <div className={classes.invoiceBox} >
                //     <img src={dummyInvoice} alt="invoice" className={classes.invoiceImg}/>
                //   </div>
                //   </>)

            } */}
          </div>
        </div>
        <div
          className="sm:hidden flex flex-col items-start gap-2  border-t border-[#ECEFF2] w-full -sm:order-2 mt-[0px]"
        >
          <div className="flex text-left w-full">
            <p className={classes.invoiceAttach}>{`Locked Documents (${lockInvoices?.length})`}</p>
              </div>
          <div className={classes.docSheetCon}>
            {/* {
              window.innerWidth < 644 &&
              // invoiceLockData?.length > 0 ? 
                <DocumentSheet
                  invoices={paymentLinkOtpRequired ? invoicesLocked : (lockInvoices?.length ? lockInvoices : invoicesLocked)}
                  invoiceId={props?.invoice?.invoice_id}
                  customerEmail={customer_attributes?.customer_email}
                  setOtpViewNowBox={setOtpViewNowBox}
                  otpViewNowBox={otpViewNowBox}
                  setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                  paymentLinkOtpRequired={paymentLinkOtpRequired}
                  handleResendOTPView={handleResendOTPView}
                  otpErrorState={otpErrorState}
                  presignedUrlErrorState={presignedUrlErrorState}
                  invoiceData={invoiceData}
                  otp={otp}
                  setOtp={setOtp}
                  handleOTPView={handleOTPView}
                  handleOTPViewNow={handleOTPViewNow}
                  setInvoicetoBigScreen={setInvoicetoBigScreen}
                  invoicetoBigScreen={invoicetoBigScreen}
                /> 
                // : 
                // (<>
                //   <div className={classes.invoiceBox}>
                //     <img src={dummyInvoice} alt="invoice" className={classes.invoiceImg}/>
                //     <img src={lockBlack2} alt="invoice" className={classes.lockBlack2Img}/>
                //   </div>
                //   </>)
              
            } */}
          </div>
        </div>
        <div className={clsx('flex flex-col items-start gap-4', (savings_details?.saving_amount !== null && 'mt-[16px]'))}>
            <div className="flex items-center justify-between w-[100%]" >
              <h3>Description</h3>
              <img className="cursor-pointer" src={descOpen ? up : down} alt="img" onClick={()=>setDescOpen(!descOpen)} />
            </div>
           {descOpen? <p
              className={clsx(
                'text-xl font-medium text-[#1E333F80] col-span-2'
              )}
            >
              {invoice_attributes?.invoice_description ||
                `Description not available`}
            </p>
            :
            ""
            }
          </div>
      </div>
    </div>
  )
}

export default InvoiceDetailsTabContent
