import InvoicesTable from './InvoicesTable'
import UseApiCall from '../../../hooks/useApiCall'
import { apiGetReceivablesData } from '../../../services'
import { ReceivablesActions } from '../../../Store/customer-redux'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import NoInvoicesView from './NoInvoicesView'
import { useSelector } from "react-redux";

const InvoicesTabContent = (props) => {
    const [customerInvoiceList, setCustomerInvoiceList] = useState(null)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    // const updateCustomersModalValue = useSelector((state) => state.customer.updateCustomers);
    const [getCustomerInvoices , loadingTime] = UseApiCall(apiGetReceivablesData, (res) => {
        setLoading(false);
        setCustomerInvoiceList(res?.data?.content)
        ReceivablesActions.getReceivabelReports(res?.data?.content)
    })

    const showInvoiceDisplay = useSelector(
        (state) => state.modal.showInvoiceDisplay
    )

    useEffect(() => {
        getCustomerInvoices({ pageNum : page,
            size : 10,
            customerIds:props?.customerId ? props?.customerId : ""})
    }, [page, showInvoiceDisplay])

    const handlePaginate = (page) => {
        setPage(page)
    }

    const tableHeader = [
        // 'created on', 'invoice id', 'amount', 'status',
        {
            id:1,
            title:"created on",
            width: 15,
            minWidth:62.26,
        },
        {
            id:2,
            title:"invoice id",
            width: 19,
            minWidth:100.26,
        },
        {
            id:3,
            title:"amount",
            width: 20,
            minWidth:72.26,
        },
        {
            id:4,
            title:"status",
            width: 20,
            minWidth:152.26,
        },
    ]

    // console.log(customerInvoiceList)
    // console.log(props)

    return (
        <>
            {
                <InvoicesTable
                    tableData={customerInvoiceList}
                    tableHeader={tableHeader}
                    mobileTableData={customerInvoiceList}
                    handlePaginate={handlePaginate}
                    loading = {loadingTime}
                    organizationId = {props.organizationId}
                    // pages={props?.summary?.SETTLED?.count + props?.summary?.OUTSTANDING?.count}
                    pages={props?.summary?.CREATED?.count}
                />
             }
        </>
    )
}
export default InvoicesTabContent
