import classes from "./NonComplianceDetails.module.scss"
import FullModal from "../../Layout/FullModal";
import Close from "../../Layout/Close";
import {modalActions} from "../../Store/modal-redux";
import React, {useState} from "react";
import FileViewer from "./FileViewer/FileViewer";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import chevronLeft from "assets/Icons/chevron-rightNew.svg"
import DatabaseMatchTag from "./DatabaseMatchTag";
import login from "../../Pages/Login";
import {Tooltip} from "antd";
import infoIcon from "../../assets/Icons/infoIcon.svg";
import CustomModal from "Components/atom/CustomModal";
import {showVerificationModal} from "../../utils/layout";

const NonComplianceDetails = (props) => {
    const {detailsData} = props;
    const jsonResponse =detailsData?.verification_data_negative_checks ? JSON.parse(detailsData?.verification_data_negative_checks[0]?.response) : {};
    const jsonResponseString = JSON.stringify(jsonResponse,null,2);
    const dispatch = useDispatch();
    const [modalRef, setModalRef] = useState(null);
    const matchData = {};
    const databases = jsonResponse?.result;
    for(let key in databases){
        if(databases[key].length > 0 && Array.isArray(databases[key])){
           matchData[key] = databases[key].length;
        }
    }

    const [searchKey, setSearchKey] = useState("");

    const handleVerificationClick = () => {

        showVerificationModal(modalRef, {
            action: "doc-verification",
            leadId : detailsData?.verification_data_negative_checks[0].id,
            getLeadDetails : props?.getLeadDetails,
            callId : props?.leadId
        })
    }

    // const scrollToSearchKey = () =>{
    //     const searchText = document.getElementById('searchText');
    //
    //     if (searchText) {
    //         const searchTextContent = searchText.textContent;
    //         const index = searchTextContent.indexOf(searchKey);
    //         console.log(index, searchKey , searchTextContent);
    //         if (index !== -1) {
    //             const newPosition = searchText.offsetTop + searchTextContent.substring(0, index).split('\n').length * 15; // Adjust the factor 20 as per your line height
    //             window.scrollTo({ top: newPosition, behavior: 'smooth' });
    //         }
    //     }
    // }

    return(
        <FullModal>
            <CustomModal ref={setModalRef}/>
            <div className={classes.firstHeader}>
                <div className="flex items-center gap-[20px]">
                    <div
                        className="w-[48px] h-[48px] rounded-[50%] bg-[#eceef0] flex items-center justify-center cursor-pointer"
                        onClick={()=> dispatch(modalActions.setSearchDetails(false))}
                    >
                        <img src={chevronLeft} alt={'back'} className={classes.backImg}/>
                    </div>
                    <div className={classes.firstHeaderLeftCont}>
                        <p className={classes.firstHead}>Non-Compliance checks</p>
                        <div className="text-[12px] leading-[16px] font-[500] flex items-center gap-[5px]">
                            <span className={classes.greyHead}>Lead name :</span> <span>Fideo Tech</span>
                            <Tooltip
                                placement="bottom"
                                color="#060A0D"
                                title={
                                    <div className="flex flex-col gap-[5px]">
                                        <p className={classes.pTag} >Brand name : <span></span> {detailsData?.brand_name}</p>
                                        <p className={classes.pTag} >Website : <span>{detailsData?.website}</span></p>
                                        <p className={classes.pTag} >Business type : {detailsData?.business_type}</p>
                                        <p className={classes.pTag} >Description : {detailsData?.product_description}</p>
                                    </div>
                                }
                            >
                                <img src={infoIcon} alt={"info"} className="w-[14px] h-[14px]"/>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {detailsData?.verification_data_negative_checks[0]?.verificationStatus !== "SUCCESS" && <button
                    className="w-[142.2px] px-[1.6rem] py-[1.2rem] rounded-[1rem] bg-[#181C30] text-white text-[1.6rem] leading-[2.4rem] font-[600]"
                    onClick={handleVerificationClick}
                >
                    Mark as verified
                </button>}
            </div>
            <div className={classes.secondHeader}>
                <p className="text-[12px] leading-[16px] font-[500] text-[#1e333f80]">Appears on</p>
                <div className={classes.tagCont}>
                    {
                        Object.keys(matchData).length > 0 ?
                        Object.keys(matchData).map((key) => {
                            return <DatabaseMatchTag
                                status={key}
                                numbers={matchData[key]}
                                // onClick={() => {
                                //     setSearchKey(key);
                                //     scrollToSearchKey(searchKey)
                                // }}
                            />
                        })
                        :
                        <div className={classes.noMatchTag}>
                            No Match
                        </div>
                    }
                </div>
            </div>
            <div className="mt-[0px] flex gap-[30px] p-[40px] pt-[10px]">

                    <div className={classes.lowerDiv}>
                        <div className={classes.headDetail}>
                            Json Response
                        </div>
                        <pre className={classes.preText} id="searchText">
                        {jsonResponseString}
                    </pre>
                    </div>

                </div>

        </FullModal>
    )
}

export default NonComplianceDetails;