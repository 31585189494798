import classes from "./ExporterReportTable.module.scss"
import clsx from "clsx"
import ReportTableLayout from "../Layout/ReportTableLayout"
import ContactInfo from "../Layout/ContactInfo"
import { formatNumberToLocaleString } from "../hooks/formatNumberToLocaleString"
import React, { useRef, useEffect, useState } from "react"
import PaymentStatusTag from "../Components/atom/PaymentStatusTag"
import { modalActions } from "../Store/modal-redux"
import { useDispatch, useSelector } from "react-redux"
import ExporterProfileInfo from "../Components/atom/ExporterProfileInfo"
import ReactDOM from 'react-dom'
import Shimmer from "../Layout/Shimmer"

const tableHeaderData = [
  {
    header: "Exporter",
    width: 20,
    maxWidth: 170
  },
  {
    header: "Total Receivables",
    width: 20,
    maxWidth: 170
  },
  {
    header: "Pending",
    width: 20,
    maxWidth: 170
  },
  {
    header: "Settled",
    width: 20,
    maxWidth: 170
  },
  {
    header: "In-Progress",
    width: 20,
    maxWidth: 170
  },
  {
    header: "V Bank Balance",
    width: 20,
    maxWidth: 170
  },
  {
    header: "Action",
    width: 10,
    maxWidth: 100
  },
]

const ExporterTable = (props) => {
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);
  const [exporter, setExporter] = useState({});

  const dispatch = useDispatch();
  const profileInfo = useSelector(state => state.modal.exporterProfileInfo);

  const getCurrencySign = (status) => {
    switch (status) {
      case 'ALL':
      case 'USD':
      case 'CAD':
        return '$';
      case 'GBP':
        return '£';
      case 'EUR':
        return '€';
      case 'AED':
        return 'د.';
      case 'AUD':
        return 'AU$';
      case 'CHF':
        return 'CHF';
      case 'CZK':
        return 'Kč';
      case 'DKK':
        return 'kr';
      case 'HKD':
        return 'HK$';
      case 'NOK':
      case 'SEK':
        return 'kr';
      case 'PLN':
        return 'zł';
      case 'SGD':
        return 'S$';
      default:
        return '';
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {
        profileInfo &&
        ReactDOM.createPortal(
          <ExporterProfileInfo
            exporter={exporter}
          />,
          document.getElementById('modal-root')
        )
      }
      <div className={classes.allWrap}>
        <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
          <thead>
            {tableHeaderData.map((header, index) => (
              <th key={`${header}-${index}`} className={classes.cell_header1}
                style={{ width: `${header.width}%`, minWidth: `${header.maxWidth}px`, maxWidth: `${header.maxWidth}px` }}
                valign="top"
              >
                <div className={classes.cell_header2}>
                  <div className={classes.head}>{header.header}</div>
                </div>
              </th>
            ))}
          </thead>
        </div>

        <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef} >
          <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
            <tbody>
              {props?.loading
                ? (
                  [1, 2, 3, 4]?.map((item, index) => (
                    <Shimmer key={index} className="w-[910%] h-[80px] mb-[12px]" />
                  ))
                )
                :
                (
                  props?.tableData?.map((row, index) => (
                    <React.Fragment key={index}>
                      {/* { row?.dashboardData?.map((data,indexOf) => ( */}
                      <tr className={classes.row}>
                        <td className={classes.cell}
                          style={{ width: '20%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          <ContactInfo
                            name={row?.dashboardData[0]?.organizationName ? row?.dashboardData[0]?.organizationName : "--"}
                            tick={row?.dashboardData[0]?.status === "ACTIVE" ? true : false}
                          />
                        </td>
                        <td className={classes.cell}
                          style={{ width: '20%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          {row?.dashboardData?.map((data, indexOf) => (
                            <div className='flex flex-col' style={{ marginTop: indexOf === 0 ? '' : '15px' }} key={indexOf}>
                              <div>
                                {data?.totalAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString(data?.totalAmount, 2, 2) : "--"}
                              </div>
                              <div className="text-[#1e333f80] mt-[4px]">
                                {data?.totalInvoices ? `${data?.totalInvoices} ${data?.totalInvoices > 1 ? "Invoices" : "Invoice"}` : "-"}
                              </div>
                            </div >
                          ))}
                        </td>
                        <td className={classes.cell}
                          style={{ width: '20%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          {row?.dashboardData?.map((data, indexOf) => (
                            <div className='flex flex-col' style={{ marginTop: indexOf === 0 ? '' : '15px' }} key={indexOf}>
                              <div>
                                {data?.outstandingAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.outstandingAmount), 2, 2) : "--"}
                              </div>
                              <div className="text-[#1e333f80] mt-[4px]">
                                {data?.outstandingInvoices ? (data?.outstandingInvoices + (data?.outstandingInvoices > 1 ? " Invoices" : " Invoice")) : "-"}
                              </div>
                            </div>
                          ))}
                        </td>
                        <td className={classes.cell}
                          style={{ width: '20%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          {row?.dashboardData?.map((data, indexOf) => (
                            <div className='flex flex-col' style={{ marginTop: indexOf === 0 ? '' : '15px' }} key={indexOf}>
                              <div>
                                {data?.settledAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.settledAmount), 2, 2) : "--"}
                              </div>
                              <div className="text-[#1e333f80] mt-[4px]">
                                {data?.settledInvoices ? (data?.settledInvoices + (data?.settledInvoices > 1 ? " Invoices" : " Invoice")) : "-"}
                              </div>
                            </div>
                          ))}
                        </td>
                        <td className={classes.cell}
                          style={{ width: '20%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          {row?.dashboardData?.map((data, indexOf) => (
                            <div className='flex flex-col' style={{ marginTop: indexOf === 0 ? '' : '15px' }} key={indexOf}>
                              <div>
                                {data?.inProgressAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.inProgressAmount), 2, 2) : "--"}
                              </div>
                              <div className="text-[#1e333f80] mt-[4px]">
                                {data?.inProgressInvoices ? (data?.inProgressInvoices + (data?.inProgressInvoices > 1 ? " Invoices" : " Invoice")) : "-"}
                              </div>
                            </div>
                          ))}
                        </td>
                        <td className={classes.cell}
                          style={{ width: '20%', minWidth: '170px', maxWidth: '170px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          {row?.bankBalanceData?.filter((data) => data?.balance !== null && data?.balance !== 0)?.length > 0 ? row?.bankBalanceData?.filter((data) => data?.balance !== null && data?.balance !== 0)?.map((data, indexOf) => (
                            <div className='flex flex-col' style={{ marginTop: indexOf === 0 ? '' : '15px' }} key={indexOf}  >
                              <div>
                                {data?.balance !== null ? data?.currency?.sign + formatNumberToLocaleString((data?.balance), 2, 2) : "--"}
                              </div>
                              <div className="text-[#1e333f80] mt-[4px]">
                                {/* {data?.inProgressInvoices ? (data?.inProgressInvoices + (data?.inProgressInvoices > 1 ? " Invoices" : " Invoice")) : "-"} */}
                              </div>
                            </div>
                          )) : '--'}
                        </td>
                        <td className={classes.cell}
                          style={{ width: '10%', minWidth: '100px', maxWidth: '100px', overflowWrap: "anywhere" }}
                          valign="top"
                        >
                          <div className="flex flex-col gap-[4px]" >
                            <button className="p-[4px] text-[#fff] bg-black rounded-[4px] opacity-25 cursor-not-allowed">Details</button>
                            <button
                              className="p-[4px] text-[#fff] bg-black rounded-[4px]"
                              onClick={() => {
                                setExporter(row)
                                dispatch(modalActions.exporterProfileInfo(true))
                              }}
                            >
                              Profile
                            </button>
                          </div>
                        </td>
                      </tr>
                      {/* ))} */}
                    </React.Fragment>
                  ))
                )
              }
            </tbody>
          </ReportTableLayout>
        </div>
      </div>
      {((props?.tableData?.length === 0 || props?.tableData?.length === 'undefined') && !props?.loading) &&
        (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '4rem',
              fontSize: '2.5rem'
            }}
            className={classes.table}
          >
            {props?.isSearch?.length === 0 ? 'No Exporters Yet.' : 'No exporters with this name.'}
          </div>
        )
      }
    </>
  )
}

export default ExporterTable