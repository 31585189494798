// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Action_action__n74ZF {
  position: relative;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
}

.Action_action__n74ZF:hover {
  background-color: #ebeaea;
}

.Action_optionDiv__rXpfr {
  display: flex;
  padding: 2.4rem 2rem;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/Action.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AACJ;;AACA;EACI,yBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,oBAAA;EACA,QAAA;EACA,mBAAA;EACA,2BAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAGJ","sourcesContent":[".action {\n    position: relative;\n    cursor: pointer;\n    width: fit-content;\n    border-radius: 4px;\n}\n.action:hover{\n    background-color:#ebeaea;\n}\n.optionDiv{\n    display: flex;\n    padding: 2.4rem 2rem;\n    gap: 8px;\n    align-items: center;\n    justify-content: flex-start;\n    font-size: 1.6rem;\n    line-height: 2.4rem;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action": `Action_action__n74ZF`,
	"optionDiv": `Action_optionDiv__rXpfr`
};
export default ___CSS_LOADER_EXPORT___;
