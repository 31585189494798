// import ReactDOM from 'react-dom'
import React, { useState, useEffect } from 'react';
import PencilIcon from '../../../assets/Icons/pencil.svg'
import Button from '../../../Layout/Button'
import EditIcon from '../../../assets/Icons/editBlack.svg'
import clsx from "clsx";
// import EditInvoice from "Components/Invoice/EditInvoice";
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'

const DraftInvoiceBanner = (props) => {
  const { invocieId } = props
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dispatch = useDispatch()
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)

  return (
    <>
      {/* {editInvoiceDisplay &&
        ReactDOM.createPortal(
          <EditInvoice
            selectedInvoiceId={invocieId}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
      )} */}
      <div
        style={{
          borderRadius: "12px",
          background: "#F6F8FB",
          boxShadow: "0px 4px 20px 0px rgba(76, 87, 125, 0.02)",
          // paddingBottom: "24px",
          height: windowSize <= 900 ? "100vh" : "100%",
          overflow: "hidden"
        }}
      >
        <p style={{
          color: "#1E333F",
          // font-feature-settings: "'clig' off, 'liga' off",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "20px",
          padding: "24px",
        }}>Payment Status</p>
        <div
          style={{
            position: "relative",
            backgroundColor: "#EAEAEA",
            zIndex: 1,
            borderRadius: "12px",
            paddingLeft: "12px",
            margin: "0 24px",
            height: "100%",
          }}
        >
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>lorem</p>
          <p>dfsg h frgeht  htuy</p>
          <p>lorem ixscpsdcf sdf s dfsg</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>lorem ixscpsdcf sdf s dfsg h frgeht  htuyixscpsdcf sdf s dfsg h frgeht  htuyixscpsdcf sdf s dfsg h frgeht  htuy</p>
          <p>lorem ixscpsdcf sdf s dfsg h frgeht  htuydfsg h frgeht  htuydfsg h frgeht  htuy</p>
          <p>lorem ixscpsdcf sdf s dfsg h frgeht  htuy</p>
          <p>lorem ixscpsdcf sdf s dfsg h dfsg h frgeht  htuy  htuy</p>
          <p>lorem</p>
          <p>lorem</p>
          <p>lorem</p>
          <p>dfsg h frgeht  htuy</p>
          <p>lorem</p>
          <p>lorem</p>
          <p> and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          {
            (windowSize <= 900) &&
            <>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              <p>lorem ixscpsdcf sdf s dfsg h frgeht dfsg h frgeht  htuydfsg h frgeht  htuy htuy</p>          <p>lorem</p>
              <p>lorem ixscpsdcf sdf s dfsg h frgeht  htuy</p>          <p>lorem</p>
              <p>lorem</p>
              <p>lorem</p>
              <p>lorem</p>
              <p>dfsg h frgeht  htuy</p>
              <p>lorem</p>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              <p>lorem ixscpsdcf sdf s dfsg h frgeht  htuy</p>          <p>lorem</p>
              <p>lorem</p>
              <p>lorem</p>
              <p>dfsg h frgeht  htuy</p>
              <p>lorem</p>
              <p>lorem</p>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              <p>lorem ixscpsdcf sdf s dfsg h frgeht  htuy</p>          <p>lorem</p>
            </>
          }
          <div
            style={{
              borderRadius: "12px",
              border: "1px solid #ECEFF2",
              // background: "#FFF",
              // margin: "0 24px",
              position: "absolute",
              top: "0",
              left: "0",
              // width: "100%",
              // height: "100%",
              // background-color: #D2D5DB;
              // border: 1px solid #060a0d1f;
              // padding: 20px
              // margin: "0 24px",
              backdropFilter: "blur(15px)",
              zIndex: 2,
              height: "100%",
            }}
          >
            <div className={clsx(
                (windowSize <= 900) ? `h-[100vh] p-[24px] mb-[24px]` : (windowSize <= 1100 ? `h-[100%]  p-[60px] mb-[40px]` : `h-[100%]  p-[40px] mb-[20px]`),
                `flex flex-col items-center justify-center`
              )}>
              <div className="flex flex-col items-center">
                <div className="w-[88px] h-[88px] flex items-center justify-center rounded-full bg-[#DBFD00]">
                  <img src={PencilIcon} alt="User" className="w-20 h-20" />
                </div>
                <div className="w-[140px] relative flex flex-row justify-center items-center h-[36px] text-[12px] text-[#FFF] -top-[12px] px-[12px] py-1 bg-[#4380A2] rounded-full ">
                  <p className="uppercase">Invoice in Draft</p>
                </div>
              </div>
              <div className="flex flex-col relative rounded-xl items-center">
                <p className="text-[16px] text-center font-inter text-[#181C30]" style={{ fontWeight: "400" }}>
                  Your invoice is in a draft state. To receive payment from this customer, please ensure all required attachments and fields are completed.
                </p>
              </div>
              <Button
                className={clsx(
                  'mt-[12px] w-[145px] h-[48px] bg-[#FFF] rounded-[8px] border border-solid border-[#060a0d1f] p-[12px]',
                  'text-[#1E333F] text-[16px] text-center font-inter font-normal'
                )}
                icon={EditIcon}
                text="Edit Invoice"
                disabled={false}
                btnClassText={clsx("font-medium")}
                onClick={() => {
                    dispatch(modalActions.editInvoice())
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DraftInvoiceBanner
