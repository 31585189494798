import classes from "./LeadVerificationModal.module.scss";
import TextInput from "../../Layout/TextInput";
import {useState} from "react";
import useApiCall from "../../hooks/useApiCall";
import {apiVerifyLead, apiVerifyComplianceDocument} from "../../services";
import {enqueueSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {modalActions} from "../../Store/modal-redux";

const LeadVerificationModal = (props) => {
    const [remarks, setRemarks] = useState("");
    const dispatch  = useDispatch();
    const [verifyLead, verifyLeadLoading] = useApiCall(apiVerifyLead, (res) => {
        window.location.reload();
        enqueueSnackbar(res?.data,{variant:"success"})
    }, (err) => {
        enqueueSnackbar(`${"Error occurred while verifying lead"}`, {variant: 'error'})
    })

    const [verifyDoc,verifyDocLoading] = useApiCall(apiVerifyComplianceDocument,(res) => {
        enqueueSnackbar("document verified successfully",{variant:"success"})
        props?.getLeadDetails({
            leadId:props?.callId
        })
        dispatch(modalActions.setSearchDetails(false))
        props?.closeModal();
    }, (err) => {
        enqueueSnackbar(`${"Error occurred while verifying document"}`, {variant: 'error'})
    })


    const handleClick = () => {
        if(props.action === "verification"){
            verifyLead({
                leadId : props?.leadId,
                status : "SUCCESS",
                remarks: remarks
            })
        }
        else if(props.action === "doc-verification"){
            verifyDoc({
                id: props?.leadId,
                status:"SUCCESS",
                remarks:remarks
            })
        }
        else {
            verifyLead({
                leadId : props?.leadId,
                status : "REJECTED",
                remarks: remarks
            })
        }
    }
    return (
        <>
            <div className={classes.bigCont}>
                <div>
                    <TextInput
                        type="text"
                        text="Add Remarks(Optional)"
                        validation={[]}
                        multiline = {true}
                        inputValidity={() => {
                        }}
                        setInput={(value) => {
                            setRemarks(value)
                        }}
                        enteredInput={remarks}
                    />
                </div>
                <div className={classes.btnCont}>
                    <button
                        className={classes.markBtn}
                        onClick={() => {
                            props?.closeModal()
                        }}>
                        Cancel
                    </button>
                    <button className={classes.markBtn} onClick={handleClick}>
                        {
                            props.action === "verification" || props.action === "doc-verification"
                                ?
                                "Mark as verified"
                                :
                                props?.action === "rejection"
                                    ?
                                    "Mark as rejected"
                                    :
                                    "Add remarks"
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default LeadVerificationModal;