import React, { useEffect, useState, memo, useMemo } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import FileIcon from "assets/Icons/file-uploader.svg";
import BgIcon from "assets/Icons/bg-file-uploader.png";
import UploadIcon from "assets/Icons/upload-cloud.svg";
import AddFileIcon from "assets/Icons/add-file.svg";
import zoomIcon from "assets/Icons/zoomIcon.png";
import { baseUrl } from "constants/Network";
import styles from "./FileBrowser.module.scss";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import {
  apiCreateMediaUploadUrl,
  apiDeleteMedia,
} from "services/invoiceServices";
import { fetchFileFromS3 } from 'services'
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import ZoomButtonsModal from "../FileViewer/ZoomFile";
import { modalActions } from "Store/modal-redux";
import { type } from "@testing-library/user-event/dist/type";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants/Enums';
import { convertBase64 } from "hooks/convertBase64";

const fileFormats = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: '0 auto',
}

const generateRandomId = (randomNumberLength) => {
  let result = ''
  while (result.length <= randomNumberLength) {
    result = `${result}${Math.round(9 * Math.random())}`
  }
  return result
}

const getAllowedFileTypes = (allowedFormats) => {
  const result = []
  allowedFormats?.forEach((types) => {
    result.push(...fileFormats[types])
  })
  return result
}

const MAX_ALLOWED_FILES = 10

const SEARCH_TEXT = "return an object which consists of these keys and their respective values [invoiceId, issueDate, dueDate, blDate, remarks, netTerms, invoiceAmount, receivingAmount, invoiceCurrency, description, customerName]\n customerName is the name of the customer or client or bill to it must be in string if not found retun null. calculate issueDate, dueDate and  blDate must be in this format DD-MM-YYYY or if not found then null. description, remarks and netTerms are text fields and invoiceCurrency is code only eg USD, EUR, GBP, INR\nJust return the object other than that nothing else\nremove json too and don't return any comments in object\n"

const FileBrowser = ({
  txnId,
  validFormats,
  isResponsive = true,
  disabled = false,
  displayUrl = [],
  setFile,
  files,
  hasError: externalHasError,
  errorMessage,
  setOcrResponse,
  setOcrLoading,
  organizationId,
  ...rest
}) => {
  const [selectedFiles, setSelectedFiles] = useState(files || {})
  const dispatch = useDispatch()
  const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)
  const [selectedPreviewFileIdx, setSelectedPreviewFileIdx] = useState(null)
  const [deletedFiles, setDeletedFile] = useState({})
  const hiddenFileInput = React.useRef(null)
  // come to thisss
  const [currentFiles, setCurrentFiles] = useState([]);
  const [saveCurrentFiles, setSaveCurrentFiles] = useState();
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [seconds, setSeconds] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const [deleteMedia] = UseApiCall(apiDeleteMedia)
  const [hasError, setHasError] = useState(externalHasError)
  let count = Object.keys(files || {})?.length;
  let fileValue = "";
  // const doEncryption = true;

  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);


  const totalFiles = useMemo(() => {
    if (!selectedFiles) {
      return [];
    }
  
    return Object.keys(selectedFiles).filter((id) => !deletedFiles[id]);
  }, [selectedFiles, deletedFiles]);

  useEffect(() => {
    setFile(saveCurrentFiles)
  }, [saveCurrentFiles])

  useEffect(() => {
    if (selectedFiles && deletedFiles) {
      const filteredFiles = Object.keys(selectedFiles).filter((file) => {
        return !deletedFiles[file];
      });
      setCurrentFiles(filteredFiles);
      setSelectedPreviewFileIdx(Object.keys(selectedFiles)[0])
    }
  }, [totalFiles]);

  useEffect(() => {
    if (selectedFiles) {
      const newObject = Object.keys(selectedFiles).reduce((result, key) => {
        if (currentFiles.includes(key)) {
          result[key] = selectedFiles[key];
        }
        return result;
      }, {});
  
      setSaveCurrentFiles(newObject);
    }
  }, [selectedFiles, currentFiles]);


  // const onSelectFiles = (files) => {
  //   if (files?.length + totalFiles?.length > MAX_ALLOWED_FILES) {
  //     alert(`Only ${MAX_ALLOWED_FILES} files are allowed at max`)
  //     return
  //   }
  //   if (files?.length > 0) {
  //     const newFiles = {}
  //     ;[...files].forEach((f) => {
  //       const id = generateRandomId(6)
  //       newFiles[id] = Object.assign(f, {
  //         preview: URL.createObjectURL(f),
  //         uploadedAt: Date.now(),
  //         inQueue: true,
  //         isUploading: null,
  //         id,
  //       })
  //     })

  //     setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
  //     uploadFiles(newFiles)
  //   }
  // }

  const onSelectFiles = (files) => {
    const maxSize = 10 * 1024 * 1024;
    const oversizedFiles = files?.filter((file) => file?.size > maxSize);
    const notOversizedFiles = files?.filter((file) => file?.size <= maxSize);

    if (notOversizedFiles?.length > 0) {
      setSeconds(4*notOversizedFiles?.length)
      const newFiles = {}
      ;[...notOversizedFiles].forEach((f) => {
        const id = generateRandomId(6)
        newFiles[id] = Object.assign(f, {
          preview: URL.createObjectURL(f),
          uploadedAt: Date.now(),
          inQueue: true,
          isUploading: null,
          id,
        })
      })

      setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
      uploadFiles(newFiles)
      setUploadingLoader(true)
    } 
    if(oversizedFiles?.length > 0){
      oversizedFiles?.forEach((f, index) => {
        setTimeout(() => {
          enqueueSnackbar(`This File ${f?.path} size exceeds 10MB:  ${(f?.size / (1024 * 1024))?.toFixed(4)} MB`, {
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
            autoHideDuration: 3000,
          });
        }, index * 3000);
      });
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      if(acceptedFiles?.length > 0){
        onSelectFiles(acceptedFiles);
        fileValue = acceptedFiles;
      }
    },
    disabled: disabled,
  });
  
  

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  const uploadFiles = async (newFiles) => {

    const sortedFiles = Object.values(newFiles).sort(
      (a, b) => a.uploadedAt - b.uploadedAt
    )
    const fileIds = sortedFiles.map((a) => a.id)
    if (!selectedPreviewFileIdx) {
      setSelectedPreviewFileIdx(fileIds[0])
    }
    for (let i = 0; i < fileIds.length; i++) {
      if (deletedFiles[fileIds[i]]) {
        continue
      }
      const _file = newFiles[fileIds[i]]
      try {
        _file.isUploading = true
        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))

        // File upload process started
        const arrayBuffer = await _file.arrayBuffer()
        const blob = new Blob([arrayBuffer], {
          type: _file.type,
        })

        let fileName = encodeURIComponent(_file?.name);
        const apiResponse = await apiCreateMediaUploadUrl({
          txnId,
          fileName: fileName,
          organizationId,
        })

        try {
          setOcrLoading(prevState => prevState + 1);

          if (fileValue[0]?.type.startsWith('image/')) {
              console.log('Image file detected');
              const BASE64_STRING = await convertBase64(fileValue[0]);
              const data = {
                "model": "gpt-4-vision-preview",
                "messages": [
                  {
                    "role": "user",
                    "content": [
                      {
                        "type": "text",
                        "text": `${SEARCH_TEXT}`
                      },
                      {
                        "type": "image_url",
                        "image_url": {
                          "url": `${BASE64_STRING}`,
                          // "url": `data:image/jpeg;base64,${BASE64_STRING}`,
                           "detail": "high"
                        }
                      }
                    ]
                  }
                ],
                "max_tokens": 3000
            };
            
            // await fetch(baseUrl.chatgpt, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': process.env.REACT_APP_CHATGPT_KEY
            //     },
            //     body: JSON.stringify(data)
            // })
            await fetch(baseUrl.api + `/proxy?docType=IMAGE&transactionId=${txnId}`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(data)
            })
            .then(async (response) => {
              let responseData;
              if (doEncryption) {
                responseData = await response.text();
              } else {
                responseData = await response.json();
              }
              let res = doEncryption ? handleDecrypt(responseData) : responseData;
              return res;
            })
            .then(res => {
                // setOcrResponse([JSON.parse(data?.choices[0]?.message?.content)]);
                setOcrResponse([JSON.parse(res?.data)]);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
              setOcrLoading(prevState => prevState + 1);
            })
          } else {
              const formData = new FormData();
              formData.append('file', fileValue[0]);
              // formData.append('organization_id', 8);
              
              const analyzeValues = await fetch(baseUrl.api + `/receivable/invoice`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
              });

              let response;
              if (doEncryption) {
                response = await analyzeValues.text();
              } else {
                response = await analyzeValues.json();
              }

              console.log(response, "text_response");
              let res = doEncryption ? handleDecrypt(response) : response;
              if (doEncryption) {
                response = await res?.data;
              } else {
                response = await response?.data;
              }
    
              // const requestData = {
              //   model: "gpt-4-turbo-preview",
              //   messages: [
              //     {
              //       role: "user",
              //       content: `\n ${response}\n\n ${SEARCH_TEXT}`
              //     }
              //   ],
              //   temperature: 1,
              //   max_tokens: 1200,
              //   top_p: 1,
              //   frequency_penalty: 0,
              //   presence_penalty: 0
              // };

              // fetch(baseUrl.chatgpt, {
              //   method: 'POST',
              //   headers: {
              //     'Content-Type': 'application/json',
              //     'Authorization': process.env.REACT_APP_CHATGPT_KEY,
              //   },
              //   body: JSON.stringify(requestData)
              // })
              fetch(baseUrl.api + `/proxy?docType=PDF&transactionId=${txnId}`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(response)
              })
              .then(async (response) => {
                let responseData;
                if (doEncryption) {
                  responseData = await response.text();
                } else {
                  responseData = await response.json();
                }
                let res = doEncryption ? handleDecrypt(responseData) : responseData;
                return res;
              })
              .then(res => {
                setOcrResponse([JSON.parse(res?.data)]);
              })
              .catch(error => {
                console.error('Error:', error);
              })
              .finally(() => {
                setOcrLoading(prevState => prevState + 1);
              })
          }
        
        } catch (error) {
          console.log(error)
          setOcrLoading(prevState => prevState + 1);
        }
        
        let urlData;
        if (doEncryption) {
          urlData = await handleDecrypt(apiResponse?.data);
        } else {
          urlData = await apiResponse?.data;
        }
        await fetch(urlData?.data?.url, {
          method: 'PUT',
          body: blob,
          headers: {
            'Content-Type': _file.type,
          },
        })
        if (deletedFiles[_file.id]) {
        }
        // File uploaded
        _file.inQueue = false
        _file.isUploading = false
        _file.invoiceLinkId = urlData.data.invoiceLinkId

        setTimeout(() => {
          if (seconds === 0) {
            setUploadingLoader(false)
            setSeconds(0)
          }
        }, [2000])

        setSeconds(prev => prev - 4)

        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))
      } catch (e) {
        handleRemoveFile(_file)
      }
    }
  }

  const onUploadClick = (event) => {
    if (disabled) return
    hiddenFileInput.current.click()
  }

  const handleFileUploadChange = (event) => {
    event.preventDefault();
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    fileValue = filesArray;
    onSelectFiles(filesArray)
  }

  const handleRemoveFile = async (file) => {
    const currentFiles = Object.values(selectedFiles)
      .filter((f) => !deletedFiles[f.id])
      .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)

    const delFileIdx = currentFiles.findIndex((f) => f.id === file.id)

    if (file.id === selectedPreviewFileIdx) {
      if (delFileIdx < currentFiles.length - 1) {
        const _id = currentFiles[delFileIdx + 1].id
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      } else {
        const _id =
          currentFiles.length > 1
            ? currentFiles[currentFiles.length - 2].id
            : null
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      }
    } else if (selectedPreviewFileIdx && deletedFiles[selectedPreviewFileIdx]) {
      const _id =
        currentFiles.length > 1
          ? currentFiles[currentFiles.length - 2].id
          : null
      setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
    }
    if (file.invoiceLinkId) {
      deleteMedia({ txnId, invoiceLinkId: file.invoiceLinkId, organizationId })
    }
    setDeletedFile((prev) => ({ ...prev, [file.id]: file.id }))
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const openZoomModal = () => {
    dispatch(modalActions.showFileDisplay())
};

  return (
    <div className={styles.container}>
        {showFileDisplay && selectedFiles[selectedPreviewFileIdx] &&
            ReactDOM.createPortal(
              <ZoomButtonsModal
                fileUrl={
                  {
                    type : selectedFiles[selectedPreviewFileIdx].type,
                    url : selectedFiles[selectedPreviewFileIdx].preview
                  }
                }
              />
              ,document.getElementById("modal-root")
          )
          }
      <div className="w-full" style={{
        border: (window.innerWidth > 640 && hasError) ? "2px solid #FF6868" : "inherit",
        borderRadius: hasError ? "0.8rem" : "inherit",
      }}>
        <div
          {...getRootProps({
            className: clsx(
              styles.dropContainer,
              'dropzone relative',
              isResponsive && '-sm:hidden'
            ),
          })}
          style={{
            backgroundImage: `url(${BgIcon})`,
            backgroundRepeat: 'repeat',
            backgroundColor: isDragActive
              ? 'rgba(194, 243, 194, 0.3)'
              : 'transparent',
            border: isDragActive
              ? '1px dashed rgb(63, 74, 63)'
              : '1px dashed #d2d6d9',
          }}
        >
          {selectedFiles[selectedPreviewFileIdx] && 
            (<p 
              className="absolute top-5 right-5 z-50 w-[34px] h-[34px] bg-[#fff] flex items-center justify-center rounded-lg " 
              onClick={(e) => {
                e.stopPropagation()
                openZoomModal()
              }}
            >
              <img src={zoomIcon} className="w-[24px] h-[24px]"/>
            </p>)
          }
          {!disabled && (
            <div
              className={clsx(styles.dropMessage, 'flex flex-col items-center')}
            >
              <img src={FileIcon} alt="file" className="w-28 h-28" />
              <p className="text-[16px] text-[#1E333F] font-normal mt-5">
                {isDragActive ? (
                  <>
                    <b className="font-semibold">Drag</b> files here to upload{' '}
                  </>
                ) : (
                  <>
                    <b className="font-semibold">Click</b> to upload or drag and
                    drop
                  </>
                )}
              </p>
              {getAllowedFileTypes(validFormats)?.length > 0 && (
                <p className={styles.fileFormat}>
                  {getAllowedFileTypes(validFormats)?.map((type, index) => {
                    return `${type.split('/')[1].toUpperCase()}${
                      !(index + 1 === getAllowedFileTypes(validFormats)?.length)
                        ? `, `
                        : ''
                    }`
                  })}{' '}
                  (max 10mb)
                </p>
              )}
            </div>
          )}
          <input {...getInputProps()} />
          {selectedFiles[selectedPreviewFileIdx] &&
            !deletedFiles[selectedPreviewFileIdx] && (
              <div
                className={clsx(
                  styles.previewContainer, 
                  isDragActive ? 'opacity-10' : ''
                )}
              >
                {fileFormats.document.includes(
                  selectedFiles[selectedPreviewFileIdx].type 
                ) && (
                  <Document
                    file={selectedFiles[selectedPreviewFileIdx].preview}
                    className={clsx(styles.documentFull)}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={""}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page 
                      key={`page_${index + 1}`} 
                      pageNumber={index + 1} 
                      className={clsx('w-full h-full', styles.pdfViewerSmall)}
                      style={{
                        minWidth: '100% !important',
                        minHeight: '100% !important',
                      }}

                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      loading={""}
                      />
                    ))}
                  </Document>
                )}
                {fileFormats.image.includes(
                  selectedFiles[selectedPreviewFileIdx].type
                ) && (
                  <img
                    alt={selectedFiles[selectedPreviewFileIdx].name}
                    src={selectedFiles[selectedPreviewFileIdx].preview}
                    style={img}
                  />
                )}
              </div>
            )}
        </div>
        <div
          className={clsx(
            styles.thumbnailContainer,
            isResponsive
              ? 'border-0 border-t-0 sm:border p-0 sm:p-5'
              : 'border border-t-0 p-5',
            'gap-4 rounded-[8px] rounded-t-none  bg-transparent border-dashed border-[#D2D6D9] w-full'
          )}
        >
          {totalFiles?.length > 0 &&
            Object.values(selectedFiles)
              .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)
              .map((file,index) =>
                deletedFiles[file.id] ? null : (
                  <div
                    key={file.id}
                    className={clsx(
                      styles.fileThumbnail,
                      'aspect-square gap-2 rounded-xl border-solid border items-center py-2 px-2',
                      selectedPreviewFileIdx === file.id
                        ? 'border-[#1E333F]'
                        : 'border-[#060A0D33]',
                      file.inQueue ? 'opacity-40' : ''
                    )}
                    onClick={() => {
                      setSelectedPreviewFileIdx(file.id)
                      // setZoomIndex(index)
                      // localStorage.setItem('zoomkaindex', index.toString())
                      // dispatch(modalActions.showFileDisplay(false))
                    }}
                  >
                    {fileFormats.document.includes(file.type) && (
                      <Document
                        file={file.preview}
                        className={clsx('w-[50px] h-[64px]', styles.documentFullSmall)}
                        // onLoadSuccess={handlePdfLoad}
                        loading={""}
                      >
                        <Page 
                          pageNumber={1}
                          className={clsx('w-[50px] h-[64px]', styles.pdfViewerSmallThumb)}
                          style={{
                            minWidth: '100% !important',
                            minHeight: '100% !important',
                          }}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          loading={""}
                        />
                      </Document>
                    )}
                    {fileFormats.image.includes(file.type) && (
                      <img alt={file.name} src={file.preview} style={img} />
                    )}
                    {!file.inQueue && !disabled && (
                      <div
                        className={clsx(styles.closeIcon)}
                        onClick={() => handleRemoveFile(file)}
                      >
                        x
                      </div>
                    )}
                    <div
                      className={clsx(
                        styles.progressBar,
                        file.isUploading === false
                          ? styles.progressBar100
                          : file.isUploading
                          ? styles.progressBar20 
                          : ''
                      )}
                    ></div>
                  </div>
                )
              )}
          {!disabled && (
            <div
              onClick={onUploadClick}
              className={clsx(
                `aspect-square flex item-center justify-center flex-col gap-2 cursor-pointer rounded-xl items-center py-4 px-8`,
                hasError ? 'border-solid border-[2px] border-[#FF6868]' : 'border-solid border border-[#060A0D33]'
              )}
            >
              <img
                src={totalFiles?.length > 0 ? AddFileIcon : UploadIcon}
                alt="+"
                className="w-8 h-8"
              />
              <p className="text-lg">
                {totalFiles?.length > 0 ? 'More' : 'Upload'}
              </p>
            </div>
          )}
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileUploadChange}
          multiple
          accept={getAllowedFileTypes(validFormats).join(', ')}
          style={{ display: 'none' }}
        />
      </div>
      {/* code to check if error add message */}
      {hasError && <div className={styles.error} style={{ width: "100%"}}>{errorMessage}</div>}
    </div>
  )
}

export default memo(FileBrowser);
