import classes from './Login.module.scss';
import TextInput from '../Layout/TextInput';
import {
    createSearchParams,
    Form,
    Link,
    redirect,
    useActionData,
    useNavigate,
    useNavigation,
    useSearchParams,
    useSubmit
} from 'react-router-dom';
import Button from '../Layout/Button';
import {useEffect, useState, useRef} from 'react';
import PasswordInput from '../Layout/PasswordInput';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {LoginActions, AuthActions} from '../Store/auth-redux';
import UseApiCall from '../hooks/useApiCall';
import {apiLogin} from '../services';
import clsx from 'clsx';
import fideoLogo from "../assets/Images/FideoLogo.svg"
import { useSnackbar } from 'notistack';
import {baseUrl} from "../constants/Network";
import { jwtDecode } from 'jwt-decode';
const emailValidation = [
    value => value.trim() !== '',
    value => /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
]

const Login = (props) => {

    const submit = useSubmit();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const [wrongInput, setWrongInput] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [searchParam, setSearchParam] = useSearchParams();

    // const [submitLoginForm, submitting] = UseApiCall(apiLogin, (data) => console.log(data))

    let loginType = searchParam.get('mode');

    // map to state
    const emailInput = useSelector(state => state.login.emailInput);
    const isEmailValid = useSelector(state => state.login.isEmailValid);
    const passwordInput = useSelector(state => state.login.passwordInput);
    const isPasswordValid = useSelector(state => state.login.isPasswordValid);
    const isMobileValid = useSelector(state => state.login.isMobileValid);
    const mobileNumberInput = useSelector(state => state.login.mobileNumberInput);
    const mobileCodeInput = useSelector(state => state.login.mobileCodeInput);
    const showBackendError = useSelector(state => state.auth.showBackendError, shallowEqual);
    const focusRef = useRef(null);
    const noneRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleFocus = () =>{
        if(focusRef.current) {
            // setInvoiceError(false)
            focusRef.current.focus()
        }
    }

    let data = useActionData();
    useEffect(() => {
        if (data !== undefined) {
            dispatch(AuthActions.setShowBackendError(true));
        } else dispatch(AuthActions.setShowBackendError(false));
    }, [data])

    useEffect(() => {
        if (localStorage.getItem('error')) {
            dispatch(AuthActions.setShowBackendError(true));
        } else dispatch(AuthActions.setShowBackendError(false));
    }, [localStorage.getItem('error')])

    let invalidForm = !(isEmailValid && isPasswordValid);
    const isSubmitting = navigation.state === "submitting";

    const formSubmitHandler = (event) => {
        event.preventDefault();
        setClicked(true);
        dispatch(AuthActions.setShowBackendError(false));

        if (invalidForm) {
            // handleInputError()
            handleFocus()
            setWrongInput(true);
            return;
        }
        setWrongInput(false);
        submit({
            email: emailInput,
            password: passwordInput
        }, {method: "POST"});

        setWrongInput(true);
    }


    // const [getLoginData, fetchingLoginData, Logindata, Loginerror] = UseApiCall(apiLogin,
    //     (res) => {
    //         props?.setIsLogin(true);
    //         // localStorage.removeItem("error")
    //         localStorage.setItem('username', res?.data?.username || '');
    //         // localStorage.setItem('cookie', res?.data?.httpHeaders?.['Set-Cookie']?.[0] || '');
    //         dispatch(AuthActions.setIsAuthenticated(true));
    //         navigate("/dashboard");
    //     }, (err) => {
    //         console.log('err',err)
    //         dispatch(AuthActions.setIsAuthenticated(false));
    //         // localStorage.setItem("error", Math.floor(100000 + Math.random() * 900000))
    //     });

    return (
        <div className={`${classes.login} ${props.className}`}>
            <div className={`${classes.adminHeader} ${props.className}`}>
                <div className={classes.fideoLogo}>
                    <img src={fideoLogo} alt="fideo-logo" />
                </div>
            </div>
            <div className={classes.ss}>
                <div className={classes.rightCont}>
                    <div className="w-[374.4px] min-w-[374.4px] -sm:w-[100%] -sm:min-w-[100%]">
                        <div className={clsx(classes.header)}>Sign in to your account</div>
                        <Form className={classes.form} onSubmit={formSubmitHandler}>

                            <TextInput
                                type='text'
                                text='Email Address'
                                className={classes.input}
                                errorMessage='Please enter a valid email address'
                                validation={emailValidation}
                                inputValidity={value => dispatch(LoginActions.setEmailValidity(value))}
                                setInput={(value) => {
                                    setClicked(true)
                                    dispatch(LoginActions.setEmailInput(value))
                                }}
                                enteredInput={emailInput}
                                hasError={wrongInput && !isEmailValid}
                                maxLength={128}
                                name="email"
                                initiateFocus = {!isEmailValid}
                                focusRef = {focusRef}
                                noneRef = {noneRef}
                            />
                            <PasswordInput
                                className={classes.password}
                                text={'Password'}
                                inputValidity={value => dispatch(LoginActions.setPasswordValidity(value))}
                                setInput={value => {
                                    setClicked(true)
                                    dispatch(LoginActions.setPasswordInput(value))
                                }}
                                errorMessage='Please enter a valid password'
                                isValid={true}
                                validation={[value => value !== '']}
                                enteredInput={passwordInput}
                                hasError={wrongInput && !isPasswordValid}
                                maxLength={30}
                                name="password"
                                initiateFocus = {(isEmailValid) && !isPasswordValid}
                                focusRef = {focusRef}
                                noneRef = {noneRef}
                            />
                            {/* <Link to={`/forgot-password${isEmailValid? `?email=${emailInput}`: ''}`} className={`${classes.link} ${classes.forget_password}`}>Forgot
                        Password?</Link> */}
                            <div></div>

                            <Button
                                text={isSubmitting ? 'Signing in...' : 'Sign in'}
                                disabled={isSubmitting}
                                className={`
                            ${classes.btn}
                            ${(isSubmitting || (clicked && (!isPasswordValid || !isEmailValid))) ? classes.disabled : classes.abled}
                        `}
                            />
                        </Form>
                        {/* <div className={classes.footer}>Don’t have an account? <Link to='/sign-up' className={classes.link}>Sign
                    up</Link></div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;

export async function action({request, params}) {
    const data = await request.formData();

    const loginData = {
        email: data.get("email"),
        password : data.get("password"),
    }

    const response = await fetch(baseUrl.api + `/auth/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
        credentials: "include"

    });

    if (!response.ok) {
        localStorage.setItem("error", Math.floor(100000 + Math.random() * 900000))
        const res =  response;
        return res;
    }
    else{
        localStorage.removeItem("error");
        const responseData = await response.json();
        localStorage.setItem('username', responseData?.data?.username || '');
        localStorage.setItem('userid', responseData?.data?.userId || '');
        const cookieHeader = responseData?.data?.httpHeaders['Set-Cookie'][0];
        const cookieValue = cookieHeader.split('=')[1];
        const token = cookieValue.split(';')[0];
        const decodedToken = jwtDecode(token);
        localStorage.setItem('exp', decodedToken.exp || '');
        localStorage.setItem('isRefresh', true);
    }

        return redirect("/dashboard");
}