import PaymentDetails from "../../../Components/Payment/PaymentDetails";
import classes from "./PaymentStatus.module.scss";
import { useEffect, useState } from "react";
import DraftInvoiceBanner from '../../../Components/atom/DraftInvoiceBanner/DraftInvoiceBanner';

const PaymentStatus = (props) => {
    const [smallestIndex, setSmallestIndex] = useState(10);
    const targetValues = ["INVOICE_CANCELLED", "INVOICE_SETTLED_OUTSIDE_FIDEO"];
    const { recent_transaction_response, invoice_status, invoiceId } = props;
    
    const  transactionData = {
        transactions_detail : {...recent_transaction_response},
        status: invoice_status.split("_").join(" ")
    };
    const showHeader = invoice_status !== 'IN_REVIEW';

    useEffect(() => {
      let indexValue = true;
      transactionData?.transactions_detail?.transaction_info_list?.forEach((item, index) => {
        if (indexValue && targetValues.includes(item?.transaction_action) && item?.is_processed) {
          indexValue = false;
          // setSmallestIndex(() => index);
        }
      });
  
      if (indexValue) {
        // setSmallestIndex(transactionData?.transactions_detail?.transaction_info_list?.length);
      }
    }, [])

    return (
        <div className={classes.payment_status_all}>
          {/* {
            invoice_status === "INVOICE_IN_DRAFT" ?
            <DraftInvoiceBanner
              invoiceId={invoiceId}
            />
            : */}
            <PaymentDetails
              className={classes.payment_status}
              transactionData={transactionData}
              // showHeader = {showHeader}
              invoice_id={invoiceId}
              organizationId={props.organizationId}
              smallestIndex={smallestIndex}
            />
          {/* } */}
        </div>
    )
}
export default PaymentStatus;