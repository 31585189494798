import { useDispatch } from "react-redux";
import Button from "../../../Layout/Button";
import MailIcon from "../../../assets/Icons/mail-white.svg";
import FileIcon from "../../../assets/Icons/file-white.svg";
import CopyIcon from '../../../assets/Icons/copy-black.svg'
import {
  apiSendPaymentLink,
  // apiGetReceivables
} from '../../../services'
// import { ReceivablesActions } from '../../../Store/customer-redux'
import UseApiCall from '../../../hooks/useApiCall'
import { useSnackbar } from 'notistack'
// import dayjs from 'dayjs'
import clsx from 'clsx'

const SendPaymentLinkPrompt = (props) => {
  const { invoiceId, paymentLink, organizationId } = props
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  // const [getReceivables, fetching] = UseApiCall(apiGetReceivables, (res) => {
  //   dispatch(ReceivablesActions.getReceivabelReports(res?.data.invoice_list))
  // })

  const [sendPaymentLink, sendingLink] = UseApiCall(apiSendPaymentLink, () => {
    enqueueSnackbar('Payment link sent Successfully', { variant: 'success' })
    if (props.closeModal) {
      props.closeModal()
      // getReceivables({
      //   page: 0,
      //   status: 'DEFAULT',
      //   fromDate: '2023-01-01',
      //   toDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      // })
    }
  })

  const copyTextToClipboard = (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
  }

  const handleClick = () => {
    sendPaymentLink({ invoiceId, paymentLink: encodeURIComponent(paymentLink), organizationId: organizationId })
  }
  const handleCopy = () => {
    try {
      copyTextToClipboard(paymentLink)
      enqueueSnackbar('Payment link copied!', { variant: 'success' })
    } catch (error) {}
  }
  return (
    <div className="w-[90vw] max-w-[500px] p-10 sm:p-[40px] h-auto flex flex-col items-center justify-center">
      <div
        className="rounded-full w-[72px] h-[72px] flex items-center justify-center"
        style={{
          background: 'linear-gradient(135deg, #18CD8C 0%, #75D6FF 100%)',
        }}
      >
        <img src={FileIcon} alt="file" className="w-[24px] h-[24px]" />
      </div>
      <p className="text-[12px] relative -top-4 text-[#FFFFFF] bg-[#1E333F] px-4 py-2 rounded-full text-center mb-5 font-semibold">
        {invoiceId ? `#${invoiceId}` : ''}
      </p>

      <p className="text-[#1E333F] font-bold text-[16px]">
        {' '}
        Payment Link successfully created
      </p>
      <p className="text-[14px] font-medium text-center mt-1 text-[#1E333F80]">
        You can now send link to the customer to collect payments.
      </p>
      <div className="w-full grid grid-cols-3 gap-6 mt-10">
        <Button
          className={clsx(
            'w-full col-span-2',
            sendingLink && 'cursor-not-allowed',
            sendingLink ? 'text-[#ffffff80]' : 'text-[#ffffff]',
          )}
          icon={MailIcon}
          text="Send Via Mail"
          disabled={sendingLink}
          onClick={handleClick}
        />
        <Button
          className="w-full bg-white border border-solid text-black border-[#181C3033]"
          icon={CopyIcon}
          text={window.innerWidth > 640 ? 'Copy Link' : ''}
          disabled={false}
          onClick={handleCopy}
        />
      </div>
    </div>
  )
}
export default SendPaymentLinkPrompt;
