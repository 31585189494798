import Action from '../../ReportTable/Action'
import ContactInfo from '../../../Layout/ContactInfo'
import classes from './MobileInvoiceTable.module.scss'
import ReportTableLayout from '../../../Layout/ReportTableLayout'
import PaymentStatusTag from '../../../ReportTable/PaymentStatusTag'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { modalActions } from '../../../Store/modal-redux'
// import { apiGetInvoiceDetails } from 'services'
// import UseApiCall from 'hooks/useApiCall'
import { CustomersActions, ReceivablesActions } from '../../../Store/customer-redux'
import { formatNumberToLocaleString } from '../../../hooks/formatNumberToLocaleString';

const MobileInvoiceTable = (props) => {
    const dispatch = useDispatch();
    // const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
    //     dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
    //     dispatch(CustomersActions.setSelectedInvoice(res?.data))
    //     dispatch(CustomersActions.setActiveInvoiceTab("details"))
    //     dispatch(modalActions.showInvoiceDisplay())
    // })

    const handleInvoiceSelection = async (invoiceId) => {
        // await getSelectedInvoice({ invoiceId })
    }
    if (props.tableData?.length > 0)
        return (
            <ReportTableLayout className={`${classes.table} ${props.className}`}>
                {props.tableData.map((data, index) => {
                    const {
                        amount,
                        status,
                        customer,
                        invoiceId,
                        invoiceAmount,
                        invoiceCurrency,
                    } = data
                    const { emailAddress, customerName, country } = customer || {}
                    return (
                        <div key={`${data.email}-${index}`} className={classes.row}>
                            <div className={classes.infoOne}>
                                <div className={classes.invoice} onClick={() => {
                                    handleInvoiceSelection(invoiceId)
                                }}>{`#${invoiceId}`}</div>
                                <Action option={props.option} className={classes.action} cellData={data}/>
                            </div>
                            <div className={classes.infoTwo}>
                                <ContactInfo
                                    name={customerName}
                                    country={country}
                                    nameCountryClass={classes.name_country}
                                    email={emailAddress}
                                    emailClass={classes.email}
                                    className={classes.contact}
                                />
                                <div className={classes.infoThree}>
                                    <div className={clsx(classes.amount, 'mb-1 -sm:mb-2')}>
                                        {invoiceCurrency?.sign}
                                        {formatNumberToLocaleString((invoiceAmount), 2, 2)}
                                    </div>
                                    {status && <PaymentStatusTag status={status} type="light" />}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </ReportTableLayout>
        )
    return (
        <div
            style={{
                textAlign: 'center',
                width: '100%',
                marginTop: '4rem',
            }}
            className={classes.table}
        >
            No Recent Invoice Yet!
        </div>
    )
}

export default MobileInvoiceTable
