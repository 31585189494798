import React, {useState} from "react";
import ReactDOM from "react-dom";
import Backdrop from "../../Layout/Backdrop";
import {modalActions} from "../../Store/modal-redux";
import classes from "./BankDetails.module.scss";
import {useDispatch} from "react-redux";
import Modal from "../../Layout/Modal"
import clsx from "clsx";
import Close from "../../Layout/Close";
import {enqueueSnackbar} from "notistack";
import Button from 'Layout/Button'
import CopyIcon from "assets/Icons/copy-black.svg"
import {baseUrl} from "../../constants/Network";

const BankDetails = (props) => {
    const dispatch = useDispatch();

    const handleVbanDownload = async (currency) => {
        const response = await fetch(baseUrl.api + `/download/vbank-details?currency=${props?.currency}&organizationId=${props?.organizationId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
            },
            credentials: "include",
        }).then(async function (t) {
            let filename = t.headers.get('Content-Disposition')?.split(';')?.find(n => n.includes('filename='))?.replace('filename=', '')?.trim();
            const b = await t.blob();
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            if (!filename) {
                filename = props?.currencyDetails?.LocalMethods[0]?.accountHolderName || props?.currencyDetails?.InternationalMethods[0]?.accountHolderName;
                filename = `${filename?.replace(' ', '_')}_${props?.currency}_vban_details`
            }
            a.setAttribute("download", filename);
            a.click();
        });

        // await downloadVban({ currency })
    }

    const handleCopy = (method) => {
        try {
            const text = `Beneficiary : ${
                method.accountHolderName
            }\nBeneficiary address: ${method.bankAddress}\nAccount Number : ${
                method.accountNumber
            }\nAccount Type : ${method.accountType}\n${
                method?.routingCodeType &&
                `${method.routingCodeType}: ${method.routingCodeType === "BIC" ?method.methodRoutingNumber?.SWIFT : method.methodRoutingNumber?.ACH || method?.methodRoutingNumber?.SEPA_INSTANT || method?.methodRoutingNumber?.CHAPS || method?.methodRoutingNumber?.FAST ||method?.methodRoutingNumber?.ECG ||method?.methodRoutingNumber?.EFT}`
            }\nBank Name: ${method.bankName}\nBank Address: ${method.bankAddress}`
            if ('clipboard' in navigator) {
                navigator.clipboard.writeText(text)
            }
            document.execCommand('copy', true, text)
            enqueueSnackbar('Details Copied!', { variant: 'success' })
            return
        } catch (error) {}
    }

    return(
        <Modal
            className={clsx(
                classes.modal,
                '!p-0 w-[600px] !z-[60] !bg-[#F4F6FA] bottom-0 !pb-[0px]'
            )}
        >
            {
                ReactDOM.createPortal(
                    <Backdrop
                        onClick={() => {
                            dispatch(modalActions.showBankDetails(false))
                        }}
                        className={classes.backdrop}
                    />,
                    document.getElementById('backdrop-root'))
            }
            <div className = "flex px-[36px] py-[20px] items-center justify-between">
                <div className = "flex flex-col gap-[4px] text-[2.25rem] leading-[2.5rem] font-[700]">
                    Virtual Bank Details
                    <div className= "text-[14px] font-[600] text-[#1E333F80]">Exporter : {props?.exporterName}</div>
                </div>
                <Close onClick = {()=>{dispatch(modalActions.showBankDetails(false))}} />
            </div>
            <div className="px-[36px]">
                {props?.currencyDetails?.LocalMethods && <div className="bg-[#FFFFFF] px-8 py-8 rounded-[12px]">
                    <div className="text-[1.875rem] leading-[2.25rem] font-[700] py-[9px] border-b-[1px]">Payment Method : {props?.currencyDetails?.LocalMethods[0]?.paymentMethod}</div>
                    <div className="flex flex-col gap-[16px] py-[9px]">
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Beneficiary name</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.accountHolderName}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Beneficiary bank country</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.beneficaryCountry}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Beneficiary address</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.beneficiaryAddress}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Account Number</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.accountNumber}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Account type</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.accountType}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Bank name</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.bankName}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Bank address</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.bankAddress}</p>
                        </div>
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.ACH &&<div className={classes.infoBox}>
                            <p className={classes.head}>ACH routing number</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.ACH}</p>
                        </div>}
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.FEDWIRE &&<div className={classes.infoBox}>
                            <p className={classes.head}>Fedwire routing number</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.FEDWIRE}</p>
                        </div>}
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.SEPA_INSTANT &&<div className={classes.infoBox}>
                            <p className={classes.head}>BIC</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.SEPA_INSTANT}</p>
                        </div>}
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.CHAPS &&<div className={classes.infoBox}>
                            <p className={classes.head}>Sort Code</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.CHAPS}</p>
                        </div>}
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.FAST &&<div className={classes.infoBox}>
                            <p className={classes.head}>Bank code</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.FAST}</p>
                        </div>}
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.ECG &&<div className={classes.infoBox}>
                            <p className={classes.head}>Bank code</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.ECG}</p>
                        </div>}
                        {props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.EFT &&<div className={classes.infoBox}>
                            <p className={classes.head}>BSB code</p>
                            <p className={classes.content}>{props?.currencyDetails?.LocalMethods[0]?.methodRoutingNumber?.EFT}</p>
                        </div>}
                        <Button
                            className="!w-full !mt-2 !bg-white !border !border-dashed !border-[#181C3033]"
                            btnClassText="!text-2xl !font-medium !text-black"
                            icon={CopyIcon}
                            text="Copy Details"
                            disabled={false}
                            onClick={() => handleCopy(props?.currencyDetails?.LocalMethods[0])}
                        />
                    </div>
                </div>}

                {props?.currencyDetails?.InternationalMethods && <div className="bg-[#FFFFFF] px-8 py-8 rounded-[12px] mt-[24px]">
                    <div className="text-[1.875rem] leading-[2.25rem] font-[700] py-[9px] border-b-[1px]">Payment Method : {props?.currencyDetails?.InternationalMethods[0]?.paymentMethod}</div>
                    <div className="flex flex-col gap-[16px] py-[9px]">
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Beneficiary name</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.accountHolderName}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Beneficiary bank country</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.beneficaryCountry}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Beneficiary address</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.beneficiaryAddress}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Account Number</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.accountNumber}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Account type</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.accountType}</p>
                        </div>
                        {props?.currencyDetails?.InternationalMethods[0]?.methodRoutingNumber?.SWIFT &&<div className={classes.infoBox}>
                            <p className={classes.head}>{props?.currencyDetails?.InternationalMethods[0]?.routingCodeType}/SWIFT Code</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.methodRoutingNumber?.SWIFT}</p>
                        </div>}
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Bank name</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.bankName}</p>
                        </div>
                        <div className={classes.infoBox}>
                            <p className={classes.head}>Bank address</p>
                            <p className={classes.content}>{props?.currencyDetails?.InternationalMethods[0]?.bankAddress}</p>
                        </div>
                        <Button
                            className="!w-full mt-2 !bg-white !border !border-dashed !border-[#181C3033]"
                            btnClassText="!text-2xl !font-medium !text-black"
                            icon={CopyIcon}
                            text="Copy Details"
                            disabled={false}
                            onClick={() => handleCopy(props?.currencyDetails?.InternationalMethods[0])}
                        />
                    </div>
                </div>}
            </div>
            <div className="px-[4rem] py-[1.2rem] sticky bottom-0 w-[100%] bg-white border-t-[1px] border-t-[#ECEFF2]">
                <button className="w-[100%] bg-[#181C30] px-[1.6rem] py-[1.2rem] flex items-center justify-center cursor-pointer text-white text-[1.6rem] leading-[2.4rem] font-[600] rounded-[1rem]"
                        onClick={handleVbanDownload}
                >
                    Download All Details
                </button>
            </div>
        </Modal>
    )
}

export default BankDetails;