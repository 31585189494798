import React, { useEffect, useState } from 'react';
import classes from "./BankAccounts.module.scss";
import maximize from "../../assets/Icons/maximize.svg"
import share from "../../assets/Icons/share.svg"
import checkCircle from "../../assets/Icons/check-circle.svg"
import file from "../../assets/Icons/file.svg"
import arrowDown from "../../assets/Icons/arrow-down.svg"
import frame32 from "../../assets/Icons/Frame 332.svg"
import copy from "../../assets/Icons/copy.svg"
import arrowDownWhite from "../../assets/Icons/arrow-downWhite.svg"
import alertCircle from "../../assets/Icons/alert-circleNew.svg"
import { enqueueSnackbar, useSnackbar } from 'notistack';
// import UseApiCall from 'hooks/useApiCall';
// import { apiDownloadVban } from 'services';
import { useLocation } from 'react-router-dom';
// import { baseUrl } from 'constants/Network';
// import { tourArrays } from "constants/Tour";
// import { Tooltip } from "antd";
// import TooltipTourData from "Components/Tour/TooltipTourData";
import { useSelector } from 'react-redux';
// import CryptoJS from 'crypto-js';
// import { secretKey } from "constants";

const VirtualBankDetails = ({selectedAcc,data,sign, amount, country}) => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar()
    const showTourModal = useSelector((state) => state.global.showTour);
    const stepNumber = useSelector((state) => state.global.stepNumberInTour);
    let localMethod = data?.details?.LocalMethods;
    let internationalMethod = data?.details?.InternationalMethods;
    let disable = (localMethod == undefined  && internationalMethod == undefined) ? true : false;

    // const [tourArray, setTourArray] = useState(tourArrays[1]);
    // const decryptValue = (encryptedValue) => {
    //   const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    //   return bytes.toString(CryptoJS.enc.Utf8);
    // };
  
    // const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');
  
    // useEffect(() => {
    //   if(storedUserRole === 'malik') setTourArray(tourArrays[0])
    // }, [])

    // const handleVbanDownload = async (currency) => {
    //     const response = await fetch(baseUrl.api + `/download/vban-details?currency=${currency}`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/pdf",
    //         },
    //         credentials: "include",
    //     }).then(async function (t) {
    //         let filename = t.headers.get('Content-Disposition')?.split(';')?.find(n => n.includes('filename='))?.replace('filename=', '')?.trim();
    //         const b = await t.blob();
    //         var a = document.createElement("a");
    //         a.href = URL.createObjectURL(b);
    //         if (!filename) {
    //             filename = localMethod[0]?.accountHolderName || internationalMethod[0]?.accountHolderName;
    //             filename = `${filename?.replace(' ', '_')}_${currency}_vban_details`
    //         }
    //         a.setAttribute("download", filename);
    //         a.click();
    //     });

    //     // await downloadVban({ currency })
    // }


    const handleCopy = (event, method) => {
        event.preventDefault()
        try {
          const text = `Payment Method : ${method.paymentMethod}\nBeneficiary : ${
            method.accountHolderName
          }\nBeneficiary address: ${method.bankAddress}\nAccount Number : ${
            method.accountNumber
          }\nAccount Type : ${method.accountType}\nFedwire Routing Number : ${
            method?.methodRoutingNumber?.ACH}\nACH routing number : ${
                method?.methodRoutingNumber?.FEDWIRE}\nBank Name: ${
                    method.bankName}\nBank Address: ${method.bankAddress}`
          if ('clipboard' in navigator) {
            navigator.clipboard.writeText(text)
          }
          document.execCommand('copy', true, text)
          enqueueSnackbar('Details Copied!', { variant: 'success' })
          return
        } catch (error) {}
      }

      const handleCopyInternational = (event, method) => {
        event.preventDefault()
        try {
          const text = `Payment Method : ${method.paymentMethod}\nBeneficiary : ${
            method.accountHolderName
          }\nBeneficiary address: ${method.bankAddress}\nIBAN (Account number) : ${
            method.accountNumber
          }\nAccount Type : ${method.accountType}\nBIC/SWIFT code : ${
            method?.methodRoutingNumber?.SWIFT}\nBeneficiary bank country : ${
                method?.beneficaryCountry}\nBank Name: ${
                    method.bankName}\nBank Address: ${method.bankAddress}`
          if ('clipboard' in navigator) {
            navigator.clipboard.writeText(text)
          }
          document.execCommand('copy', true, text)
          enqueueSnackbar('Details Copied!', { variant: 'success' })
          return
        } catch (error) {}
      }


  return (
    <div>
        { (disable == false || showTourModal) &&
        (
        <div className={`${classes.virtualAccContainer}`}>
            {/* <div className={`${classes.virtualAccContainerTop}`}>
                <span className={`${classes.virtualAccContainerTopText1}`}>Account Details</span>
                <div className={`${classes.virtualAccContainerTopRight}`}>
                    <div className={`${classes.virtualAccContainerTopRight1}`}>
                        <img src={file} alt='file' className={`${classes.virtualAccContainerTopRight1Img}`} />
                        <span className={`${classes.virtualAccContainerTopRight1Text}`}>View Statement</span>
                    </div>
                    {
                            <div className={`${classes.virtualAccContainerTopRight2}`}>
                                <a
                                    // href={`${baseUrl}/api/download/vban-details?currency=${selectedAcc}`}
                                    // onClick={() => handleVbanDownload(selectedAcc)}
                                >
                                    <div className={`${classes.virtualAccContainerTopRight2A}`}>
                                        <img src={arrowDown} alt='arrow-down' className={`${classes.virtualAccContainerTopRight2AImg}`} />
                                        <span className={`${classes.virtualAccContainerTopRight2AText}`} 
                                            >Download All Details</span>
                                    </div>
                                </a>
                            </div>
                    }
                </div>
            </div> */}
            <div className={`${classes.virtualAccContainerBottom}`}>
            {localMethod && localMethod.length >= 0 &&
            (
                <div className={`${classes.virtualAccContainerBottomOne}`}>
                    <div className={`${classes.virtualAccContainerBottomOneHeader}`}>
                        <div className={`${classes.virtualAccLeft}`}>
                            <span className={`${classes.virtualAccLeftText}`}>Local {selectedAcc} Account</span>
                            <div className={`${classes.virtualAccLeftBottom}`}>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={share} alt='share' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>Get paid via local method</span>
                                </div>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={checkCircle} alt='check-circle' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>Cheaper than Swift</span>
                                </div>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={maximize} alt='maximize' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>Only accepts {selectedAcc}</span>
                                </div>
                                {internationalMethod && internationalMethod.length >= 0 &&
                                (<div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={alertCircle} alt='maximize' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>{internationalMethod[0]?.railIdentifier == 2 ? ('For any amount- No restrictions') : (`For amount less than ${sign}${amount}`)}</span>
                                </div>)}
                            </div>
                        </div>
                        <div className={`${classes.virtualAccRight}`}>
                            <div className={`${classes.virtualAccRightOne}`}>
                                <img src={frame32} alt='frame32'  className={`${classes.virtualAccRightOneImg}`}/>

                            </div>
                            <div className={`${classes.virtualAccRightTwo}`}>
                                <span className={`${classes.virtualAccRightTwoText}`}>Preferred by payers in {country}</span>
                            </div>
                        </div>
                    </div>
                        {/* {localMethod && localMethod.length >= 0 &&
                        ( */}
                            <div className={`${classes.virtualAccContainerBottomContentTop}`}>
                                <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                                <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                    <span className={`${classes.virtualTitle}`}>Payment method</span>
                                    <span className={`${classes.virtualDesc}`}>{localMethod[0]?.paymentMethod}</span>
                                </div>
                                <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                    <span className={`${classes.virtualTitle}`}>Account number</span>
                                    <span className={`${classes.virtualDesc}`}>{localMethod[0]?.accountNumber}</span>
                                </div>
                                <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                    <span className={`${classes.virtualTitle}`}>ACH routing number</span>
                                    <span className={`${classes.virtualDesc}`}>{localMethod[0]?.methodRoutingNumber?.ACH}</span>
                                </div>
                                </div>
                                <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                                    <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                        <span className={`${classes.virtualTitle}`}>Fedwire routing number</span>
                                        <span className={`${classes.virtualDesc}`}>{localMethod[0]?.methodRoutingNumber?.FEDWIRE}</span>
                                    </div>
                                    <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                        <span className={`${classes.virtualTitle}`}>Account type</span>
                                        <span className={`${classes.virtualDesc}`}>{localMethod[0]?.accountType}</span>
                                    </div>
                                    <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                        <span className={`${classes.virtualTitle}`}>Bank name</span>
                                        <span className={`${classes.virtualDesc}`}>{localMethod[0]?.bankName}</span>
                                    </div>
                                </div>
                                <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                                    <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                        <span className={`${classes.virtualTitle}`}>Beneficiary name</span>
                                        <span className={`${classes.virtualDesc}`}>{localMethod[0]?.accountHolderName}</span>
                                    </div>
                                    <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                        <span className={`${classes.virtualTitle}`}>Beneficiary address</span>
                                        <span className={`${classes.virtualDesc}`}>{localMethod[0]?.beneficiaryAddress}</span>
                                    </div>
                                    <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                        <span className={`${classes.virtualTitle}`}>Bank address</span>
                                        <span className={`${classes.virtualDesc}`}>{localMethod[0]?.bankAddress}</span>
                                    </div>
                                </div>
                                <hr className={`${classes.virtualHr}`}/>

                                <div className={`${classes.virtualBottomDownload}`}>
                                    <div className={`${classes.virtualBottomDownloadSection}`}>
                                        <img src={copy} alt='copy'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                        <span className={`${classes.virtualBottomDownloadSectionDesc}`} 
                                            onClick={(e) => handleCopy(e, localMethod[0])}
                                        >
                                            Copy Details</span>
                                    </div>
                                    <div className={`${classes.virtualBottomDownloadSectionDisable}`}>
                                        <img src={arrowDownWhite} alt='arrowDown'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                        <span className={`${classes.virtualBottomDownloadSectionDesc}`}
                                        >Download Details</span>
                                    </div>
                                </div>
                            </div>
                </div>)}
                {internationalMethod && internationalMethod.length >= 0 &&
                    (
                <div className={`${classes.virtualAccContainerBottomTwo}`}>
                <div className={`${classes.virtualAccContainerBottomOneHeader}`} style={{gap : "0px"}} >
                        <div className={`${classes.virtualAccLeft}`}>
                            <span className={`${classes.virtualAccLeftText}`}>International Swift Account</span>
                            <div className={`${classes.virtualAccLeftBottom}`}>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={maximize} alt='share' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>25+ currencies accepted</span>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`} style={{textDecorationLine : 'underline'}}>View all</span>
                                </div>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={checkCircle} alt='alert-circle' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>Swift charges might apply</span>
                                </div>
                                {internationalMethod && internationalMethod.length >= 0 &&
                                (<div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={alertCircle} alt='maximize' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>{internationalMethod[0]?.railIdentifier == 2 ? ('For any amount- No restrictions') : (`For amount less than ${sign}${amount}`)}</span>
                                </div>)}
                            </div>
                        </div>
                        <div className={`${classes.virtualAccRight}`}>
                            <div className={`${classes.virtualAccRightOne}`}>
                                {/* <img src={frame32} alt='frame32'  className={`${classes.virtualAccRightOneImg}`}/> */}
                            </div>
                            {/* <div className={`${classes.virtualAccRightTwo}`}>
                                <span className={`${classes.virtualAccRightTwoText}`}>Preferred by payers in USA</span>
                            </div> */}
                        </div>
                    </div>
                    {/* {internationalMethod && internationalMethod.length >= 0 &&
                    ( */}
                    <div className={`${classes.virtualAccContainerBottomContent}`}>
                        <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Payment method</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.paymentMethod}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>IBAN (Account number)</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.accountNumber}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>BIC/SWIFT code</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.methodRoutingNumber.SWIFT}</span>
                            </div>
                        </div>
                        <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Account type</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.accountType}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Bank name</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.bankName}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Beneficiary address</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.bankAddress}</span>
                            </div>
                        </div>
                        <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Beneficiary name</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.accountHolderName}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Beneficiary bank country</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.beneficaryCountry}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Bank address</span>
                                <span className={`${classes.virtualDesc}`}>{internationalMethod[0]?.bankAddress}</span>
                            </div>
                        </div>

                        <hr className={`${classes.virtualHr}`}/>

                        <div className={`${classes.virtualBottomDownload}`}>
                            <div className={`${classes.virtualBottomDownloadSection}`}>
                                <img src={copy} alt='copy'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                <span className={`${classes.virtualBottomDownloadSectionDesc}`} 
                                 onClick={(e) => handleCopyInternational(e, internationalMethod[0])}
                                >
                                    Copy Details</span>
                            </div>
                            <div className={`${classes.virtualBottomDownloadSectionDisable}`}>
                                <img src={arrowDownWhite} alt='arrowDown'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                <span className={`${classes.virtualBottomDownloadSectionDesc}`}>Download Details</span>
                            </div>
                            {/* <div className={`${classes.virtualBottomDownloadSection}`}>
                                <img src={shareIcon} alt='share'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                <span className={`${classes.virtualBottomDownloadSectionDesc}`}>Share Details</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                )}
            </div>
       </div>)}
    </div>
  )
}

export default VirtualBankDetails