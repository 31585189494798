import { createSlice } from "@reduxjs/toolkit";

const authInitialState = {
    showBackendError : false,
    isAuthenticated : false,
}

export const AuthSlice = createSlice({
    name: 'auth-slice',
    initialState: authInitialState,
    reducers: {
        setShowBackendError(state, action){
            state.showBackendError = action.payload;
        },
        setIsAuthenticated(state, action){
            state.isAuthenticated = action.payload;
        }
    }
})

export const AuthActions = AuthSlice.actions;

const loginInitialState = {
    userName : '',
    userId: '',
    emailInput : '', 
    isEmailValid : false, 
    passwordInput : '',
    isPasswordValid : false,
    mobileNumberInput : '',
    mobileCodeInput: '+91',
    isMobileValid : false,
    showBackendError :false,
}

 const LoginSlice = createSlice({
    name: 'login-slice',
    initialState : loginInitialState,
    reducers : {
        setUserName(state, action){
            state.userName = action.payload
        },
        setUserId(state, action){
            state.userId = action.payload
        },
        setEmailInput(state, action){
            state.emailInput = action.payload
        },
        setEmailValidity(state, action){
            state.isEmailValid = action.payload
        },
        setPasswordInput(state, action){
            state.passwordInput = action.payload
        },
        setPasswordValidity(state, action){
            state.isPasswordValid = action.payload;
        },
        setMobileNumberInput(state, action){
            state.mobileNumberInput = action.payload
        },
        setMobileCodeInput(state, action){
            state.mobileCodeInput = action.payload
        },
        setMobileValidity(state, action){
            state.isMobileValid = action.payload
        },
        setShowBackendError(state, action){
            state.showBackendError = action.payload;
        }
    }
})

export const LoginActions = LoginSlice.actions;

export default LoginSlice;