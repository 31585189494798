import { combineReducers, configureStore } from '@reduxjs/toolkit'
import LoginSlice, {
  AuthSlice,
} from './auth-redux'
import GlobalSlice from './global-redux'
import { ModalSlice } from './modal-redux'
import { FiraReportsSlice } from './reports-redux';
import {
  ContactsSlice,
  CustomersSlice,
  DashboardSummarySlice,
  ReceivablesSlice,
  ComplianceSlice,
  OrganizationSlice,
} from 'Store/customer-redux'


import {BankAccountSlice} from "./bank-redux";

const CLEAR_STATE = 'CLEAR_STATE';

const appReducer = combineReducers({
      auth: AuthSlice.reducer,
      login: LoginSlice.reducer,
      global: GlobalSlice.reducer,
      modal: ModalSlice.reducer,
      reports: FiraReportsSlice.reducer,
      dashboardSummary: DashboardSummarySlice.reducer,
      exporterList: OrganizationSlice.reducer,
      receivable: ReceivablesSlice.reducer,
      // user: UsersSlice.reducer,
      customer: CustomersSlice.reducer,
      customerContacts: ContactsSlice.reducer,
      // bankAccount: BankAccountSlice.reducer,
      // completeForm: CompleteFormSlice.reducer,
      // compliance: ComplianceSlice.reducer,
      bankAccount:BankAccountSlice.reducer,
})

const rootReducer = (state, action) => {
    if (action.type === CLEAR_STATE) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

const store = configureStore({
  reducer: rootReducer,
})

export default store