import classes from  "./ExporterProfileInfo.module.scss"
import Modal from "../../Layout/Modal"
import ReactDOM from 'react-dom'
import clsx from "clsx"
import Backdrop from "../../Layout/Backdrop"
import { useDispatch } from "react-redux"
import { modalActions } from "../../Store/modal-redux"
import { Tabs } from "antd"
import Close from "../../Layout/Close"
import ExporterProfile from "./ExporterProfile"
import ComplianceChecksProfile from "./ComplianceChecksProfile"
import CommercialsInfo from "./CommercialsInfo"
import SettingsInfo from "./SettingsInfo"
import useApiCall from "../../hooks/useApiCall";
import {useState, useEffect} from "react";
import VBanksInfo from "./VBanksInfo";
import {apiGetVBankDetails, apiGetExporterDetails} from "../../services";

const ExporterProfileInfo = (props) => {
    const dispatch = useDispatch();
    const defaultActiveKey = 'vBankBalance';
    const [currencyArray, setCurrencyArray] = useState([]);
    const [currencyDetailObj, setCurrencyDetailObj] = useState({});
    const [exporterDetails, setExporterDetails] = useState({});
    const [localBankAccounts, setLocalBankAccounts] = useState([]);

    function convertCurrencyObjectToArray(currencyObj) {

        let currencyArray = Object.keys(currencyObj).map(currency => {
            return {
                currency: currencyObj[currency].currencyDTO.code,
                sign: currencyObj[currency].currencyDTO.sign,
                description: currencyObj[currency].currencyDTO.description,
                balance: currencyObj[currency].balance
            };
        });


        currencyArray.sort((a, b) => {
            return currencyObj[a.currency].currencyDTO.order - currencyObj[b.currency].currencyDTO.order;
        });

        return currencyArray;
    }
    const [getVBankBalance, vBankLoading] = useApiCall(apiGetVBankDetails,
        (res) => {
            setCurrencyArray(convertCurrencyObjectToArray(res?.data?.balances))
            setCurrencyDetailObj(res?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap)
        }
    )

    const [getExporterDetails, exporterDetailsLoading] = useApiCall(apiGetExporterDetails,
        (res) => {
            setExporterDetails(res?.data?.user_profile_details);
            setLocalBankAccounts(res?.data?.bank_account_dtos);
        }
    )

    const handleReconClick = () => {
        getVBankBalance({
            organizationId:props?.exporter?.organizationId,
        });
    }

    const items = [
        {
          key: "vBankBalance",
          label: "Virtual Banks",
          children: (
              <VBanksInfo
                  tableData = {currencyArray}
                  loading={vBankLoading}
                  currencyDetails = {currencyDetailObj}
                  organizationId = {props?.exporter?.organizationId}
                  exporterName = {props?.exporter?.organizationName}
                  reconClick = {handleReconClick}
              />
          ),
        },
        {
            key: "profile",
            label: "Profile",
            children: (
                <ExporterProfile
                    exporterDetails = {exporterDetails}
                    bankAccounts = {localBankAccounts}
                    loading = {exporterDetailsLoading}
                />
            )
        },
        {
            key: "Settings",
            label: "Settings",
            children: (
                <SettingsInfo/>
                // <div className="p-[4rem] text-[18px] bg-[#fff] rounded-[8px]">
                //     Coming Soon
                // </div>
            )
        },
        {
            key: "commercials",
            label: "Commercials",
            children: (
                <CommercialsInfo/>
                // <div className="p-[4rem] text-[18px] bg-[#fff] rounded-[8px]">
                //     Coming Soon
                // </div>
            )
        },
        {
            key: "compliance",
            label: "Compliance Checks",
            children: (
                <ComplianceChecksProfile/>
                // <div className="p-[4rem] text-[18px] bg-[#fff] rounded-[8px]">
                //     Coming Soon
                // </div>
            )
        },
    ]




    useEffect(()=>{
        getVBankBalance({
            organizationId:props?.exporter?.organizationId,
        });
        getExporterDetails({
            organizationId:props?.exporter?.organizationId
        })
    },[])
    return(
        <Modal className={clsx(
            classes.modal,
            'p-0 w-[1200px] z-[51] !bg-[#F4F6FA] bottom-0 !pb-[20px]'
          )}
        >
            {
                ReactDOM.createPortal(
                <Backdrop
                onClick={() => {
                    dispatch(modalActions.exporterProfileInfo(false))
                }}
                className={classes.backdrop}
                />,
                document.getElementById('backdrop-root')
            )}
            <div className={classes.profileInfo}>
                <div className={classes.profileInfoHeader}>
                    <div>Exporter : {props?.exporter?.organizationName ? props?.exporter?.organizationName : "-"} </div>
                    <Close
                        onClick = {()=>dispatch(modalActions.exporterProfileInfo(false))}
                    />
                </div>
                <Tabs
                    defaultActiveKey={defaultActiveKey}
                    items={items}
                    // onChange={onChange}
                />
            </div>
        </Modal>
    )
}

export default ExporterProfileInfo