// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankDetails_backdrop__Mac\\+- {
  z-index: 55;
  top: 0;
  height: 100vh;
}

.BankDetails_modal__LxUaT {
  height: calc(100vh - var(--admin_header_length));
}

.BankDetails_infoBox__ju7t6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 2rem;
}
.BankDetails_infoBox__ju7t6 .BankDetails_head__qLaUU {
  color: rgba(30, 51, 63, 0.5019607843);
}
.BankDetails_infoBox__ju7t6 .BankDetails_cont__MkDBD {
  color: #1E333F;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/BankDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,MAAA;EACA,aAAA;AACF;;AAEA;EACE,gDAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;AACF;AACE;EACE,qCAAA;AACJ;AACE;EACE,cAAA;AACJ","sourcesContent":[".backdrop{\n  z-index: 55;\n  top: 0;\n  height: 100vh;\n}\n\n.modal {\n  height: calc(100vh - var(--admin_header_length));\n}\n\n.infoBox{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  justify-content: space-between;\n  font-size: 1.5rem;\n  line-height: 2rem;\n\n  .head{\n    color: #1E333F80;\n  }\n  .cont{\n    color : #1E333F;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `BankDetails_backdrop__Mac+-`,
	"modal": `BankDetails_modal__LxUaT`,
	"infoBox": `BankDetails_infoBox__ju7t6`,
	"head": `BankDetails_head__qLaUU`,
	"cont": `BankDetails_cont__MkDBD`
};
export default ___CSS_LOADER_EXPORT___;
