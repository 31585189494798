// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeadVerificationModal_bigCont__33P36 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 11px 20px 20px 20px;
  z-index: 100;
  width: 380px;
}

.LeadVerificationModal_btnCont__Au3bN {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.LeadVerificationModal_markBtn__-cexR {
  padding: 1.2rem 1.6rem;
  border-radius: 1rem;
  background-color: #181C30;
  color: white;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/LeadVerificationModal.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,4BAAA;EACA,YAAA;EACA,YAAA;AAAF;;AAIA;EAEE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;AAFF;;AAMA;EAEE,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAJF","sourcesContent":[".bigCont {\n\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 11px 20px 20px 20px;\n  z-index: 100;\n  width: 380px;\n\n}\n\n.btnCont {\n\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  justify-content: space-between;\n\n}\n\n.markBtn {\n\n  padding: 1.2rem 1.6rem;\n  border-radius: 1rem;\n  background-color: #181C30;\n  color: white;\n  font-size: 1.6rem;\n  line-height: 2.4rem;\n  font-weight: 600;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigCont": `LeadVerificationModal_bigCont__33P36`,
	"btnCont": `LeadVerificationModal_btnCont__Au3bN`,
	"markBtn": `LeadVerificationModal_markBtn__-cexR`
};
export default ___CSS_LOADER_EXPORT___;
