import ReactDOM from 'react-dom'
import classes from "./ReceivableReportTable.module.scss"
import clsx from "clsx"
import ReportTableLayout from "../Layout/ReportTableLayout"
import ContactInfo from "../Layout/ContactInfo"
import React, { useRef, useEffect, useState } from "react"
import dayjs from 'dayjs';
import { formatNumberToLocaleString } from "../hooks/formatNumberToLocaleString"
import PaymentStatusTag from "../Components/atom/PaymentStatusTag"
import Action from "../Components/atom/Action"
import actionIcon from "../assets/Icons/action-icon.svg"
import { Dropdown, Pagination, Space, Spin, Tooltip } from 'antd';
import Shimmer from "../Layout/Shimmer"
import InvoiceDetailsModal from '../Components/molecule/InvoiceDetailsModal/InvoiceDetailsModal'
import { useLocation, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import UseApiCall from "../hooks/useApiCall"
import { enqueueSnackbar, useSnackbar } from "notistack"
import { modalActions } from "../Store/modal-redux"
import { apiGetInvoiceDetails, apiVerifyCustomers, apiVerifyInvoice, apiDeleteInvoice } from '../services'
import { CustomersActions, ReceivablesActions } from '../Store/customer-redux'
import EditInvoice from 'Components/Invoice/EditInvoice'
import { LoadingOutlined } from "@ant-design/icons";

const tableHeaderData = [
  {
    header: "Exporter",
    width: 15,
    maxWidth: 150
  },
  {
    header: "Invoice Id",
    width: 15,
    maxWidth: 120
  },
  {
    header: "Issue Date",
    width: 15,
    maxWidth: 100
  },
  {
    header: "Customer Details",
    width: 15,
    maxWidth: 150
  },
  {
    header: "Receivable Amount",
    width: 10,
    maxWidth: 120
  },
  {
    header: "Pending Amount",
    width: 10,
    maxWidth: 110
  },
  {
    header: "Due Date",
    width: 10,
    maxWidth: 100
  },
  {
    header: "Status",
    width: 10,
    maxWidth: 140
  },
  {
    header: "Action",
    width: 10,
    maxWidth: 100
  },
]

// const optionArr = [
//     {
//         text: "View"
//     },
//     {
//         text: "Download"
//     },
//     {
//         text: "Verify"
//     }
// ]

const items = [
  {
    label: '1st menu item',
    key: '0',
  },
  {
    label: '2nd menu item',
    key: '1',
  },
  {
    label: '3rd menu item',
    key: '2',
  },
];

const ReceivableReportTable = (props) => {
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);
  let { section } = useParams()
  const location = useLocation()
  // const modalRef = useRecoilValue(modalRefState)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('details')
  const [organizationId, setOrganizationId] = useState('details')
  const [invoiceId, setInvoiceId] = useState('')
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const showInvoiceDisplay = useSelector((state) => state.modal.showInvoiceDisplay)
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
  const [loaderId, setLoaderId] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const updateOnVerify = useSelector((state) => state.receivable.updateOnVerify);
  const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
    dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
    setSelectedInvoice(res?.data)
    dispatch(modalActions.showInvoiceDisplay())
  }, (err) => {
    enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  })

  const [getSelectedInvoiceDraft] = UseApiCall(apiGetInvoiceDetails, (res) => {
    dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
    setSelectedInvoice(res?.data)
    dispatch(modalActions.editInvoice())
  }, () => {
    enqueueSnackbar(`Error while fetching invoice details.`, { variant: 'error' })
  })

  const [verifyInvoice, fetchingInvoice, InvoiceData, Invoiceerror] = UseApiCall(apiVerifyInvoice,
    (res) => {
      // console.log(res?.data)
      // setVerifyCust(true)
      // enqueueSnackbar('', snackbarOptions);
      // enqueueSnackbar('Invoice verified successfully.', { variant: 'success' , anchorOrigin: { horizontal: "center", vertical: "top" }})
      enqueueSnackbar('Invoice verified successfully.', {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 3000, // Specify the duration in milliseconds
        style: { fontSize: '10px' } // Adjust the font size as needed
      });

      setLoaderState(true)

      const fetchData = () => {
        setLoaderState(false)
        dispatch(ReceivablesActions.setUpdateOnVerify(true));
      };

      const timer = setTimeout(fetchData, 3000);
      return () => {
        clearTimeout(timer)
      };

    },
    (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`,
        {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          autoHideDuration: 3000, // Specify the duration in milliseconds
          style: { fontSize: '10px' }
        })
    }
  )

  const [deleteInvoice, fetchingDeleteInvoice, deleteInvoiceData, deleteInvoiceError] = UseApiCall(apiDeleteInvoice,
    (res) => {
      enqueueSnackbar('Invoice deleted successfully.', {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 3000,
        style: { fontSize: '10px' }
      });

      setLoaderState(true)
      const fetchData = () => {
        setLoaderState(false)
        dispatch(ReceivablesActions.setUpdateOnVerify(true));
      };

      const timer = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timer)
      };

    },
    (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message || "Error occured while deleting the invoice."}`,
        {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          autoHideDuration: 3000,
          style: { fontSize: '10px' }
        })
    }
  )

  const handleVerify = (data) => {
    setLoaderId(data?.id)
    data && verifyInvoice({ invoiceId: data?.invoiceId, organizationId: data?.organizationId, paymentRail: '' })
  }
  
  const handleDeleteInvoice = (data) => {
    setLoaderId(data?.id)
    data && deleteInvoice({ invoiceId: data?.id, organizationId: data?.organizationId })
  }

  // useEffect(() => {
  //   if (updateOnVerify) {
  //       invoiceId && organizationId && handleInvoiceSelection(invoiceId,organizationId)
  //       // data && handleInvoiceSelectionDraft(data?.invoiceId,data?.organizationId)
  //   }
  // }, [updateOnVerify])

  function abbreviateString(str) {
    if (str.length > 12) {
      return str.substr(0, 5) + '...' + str.substr(-4);
    }
    return str;
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleInvoiceSelection = async (invoiceId, organizationId) => {
    setOrganizationId(organizationId);
    setInvoiceId(invoiceId);
    localStorage.setItem('zoomkaindex', '0')
    await getSelectedInvoice({ invoiceId, organizationId })
    // let tab = 'details'
    // if (section === 'details') {
    //   tab = 'details'
    //   window.history.pushState(
    //     {},
    //     undefined,
    //     `/receivables/${tab}?invoiceId=${invoiceId}`
    //   )
    // } else if (section === 'paymentStatus') {
    //   tab = 'payment-status'
    //   window.history.pushState(
    //     {},
    //     undefined,
    //     `/receivables/${tab}?invoiceId=${invoiceId}`
    //   )
    // } else if (section === 'bankAccount') {
    //   tab = 'bank-account'
    //   window.history.pushState(
    //     {},
    //     undefined,
    //     `/receivables/${tab}?invoiceId=${invoiceId}`
    //   )
    // }
  }

  const handleInvoiceSelectionDraft = async (invoiceId, organizationId) => {
    setOrganizationId(organizationId);
    setInvoiceId(invoiceId);
    localStorage.setItem('zoomkaindex', '0')
    await getSelectedInvoiceDraft({ invoiceId, organizationId })
  }

  // useEffect(() => {
  //   // keys: details, paymentStatus, bankAccount
  //   const searchParams = new URLSearchParams(location.search)
  //   const invoiceId = searchParams.get('invoiceId')

  //   if (section === 'details') {
  //     setActiveTab('details')
  //   } else if (section === 'payment-status') {
  //     setActiveTab('paymentStatus')
  //   } else if (section === 'bank-account') {
  //     setActiveTab('bankAccount')
  //   }
  //   if (invoiceId && selectedInvoice?.invoiceId !== invoiceId) {
  //     handleInvoiceSelection(invoiceId)
  //   }
  // }, [section, location])

  return (
    <>
      {
        showInvoiceDisplay &&
        selectedInvoice &&
        ReactDOM.createPortal(
          <InvoiceDetailsModal
            activeTab={activeTab}
            invoice={selectedInvoice}
            organizationId={organizationId}
            mobileTableData
          />,
          document.getElementById('modal-root')
        )
      }
      {
        editInvoiceDisplay &&
        ReactDOM.createPortal(
          <EditInvoice
            selectedInvoiceData={selectedInvoice}
            organizationId={organizationId}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
        )
      }
      <div className={classes.allWrap}>
        <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
          <thead>
            {tableHeaderData?.map((header, index) => (
              <th key={`${header}-${index}`} className={classes.cell_header1}
                style={{ width: `${header.width}%`, minWidth: `${header.maxWidth}px`, maxWidth: `${header.maxWidth}px` }}
                valign="top"
              >
                <div className={classes.cell_header2}>
                  <div className={classes.head}>{header.header}</div>
                </div>
              </th>
            ))}
          </thead>
        </div>
        <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef} >
          <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
            {
              props.loading
                ?
                <td colSpan={7}>
                  {[1, 2, 3, 4]?.map((item, index) => (
                    <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]" />
                  ))}
                </td>
                :
                props?.tableData?.map((data, index) => {
                  return <tr className={classes.row}
                  >
                    <td className={classes.cell}
                      style={{ width: '15%', minWidth: '150px', maxWidth: '150px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      <ContactInfo
                        name={data?.organizationName ? data?.organizationName : "--"}
                      // email = {data?.exporter?.country}
                      />
                    </td>
                    <td className={classes.cell}
                      style={{ width: '15%', minWidth: '120px', maxWidth: '120px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      {/* { data?.invoiceId ? data?.invoiceId : "--" } */}
                      {
                        data?.invoiceId?.length > 12
                          ?
                          <Tooltip
                            title={<div
                            // style={{ color: '#1e333fcc' }}
                            >{data.invoiceId}</div>}
                            placement="bottomLeft"
                          // arrow={false}
                          // color={"white"}
                          >
                            {abbreviateString(data.invoiceId, 12)}
                          </Tooltip>
                          :
                          data?.invoiceId
                      }
                    </td>
                    <td className={classes.cell}
                      style={{ width: '15%', minWidth: '100px', maxWidth: '100px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      {data?.issueDate ? dayjs(data?.issueDate).format(`D MMM 'YY`) : '--'}
                    </td>
                    <td className={classes.cell}
                      style={{ width: '15%', minWidth: '150px', maxWidth: '150px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      <ContactInfo
                        name={data?.customer?.customerName ? data?.customer?.customerName : "--"}
                        email={data?.customer?.countryName ? data?.customer?.countryName : "--"}
                      />
                    </td>
                    <td className={classes.cell}
                      style={{ width: '10%', minWidth: '120px', maxWidth: '120px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      <div className={classes.amount}>
                        {data?.invoiceCurrency?.sign ? data?.invoiceCurrency?.sign : "-"} {data?.invoiceAmount ? formatNumberToLocaleString((data?.invoiceAmount), 2, 2) : "--"}
                      </div>
                      <div className={classes.amount} style={{ color: "#1e333f80" }}>
                        {data?.conversionInvoiceCurrency && data?.convertedInvoiceAmount ? (
                          <span>
                            {`${data?.conversionInvoiceCurrency?.sign}${data?.convertedInvoiceAmount?.toLocaleString(
                              'en-IN'
                            )}`}
                          </span>
                        ) : ('')
                        }
                      </div>
                    </td>
                    <td className={classes.cell}
                      style={{ width: '10%', minWidth: '110px', maxWidth: '110px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      <div className={classes.amount}>
                        {data?.invoiceCurrency?.sign ? data?.invoiceCurrency?.sign : "-"} {data?.pendingAmount || data?.pendingAmount === 0 ? formatNumberToLocaleString((data?.pendingAmount), 2, 2) : "--"}
                      </div>
                    </td>
                    <td className={classes.cell}
                      style={{ width: '10%', minWidth: '100px', maxWidth: '100px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      {data?.dueDate ? dayjs(data?.dueDate).format(`D MMM 'YY`) : "--"}
                    </td>
                    <td className={classes.cell}
                      style={{ width: '10%', minWidth: '140px', maxWidth: '140px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      {
                        data?.status.split(' ').join('').toLowerCase() === 'settledggoutside'
                          ?
                          <p>Settled Outside Fideo</p>
                          :
                          <>
                            <PaymentStatusTag
                              status={data?.status}
                              type="light"
                            />
                            {/* <div className={classes.text}>
                                        {
                                          getTime(data.lastUpdatedTime) === 0
                                          ? "Today"
                                          : (
                                            `${getTime(data.lastUpdatedTime)} day${getTime(data.lastUpdatedTime) > 1 ? 's' : ''} ago`
                                          )
                                        }
                                      </div> */}
                            <div className={classes.text} style={{ paddingLeft: "8px", marginTop: "4px" }}>
                              {
                                data?.status?.split(' ').join('').toLowerCase() === 'settledoutside'
                                  ? "Outside Fideo"
                                  : (
                                    data?.status.split(' ').join('').toLowerCase() === 'settled'
                                      ? "Via Fideo"
                                      : ""
                                  )
                              }
                            </div>
                          </>
                      }
                    </td>
                    <td className={classes.cell}
                      style={{ width: '10%', minWidth: '100px', maxWidth: '100px', overflowWrap: "anywhere" }}
                      valign="top"
                    >
                      {/* <div className="flex items-center justify-end">
                                <Action options = {optionArr} />
                              </div> */}
                      <div className="flex flex-col gap-[4px]" >
                        {
                          data?.status === "In Draft"
                            ?
                            <div className='flex flex-col gap-[4px]'>
                              <button
                                className="p-[4px] text-[#fff] bg-black rounded-[4px] opacity-100"
                                onClick={() => handleInvoiceSelectionDraft(data?.id, data?.organizationId)}
                              >
                                Edit
                              </button>
                              <button
                                // className="p-[4px] text-[12px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                className={`p-[4px] text-[12px] text-[#fff] bg-black rounded-[4px] cursor-pointer`}
                                onClick={() => handleDeleteInvoice(data)}
                                disabled={loaderState}
                              >
                                {(fetchingDeleteInvoice || loaderState) && loaderId === data?.id ? (
                                  <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: '#FFFFFF', strokeWidth: 4 }} spin />} />
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </div>
                            :
                            <>
                              <button className="p-[4px] text-[#fff] bg-black rounded-[4px] opacity-100"
                                onClick={() => handleInvoiceSelection(data?.id, data?.organizationId)}
                              >View
                              </button>
                              {
                                // (data?.status === "In review" || data?.verificationStatus === "VERIFICATION_IN_PROGRESS") &&
                                (data?.status === "In review") &&
                                // <button
                                //     className="p-[4px] text-[#fff] bg-black rounded-[4px] opacity-25 cursor-not-allowed"
                                // >Verify</button>

                                <button
                                  // className="p-[4px] text-[12px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                  className={`p-[4px] text-[12px] text-[#fff] bg-black rounded-[4px]  ${(data?.verificationStatus === "VERIFICATION_IN_PROGRESS") ? 'opacity-25 cursor-not-allowed' : 'cursor-pointer'}`}
                                  onClick={() => handleVerify(data)}
                                  disabled={loaderState || data?.verificationStatus === "VERIFICATION_IN_PROGRESS"}
                                >
                                  {(fetchingInvoice || loaderState) && loaderId === data?.id ? (
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#FFFFFF', strokeWidth: 4 }} spin />} />
                                  ) : (
                                    "Verify"
                                  )}
                                </button>
                              }
                            </>
                        }
                      </div>
                    </td>
                  </tr>
                })
            }
          </ReportTableLayout>
          {
            props?.tableData?.length > 0 &&
            <div className={classes.paginationWrap}>
              <Pagination
                showSizeChanger={false}
                current={props?.currentPage + 1}
                total={props?.resData?.totalElements}
                pageSize={props?.pageSize}
                // defaultPageSize={30}
                itemRender={itemRender}
                onChange={props.handlePaginate}
              />
            </div>
          }
        </div>
      </div>
      {((props?.tableData?.length === 0 || props?.tableData?.length === 'undefined') && !props?.loading) &&
        (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '4rem',
              fontSize: '2.5rem'
            }}
            className={classes.table}
          >
            {props?.isSearch?.length === 0 ? 'No Receivables Yet.' : 'No receivable with this invoice id.'}
          </div>
        )
      }
    </>
  )
}

export default ReceivableReportTable