import classes from "./Exporter.module.scss"
import PageIntro from "../Layout/PageIntro"
import ExporterTable from "../ReportTable/ExporterReportTable"
import ExporterFilter from "../Components/atom/ExporterFilter"
import {useEffect, useState} from "react"
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import LeadsTable from "../ReportTable/LeadsTable"
import UseApiCall from "../hooks/useApiCall";
import {apiGetLeads} from "../services";
import dayjs from "dayjs";
import LeadsFilter from "../Components/atom/LeadsFilter";
import {DashboardSummaryActions} from "../Store/customer-redux";

const Leads = () => {
    const [filteredData, setFilteredData] = useState();
    const [totalElements, setTotalElements] = useState();
    const [currentPage , setCurrentPage] = useState(0);
    const [pageSize , setPageSize] = useState(30);

    const [selectedCustomers, setSelectedCustomers] = useState({});
    const [allSelected, setAllSelected] = useState(true);
    const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [isSearch, setIsSearch] = useState();
    const [customers,setCustomers] = useState();
    const [customerCounter, setCustomerCounter] = useState('0');
    const [statuses,setStatuses] = useState();
    const [selectedStatus, setSelectedStatus] = useState(["verified", "UNDER REVIEW"]);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [allStatusSelected, setAllStatusSelected] = useState(true);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showInvoiceDateDropdown, setShowInvoiceDateDropdown] = useState(false);
    const [showDateCounter, setShowDateCounter] = useState(false);
    const [statusCounter, setStatusCounter] = useState('0');
    const [activeDate, setActiveDate] = useState('all');
    const [selectedDate, setSelectedDate] = useState({
        fromDate:'',
        toDate:''
    });

    const handlePaginate = (page,pageSize)=>{
        // console.log('page',pageSize)
        setCurrentPage(page - 1)
    }

    const handlePageSize = (current, size)=>{
        setPageSize(size)
    }

    const toggleCustomerDropdown = () => {
        setShowCustomerDropdown(!showCustomerDropdown);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleSearchDropdown = () => {
        setShowSearchDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleCustomerSelection = async (customerId, len) => {
        // setCurrentPage(1)
        // console.log('customerId',customerId)
        // console.log('len',len)
        setSelectedCustomers(prevSelectedCustomers => {
            const updatedSelection = {
                ...prevSelectedCustomers,
                [customerId]: !prevSelectedCustomers[customerId]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            // console.log('countTrueKeys',countTrueKeys)

            setCustomerCounter(countTrueKeys);
            setAllSelected(countTrueKeys === len);
            return updatedSelection;
        });
    };

    const toggleStatusDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(!showStatusDropdown);
        setShowInvoiceDateDropdown(false);
    };

    const toggleStatusSelection = (status, len) => {
        // setCurrentPage(1)
        if(status === 'reset'){
            setAllStatusSelected(false)
            setStatusCounter('0')
            return setSelectedStatuses({});
        }
        setSelectedStatuses(prevSelectedStatuses => {
            const updatedSelection = {
                ...prevSelectedStatuses,
                [status]: !prevSelectedStatuses[status]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            setStatusCounter(countTrueKeys)
            setAllStatusSelected(statuses?.length === countTrueKeys)

            return updatedSelection;
        });
    };

    const toggleInvoiceDateDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(!showInvoiceDateDropdown);
    };

    const handleDateSelection = (period) => {
        // setCurrentPage(1)
        setShowDateCounter(period === 'all' ? false : true)
        setActiveDate(period)
        const currentDate = dayjs();
        let fromDate, toDate;

        switch (period) {
            case 'all':
                fromDate = '';
                toDate = '';
                break;
            case 'one':
                fromDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'three':
                fromDate = currentDate.subtract(3, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'six':
                fromDate = currentDate.subtract(6, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            default:
                // Handle default case or throw an error if needed
                break;
        }

        setSelectedDate({
            fromDate: fromDate,
            toDate: toDate
        });
    };

    const [getLeadsTableData, fetchingtLeads, LeadsTabledata, Tableerror] = UseApiCall(apiGetLeads,
        (res) => {
            // console.log(res)
            setTotalElements(res?.data?.totalElements)
            setFilteredData(res?.data?.content)

            const uniqueOrganizationsMap = new Map();

            res?.data?.content?.forEach((organization) => {
                const organizationName = organization.organizationName;
                if (!uniqueOrganizationsMap.has(organizationName)) {
                    uniqueOrganizationsMap.set(organizationName, organization);
                    return true
                }else {
                    return false
                }
            });

            const uniqueOrganizations = Array.from(uniqueOrganizationsMap.values());
            setCustomers(uniqueOrganizations);

            // console.log('uniqueLeads',uniqueLeads)

            const uniqueStatuses = [...new Set(res?.data?.content?.map(data => data?.status))];
            setStatuses(uniqueStatuses);
            // dispatch(DashboardSummaryActions.setDashboardTableData(res?.data));
        }, (err) => {
            console.log(err)
            // dispatch(DashboardSummaryActions.setShowBackendError(err));
        });

    useEffect(() => {
        getLeadsTableData(
            {
                pageNum : currentPage,
                size : pageSize
            }
        )
    }, [currentPage,pageSize])

    const exportData = async () => {
        const workbook = new Excel.Workbook();
    
        const columns = [
            { header: 'Leads Id', key: "leads_id" },
            { header: 'Organization Name', key: "organization_name" },
            { header: 'Lead Name', key: 'lead_Name' },
            { header: 'Email Address', key: 'email_address' },
            { header: 'Mobile Number', key: 'mobile_umber' },
            { header: 'Creation Date', key: "creation_date" },
            { header: 'Status', key: 'status' },
        ];
        const data = filteredData?.map(item => ({
          leads_id: item?.leadId ? item?.leadId : "--",
            organization_name: item?.organizationName ? item?.organizationName : "--",
            lead_Name: item?.userName ? item?.userName : "--",
            email_address: item?.email ? item?.email : "--",
            mobile_umber: item?.phone ? item?.phone : "--",
          creation_date: item?.creationDate ? dayjs(new Date(...item?.creationDate.slice(0, 4))).format("D MMM 'YY") : "--",
          status: item?.status ? item?.status : "--",
        }));
    
        try {
          const fileName = "LeadsReport";
    
          const worksheet = workbook.addWorksheet("LeadsTableData");
    
          worksheet.columns = columns;
    
          worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF434343' },
          };
    
          worksheet.getColumn(1).font = { bold: true };
    
          worksheet.getRow(1).font = {
            color: { argb: 'FFFFFFFF' },
            bold: true,
          };
    
          const columnsToAlignRight = [2, 3, 4, 5];
    
          columnsToAlignRight.forEach(columnIndex => {
            worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
          });
    
          worksheet.columns.forEach(column => {
            column.width = column.header.length;
            // column.alignment = { horizontal: 'center' };
          });
    
          data.forEach(singleData => {
            worksheet.addRow(singleData);
          });
    
          worksheet.eachRow({ includeEmpty: false }, row => {
            // store each cell to currentCell
            const currentCell = row._cells;
    
            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCell.forEach(singleCell => {
              // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
              const cellAddress = singleCell._address;
    
              // apply border
              worksheet.getCell(cellAddress).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            });
          });
    
          const buf = await workbook.xlsx.writeBuffer();
    
          saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
          console.error('<<<ERRROR>>>', error);
          console.error('Something Went Wrong', error.message);
        } finally {
          workbook.removeWorksheet('MyWorkBook');
        }
    }

    return (
        <>
        <PageIntro 
            pageName={"Leads"}
            message={`Total ${totalElements || ''} Leads submitted`}
            downloadBtn = {true}
            handleExport = {exportData}
        />
        {/* <div className="py-[16px] px-[12px] mb-[48px] text-[14px] bg-[#FFF] rounded-[8px]">
            <span className="text-[18px]">{dummyData.length} Exporters  </span> are onboarded on platform
        </div> */}
        <LeadsFilter
            allData = {LeadsTabledata?.data?.content}
            filteredData = {filteredData}
            setFilteredData = {setFilteredData}
            selectedStatus = {selectedStatus}
            setSelectedStatus = {setSelectedStatus}
            isStatus={false}
            placeHolder={'Search by Lead id / Lead name'}
            customers={customers}
            setCustomers={setCustomers}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            customerCounter={customerCounter}
            setCustomerCounter={setCustomerCounter}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            toggleSearchDropdown={toggleSearchDropdown}
            setShowSearchDropdown={setShowSearchDropdown}
            setIsSearch={setIsSearch}
            toggleCustomerSelection={toggleCustomerSelection}
            toggleCustomerDropdown={toggleCustomerDropdown}
            showCustomerDropdown={showCustomerDropdown}
            setShowCustomerDropdown={setShowCustomerDropdown}
            statuses={statuses}
            allStatusSelected={allStatusSelected}
            setAllStatusSelected={setAllStatusSelected}
            setSelectedStatuses={setSelectedStatuses}
            showStatusDropdown={showStatusDropdown}
            setShowStatusDropdown={setShowStatusDropdown}
            toggleStatusDropdown={toggleStatusDropdown}
            statusCounter={statusCounter}
            setStatusCounter={setStatusCounter}
            toggleStatusSelection={toggleStatusSelection}
            selectedStatuses={selectedStatuses}
            setShowInvoiceDateDropdown={setShowInvoiceDateDropdown}
            toggleInvoiceDateDropdown={toggleInvoiceDateDropdown}
            showDateCounter={showDateCounter}
            showInvoiceDateDropdown={showInvoiceDateDropdown}
            handleDateSelection={handleDateSelection}
            activeDate={activeDate}
        />
        <LeadsTable
            tableData = {filteredData}
            isSearch={isSearch}
            totalElements = {totalElements}
            loading={fetchingtLeads}
            handlePaginate={handlePaginate}
            currentPage={currentPage}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
            getLeadsTable = {getLeadsTableData}
        />
        </>
    )
}

export default Leads