import React, { useEffect } from "react";
import classes from "./InvoiceViewReconModal.module.scss"
import clsx from "clsx";
import Modal from "../../Layout/Modal";
import Close from "../../Layout/Close";
import Backdrop from "../../Layout/Backdrop";
import { useDispatch , useSelector } from "react-redux";
import { modalActions } from "../../Store/modal-redux";
import ReactDOM from 'react-dom'
import { useState,useRef } from "react";
import { fetchFileFromS3 } from "../../services";
import UseApiCall from "../../hooks/useApiCall";
import FileViewer from "../../Components/atom/FileViewer/FileViewer";

const InvoiceViewRecon = (props) => {
    const {viewInvoice} = props;
    const [whereCalled,setWhereCalled] = useState()
    const dispatch = useDispatch();
    const invoiceViewRecon = useSelector((state) => state.modal.invoiceViewRecon);
    const [invoicesData, setInvoicesData] = useState(viewInvoice?.presigned_url_responses)
    const [invoices, setInvoices] = useState([])

    const [unlockInvoices, setunLockInvoices] = useState([])
    const [invoicetoBigScreen, setInvoicetoBigScreen] = useState([])
    const [activeTab,setActiveTab] = useState("invoice")

    const invoiceLockData = invoicesData?.filter((item)=> item.isLocked === true)
    const invoiceunLockData = invoicesData?.filter((item)=> item.isLocked === false)

    const [getFilesFromS3, fetchingFiles] = UseApiCall(
        fetchFileFromS3,
        (res, headers) => {
            try {
                var blob = new Blob([res], { type: headers['content-type'] })
                let link = window.URL.createObjectURL(blob)
                setInvoices((pre) => [
                    ...pre,
                    {
                        url: link,
                        type: headers['content-type'],
                    },
                ])
            } catch (error) {
                console.log(error)
            }
        },
        (err)=> {},
        'direct'
    )

    const [getunLockFilesFromS3, fetchingunLockFiles] = UseApiCall(
        fetchFileFromS3,
        (res, headers) => {
            try {
                var blob = new Blob([res], { type: headers['content-type'] })
                let link = window.URL.createObjectURL(blob)
                setunLockInvoices((pre) => [
                    ...pre,
                    {
                        url: link,
                        type: headers['content-type'],
                        isLocked: false,
                    },
                ])
            } catch (error) {
                // console.log(error)
            }
        },
        (err)=> {},
        'direct'
    )
    const mappingUrls = () => {
        viewInvoice.presigned_url_responses.map((item)=>{
            getFilesFromS3(item.url)
        })
    }
    const downloadunLockInvoices = () => {
        invoiceunLockData?.map((item, index) => {
            getunLockFilesFromS3(item?.url)
            return item
        })
    }
    useEffect(() => {
        if(viewInvoice){
            mappingUrls();
            // downloadLockInvoices();
            downloadunLockInvoices();
        }
    },[viewInvoice])

    useEffect(()=>{
        setInvoicetoBigScreen([unlockInvoices[0]])
    },[unlockInvoices])
    return (
        <Modal
            className={clsx(
                classes.modal,
                'h-[100vh] !p-0 !w-[660px] z-[260] !bg-[#F4F6FA] top-0'
            )}
        >
            {
                ReactDOM.createPortal(
                    <Backdrop
                        onClick={() => {
                            dispatch(modalActions.invoiceViewRecon(false))
                        }}
                        className={classes.backdrop}
                    />,
                    document.getElementById('backdrop-root')
                )}
            <div className={classes.bigCont}>
                <div className={classes.closeHeader}>
                    <Close onClick = {()=>{
                        dispatch(modalActions.invoiceViewRecon(false))
                    }}
                           className = "bg-gray-300 w-[32px] h-[32px]  rounded-bl-2xl"
                    />
                </div>
                <div className={classes.invoiceName}>
                    <div className={classes.borderBtm}>
                        Invoice : {viewInvoice?.invoice_id}
                    </div>
                </div>
                {<div className={classes.fileViewSection}>
                    {
                        // window.innerWidth >= 600 &&
                        <FileViewer
                            format={['document', 'image']}
                            displayUrls={invoices}

                            downloadPrefix={viewInvoice?.invoice_id}

                            customerEmail={viewInvoice?.reconcile_customer_details?.customer_email}

                            whereCalled="bigScreen"
                            invoicetoBigScreen={invoicetoBigScreen}

                        />
                    }
                    <div className={clsx(classes.invoiceDetailCon)}>
                        <div className={clsx(classes.invoiceTabCon)}>
                            <div className={clsx(`${classes.invoiceTabLocked} `)}
                                 style={{ borderBottom: activeTab === "invoice" ? "2px solid #181C30" : "none" }}
                                 onClick={()=>setActiveTab("invoice")}>
                                <p className={clsx(`${classes.invoiceTabLockedText} `)}
                                   style={{ color: activeTab === "invoice" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
                                >Invoice Attachments</p>
                            </div>
                            {/* <div className={clsx(`${classes.invoiceTabLocked} `)}
              style={{ borderBottom: activeTab === "locked" ? "2px solid #181C30" : "none" }}
              onClick={()=>setActiveTab("locked")}>
              <p className={clsx(`${classes.invoiceTabLockedText} `)}
              style={{ color: activeTab === "locked" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
              >Locked Documents</p>
            </div> */}
                        </div>
                        {activeTab === "invoice" &&
                            (
                                <div className={classes.invoiceCon}>
                                    {
                                        (
                                            <FileViewer
                                                format={['document', 'image']}
                                                displayUrls={unlockInvoices}
                                                downloadPrefix={viewInvoice?.invoice_id}
                                                whereCalled="smallScreen"
                                                setWhereCalled={setWhereCalled}
                                                invoiceData={invoiceunLockData}
                                                setInvoicetoBigScreen={setInvoicetoBigScreen}
                                                //   setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                                                //   paymentLinkOtpRequired={paymentLinkOtpRequired}
                                                invoicetoBigScreen={invoicetoBigScreen}
                                            />
                                        )
                                    }

                                </div>
                            )}

                        {/* {activeTab === "locked" &&
          (
            <div className={classes.invoiceCon}>
              {lockInvoices?.length > 0 ?
                  (
                    <FileViewer
                      format={['document', 'image']}
                      displayUrls={lockInvoices}
                      downloadPrefix={props?.invoice?.invoice_id}
                      whereCalled="smallScreen"
                    //   setWhereCalled={setWhereCalled}
                      invoiceData={invoiceLockData}
                      setInvoicetoBigScreen={setInvoicetoBigScreen}
                      setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                      paymentLinkOtpRequired={paymentLinkOtpRequired}
                      invoicetoBigScreen={invoicetoBigScreen}
                    />
                  )
                  :

                <p className={clsx(`${classes.NoinvoiceTabLockedText} `)}
                style={{ color: activeTab === "lockedd" ? "#1E333F" : "rgba(30, 51, 63, 0.50)" }}
                >No locked document uploaded</p>
                }
              </div>
          )} */}
                    </div>
                </div>}
            </div>
        </Modal>
    )
}

export default InvoiceViewRecon