import classes from './CustomerDetailsModalHeader.module.scss'
import Button from '../../../Layout/Button'
import {Breadcrumb, Spin} from 'antd'
import sideBarIcon from '../../../assets/Images/SideBarIcon.svg'
import adminAvatar from '../../../assets/Icons/account.svg'
import { useDispatch } from 'react-redux'
import { GlobalActions } from '../../../Store/global-redux'
import clsx from 'clsx'
import useClickOutside from '../../../hooks/useClickOutside'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseApiCall from '../../../hooks/useApiCall'
import {apiGetComplianceDetails, apiSignOut, apiVerifyCustomers} from '../../../services'
import CustomerStatusTag from './CustomerStatusTag'
import {enqueueSnackbar} from "notistack";
import {LoadingOutlined} from "@ant-design/icons";
import {CustomersActions} from "../../../Store/customer-redux";
import {modalActions} from "../../../Store/modal-redux";

const CustomerDetailsModalHeader = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showOptions, setShowOptions] = useState(false)
    const optRef = useClickOutside(() => setShowOptions(false))
    const [verifyCust, setVerifyCust] = useState(false)
    const [logOut] = UseApiCall(apiSignOut, () => {
        dispatch({ type: 'CLEAR_STATE' })
        navigate('/login?mode=email')
    })

    const options = [
        {
            title: 'Log Out',
            onClick: () => {
                logOut()
            },
        },
    ]

    const snackbarOptions = {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        action: (key) => (
            <div style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, color: '#FFF' }}>
                Customer verified successfully.
            </div>
        )
    };


    const [verifyCustomer, fetchingCost,ComplianceData] = UseApiCall(apiVerifyCustomers,
        (res) => {
        // console.log(res?.data)
        setVerifyCust(true);

        // enqueueSnackbar('', snackbarOptions);
        enqueueSnackbar('Customer verified successfully.',
            {
                variant: 'success' ,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                autoHideDuration: 3000, // Specify the duration in milliseconds
                style: { fontSize: '10px' }
            }
            )
        dispatch(CustomersActions.setUpdateCustomers())

    }, (err)=>{
            console.log('err',err)
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`,
                {
                    variant: 'error' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        })

    const handleVerify = () => {
        props?.customerId && verifyCustomer({ customerId: props?.customerId, remark: ''})
    }

    // console.log('selectedCustomerId',props.selectedCustomerId)
    // console.log('status',props.status)
    return (
        <div ref={optRef} className={`${classes.page_intro} ${props.className}`}>
            <img
                src={sideBarIcon}
                alt="side-bar-icon"
                className={classes.side_bar}
                onClick={() => dispatch(GlobalActions.setShowSideBarMobile())}
            />
            <div className={classes.pageInfo}>
                <div
                    className={`flex items-center -sm:flex-col -sm:gap-1 -sm:items-center`}
                >
                    <div className={classes.page_name}>{props.pageName}</div>
                    {
                        (<div className="ml-4 invoiceDetailsStatusTag">
                        <CustomerStatusTag
                            status={verifyCust ? "verified" : props.status?.toLowerCase()}
                            type="light"
                            className={`ml-6 text-lg -sm:text-2xl`}
                        />
                    </div>)}
                </div>
                <div className={classes.pageSubtitleContainer}>
                    {props.message && (
                        <div className={classes.message}>{props.message}</div>
                    )}
                    <div className="-md:hidden">
                        <Breadcrumb
                            separator=">"
                            items={[
                                {
                                    title: 'Customer',
                                },
                                {
                                    title: props.customerName,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className={clsx(classes.button, '-md:flex-col gap-5 flex-wrap justify-end')}>
                {props.status === 'PENDING_VERIFICATION' && !verifyCust &&(
                    <button
                        className="px-[1.6rem] py-[1.2rem] w-[100px] text-[1.6rem] text-[#fff] bg-black rounded-[1rem] cursor-pointer"
                        onClick={() => handleVerify()}
                    >
                        {fetchingCost ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#FFFFFF', strokeWidth: 2 }} spin />} />
                        ) : (
                            "Verify"
                        )}
                    </button>
                )}

                {props.arrBtn?.map((item, index) => (
                    <Button
                        key={`${item.text}-${index}`}
                        text={item.text === 'Payment Link' ? 'Single Payment Link' : item.text}
                        icon={item.icon}
                        disabled={!item.active}
                        onClick={() => item.onClick()}
                        className={clsx(classes.btn, (item.text === 'Payment Link')  && `!bg-[#FFF] !border !border-solid !border-[1px] !border-[#060a0d1f]`)}
                        btnClass={clsx(classes.btnClass, item.isVisible || 'hidden')}
                        btnClassIcon={clsx(
                            classes.btnClass_icon,
                            !item.active && 'opacity-50'
                        )}
                        btnClassText={clsx(
                            classes.btnClass_text,
                            !item.active && 'opacity-50',
                            (item.text === 'Payment Link')  && `text-[#000]`
                        )}
                    />
                ))}
            </div>
            <div
                className={classes.profile}
                onClick={() => setShowOptions((pre) => !pre)}
            >
                <img src={adminAvatar} alt="admin-img" className={classes.userImg} />
                <div
                    className={clsx(
                        'absolute top-32 right-[12px] min-w-[200px] z-10 bg-bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700',
                        showOptions ? 'block' : 'hidden'
                    )}
                >
                    <ul
                        className="py-2 w-full text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                    >
                        {options?.map((option) => (
                            <li onClick={option.onClick}>
                                <p className="block cursor-pointer w-full text-center px-8 py-4 text-2xl hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    {option.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetailsModalHeader
