import classes from 'Components/Dropdown/Dropdown.module.scss';
import DropdownLayout from 'Components/Dropdown/DropdownLayout';
import DropdownOption from 'Components/Dropdown/DropdownOption';

const Dropdown = (props) => {

    return (
        <DropdownLayout className={`${classes.dropdown} ${props.className}`}>
            {props.list.map(data => 
                <DropdownOption 
                    data={data}
                    id = {data.id}
                    isActive = {props.selectedOption.id === data.id}
                    onClick={(value) =>{props.onClick(value)}}
                    heading={data.heading}
                />
            )}
        </DropdownLayout>
    )

}

export default Dropdown;