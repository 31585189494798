import classes from "./CustomerReportTable.module.scss"
import clsx from "clsx"
import Shimmer from "../Layout/Shimmer"
import PaymentStatusTag from "./PaymentStatusTag"
import ContactInfo from "../Layout/ContactInfo"
import {Pagination, Tooltip} from "antd"
import CustomerInvoiceTotal from "../Components/atom/CustomerInvoiceTotal"
import React, {useState, useEffect, useRef } from "react"
import ReportTableLayout from "../Layout/ReportTableLayout"
import { formatNumberToLocaleString } from "../hooks/formatNumberToLocaleString"
import CustomerDetailsModal from "../Components/molecule/CustomerDetailsModal/CustomerDetailsModal";
import Action from "../Components/atom/Action"
import {useDispatch, useSelector} from "react-redux";
import {apiGetCustomerDetails, apiVerifyCustomers} from '../services'
import UseApiCall from "../hooks/useApiCall";
import {modalActions} from "../Store/modal-redux";
import {CustomersActions} from "../Store/customer-redux";
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ReactDOM from 'react-dom';
import {enqueueSnackbar, useSnackbar} from "notistack";
import CustomerStatusTag from "../Components/molecule/CustomerDetailsModal/CustomerStatusTag";

const CustomerReportTable = (props) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);
    const [verifyCust, setVerifyCust] = useState(false);

    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [customerOrgId, setCustomerOrgId] = useState('');
    const [loaderId, setLoaderId] = useState('');

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const showCustomerDisplay = useSelector((state) => state.modal.showCustomerDisplay)
    const updateCustomers = useSelector((state) => state.customer.updateCustomers)

    const [getSelectedCustomer] = UseApiCall(apiGetCustomerDetails, (res) => {
        // console.log(res?.data)
        dispatch(CustomersActions.setSelectedCustomer(res?.data))
        setSelectedCustomer(res?.data)
        !showCustomerDisplay && dispatch(modalActions.showCustomerDisplay())
    })

    const snackbarOptions = {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        action: (key) => (
            <div style={{ fontFamily: 'Inter', fontSize: '8px', fontWeight: 500, color: '#FFF' }}>
                Customer verified successfully.
            </div>
        )
    };

    const [verifyCustomer, fetchingCost,ComplianceData,error] = UseApiCall(apiVerifyCustomers,
        (res) => {
        // console.log(res?.data)
        setVerifyCust(true)
        // enqueueSnackbar('', snackbarOptions);

        enqueueSnackbar('Customer verified successfully.',
            {
                variant: 'success' ,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                autoHideDuration: 3000, // Specify the duration in milliseconds
                style: { fontSize: '10px' }
            }
        )
        dispatch(CustomersActions.setUpdateCustomers())
    },
        (err)=>{
            // console.log('errhvdb',JSON.stringify(err?.data?.error_response?.errors[0]))
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`,
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    // console.log('updateCustomers',updateCustomers)
    // console.log('error',error)

    const handleVerify = (data) => {
        // console.log('data',data)
        setLoaderId(data?.id)
        data && verifyCustomer({ customerId: data?.id, remark: ''})
    }

    const handleCustomerSelection = async (customerDetail) => {
        setSelectedCustomerId(customerDetail?.customerId)
        setCustomerId(customerDetail?.id)
        setCustomerOrgId(customerDetail?.organizationId)

        await getSelectedCustomer({ customerId: customerDetail?.id, organizationId:customerDetail?.organizationId })
    }
    // const handleCustomerSelection = () => {
    //     dispatch(modalActions.showCustomerDisplay())
    // }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>
        }
        if (type === 'next') {
            return <a>Next</a>
        }
        return originalElement
    }

    const getCurrencySign = (status) =>{
        if (status === 'ALL' || status === 'USD' || status === 'CAD') {
            return '$'
        }
        else if (status === 'GBP') {
            return '£'
        } else if (status === 'EUR') {
            return '€'
        } else if (status === 'AED') {
            return 'د.'
        }
    }

    const optionArr = [
        {
            text: "View"
        },
        {
            text: "Verify"
        }
    ]

    // const getTotalTooltipText = (data, type) => {
    //     // {X} invoices {Currency icon} {Amount}
    //     // {number_of_invoice} invoices {currency.sign} {amount}
    //
    //     const output = data || [];
    //
    //     const currencyOrder = ['USD', 'EUR', 'GBP']
    //
    //     const sortedData = output.filter((item) =>
    //       currencyOrder.includes(item.currency)
    //     )
    //
    //     const sortedInvoiceSummary = sortedData.sort((a, b) => {
    //       return (
    //         currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
    //       )
    //     })
    //
    //     let result
    //
    //     const map = {
    //       RECEIVABLES: 'totalReceivables',
    //       SETTLED: 'settled',
    //       SETTLEDOUTSIDE: 'settledOutsideFideo',
    //       OUTSTANDING: 'totalOutstanding',
    //     }
    //
    //     let noInvoiceCount = 0
    //
    //     result = sortedInvoiceSummary?.map((item, index) => {
    //       item = item[map[type]]
    //       if (item?.number_of_invoice === 0) {
    //         noInvoiceCount++
    //         // return (
    //         //   <div key={index} className={clsx(classes.currencyTooltip)}>
    //         //     No invoices
    //         //   </div>
    //         // )
    //       } else {
    //         return (
    //           <div key={index} className={clsx(classes.currencyTooltip)}>
    //             <div className={clsx(classes.currencyAmount)}>
    //               {`${item?.number_of_invoice || 'No' } invoices`}
    //             </div>
    //             &nbsp; &nbsp;
    //             <div className={clsx(classes.currencySymbol)}>
    //               {item?.currency?.sign}
    //               {formatNumberToLocaleString((item?.amount), 2, 2)}
    //             </div>
    //           </div>
    //         )
    //       }
    //     })
    //
    //     if (noInvoiceCount === data.length) {
    //       return <div className={clsx(classes.currencyTooltip)}>No invoices</div>
    //     }
    //
    //     return <>{result}</>
    //   }

    useEffect(() => {
        const handleScroll = (event) => {
          if (event.target.id === 'thead') {
            tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
          }
          else if (event.target.id === 'tbody') {
            theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
          }
        };
    
        const thead = theadRef.current;
        const tbody = tbodyRef.current;
    
        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);
    
        return () => {
          thead.removeEventListener('scroll', handleScroll);
          tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);


   return(<>
       {showCustomerDisplay &&
           selectedCustomer &&
           ReactDOM.createPortal(
               <CustomerDetailsModal
                   customerId={customerId}
                   selectedCustomerId={selectedCustomerId}
                   customerData={selectedCustomer}
                   organizationId = {customerOrgId}
                   whereCalled={'compliance'}
               />,
               document.getElementById('modal-root'))
       }
        <div className={classes.allWrap} >
            <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                <thead className={clsx(`${classes.thead}`)}>
                  {props.tableHeader.map((header, index) => (
                    <th key={`${header}-${index}`} className={clsx(classes.cell_header1)} 
                      style={{width:`${header.width}%`,minWidth:`${header.minWidth}px`, maxWidth: `${header.maxWidth}px`}} valign="top">
                      <div className={classes.cell_header2}>
                        <div className={classes.head}>{header?.title}</div>
                        {/* <img
                          src={sorting}
                          alt="sorting-icon"
                          className={classes.sorting}
                        /> */}
                      </div>
                    </th>
                  ))}
                  <th className={classes.cell_header1} style={{ width: '8%' ,minWidth:'100px', maxWidth:'100px'}} >
                    <div
                      className={`${classes.cell_header2}`}
                    >
                      <div className={`${classes.headAction}`}>Action</div>
                    </div>
                  </th>
                  {/* <th className={classes.cell_header1}></th> */}
                </thead>
            </div>
          <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
          <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
            <tbody>
                { props.loading
                  ?
                    <td colSpan={7}>
                      {[1,3,4]?.map((item, index) => (
                        <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                      ))}
                    </td>
                  :
                    props?.tableData?.map((data, index) => (
                      <tr className={classes.row}>
                        <td className={classes.cell}
                          style={{ width: '20%' ,minWidth:'150px',maxWidth:'150px'}}
                            valign={"top"}
                        //  onClick={() =>handleCustomerSelection(data?.customer_detail)}
                         >
                          <div className="flex flex-col items-start">
                            <p
                              className={clsx(
                                classes.invoice,
                                'truncate max-w-[15rem] w-full'
                              )}
                              style={{
                                cursor: 'pointer',
                                userSelect: 'none',
                              }}
                            >
                              {data?.customerId}
                            </p>
                            <div className="h-[20px] mt-1">
                              {data?.verificationStatus !=='VERIFIED' && (
                                // <CustomerVerificationTag
                                //   status={data?.customer_detail?.verificationStatus}
                                //   // message={data?.customer_detail?.verificationComment || 'Hello'}
                                // />
                                <CustomerStatusTag
                                    status={data?.verificationStatus}
                                    type="light"
                                    // status={verifyCust ? "verified" : props.status?.toLowerCase()}
                                    // type="light"
                                    // className={`ml-6 text-lg -sm:text-2xl`}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td className={classes.cell} 
                        style={{ width: '20%' ,minWidth:'216.8px',maxWidth:'216.8px'}}
                            valign={"top"}
                        // onClick={() =>handleCustomerSelection(data?.customer_detail)}
                        >
                          <ContactInfo
                            name={data?.customerName ? data?.customerName : '--'}
                            email={data?.customerEmail ? data?.customerEmail : '--'}
                            country={data?.customerCountry}
                            // countryName={data?.customer_detail?.countryName}
                            classNameCountry={classes.classNameCountry} 
                            tick={data?.verificationStatus ==='VERIFIED'}
                          />
                        </td>
                        <td className={classes.cell} 
                        style={{ width: '10%' ,minWidth:'150px',maxWidth:'150px'}}
                            valign={"top"}
                        // onClick={() =>handleCustomerSelection(data?.customer_detail)}
                        >
                          <ContactInfo
                            name={data?.organizationName}
                            // classExporterName={classes.exporterName}
                              // tick={true}
                          />
                        </td>
                        {/* RECEIVABLES */}
                        <td className={classes.cell} 
                        style={{ width: '16%' ,minWidth:'130px',maxWidth:'130px'}}
                        valign={"top"}
                        // onClick={() =>handleCustomerSelection(data?.customer_detail)}
                        > 
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              // title={() =>
                              //   getTotalTooltipText(
                              //     data?.invoice_summary,
                              //     'RECEIVABLES'
                              //   )
                              // }
                            >
                            {/*<div className={clsx(classes.currencySymbolContainer)}>*/}
                              {/* {data?.invoice_summary?.map((item,index)=>(
                                <div className={clsx(classes.currencySymbolInvoice)}>
                                  <span className={clsx(classes.currencySymbolSign)}>{item?.totalReceivables?.currency?.sign}</span>
                                  <span className={clsx(classes.currencySymbolAmt)}>{item?.totalReceivables?.amount ? item?.totalReceivables?.amount?.toFixed(2)?.toLocaleString('en-US') : '-'}</span>
                                </div>
                              ))} */}
                              {/* <CustomerInvoiceTotalCount data={data} category="totalReceivables" /> */}
                              {/*<CustomerInvoiceTotal data={data} category="totalReceivables" />*/}
                                {
                                    data?.reportsData === null ? (
                                        <div className='flex flex-col' style={{ marginTop: 0 }}>
                                            <div>
                                                {"--"}
                                            </div>
                                            <div className="text-[#1e333f80] mt-[4px]">
                                                {"--"}
                                            </div>
                                        </div>
                                    ) : (
                                        data?.reportsData?.map((data, indexOf) => (
                                            <div className='flex flex-col' style={{ marginTop: indexOf === 0 ? '' : '15px' }} key={indexOf}>
                                                <div>
                                                    {data?.totalAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.totalAmount), 2, 2) : "--"}
                                                </div>
                                                <div className="text-[#1e333f80] mt-[4px]">
                                                    {data?.totalInvoices ? (data?.totalInvoices + (data?.totalInvoices > 1 ? " Invoices" : " Invoice")) : "--"}
                                                </div>
                                            </div>
                                        ))
                                    )
                                }
                            </Tooltip>
                          </div>
                        </td>
                          {/* SETTLED */}
                          <td className={clsx(classes.cell)}
                              style={{width: '14%', minWidth: '130px', maxWidth: '130px'}}
                              valign={"top"}
                              // onClick={() =>handleCustomerSelection(data?.customer_detail)}
                          >
                              <div className={classes.text}>
                                  <Tooltip
                                      placement="bottomLeft"
                                      // title={() =>
                                      //   getTotalTooltipText(data?.invoice_summary, 'SETTLED')
                              // }
                            >
                                {data?.reportsData === null ? (
                                        <div className='flex flex-col' style={{ marginTop: 0 }}>
                                            <div>
                                                {"--"}
                                            </div>
                                            <div className="text-[#1e333f80] mt-[4px]">
                                                {"--"}
                                            </div>
                                        </div>
                                    ) :
                                    (data?.reportsData?.map((data,indexOf) => (
                                    <div className='flex flex-col' style={{marginTop : indexOf === 0 ? '' : '15px' }} key={indexOf}>
                                        <div>
                                            {data?.settledAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.settledAmount),2,2) : "--"}
                                        </div>
                                        <div className="text-[#1e333f80] mt-[4px]">
                                            {data?.settledInvoices ? (data?.settledInvoices + (data?.settledInvoices > 1 ? " Invoices" : " Invoice")) : "-"}
                                        </div>
                                    </div>
                                        ))
                                    )
                                }
                            </Tooltip>
                          </div>
                        </td>
                        {/* SETTLEDOUTSIDE */}
                        <td className={classes.cell} style={{ width: '15%' ,minWidth:'130px',maxWidth:'130px'}}
                            valign={"top"}
                            // onClick={() =>handleCustomerSelection(data?.customer_detail)}
                        >
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              // title={() =>
                              //   getTotalTooltipText(data?.invoice_summary, 'SETTLEDOUTSIDE')
                              // }
                            >
                                {data?.reportsData === null ? (
                                    <div className='flex flex-col' style={{ marginTop: 0 }}>
                                        <div>
                                            {"--"}
                                        </div>
                                        <div className="text-[#1e333f80] mt-[4px]">
                                            {"--"}
                                        </div>
                                    </div>
                                ) : (data?.reportsData?.map((data,indexOf) => (
                                    <div className='flex flex-col' style={{marginTop : indexOf === 0 ? '' : '15px' }} key={indexOf}>
                                        <div>
                                            {data?.settledOutsideAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.settledOutsideAmount),2,2) : "--"}
                                        </div>
                                        <div className="text-[#1e333f80] mt-[4px]">
                                            {data?.settledOutsideInvoices ? (data?.settledOutsideInvoices + (data?.settledOutsideInvoices > 1 ? " Invoices" : " Invoice")) : "-"}
                                        </div>
                                    </div>
                                    )))
                                }
                            </Tooltip>
                          </div>
                        </td>
                        {/* OUTSTANDING */}
                        <td className={classes.cell} style={{ width: '14%' ,minWidth:'130px',maxWidth:'130px'}}
                            valign={"top"}
                            // onClick={() =>handleCustomerSelection(data?.customer_detail)}
                        >
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              // title={() =>
                              //   getTotalTooltipText(data?.invoice_summary, 'OUTSTANDING')
                              // }
                            >
                                {data?.reportsData === null ? (
                                    <div className='flex flex-col' style={{ marginTop: 0 }}>
                                        <div>
                                            {"--"}
                                        </div>
                                        <div className="text-[#1e333f80] mt-[4px]">
                                            {"--"}
                                        </div>
                                    </div>
                                ) : (data?.reportsData?.map((data,indexOf) => (
                                    <div className='flex flex-col' style={{marginTop : indexOf === 0 ? '' : '15px' }} key={indexOf}>
                                        <div>
                                            {data?.outstandingAmount ? getCurrencySign(data?.currency) + formatNumberToLocaleString((data?.outstandingAmount),2,2) : "--"}
                                        </div>
                                        <div className="text-[#1e333f80] mt-[4px]">
                                            {data?.outstandingInvoices ? (data?.outstandingInvoices + (data?.outstandingInvoices > 1 ? " Invoices" : " Invoice")) : "-"}
                                        </div>
                                    </div>
                                    ))
                                )
                                }
                            </Tooltip>
                          </div>
                        </td>
                        <td className={classes.cell}  style={{ width: '8%' ,minWidth:'100px',maxWidth:'100px'}} valign={"top"}>
                            {/* <div className="flex items-center justify-end">
                                <Action options = {optionArr} />
                            </div> */}
                            <div className="flex flex-col gap-[4px]">
                                <button
                                    className="p-[4px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                    onClick={() => handleCustomerSelection(data)}
                                >
                                    Details
                                </button>
                                {data?.verificationStatus === 'PENDING_VERIFICATION' && (
                                    <button
                                        className="p-[4px] text-[12px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                        onClick={() => handleVerify(data)}
                                    >
                                        {fetchingCost && loaderId === data?.id ? (
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: '#FFFFFF', strokeWidth: 4 }} spin />} />
                                        ) : (
                                            "Verify"
                                        )}
                                    </button>
                                )}
                            </div>

                        </td>
                      </tr>
                    ))
                  }
            </tbody>
          </ReportTableLayout>
              {
                  props?.tableData?.length > 0 &&
                  <div className={classes.paginationWrap}>
                      <Pagination
                          showSizeChanger={false}
                          current={props?.curentPage + 1}
                          total={props?.resData?.totalElements}
                          pageSize={props?.pageSize}
                          // defaultPageSize={30}
                          itemRender={itemRender}
                          onChange={props?.handlePaginate}
                      />
                  </div>
              }
          </div>
        </div>
            {(props?.tableData?.length === 0 && !props.loading) &&
              (<div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '4rem',
                  fontSize: '2.5rem'
                }}
                className={classes.table}
              >
                  {props?.isSearch?.length === 0 ? 'No Recent Customer Yet.' : 'No customer with this id / name.' }
              </div>)
            }
    </>)
}

export default CustomerReportTable