import React from 'react';
import classes from "./ComplianceChecksProfile.module.scss";
import clsx from "clsx"
import ReportTableLayout from "../../Layout/ReportTableLayout"
import {useRef, useEffect, useState} from "react"
import PaymentStatusTag from "../../Components/atom/PaymentStatusTag"
import {modalActions} from "../../Store/modal-redux"
import {useDispatch, useSelector} from "react-redux"
import ReactDOM from 'react-dom'
import LeadComplianceDetails from "./LeadComplianceDetails";
import NonComplianceDetails from "./NonComplianceDetails";
import SearchDetailsInfo from "./SearchDetailsInfo";
import Shimmer from "../../Layout/Shimmer";


const tableHeaderData = [
    {
        header: "Verification Type",
        width: 20,
        maxWidth: 150
    },
    {
        header: "Value",
        width: 20,
        maxWidth: 150
    },
    {
        header: "Category",
        width: 20,
        maxWidth: 150
    },
    {
        header: "Status",
        width: 20,
        maxWidth: 100
    },
    {
        header: "Remarks",
        width: 20,
        maxWidth: 150
    },
    {
        header: "Action",
        width: 20,
        maxWidth: 100
    },
]


const ComplianceChecksProfile = (props) => {
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);
    const [detailsData, setDetailsData] = useState({});
    const [detailsKey, setDetailsKey] = useState("");
    const [preSignedUrls, setPreSignedUrls] = useState([]);
    const [openModal, setOpenModal] = useState("");

    const dispatch = useDispatch();
    const searchDetails = useSelector(state => state.modal.searchDetails);

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            } else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const userBasicCompliance = props?.exporterDetails?.basic_user_details?.verification_data || [];
    const gstinDetails = props?.exporterDetails?.business_details?.company_identification_details?.verification_data_gstin || [];
    const cinDetails = props?.exporterDetails?.business_details?.company_identification_details?.verification_data_cin || [];
    const llpinDetails = props?.exporterDetails?.business_details?.company_identification_details?.verification_data_llpin || [];
    const companyPanDetails = props?.exporterDetails?.business_details?.company_identification_details?.verification_data_company_pan || [];
    const businessDirectorDetails = props?.exporterDetails?.business_details?.company_business_director_details || [];
    const businessOwnerDetails = props?.exporterDetails?.business_details?.company_business_owner_details || [];
    const userDetails = props?.exporterDetails?.business_details?.company_account_user_details || [];
    const nonComplianceDetails = props?.exporterDetails?.business_details?.company_details?.verification_data_negative_checks || [];
    const bankAccountDetails = props?.exporterDetails?.bank_details?.verification_data_bank_account || [];
    //
    const complianceData = [...userBasicCompliance, ...gstinDetails, ...cinDetails, ...llpinDetails, ...companyPanDetails, ...nonComplianceDetails, ...bankAccountDetails];


    businessOwnerDetails.map(item => {
        if (item?.verification_data && item?.verification_data.length > 0) {
            complianceData.push(...item?.verification_data);
        }
    })

    businessDirectorDetails.map(item => {
        if (item?.verification_data && item?.verification_data.length > 0) {
            complianceData.push(...item?.verification_data);
        }
    })

    userDetails.map(item => {
        if (item?.verification_data && item?.verification_data.length > 0) {
            complianceData.push(...item?.verification_data);
        }
    })


    const handleDetails = (row) => {

        if (row?.subGrouping === "ORGANISATION" && row?.piiDataType === "PAN") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("ORG_PAN");
            setDetailsData({
                company_pan: props?.exporterDetails?.business_details?.company_identification_details?.company_pan,
                company_pan_urls: props?.exporterDetails?.business_details?.company_identification_details?.company_pan_urls,
                verification_data: props?.exporterDetails?.business_details?.company_identification_details?.verification_data_company_pan
            })
            setPreSignedUrls(props?.exporterDetails?.business_details?.company_identification_details?.company_pan_urls);
        } else if (row?.subGrouping === "GST") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("GST");
            setDetailsData({
                gstin_number: props?.exporterDetails?.business_details?.company_identification_details?.gstin_number,
                gstin_urls: props?.exporterDetails?.business_details?.company_identification_details?.gstin_urls,
                verification_data: props?.exporterDetails?.business_details?.company_identification_details?.verification_data_gstin
            })
            setPreSignedUrls(props?.exporterDetails?.business_details?.company_identification_details?.gstin_urls)
        } else if (row?.subGrouping === "CIN") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("CIN");
            setDetailsData({
                cin_number: props?.exporterDetails?.business_details?.company_identification_details?.cin_number,
                cin_urls: props?.exporterDetails?.business_details?.company_identification_details?.cin_urls,
                verification_data: props?.exporterDetails?.business_details?.company_identification_details?.verification_data_cin
            })
            setPreSignedUrls(props?.exporterDetails?.business_details?.company_identification_details?.cin_urls)
        } else if (row?.subGrouping === "LLPIN") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("LLPIN");
            setDetailsData({
                llpin: props?.exporterDetails?.business_details?.company_identification_details?.llpin,
                llpin_urls: props?.exporterDetails?.business_details?.company_identification_details?.llpin_urls,
                verification_data: props?.exporterDetails?.business_details?.company_identification_details?.verification_data_llpin
            })
            setPreSignedUrls( props?.exporterDetails?.business_details?.company_identification_details?.llpin_urls);
        } else if (row?.subGrouping === "NEGATIVE_CHECK") {
            setOpenModal("NON_COMPLIANCE");
            setDetailsData(props?.exporterDetails?.business_details?.company_details)
        } else if (row?.subGrouping === "DIRECTOR") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("DIRECTOR");
            businessDirectorDetails.map(item => {
                if (item?.director_pan === row?.value) {
                    setDetailsData(item);
                    setPreSignedUrls(item?.director_pan_urls);
                }
            })
        } else if (row?.subGrouping === "OWNER") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("OWNER");
            businessOwnerDetails.map(item => {
                if (item?.owner_pan === row?.value) {
                    setDetailsData(item);
                    setPreSignedUrls(item?.owner_pan_url);
                }
            })
        } else if (row?.subGrouping === "USER") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("USER");
            setDetailsData( props?.exporterDetails?.basic_user_details)
            setPreSignedUrls(userDetails[0].user_pan_urls)
            // userDetails.map(item => {
            //     if (item?.user_pan === row?.value) {
            //         setDetailsData(item);
            //         setPreSignedUrls(item?.user_pan_urls);
            //     }
            // })
        }
        else if(row?.subGrouping === "BANK") {
            setOpenModal("LEAD_COMPLIANCE");
            setDetailsKey("BANK");
            setDetailsData({
                bankAccountDetails:props?.exporterDetails?.bank_details?.bank_account_details,
                verification_data:bankAccountDetails
            })
        }
        dispatch(modalActions.setSearchDetails(true))
    }


    return (
        <>
            {
                searchDetails &&
                ReactDOM.createPortal(
                    openModal === "NON_COMPLIANCE"
                        ?
                        <NonComplianceDetails
                            detailsData={detailsData}
                            getLeadDetails = {props?.getLeadDetails}
                            leadId = {props?.leadId}
                        />
                        :
                        <LeadComplianceDetails
                            detailsData={detailsData}
                            detailsKey={detailsKey}
                            companyDetails={props?.exporterDetails?.business_details?.company_details}
                            preSignedUrls={preSignedUrls}
                            setDetailsData={setDetailsData}
                            setPreSignedUrls={setPreSignedUrls}
                            setDetailsKey={setDetailsKey}
                            getLeadDetails = {props?.getLeadDetails}
                            leadId = {props?.leadId}
                        />


                    ,
                    document.getElementById('modal-root')
                )
            }
            <div className={classes.allWrap}>
                <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)}
                     id="thead" ref={theadRef}>
                    <thead>
                    {tableHeaderData.map((header, index) => (
                        <th key={`${header}-${index}`} className={classes.cell_header1}
                            style={{
                                width: `${header.width}%`,
                                minWidth: `${header.maxWidth}px`,
                                maxWidth: `${header.maxWidth}px`
                            }}
                            valign="top"
                        >
                            <div className={classes.cell_header2}>
                                <div className={classes.head}>{header.header}</div>
                            </div>
                        </th>
                    ))}
                    </thead>
                </div>

                <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef}>
                    <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
                        {props.loading
                            ?
                            <td colSpan={7}>
                                {[1,2,3,4]?.map((item, index) => (
                                    <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                                ))}
                            </td>
                            :
                            <tbody>
                        {
                            complianceData.map((data, index) =>
                            // data.status !== "UNDER REVIEW" &&
                            (
                            <tr className={classes.row}>
                        <td className={classes.cell}
                            style={{
                                width: '20%',
                                minWidth: '150px',
                                maxWidth: '150px',
                                overflowWrap: "anywhere"
                            }}
                            valign="top"
                        >
                            {
                                data?.piiDataType === "PAN"
                                    ?
                                    <span>
                                                            {
                                                                data?.subGrouping === "ORGANISATION"
                                                                    ?
                                                                    "Company's"
                                                                    :
                                                                    data?.subGrouping === "DIRECTOR"
                                                                        ?
                                                                        "Director's"
                                                                        :
                                                                        data?.subGrouping === "OWNER"
                                                                            ?
                                                                            "Owner's"
                                                                            :
                                                                            "User's"
                                                            } PAN
                                                        </span>
                                    :
                                    data?.piiDataType === "NEGATIVE_CHECK"
                                        ?
                                        "Non-Compliance"
                                        :
                                        data?.piiDataType === "BANK_ACCOUNT"
                                            ?
                                            "Bank Account"
                                            :
                                            data?.piiDataType
                            }
                        </td>
                        <td className={classes.cell}
                            style={{
                                width: '20%',
                                minWidth: '150px',
                                maxWidth: '150px',
                                overflowWrap: "anywhere"
                            }}
                            valign="top"
                        >
                            <div>{data?.value ? data?.value : "-"}</div>
                        </td>
                        <td className={classes.cell}
                            style={{
                                width: '20%',
                                minWidth: '150px',
                                maxWidth: '150px',
                                overflowWrap: "anywhere"
                            }}
                            valign="top"
                        >
                            {data?.category ? data?.category : "-"}
                        </td>
                        <td className={classes.cell}
                            style={{
                                width: '20%',
                                minWidth: '100px',
                                maxWidth: '100px',
                                overflowWrap: "anywhere"
                            }}
                            valign="top"
                        >
                            {data?.verificationStatus ?
                                <PaymentStatusTag status={data?.verificationStatus} type="light"/>
                                :
                                "--"
                            }
                        </td>
                        <td className={classes.cell}
                            style={{
                                width: '20%',
                                minWidth: '150px',
                                maxWidth: '150px',
                                overflowWrap: "anywhere"
                            }}
                            valign="top"
                        >
                            {data?.comment.length >0 ? (data?.comment[data?.comment?.length-1]?.remarks?.length > 70 ? (data?.comment[data?.comment?.length-1]?.remarks?.slice(0, 70) + "...") : data?.comment[data?.comment?.length-1]?.remarks) : "--"}
                        </td>
                        <td className={classes.cell}
                            style={{
                                width: '20%',
                                minWidth: '100px',
                                maxWidth: '100px',
                                overflowWrap: "anywhere"
                            }}
                            valign="top"
                        >
                            <div className="flex flex-col gap-[4px]">
                                <button
                                    // className="p-[4px] text-[#fff] bg-black rounded-[4px] opacity-25 cursor-not-allowed"
                                    className="p-[4px] text-[#fff] bg-black rounded-[4px]"
                                    onClick={() => {
                                        handleDetails(data)
                                    }}
                                >Details
                                </button>
                            </div>
                        </td>
                    </tr>
                    ))


                    }
                </tbody>

                        }
                    </ReportTableLayout>
                    {((complianceData.length === 0)&& !props?.loading) &&
                        (
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop: '4rem',
                                    fontSize: '2.5rem'
                                }}
                                className={classes.table}
                            >
                                No compliance data yet !
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceChecksProfile