// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReconSuccessModal_bigCont__R5Grz {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  z-index: 100;
}
.ReconSuccessModal_bigCont__R5Grz .ReconSuccessModal_imgBox__FIw\\+n {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.ReconSuccessModal_bigCont__R5Grz .ReconSuccessModal_imgBox__FIw\\+n .ReconSuccessModal_mainIcon__-UMfa {
  width: 88px;
  height: 88px;
}
.ReconSuccessModal_bigCont__R5Grz .ReconSuccessModal_contentBox__RxQZt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 0px 40px;
}
.ReconSuccessModal_bigCont__R5Grz .ReconSuccessModal_contentBox__RxQZt .ReconSuccessModal_subHeading__j\\+7pN {
  color: #181C30;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.ReconSuccessModal_bigCont__R5Grz .ReconSuccessModal_contentBox__RxQZt .ReconSuccessModal_subHeading__j\\+7pN .ReconSuccessModal_reconAmount__8eSkw {
  font-weight: 700 !important;
}
.ReconSuccessModal_bigCont__R5Grz .ReconSuccessModal_contentBox__RxQZt .ReconSuccessModal_doneBtn__-yX1O {
  width: 416px;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  background-color: #181C30;
}

.ReconSuccessModal_errorBox__YHmPl {
  border-radius: 8px;
  background: rgba(255, 78, 78, 0.12);
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/ReconSuccessModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;AAEJ;AAAI;EACE,WAAA;EACA,YAAA;AAEN;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,iBAAA;AAAJ;AAEI;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAN;AAEM;EACE,2BAAA;AAAR;AAII;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;AAFN;;AAQA;EACE,kBAAA;EACA,mCAAA;EACA,aAAA;EAEA,aAAA;EACA,uBAAA;EACA,SAAA;AANF","sourcesContent":[".bigCont{\n  display: flex;\n  flex-direction: column;\n  padding: 40px 0px;\n  z-index: 100;\n  .imgBox{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-bottom: 20px;\n\n    .mainIcon{\n      width: 88px;\n      height: 88px;\n    }\n  }\n\n  .contentBox{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 24px;\n    padding: 0px 40px;\n\n    .subHeading{\n      color: #181C30;\n      text-align: center;\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 24px;\n\n      .reconAmount{\n        font-weight: 700 !important;\n      }\n    }\n\n    .doneBtn{\n      width: 416px;\n      padding: 12px 20px;\n      border-radius: 8px;\n      font-size: 16px;\n      text-align: center;\n      color: #FFF;\n      font-weight: 600;\n      background-color: #181C30;\n    }\n  }\n\n}\n\n.errorBox {\n  border-radius: 8px;\n  background: rgba(255, 78, 78, 0.12);\n  display: flex;\n  // width: 416px;\n  padding: 20px;\n  align-items: flex-start;\n  gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigCont": `ReconSuccessModal_bigCont__R5Grz`,
	"imgBox": `ReconSuccessModal_imgBox__FIw+n`,
	"mainIcon": `ReconSuccessModal_mainIcon__-UMfa`,
	"contentBox": `ReconSuccessModal_contentBox__RxQZt`,
	"subHeading": `ReconSuccessModal_subHeading__j+7pN`,
	"reconAmount": `ReconSuccessModal_reconAmount__8eSkw`,
	"doneBtn": `ReconSuccessModal_doneBtn__-yX1O`,
	"errorBox": `ReconSuccessModal_errorBox__YHmPl`
};
export default ___CSS_LOADER_EXPORT___;
