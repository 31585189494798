// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Authentication_errors__NhxZl {
  background-color: #FF6868;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
.Authentication_errors_moreinfo__PRAWg {
  filter: var(--whiteColor_filter);
  margin-right: 1.2rem;
  margin-left: auto;
}
.Authentication_errors_text__HxbFh {
  color: white;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-right: 1.2rem;
}
.Authentication_errors_close__0KPNC {
  margin-left: auto;
  filter: var(--whiteColor_filter);
  height: 2.8rem;
  width: 2.8rem;
  padding: 0.2rem;
  background-color: rgba(30, 51, 63, 0.2);
  border-radius: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/Layout/Authentication.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;AACE;EACE,gCAAA;EAGA,oBAAA;EACA,iBAAA;AADJ;AAIE;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,oBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;EACA,uCAAA;EACA,qBAAA;AAHJ","sourcesContent":[".errors{\n  background-color: #FF6868;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem 2rem;\n\n  &_moreinfo{\n    filter: var(--whiteColor_filter);\n    // height: 2rem;\n    // width: 2rem;\n    margin-right: 1.2rem;\n    margin-left: auto;\n  }\n\n  &_text{\n    color: white;\n    font-size: 1.4rem;\n    line-height: 2.4rem;\n    margin-right: 1.2rem;\n  }\n\n  &_close{\n    margin-left: auto;\n    filter: var(--whiteColor_filter);\n    height: 2.8rem;\n    width: 2.8rem;\n    padding: 0.2rem;\n    background-color: rgba(30, 51, 63, 0.2);\n    border-radius: 0.4rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errors": `Authentication_errors__NhxZl`,
	"errors_moreinfo": `Authentication_errors_moreinfo__PRAWg`,
	"errors_text": `Authentication_errors_text__HxbFh`,
	"errors_close": `Authentication_errors_close__0KPNC`
};
export default ___CSS_LOADER_EXPORT___;
