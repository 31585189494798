import classes from './PayoutReportTable.module.scss'
import ReportTableLayout from '../Layout/ReportTableLayout'
import { DownloadOutlined } from '@ant-design/icons'
import {Pagination, Tag} from 'antd'
// import MobilePayoutReportTable from './MobilePayoutReportTable'
import UseApiCall from '../hooks/useApiCall'
import { apiDownloadFiraReportWithFiraId} from '../services';
import { enqueueSnackbar } from 'notistack'
// import { tourArrays } from "constants/Tour";
import { Tooltip } from "antd";
// import TooltipTourData from "Components/Tour/TooltipTourData";
// import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import PaymentStatusTag from "./PaymentStatusTag";
import dayjs from "dayjs";
import { baseUrl } from "../constants/Network";
import Shimmer from '../Layout/Shimmer'
import ContactInfo from '../Layout/ContactInfo'
import MobilePayoutReportTable from './MobilePayoutReportTable'
import { useSelector } from 'react-redux'
import infoIcon from "../assets/Icons/moreInfo.svg"

const PayoutReportTable = (props) => {
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);
  const scrollXValue = useSelector((state) => state.global.scrollXValue);

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }

  const tableHeaderData = [
  {
    header:'EXPORTER',
    maxWidth:130,
    width:10,
  },
  {
    header:'Payout Reference',
    maxWidth:200,
    width:15,
  },
  {
    header:'Initiated On',
    maxWidth:100,
    width:10,
  },
  {
    header:'Expected On',
    maxWidth:100,
    width:10,
  },
  {
    header:'Gross Payout',
    maxWidth:100,
    width:10,
  },
  {
    header:'fx rate',
    maxWidth:100,
    width:10,
    icon:true
  },
  {
    header:'Settled Amount',
    maxWidth:100,
    width:10,
  },
  {
    header:'Payout Status',
    maxWidth:130,
    width:10,
  },
  {
    header:'Payment Advice',
    maxWidth:70,
    width:15,
  }
  ]

  const [downloadFiraReportFiraId] = UseApiCall(apiDownloadFiraReportWithFiraId,
    (res) => {
      let url = res?.data
      window.location.assign(url)
      // console.log(res)
      // console.log(url)
    }, () => {
      enqueueSnackbar(`Error in downloading Payment Advice.`, { variant: 'error' })
    }
  )

  const handleReportDownload = async (firaId) => {
    await downloadFiraReportFiraId({ firaId })
  }

  const handleReportDownloadWithPayout = async (payoutId) => {
    const response = await fetch(baseUrl.api + `/payment-advice/mt103?payoutReference=${payoutId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/pdf",
        },
        credentials: "include",
    }).then(async function (t) {
        let filename = t.headers.get('Content-Disposition')?.split(';')?.find(n => n.includes('filename='))?.replace('filename=', '')?.trim();
        const b = await t.blob();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        if (!filename) {
            filename = "MT103_Report";
            // filename = `${filename?.replace(' ', '_')}_details`
        }
        a.setAttribute("download", filename);
        a.click();
    });
  }


  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let topValue = '-43';

  if(scrollXValue >= 3000)
    topValue = '-93'
  else if(scrollXValue >= 2600 && scrollXValue < 3000)
    topValue = '-83'
  else if(scrollXValue >= 2300 && scrollXValue < 2600)
    topValue = '-73'
  else if(scrollXValue >= 1900 && scrollXValue < 2300)
    topValue = '-63'
  else if(scrollXValue >= 1600 && scrollXValue < 1900)
    topValue = '-48'
  else if(scrollXValue < 1600)
    topValue = '-43'


  // console.log('totalItems',props?.totalItems.totalItems)

  return (
    <>
      <div className={classes.allWrap}>
          <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} style={{top : `${topValue}px`}} id="thead" ref={theadRef}>
            <thead>
              {tableHeaderData.map((header, index) => (
                <th key={`${header}-${index}`} className={classes.cell_header1} 
                  style={{width:`${header.width}%`, minWidth:`${header.maxWidth}px`, maxWidth:`${header.maxWidth}px`}}
                  valign="top"
                >
                  <div className={classes.cell_header2}>
                    <div className={classes.head}>{header.header}</div>
                    {
                      header?.icon && <Tooltip title={'Mid Market Rate'} placement="bottom">
                        <img src={infoIcon} className='w-[15px] h-[15px] ml-[8px]' />
                      </Tooltip>
                    }
                  </div>
                </th>
              ))}
            </thead>
          </div>

          <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
            <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
              <tbody>
              { 
              props?.loading
                  ?
                    <td colSpan={5}>
                      {[1,2,3]?.map((item, index) => (
                        <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                      ))}
                    </td>
                  :
                    props?.tableData?.map((data, index) => (
                    <tr key={data.invoiceId} className={classes.row}>
                        <td className={classes.cell} 
                                style={{width: '10%', minWidth: '130px', maxWidth: '130px', overflowWrap: "anywhere" }}
                                valign="top"
                        >
                            <ContactInfo 
                                name = {data?.organizationName ? data?.organizationName : "--"}
                                // name = {data?.exporter?.name}
                                // email = {data?.exporter?.country}
                            />
                        </td>
                        <td className={classes.cell} 
                              style={{width: '15%', minWidth: '200px', maxWidth: '200px', overflowWrap: "anywhere"}}
                              valign="top"
                          >
                              {data?.payoutReference ? data?.payoutReference : '--'}
                        </td>
                        <td className={classes.cell}
                        style={{width: '10%', minWidth: '100px', maxWidth: '100px'}}
                        valign="top"
                        >
                          <div style={{ textAlign: "left", marginBottom: "0.4rem" }}>
                            {data?.initiatedOn ? dayjs(data?.initiatedOn).utc().utcOffset('+05:30').format('D MMM YYYY') : '--'}
                          </div>
                          <div style={{ color: "#1e333f80", textAlign: "left", marginBottom: "0.4rem" }}>
                            {data?.initiatedOn ? dayjs(data?.initiatedOn).utc().utcOffset('+05:30').format('hh:mm a') : '--'}
                          </div>
                        </td>
                        <td className={classes.cell}
                        style={{width: '10%', minWidth: '100px', maxWidth: '100px'}}
                        valign="top"
                        >
                          <div style={{ textAlign: "left", marginBottom: "0.4rem" }}>
                            {data?.expectedOn ? dayjs(data?.expectedOn).utc().utcOffset('+05:30').format('D MMM YYYY') : '--'}
                          </div>
                          <div style={{ color: "#1e333f80", textAlign: "left", marginBottom: "0.4rem" }}>
                            {data?.expectedOn ? dayjs(data?.expectedOn).utc().utcOffset('+05:30').format('hh:mm a') : '--'}
                          </div>
                        </td>
                        
                        <td 
                            className={clsx(classes.cell, 'tableHeader')} 
                            style={{width: '10%', minWidth: '100px', maxWidth: '100px', overflowWrap: "anywhere"}}
                            valign="top"
                        >
                          {/* {
                        //   data?.invoiceCurrency?.sign +
                            formatNumberToLocaleString((data?.grossPayOut), 2, 2)
                          } */}
                          <Tooltip
                            title={
                              <table style={{ borderCollapse: 'collapse', width: '100%', fontSize: "1.4rem" }}>
                                <thead>
                                  <tr>
                                    <th style={{ maxWidth: '50%', borderBottom: '1px solid white', padding: '8px', textAlign: 'left', overflowWrap: "anywhere" }}>Invoice Id</th>
                                    <th style={{ maxWidth: '50%', minWidth: "130px", borderBottom: '1px solid white', padding: '8px', textAlign: 'right', overflowWrap: "anywhere" }}>Gross Amount</th>
                                    {/* <th style={{ maxWidth: '50%', borderBottom: '1px solid white', padding: '8px', textAlign: 'left', overflowWrap: "anywhere" }}>Net Payout</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.invoices?.map((item) => (
                                    <tr key={item?.id}>
                                      <td style={{ maxWidth: '50%', padding: '8px', textAlign: 'left', overflowWrap: "anywhere" }}>
                                        {item?.invoiceId}
                                      </td>
                                      <td style={{ maxWidth: '50%', minWidth: "130px", padding: '8px', textAlign: 'right', overflowWrap: "anywhere" }}>
                                        {data?.invoiceCurrency?.sign}
                                        {
                                          data?.invoiceCurrency?.sign === '₹' 
                                            ? item?.grossAmount?.toLocaleString('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                            : item?.grossAmount?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                        }
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            }
                            overlayInnerStyle={{
                              // color: "orange",
                              background: "#060A0D",
                              width: "250px",
                              padding: "0px",
                              // borderRadius: "16px",
                            }}
                            // open={true}
                            overlayStyle={{maxWidth: '266px'}}
                            placement="bottomLeft"
                          >
                            <div style={{ textAlign: "left", marginBottom: "0.4rem" }}>
                              {data?.grossPayout ? data?.invoiceCurrency?.sign : '--'}
                              {
                                data?.invoiceCurrency?.sign === '₹' 
                                  ? data?.grossPayout?.toLocaleString('en-IN', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : data?.grossPayout?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                              }
                            </div>
                            <div style={{ color: "#1e333f80", textAlign: "left", marginBottom: "0.4rem" }}>
                              {`${data?.invoices?.length?data?.invoices?.length:'--'} Invoice${data?.invoices?.length >= 2 ? 's' : ''}`}
                            </div>
                          </Tooltip>
                        </td>
                        <td 
                            className={classes.cell} 
                            style={{width: '10%', minWidth: '100px', maxWidth: '130px', overflowWrap: "anywhere"}}
                            valign="top"
                        >
                           {data?.midMarketRate ? data?.midMarketRate : "-"}
                        </td>
                        <td 
                            className={classes.cell} 
                            style={{width: '10%', minWidth: '100px', maxWidth: '100px', overflowWrap: "anywhere"}}
                            valign="top"
                        >
                          <Tooltip
                            title={
                              <table style={{ borderCollapse: 'collapse', width: '100%', fontSize: "1.4rem" }}>
                                <thead>
                                  <tr>
                                    <th style={{ maxWidth: '50%', borderBottom: '1px solid white', padding: '8px', textAlign: 'left', overflowWrap: "anywhere" }}>Invoice Id</th>
                                    <th style={{ maxWidth: '50%', minWidth: "136px", borderBottom: '1px solid white', padding: '8px', textAlign: 'right', overflowWrap: "anywhere" }}>Settled Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.invoices?.map((item) => (
                                    <tr key={item?.id}>
                                      <td style={{ maxWidth: '50%', padding: '8px', textAlign: 'left', overflowWrap: "anywhere" }}>
                                        {item?.invoiceId}
                                      </td>
                                      <td style={{ maxWidth: '50%', minWidth: "136px", padding: '8px', textAlign: 'right', overflowWrap: "anywhere" }}>
                                        {item?.settledAmount ? data?.settledAmountCurrency?.sign : '--'}
                                        {
                                          data?.settledAmountCurrency?.sign === '₹' 
                                            ? item?.settledAmount?.toLocaleString('en-IN', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                            : item?.settledAmount?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })
                                        }
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            }
                            overlayInnerStyle={{
                              // color: "orange",
                              background: "#060A0D",
                              width: "230px",
                              padding: "0px",
                              // borderRadius: "16px",
                            }}
                            overlayStyle={{maxWidth: '376px'}}
                            placement="bottom"
                          >
                          {data?.settledAmount ? data?.settledAmountCurrency?.sign : '--'}
                          {
                            data?.settledAmountCurrency?.sign === '₹' 
                              ? data?.settledAmount?.toLocaleString('en-IN', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : data?.settledAmount?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                          }
                          </Tooltip>
                        </td>
                        <td 
                            className={classes.cell} 
                            style={{width: '10%', minWidth: '130px', maxWidth: '130px'}}
                            valign="top"
                        >
                          <PaymentStatusTag status={data?.status} type="light" />
                        </td>
  
                        <td className={clsx(classes.cell,'!pl-[0px] !pr-[0px]')} style={{width: '15%', minWidth: '70px', maxWidth: '70px'}} valign="top">
                          <div
                            className={clsx(classes.cell_action,`flex flex-col items-center`)}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {
                              data?.firaStatus === 'PENDING'
                                ?
                                  <Tag color="volcano">PENDING</Tag>
                                :
                                  (
                                    data?.firaStatus === 'NOT_GENERATED'
                                      ?
                                        (
                                          (data?.swiftMessage !== null && data?.swiftMessage !== undefined)
                                            ?
                                              <Tooltip
                                                title={"Download MT103 Details"}
                                                placement="bottomRight"
                                              >
                                                <DownloadOutlined
                                                  style={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                  }}
                                                onClick={() => handleReportDownloadWithPayout(data?.payoutReference)}
                                                />
                                              </Tooltip>
                                            :
                                              <Tag color="#f84f4f">NOT GENERATED</Tag>
                                        )
                                      :
                                        (
                                          data?.firaStatus === 'GENERATED'
                                            ?
                                              <Tooltip
                                                title={"Download Payment Advice"}
                                                placement="bottomRight"
                                              >
                                                <DownloadOutlined
                                                  style={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                  }}
                                                onClick={() => handleReportDownload(data?.firaId)}
                                                />
                                              </Tooltip>
                                            :
                                                '--'
                                        )
                                  )
                            }
                          </div>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </ReportTableLayout>
            {
                props?.tableData?.length > 0 &&
                <div className={classes.paginationWrap}>
                  <Pagination
                      showSizeChanger={false}
                      current={props?.curentPage + 1}
                      total={props?.resData?.totalElements}
                      pageSize={props?.pageSize}
                      // defaultPageSize={30}
                      itemRender={itemRender}
                      onChange={props?.handlePaginate}
                  />
                </div>
            }
          </div>
        </div>
      {(props?.tableData?.length === 0 && !props?.loading) && 
        (<div
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: '4rem',
            fontSize: '2.5rem'
          }}
          className={classes.table}
        >
          {props?.isSearch?.length === 0 ? 'No Payouts Yet.' : 'No payout with this id.' }
        </div>)
      }
      {/* TODO: MOBILE */}
      {/*<MobilePayoutReportTable*/}
      {/*  tableData={props.mobileTableData}*/}
      {/*  // handleReportDownload={handleReportDownload}*/}
      {/*  loading={props?.loading}*/}
      {/*/>*/}
    </>
  )
}

export default PayoutReportTable