import classes from "../../atom/LeadVerificationModal.module.scss";
import TextInput from "../../../Layout/TextInput";
import {useState} from "react";
import useApiCall from "../../../hooks/useApiCall";
import {apiVerifyLead, apiVerifyComplianceDocument} from "../../../services";
import {enqueueSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {modalActions} from "../../../Store/modal-redux";

const RemarksVerificationModal = (props) => {
    const [remarks, setRemarks] = useState("");
    const dispatch  = useDispatch();

    const handleClick = () => {
        if(props.action === "remarks"){
            props.updateCompliance({
                searchId : props.searchId,
                remarks : remarks,
            })
        }
        else if(props.action === "riskLevel"){
            props.updateCompliance({
                searchId : props.searchId,
                riskLevel : props.level,
                remarks : remarks,
            })
        }
        else if(props.action === "matchStatus"){
            props.updateCompliance({
                searchId : props.searchId,
                matchStatus : props.status,
                remarks : remarks,
            })
        }

        props?.closeModal();
    }
    return (
        <>
            <div className={classes.bigCont}>
                <div>
                    <TextInput
                        type="text"
                        text="Add Remarks(Optional)"
                        validation={[]}
                        multiline = {true}
                        inputValidity={() => {
                        }}
                        setInput={(value) => {
                            console.log('value',value)
                            setRemarks(value)
                        }}
                        enteredInput={remarks}
                    />
                </div>
                <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#1E333F]">*Remarks will shown to the customer.</p>
                <div className={classes.btnCont}>
                    <button
                        className={classes.markBtn}
                        onClick={() => {
                            props?.closeModal()
                        }}>
                        Cancel
                    </button>
                    <button className={classes.markBtn} onClick={handleClick}>
                        {
                            "Confirm"
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default RemarksVerificationModal;