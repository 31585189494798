const URL = {
  refreshToken: 'refresh-token',
  login: '/auth/login',
  setPassword: '/set-password',
  register: '/users/register',
  resetPassword: '/reset-password',
  verifyPasswordResetToken: '/verify-password-reset-token',
  payoutReports: '/payouts',
  downloadFiraReportWithFiraId: '/payment-advice/fira/id',
  summary: '/dashboard/summary',
  dashboardTableData: '/dashboard/data',
  receivablesTableData: '/receivable',
  organizationData: '/organization',
  signOut: '/auth/logout',
  customer: '/customers',
  leads: '/lead',
  invoiceDetails: '/receivable/invoice/details',
  sendPaymentLink: '/payment-link/send-payment-link-email',
  customers: '/customers/list',
  bulkTransactionsId: '/receivable/transactions',
  // bankAccounts: '/payment-link/send-payment-link-email',
  receivables: '/payment-link/send-payment-link-email',
  transactions: '/receivable/transactions',
  createInvoiceData: '/receivable/create-invoice-data',
  createInvoice: '/receivable/invoices',
  customerDetails: 'customers/details',
  complianceDetails: 'compliance/customer',
  complianceDetailsQuick: 'compliance/retrigger',
  complianceCustom: 'compliance/search',
  searchComp: '/compliance/search-data',
  updateComp: '/compliance/update-data',
  updateCompHit: '/compliance/update-hit',
  customSources: '/compliance/sources',
  verifyCustomer: 'customers/verify',
  vBankBalance: "/vBank/details",
  reconcilableCustomerList: "/reconcile/customers",
  reconcilableReceivablesList: "/reconcile",
  reconcileReceivables: "/reconcile/reconcileReceivables",
  verifyInvoice: 'verify/invoice',
  deleteInvoice: '/receivable/delete',
  getSinglePaymentLink: '/payment-link/generate-single-payment-link',
  sendSinglePaymentLink: '/payment-link/send-single-payment-link-email',
  downloadFiraReport: '/payment-advice/fira/txn',
  downloadFiraReportWithTxnId: '/payment-advice/fira/txn',
  downloadFiraReportWithInvoiceId: '/payment-advice/invoice',
  addCustomers: 'customers/create',
  filterExporters: '/filter/exporters',
  filterCustomers: '/filter/customers',
  filterStatuses: '/filter/statuses',
  organizationDetails:'/organization/details',
  leadDetails:'/lead/details',
  verifyLead:'/lead/updateLeadStatus',
  createUserFromLead:'/lead/createUserFromLead',
  leadComplianceDetails:'/lead/fetch-verification',
  leadDocumentVerification:'/lead/add-update-remark',
}

const baseUrl = {
  api:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://admin.fideo.tech/api"
      : "https://dev-admin.fideo.tech/api",
  // :"http://localhost:8082/api",
  media:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://admin.fideo.tech/api"
      : "https://dev-admin.fideo.tech/api",
  // :"http://localhost:8082/api",
};

const COMMON_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

/**
 * These config options can be passed to the api options
 * errMsg: Error message to be shown in case of error
 * hasFormData - to convert api data into form data
 * fullResponse - to get the actual axios api response (only data object in the apu response is returned)
 * hideDefaultError - to hide the default error message (use in case of custom conditional error message)
 * onProgressCallback - to get the progress of the upload (in put and post requests)
 */
const DEFAULT_INTERCEPTOR_CONFIG = {
  errMsg: "",
  hasFormData: false,
  fullResponse: false,
  hideDefaultError: false,
  onProgressCallback: null,
};

export { COMMON_HEADERS, DEFAULT_INTERCEPTOR_CONFIG, baseUrl };
export default URL;
