import ReactDOM from 'react-dom'
import classes from './YearlyReportTable.module.scss'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { Dropdown, Tooltip } from 'antd'
import Shimmer from '../Layout/Shimmer'
import ReportTableLayout from "../Layout/ReportTableLayout"
import { formatNumberToLocaleString } from '../hooks/formatNumberToLocaleString'
import down from "../assets/Icons/chevron-down.svg";
// import CustomerInvoiceTotal from '../Components/atom/CustomerInvoiceTotal'
// import { useSelector } from 'react-redux'


const YearlyReportTable = (props) => {

  const {activeTab,handleCount,handleDropdownItemClick,more,currencyCodeSign,currencyWise,tempData} = props
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);

  const items = [
    {
      key: 'CAD',
      label: <div className='text-[16px] font-inter'>CAD</div>,
    },
    {
      key: 'AED',
      label: <div className='text-[16px] font-inter '>AED</div>,
    },
  ];

  // const getTotalTooltipText = (data, type) => {
  //   // {X} invoices {Currency icon} {Amount}
  //   // {number_of_invoice} invoices {currency.sign} {amount}

  //   const output = data || [];

  //   const currencyOrder = ['USD', 'EUR', 'GBP']

  //   const sortedData = output.filter((item) =>
  //     currencyOrder.includes(item.currency)
  //   )

  //   const sortedInvoiceSummary = sortedData.sort((a, b) => {
  //     return (
  //       currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
  //     )
  //   })

  //   let result

  //   const map = {
  //     RECEIVABLES: 'totalReceivables',
  //     SETTLED: 'settled',
  //     SETTLEDOUTSIDE: 'settledOutsideFideo',
  //     OUTSTANDING: 'totalOutstanding',
  //   }

  //   let noInvoiceCount = 0

  //   result = sortedInvoiceSummary?.map((item, index) => {
  //     item = item[map[type]]
  //     if (item?.number_of_invoice === 0) {
  //       noInvoiceCount++
  //       // return (
  //       //   <div key={index} className={clsx(classes.currencyTooltip)}>
  //       //     No invoices
  //       //   </div>
  //       // )
  //     } else {
  //       return (
  //         <div key={index} className={clsx(classes.currencyTooltip)}>
  //           <div className={clsx(classes.currencyAmount)}>
  //             {`${item?.number_of_invoice || 'No' } invoices`}
  //           </div>
  //           &nbsp; &nbsp;
  //           <div className={clsx(classes.currencySymbol)}>
  //             {item?.currency?.sign}
  //             {formatNumberToLocaleString((item?.amount), 2, 2)}
  //           </div>
  //         </div>
  //       )
  //     }
  //   })

  //   if (noInvoiceCount === data.length) {
  //     return <div className={clsx(classes.currencyTooltip)}>No invoices</div>
  //   }

  //   return <>{result}</>
  // }

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="">
      {(
        <>
          <div className={classes.allWrap} >
              <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                  <div className={clsx(classes.tabHeader)}>
                    <div className={clsx(activeTab === "ALL" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("ALL")}>All</div>
                    <div className={clsx(activeTab === "USD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("USD")}>USD</div>
                    <div className={clsx(activeTab === "EUR" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("EUR")}>EUR</div>
                    <div className={clsx(activeTab === "GBP" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("GBP")}>GBP</div>
                    <Dropdown
                      menu={{
                        onClick: handleDropdownItemClick,
                        items: items,
                      }}
                      placement="bottom"
                      overlayStyle={{width:"100px", fontSize:"16px !important" }}
                    >
                      <div className={clsx((activeTab === "CAD" || activeTab === "AED") ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}><>{more}</><img src={down} className="w-[20px] h-[20px]" /></div>
                    </Dropdown>
                  </div>
                  <thead className={clsx(`${classes.thead}`)}>
                    {props?.tableHeader?.map((header, index) => (
                      <th key={`${header}-${index}`} className={clsx(classes.cell_header1)} 
                        style={{ 
                          width:`${header.width}%`,
                          minWidth:`${header.minWidth}px`, 
                          maxWidth: `${header.minWidth}px`,
                          // display: `${props?.activeTab === "ALL" && header?.title?.toLowerCase() === "exportersonboarded" ? "none" : "block"}`
                          }}
                          valign="top">
                        <div className={classes.cell_header2} style={{flexDirection : "column" , alignItems:"flex-start"}}>
                          <div className={classes.head}>{header?.title}</div>
                            {
                              <div className={classes.head}>{header?.title1}</div>
                            }
                        </div>
                      </th>
                    ))}
                    {/* <th className={classes.cell_header1} style={{ width: '8%' ,minWidth:'80.26px'}} >
                      <div
                        className={`${classes.cell_header2} ${classes.actionHeader}`}
                      >
                        <div className={`${classes.headAction}`}>Action</div>
                      </div>
                    </th> */}
                  </thead>
                  <tbody>
                    { props.loading
                      ?
                        <td colSpan={7}>
                          {[1]?.map((item, index) => (
                            <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                          ))}
                        </td>
                      : currencyWise[activeTab]?.monthlyDataTotal  &&
                        (<tr
                            className={`${classes.row}`}
                        >
                          <td className={classes.cellTotal}
                              style={{
                                width:`12%`,
                                minWidth:`100px`,
                                maxWidth:`100px`
                              }}
                              valign="top"
                          >
                            {/* {data?.month} */}
                            {"TOTAL"}
                          </td>
                          <td className={classes.cellTotal}
                              style={{
                                width:`10%`,
                                minWidth:`100px`,
                                maxWidth:`100px`
                              }}
                              valign="top"
                          >
                            {(currencyWise[activeTab]?.monthlyDataTotal?.leadsOnboarded ? currencyWise["ALL"]?.monthlyDataTotal?.leadsOnboarded : "-")}
                          </td>
                          <td className={classes.cellTotal}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {currencyWise[activeTab]?.monthlyDataTotal?.totalAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((currencyWise[activeTab]?.monthlyDataTotal?.totalAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {currencyWise[activeTab]?.monthlyDataTotal?.totalInvoices ? currencyWise[activeTab]?.monthlyDataTotal?.totalInvoices + (currencyWise[activeTab]?.monthlyDataTotal?.totalInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={clsx(classes.cellTotal)}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {currencyWise[activeTab]?.monthlyDataTotal?.totalDeposit ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((currencyWise[activeTab]?.monthlyDataTotal?.totalDeposit), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {currencyWise[activeTab]?.monthlyDataTotal?.totalDepositNumber ? currencyWise[activeTab]?.monthlyDataTotal?.totalDepositNumber + (currencyWise[activeTab]?.monthlyDataTotal?.totalDepositNumber > 1 ? ' Payments' : ' Payment') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={clsx(classes.cellTotal)}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            {/*<div className='flex flex-col'>*/}
                            {/*  <div className='flex'>*/}
                            {/*    <div className={clsx(classes.price, 'flex')}>*/}
                            {/*      {currencyWise[activeTab]?.monthlyDataTotal?.fullySettledAmount ? (*/}
                            {/*          <>*/}
                            {/*            <span className='font-[400]'>{currencyCodeSign}</span>*/}
                            {/*            <span*/}
                            {/*                className=''>{formatNumberToLocaleString((currencyWise[activeTab]?.monthlyDataTotal?.fullySettledAmount), 2, 2)}</span>*/}
                            {/*          </>*/}
                            {/*      ) : ('-')}*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*  <div className='text-[#1e333f80]'>*/}
                            {/*    {currencyWise[activeTab]?.monthlyDataTotal?.fullySettledInvoices ? currencyWise[activeTab]?.monthlyDataTotal?.fullySettledInvoices + (currencyWise[activeTab]?.monthlyDataTotal?.fullySettledInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {(activeTab === "ALL"||activeTab==="USD"||activeTab==="EUR"||activeTab==="AED") ?tempData?.fullySettledAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((tempData?.fullySettledAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-') : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {(activeTab === "ALL"||activeTab==="USD"||activeTab==="EUR"||activeTab==="AED") ?tempData?.fullySettledInvoices ? tempData?.fullySettledInvoices + (tempData?.fullySettledInvoices > 1 ? ' Invoices' : ' Invoice') : "-" : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={classes.cellTotal}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            {/*<div className='flex flex-col'>*/}
                            {/*  <div className='flex'>*/}
                            {/*    <div className={clsx(classes.price, 'flex')}>*/}
                            {/*      {currencyWise[activeTab]?.monthlyDataTotal?.partiallyPaidAmount ? (*/}
                            {/*          <>*/}
                            {/*            <span className='font-[400]'>{currencyCodeSign}</span>*/}
                            {/*            <span*/}
                            {/*                className=''>{formatNumberToLocaleString((currencyWise[activeTab]?.monthlyDataTotal?.partiallyPaidAmount), 2, 2)}</span>*/}
                            {/*          </>*/}
                            {/*      ) : ('-')}*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*  <div className='text-[#1e333f80]'>*/}
                            {/*    {currencyWise[activeTab]?.monthlyDataTotal?.partiallyPaidInvoices ? currencyWise[activeTab]?.monthlyDataTotal?.partiallyPaidInvoices + (currencyWise[activeTab]?.monthlyDataTotal?.partiallyPaidInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {(activeTab === "ALL"||activeTab==="USD"||activeTab==="EUR"||activeTab==="AED") ?tempData?.partiallyPaidAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((tempData?.partiallyPaidAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-'):('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {(activeTab === "ALL"||activeTab==="USD"||activeTab==="EUR"||activeTab==="AED") ?tempData?.partiallyPaidInvoices ?tempData?.partiallyPaidInvoices + (tempData?.partiallyPaidInvoices > 1 ? ' Invoices' : ' Invoice') : "-":"-"}
                              </div>
                            </div>
                          </td>
                          <td className={classes.cellTotal}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {currencyWise[activeTab]?.monthlyDataTotal?.settledOutsideAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((currencyWise[activeTab]?.monthlyDataTotal?.settledOutsideAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {currencyWise[activeTab]?.monthlyDataTotal?.settledOutsideInvoices ? currencyWise[activeTab]?.monthlyDataTotal?.settledOutsideInvoices + (currencyWise[activeTab]?.monthlyDataTotal?.settledOutsideInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={classes.cellTotal}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {currencyWise[activeTab]?.monthlyDataTotal?.outstandingAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((currencyWise[activeTab]?.monthlyDataTotal?.outstandingAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {currencyWise[activeTab]?.monthlyDataTotal?.outstandingInvoices ? currencyWise[activeTab]?.monthlyDataTotal?.outstandingInvoices + (currencyWise[activeTab]?.monthlyDataTotal?.outstandingInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                        </tr>)
                    }
                  </tbody>
              </div>
            <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef}>
              <ReportTableLayout className={`${classes.table} !pt-[0px]`}>
                <tbody>
                {props?.loading
                    ?
                    <td colSpan={7}>
                      {[1, 3, 4]?.map((item, index) => (
                          <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                      ))}
                    </td>
                    :
                    currencyWise[activeTab]?.monthlyData?.map((data, index) => (
                        <tr
                            key={index}
                            className={`${classes.row} ${
                                data.status && classes.row_deactivate
                            }`}
                        >
                          <td className={classes.cell}
                              style={{
                                width:`12%`,
                                minWidth:`100px`,
                                maxWidth:`100px`
                              }}
                              valign="top"
                          >
                            {data?.month}
                          </td>
                          <td className={classes.cell}
                              style={{
                                width:`10%`,
                                minWidth:`100px`,
                                maxWidth:`100px`
                              }}
                              valign="top"
                          >
                            {data?.values?.leadsOnboarded ? data?.values?.leadsOnboarded : "-"}
                          </td>
                          <td className={classes.cell}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {data?.values?.totalAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((data?.values?.totalAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='flex text-[#1e333f80]'>
                                {data?.values?.totalInvoices ? data?.values?.totalInvoices + (data?.values?.totalInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={clsx(classes.cell)}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            {/* <CustomerInvoiceTotal data={data} category="settled" /> */}
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {data?.values?.totalDeposit ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        {formatNumberToLocaleString((data?.values?.totalDeposit), 2, 2)}
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='flex text-[#1e333f80]'>
                                {data?.values?.totalDepositNumber ? data?.values?.totalDepositNumber + (data?.values?.totalDepositNumber > 1 ? ' Payments' : ' Payment') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={clsx(classes.cell)}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            {/* <CustomerInvoiceTotal data={data} category="settled" /> */}
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {data?.values?.fullySettledAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        {formatNumberToLocaleString((data?.values?.fullySettledAmount), 2, 2)}
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='flex text-[#1e333f80]'>
                                {data?.values?.fullySettledInvoices ? data?.values?.fullySettledInvoices + (data?.values?.fullySettledInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={classes.cell}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {data?.values?.partiallyPaidAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((data?.values?.partiallyPaidAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {data?.values?.partiallyPaidInvoices ? data?.values?.partiallyPaidInvoices + (data?.values?.partiallyPaidInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={classes.cell}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {data?.values?.settledOutsideAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((data?.values?.settledOutsideAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {data?.values?.settledOutsideInvoices ? data?.values?.settledOutsideInvoices + (data?.values?.settledOutsideInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                          <td className={classes.cell}
                              style={{
                                width:`14%`,
                                minWidth:`120px`,
                                maxWidth:`120px`
                              }}
                              valign="top"
                          >
                            <div className='flex flex-col'>
                              <div className='flex'>
                                <div className={clsx(classes.price, 'flex')}>
                                  {data?.values?.outstandingAmount ? (
                                      <>
                                        <span className='font-[400]'>{currencyCodeSign}</span>
                                        <span
                                            className=''>{formatNumberToLocaleString((data?.values?.outstandingAmount), 2, 2)}</span>
                                      </>
                                  ) : ('-')}
                                </div>
                              </div>
                              <div className='text-[#1e333f80]'>
                                {data?.values?.outstandingInvoices ? data?.values?.outstandingInvoices + (data?.values?.outstandingInvoices > 1 ? ' Invoices' : ' Invoice') : "-"}
                              </div>
                            </div>
                          </td>
                        </tr>
                    ))
                }
                </tbody>
              </ReportTableLayout>
            </div>
          </div>
          {(Object.keys(currencyWise)?.length === 0 && !props?.loading) &&
              (
                  <div
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '4rem',
                        fontSize: '2.5rem'
                      }}
                      className={classes.table}
                  >
                    No Reports Yet.
                  </div>
              )
          }
        </>
      )}
    </div>
  )
}

export default YearlyReportTable
