import {Navigate, Outlet} from "react-router-dom";
import classes from "./Admin.module.scss";
import AdminHeader from "../../Components/General/AdminHeader";
import SideBar from "../../Components/General/SideBar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GlobalActions } from "../../Store/global-redux";
import CustomModal from "../../Components/atom/CustomModal/CustomModal";

const Admin  = (props) => {
  const dispatch = useDispatch()
//   const showSideBarMobile = useSelector((state) => state.global.showSideBarMobile);
  const [modalRef, setModalRef] = useState();
//   const scrollEffect = useSelector((state) => state.global.scrollEffect)

//   const setRecoilModalRef = useSetRecoilState(modalRefState);
//   useEffect(() => {
//     if (modalRef) {
//       setRecoilModalRef(modalRef);
//     }
//   }, [modalRef]);

  // console.log('scrollEffect',scrollEffect)

  const handleScroll = () => {
    const scrollElement = document.getElementById('yourScrollableElementId');
    const scrollY = scrollElement.scrollTop;
    // dispatch(GlobalActions.setScrollYValue(scrollY))
    // Perform actions based on the scrollY value
  };

  useEffect(() => {
    const scrollElement = document.getElementById('yourScrollableElementId');
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      dispatch(GlobalActions.setScrollXValue(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log('first',scrollX)

    // if (!props?.isLogin) {
    //     return <Navigate to={"/login?mode=email"} />;
    // }

    return(
        <>
            <AdminHeader className = {classes.admin_header} setIsLogin={props?.setIsLogin}/>
            <div className={classes.admin_root}>
                <div id="backdrop-root"></div>
                <div id="modal-root"></div>
                <SideBar className={classes.sideBar} />
                <div className={`${classes.outlet}`} style={{overflow : "scroll"}} id="yourScrollableElementId">
                    {/* <CustomModal ref={setModalRef} /> */}
                    <>
                        {/* <ScrollToTop /> */}
                        <Outlet />
                    </>
                </div>
            </div>
        </>
    )
}

export default Admin