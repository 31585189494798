// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminHeader_adminHeader__binqX {
  border-bottom: 1px solid #E8EAEF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 45;
  height: var(--admin_header_length);
}
.AdminHeader_adminHeader__binqX .AdminHeader_fideoLogo__p0dVu {
  margin-left: 24.3px;
}

.AdminHeader_adminView__assZh {
  display: flex;
  background-color: #E6E9EE;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/General/AdminHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,kCAAA;AACJ;AACI;EACI,mBAAA;AACR;;AAGA;EACI,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAAJ","sourcesContent":[".adminHeader{\n    border-bottom: 1px solid #E8EAEF;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    background-color: white;\n    position: sticky;\n    top: 0px;\n    left: 0px;\n    z-index: 45;\n    height: var(--admin_header_length);\n\n    .fideoLogo{\n        margin-left: 24.3px;\n    }\n}\n\n.adminView{\n    display: flex;\n    background-color: #E6E9EE;\n    border-radius: 8px;\n    padding: 5px 10px;\n    font-size: 14px;\n    margin-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminHeader": `AdminHeader_adminHeader__binqX`,
	"fideoLogo": `AdminHeader_fideoLogo__p0dVu`,
	"adminView": `AdminHeader_adminView__assZh`
};
export default ___CSS_LOADER_EXPORT___;
