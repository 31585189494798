// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FullModal_fullModal__-Sy7w {
  box-sizing: border-box;
  /* min-width: 100vw; */
  /* min-height: 100vh; */
  background-color: #F4F6FA;
  position: fixed;
  bottom: 0;
  left: 0px;
  z-index: 60;
  height: calc(100vh - var(--admin_header_length));
  width: 100vw;
  overflow-y: scroll;
}
@media (max-width: 640px) {
  .FullModal_fullModal__-Sy7w {
    left: 0px;
    right: 0px;
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout/FullModal.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,eAAA;EACA,SAAA;EACA,SAAA;EACA,WAAA;EACA,gDAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EAbF;IAiBI,SAAA;IACA,UAAA;IACA,YAAA;EADF;AACF","sourcesContent":[".fullModal{\n  box-sizing: border-box;\n  /* min-width: 100vw; */\n  /* min-height: 100vh; */\n  background-color: #F4F6FA;\n  position: fixed;\n  bottom: 0;\n  left: 0px;\n  z-index: 60 ;\n  height: calc(100vh - var(--admin_header_length));\n  width: 100vw;\n  overflow-y: scroll;\n\n  @media (max-width:640px) {\n    // min-width: auto;\n    // left: 0;\n    // padding: 0 2rem;\n    left: 0px;\n    right: 0px;\n    width: calc(100vw);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullModal": `FullModal_fullModal__-Sy7w`
};
export default ___CSS_LOADER_EXPORT___;
