/* eslint-disable complexity */
import { get, post, httpPut, httpDelete } from "./network";
import URL from "../constants/Network";
import { REFRESH_TOKEN } from "../constants/Enums";
import axios from 'axios'

export async function apiAuthToken() {
  const token = window.localStorage.getItem(REFRESH_TOKEN)
  return get(URL.refreshToken, {}, { Authorization: `Bearer ${token}` })
}
export async function apiGetReceivablesData(params) {
  let { pageNum, size, customerIds, organizationIds, invoiceStatuses } = params
  customerIds = customerIds || "";
  return get(`${URL.receivablesTableData}?page=${pageNum}&size=${size}&customerIds=${customerIds}${invoiceStatuses && invoiceStatuses?.length ? `&invoiceStatuses=${invoiceStatuses}` : ''}${organizationIds && organizationIds?.length ? `&organizationIds=${organizationIds}` : ''}`)
}

export async function apiGetVBankDetails(params) {
  let { organizationId = "", customerIds = "" } = params;
  return get(`${URL.vBankBalance}?organizationId=${organizationId}&customerIds=${customerIds}`)
}

export async function apiGetPayoutReports(params) {
  const { pageNum, size, organizationIds } = params;
  const url = `${URL.payoutReports}?page=${pageNum}&size=${size}${organizationIds && organizationIds?.length ? `&organizationIds=${organizationIds}` : ''}`;
  return get(url);
}
export async function apiGetOrganizationData() {
  return get(URL.organizationData)
}
export async function apiGetCustomers(params) {
  const { pageNum, size, organizationIds } = params;
  return get(`${URL.customer}?page=${pageNum}&size=${size}${organizationIds && organizationIds?.length ? `&organizationIds=${organizationIds}` : ''}`)
}

export async function apiGetCustomerDetails(params) {
  const { customerId, organizationId } = params
  return get(`${URL.customerDetails}?customerId=${customerId}&organizationId=${organizationId}`)
}

export async function apiGetComplianceDetails(params) {
  const { customerId } = params
  return get(`${URL.complianceDetails}?customerId=${customerId}`)
}

export async function apiGetComplianceDetailsQuick(params) {
  const { customerId } = params
  return post(`${URL.complianceDetailsQuick}?customerId=${customerId}`)
}

export async function apiGetComplianceCustom(params) {
  // const { customerId } = params
  return post(URL.complianceCustom,params)
}

export async function apiGetSearchComp(params) {
  const { searchId } = params;
  return get(`${URL.searchComp}?searchId=${searchId}`)
}

export async function apiUpdateComp(params) {
  // console.log('params',params)
  const { searchId="", matchStatus="",remarks = "" } = params;
  return httpPut(`${URL.updateComp}?searchId=${searchId}&matchStatus=${matchStatus}&remarks=${remarks}`)
}

export async function apiUpdateCompRiskLevel(params) {
  // console.log('params',params)
  const { searchId="",riskLevel="",remarks = "" } = params;
  return httpPut(`${URL.updateComp}?searchId=${searchId}&riskLevel=${riskLevel}&remarks=${remarks}`)
}

export async function apiUpdateRiskLevelHit(params) {
  console.log('params',params)
  // const { searchId="", matchStatus="" } = params;
  return httpPut(URL.updateCompHit,params)
}

export async function apiGetCustomSources() {
  return get(URL.customSources)
}

export async function apiVerifyCustomers(params) {
  const { customerId, remark } = params
  return get(`${URL.verifyCustomer}?customerId=${customerId}&remark=${remark}`)
}

export async function apiVerifyInvoice(params) {
  const { invoiceId, organizationId, paymentRail } = params
  return post(`${URL.verifyInvoice}?invoiceId=${invoiceId}&organizationId=${organizationId}&paymentRail=${paymentRail}`)
}

export async function apiDeleteInvoice(params) {
  const { ...rest } = params
  return httpPut(`${URL.deleteInvoice}`, rest)
}

export async function apiGenerateSinglePaymentLink(params) {
  const { customerId } = params
  return get(`${URL.getSinglePaymentLink}?customerId=${customerId}`)
}

export async function apiSendSinglePaymentLink({ customerId, paymentLink, organizationId }) {
  return post(`${URL.sendSinglePaymentLink}?customerId=${customerId}&paymentLink=${paymentLink}&organizationId=${organizationId}`)
}

export async function apiGetLeads(params) {
  const { pageNum, size } = params
  return get(`${URL.leads}?page=${pageNum}&size=${size}`)
}
export async function apiGetSummary() {
  return get(URL.summary)
}
export async function apiGetTableData() {
  return get(URL.dashboardTableData)
}
export async function apiDownloadFiraReportWithFiraId(params) {
  const { firaId } = params;
  return get(`${URL.downloadFiraReportWithFiraId}?firaId=${firaId}`)
}
export async function apiSignOut() {
  return get(URL.signOut)
}
export async function apiLogin(params) {
  return post(URL.login, params)
}
export async function fetchFileFromS3(presignedUrl) {
  return axios.get(presignedUrl, {
    responseType: 'arraybuffer',
  })
}

export async function apiGetInvoiceDetails(params) {
  const { invoiceId, organizationId } = params;
  return get(`${URL.invoiceDetails}?invoiceId=${invoiceId}&organizationId=${organizationId}`)
}

export async function apiSendPaymentLink(params) {
  const { invoiceId, paymentLink, organizationId, ...rest } = params
  return post(
    `${URL.sendPaymentLink}?invoiceId=${invoiceId}&paymentLink=${paymentLink}&organizationId=${organizationId}`,
    rest
  )
}

export async function apiGetCustomerContactList(params) {
  return get(URL.customers, params)
}

export async function apiGetBulkTransactionsId(params) {
  return post(URL.bulkTransactionsId, params)
}

export async function apiGetBankList() {
  return get(URL.bankAccounts)
}

export async function apiToCreateInvoice(params) {
  const { transactionId, organizationId, userId, ...rest } = params
  return httpPut(`${URL.createInvoice}?transactionId=${transactionId}&organizationId=${organizationId}&userId=${userId}`, rest)
}

export async function apiCreateTransaction(params) {
  const { customerId, organizationId, userId } = params
  let url = `${URL.transactions}?organizationId=${organizationId}&userId=${userId}`;

  if (customerId !== undefined) {
    url += `&customerId=${customerId}`;
  }

  return post(url);
}

export async function apiGetCreateInvoiceData(params) {
  return get(URL.createInvoiceData, params)
}

export async function apiGetReconcilationCustomerList(params) {
  const { currency, organizationId } = params;
  return get(`${URL.reconcilableCustomerList}/${currency}?organizationId=${organizationId}`)
}

export async function apiGetReconcilationData(params) {
  const { currency, organizationId, customerIds = "" } = params;
  return get(`${URL.reconcilableReceivablesList}/${currency}?organizationId=${organizationId}&customerIds=${customerIds}`)
}

export async function apiSendReconcilationData(params) {
  const { organizationId, body } = params
  return post(`${URL.reconcileReceivables}?organizationId=${organizationId}`, body)
}

export async function apiDownloadFiraReportUrl(params) {
  const { invoiceId, organizationId } = params;
  return get(`${URL.downloadFiraReport}?invoiceId=${invoiceId}&organizationId=${organizationId}`)
}

export async function apiDownloadFiraReportWithTxnId(params) {
  const { invoiceId, txnId, organizationId } = params;
  return get(`${URL.downloadFiraReportWithTxnId}?invoiceId=${invoiceId}&fideoTxnId=${txnId}&organizationId=${organizationId}`)
}

export async function apiDownloadFiraReportWithInvoiceId(params) {
  const { invoiceId } = params;
  return get(`${URL.downloadFiraReportWithInvoiceId}?invoiceId=${invoiceId}`)
}

export async function apiAddCustomer(params) {
  const { organizationId, userId, ...rest } = params
  return post(`${URL.addCustomers}?trace=true&organizationId=${organizationId}&userId=${userId}`, rest)
}

export async function apiGetFilterExportersList(params) {
  return get(`${URL.filterExporters}?params=${params}`)
}

export async function apiGetFilterCustomersList(params) {
  const { organizationIds } = params;
  return get(`${URL.filterCustomers}?${organizationIds && organizationIds?.length ? `organizationIds=${organizationIds}` : ''}`)
}

export async function apiGetFilterStatusesList(params) {
  const { customerIds, organizationIds } = params;
  return get(`${URL.filterStatuses}?${customerIds && customerIds?.length ? `&customerIds=${customerIds}` : ''}${organizationIds && organizationIds?.length ? `&organizationIds=${organizationIds}` : ''}`)
}

export async function apiGetExporterDetails(params) {
  const { organizationId } = params;
  return get(`${URL.organizationDetails}?organizationId=${organizationId}`)
}

export async function apiGetLeadDetails(params) {
  const { leadId } = params;
  return get(`${URL.leadDetails}?leadId=${leadId}`)
}

export async function  apiVerifyLead(params){
  const {leadId, status, remarks=""} = params;
  return httpPut(`${URL.verifyLead}?leadId=${leadId}&status=${status}&remark=${remarks}`)
}

export async function apiCreateUserFromLead(params){
  const {leadId} = params;
  return httpPut(`${URL.createUserFromLead}?leadId=${leadId}`)
}

export async function apiGetLeadComplianceDetails(params){
  const {leadId} = params;
  return get(`${URL.leadComplianceDetails}?leadId=${leadId}`)
}

export async function apiVerifyComplianceDocument(params) {
  const {id ,remarks, status} = params;
  return post(`${URL.leadDocumentVerification}?id=${id}&remark=${remarks}&status=${status}`)
}