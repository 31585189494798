import Backdrop from 'Layout/Backdrop'
import Modal from '../../../Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import classes from './ComplianceChecks.module.scss'
import leftPointer from 'assets/Icons/leftWhite.svg'
// import alert from 'assets/Icons/alert-circleNew.svg'
import React, { useEffect, useRef, useState } from 'react'
import {Tooltip, Pagination, Tabs} from "antd";
// import AccessTooltip from "../../../Pages/Admin/profile/profileComponents/AccessTooltip";
// import CompTooltip from "./CompTooltip";
// import useClickOutside from "../../../hooks/useClickOutside";
// import InvoicesTabContent from "../CustomerDetailsModal/InvoicesTabContent/InvoicesTabContent";
// import CustomerDetailsTabContent from "../CustomerDetailsModal/CustomerDetailsTabContent/CustomerDetailsTabContent";
import KeyDataHit from "./KeyDataHit";
import AdverseMediaHit from "./AdverseMediaHit";
import downPointer from "../../../assets/Icons/chevron-down-white.svg";
import Shimmer from "../../../Layout/Shimmer";
import useClickOutside from "../../../hooks/useClickOutside";
import UseApiCall from "../../../hooks/useApiCall";
import {apiUpdateCompRiskLevel, apiUpdateRiskLevelHit} from "../../../services";
import {useSnackbar} from "notistack";
import ComplianceStatusTag from "../../atom/ComplianceStatusTag";


const HitDetailsModalComp = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    const [riskLevelOpen, setRiskLevelOpen] = useState(false);
    const [matchStatusOpen, setMatchStatusOpen] = useState(false);
    // const showHitComp = useSelector((state) => state.modal.showHitComp)
    const customerDetailModalRef = useRef(null);
    const riskLevelRef = useClickOutside(() => setRiskLevelOpen(false));
    const matchStatusRef = useClickOutside(() => setMatchStatusOpen(false));

    const items = [
        {
            key: 'KeyData',
            label: `Key Data`,
            children: <KeyDataHit
                hitData={props.hitData}
                SearchData={props.SearchData}
                dobArray={props.dobArray}
                countryArray={props.countryArray}
                typeCountArray={props.typeCountArray}
                groupedStringsResult={props.groupedStringsResult}
            />,
        },
        {
            key: 'AdverseMedia',
            label: `Adverse Media`,
            children: <AdverseMediaHit
                hitData={props.hitData}
                SearchData={props.SearchData}
                dobArray={props.dobArray}
                countryArray={props.countryArray}
                typeCountArray={props.typeCountArray}
            />,
        }
    ]

    const handleRiskLevel = () => {
        setRiskLevelOpen(!riskLevelOpen)
        // setMatchStatusOpen(false)
    }

    const handleMatchStatus = () => {
        setMatchStatusOpen(!matchStatusOpen)
        // setRiskLevelOpen(false)
    }

    const handleRiskLevelSelect = (level) => {
        console.log('level',level)
        // props.setRiskLevel(level)
        updateRiskLevel(
            {
                doc_id : [props.hitData?.doc?.id],
                search_id:props.searchId,
                // match_status: null,
                // remarks:null,
                risk_level:level || null
            }
        )
    }

    const handleMatchStatusSelect = (status) => {
        // console.log('status',status)
        // props.setMatchStatus(status)
        updateMatchStatus(
            {
                doc_id : [props.hitData?.doc?.id],
                search_id:props.searchId,
                match_status: status || null,
                // remarks:null,
                // risk_level:level
            }
        )
    }

    const onChange = (key) => {
        // console.log('on tab change', key);
    }

    const [updateRiskLevel, fetchingRiskLevel,UpdateRiskLevelData] = UseApiCall(apiUpdateRiskLevelHit, (res) => {
            // console.log(res?.data)

            enqueueSnackbar('Risk level updated successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )
            props.getSearchDetails({ searchId: props.searchId})
            // props.getComplianceDetails({ customerId: props?.customerId})
        },
        (err)=>{
            // console.log('err11',err)
            enqueueSnackbar('Error while updating risk level.',
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    const [updateMatchStatus, fetchingMatchStatus,UpdateMatchStatusData] = UseApiCall(apiUpdateRiskLevelHit, (res) => {
            // console.log(res?.data)

            enqueueSnackbar('Match status updated successfully.',
                {
                    variant: 'success' ,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                }
            )
            props.getSearchDetails({ searchId: props.searchId})
            // props.getComplianceDetails({ customerId: props?.customerId})
        },
        (err)=>{
            // console.log('err11',err)
            enqueueSnackbar('Error while updating match status.',
                {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    autoHideDuration: 3000, // Specify the duration in milliseconds
                    style: { fontSize: '10px' }
                })
        }
    )

    // console.log('hitData',props.hitData);
    // console.log('end',end);
    // console.log('props.searchId',props.searchId);
    // console.log('showHitComp',showHitComp);

    return (
        <>
            <Modal
                className={clsx(
                    classes.modal,
                    `bg-[#F4F6FA] p-[0px] overflow-scroll z-[42]`
                )}
            >
                <div
                    ref={customerDetailModalRef}
                    className={clsx(classes.topHeader, "flex items-start justify-between border-b-[1px] border-[#E8EAEF] px-[20px] md:px-[60px] py-[20px] bg-[#1E333F]")}
                >
                    {/*left*/}
                    <div className="flex gap-[16px]">
                        <div
                            className="flex items-center justify-center w-[48px] h-[48px] rounded-[100px] px-[12px] py-[8px] bg-[#FFFFFF14] cursor-pointer"
                            onClick={() => {
                                dispatch(modalActions.setShowHitComp(false))
                            }}
                        >
                            <img src={leftPointer} style={{width: "48px", height: "48px"}}/>
                        </div>


                        <div className="flex-start flex-col gap-[4px]">
                            <p className="font-inter font-[700] text-[16px] leading-[20px] text-[#FFFFFF]">{props.hitData?.doc?.name ? props.hitData?.doc?.name : "--"}</p>
                            <div className="flex items-center gap-[4px]">
                                <div
                                    className="flex flex-col items-start md:items-center justify-center md:flex-row gap-[4px]">
                                    <p className="font-inter font-[500] text-[14px] leading-[16px] text-[#FFFFFF66]">Search
                                        Reference</p>
                                    <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#FFFFFF]">{props.hitData?.doc?.id ? props.hitData?.doc?.id : "--"}</p>
                                    {/*<p className="font-inter font-[400] text-[14px] leading-[20px] text-[#FFFFFF]">{props.SearchData?.searchId ? props.SearchData?.searchId : "--"}</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*right*/}
                    <div className="flex gap-[16px] relative">
                        <div className="flex gap-[4px] relative cursor-pointer"
                             onClick={handleRiskLevel}
                        >
                            {/*Risk Level*/}
                            <div className="flex px-[12px] py-[0px] absolute top-[-8px] left-[10px] ">
                                <div className="flex px-[4px] py-[0px] bg-[#1E333F]">
                                    <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#FFFFFF80]">Risk
                                        Level</p>
                                </div>
                            </div>
                            <div
                                className="flex gap-[10px] justify-between min-w-[140px] border-[1px] border-[#D2D6D9] rounded-[8px] px-[16px] py-[12px]  "
                                // style={{width: props.hitData?.doc?.['risk_level']?.toUpperCase() === "UNKNOWN" ? '140px' : '120px'}}
                            >

                                {
                                    fetchingRiskLevel
                                    ?
                                    [1]?.map((item, index) => (
                                        <Shimmer key={index} className="w-[100%] h-[20px] rounded-[8px] mt-[0px]"/>
                                    ))
                                    :
                                    <div
                                        className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#FFFFFF]">

                                        {props.hitData?.doc?.['risk_level'] ? props.hitData?.doc?.['risk_level']?.slice(0, 1)?.toUpperCase() + props.hitData?.doc?.['risk_level']?.slice(1, props.hitData?.doc?.['risk_level']?.length)?.toLowerCase() : 'Low'}
                                    </div>
                                }
                                <img src={downPointer} className=""/>
                            </div>
                        </div>
                        {riskLevelOpen &&
                            (<div
                                className="flex flex-col min-w-[140px] gap-[10px] bg-[#FFFFFF] shadow-lg rounded-[8px] px-[16px] py-[16px] absolute top-[52px] left-[0px] z-10"
                                style={{
                                    // width: props.hitData?.doc?.['risk_level']?.toUpperCase() === "UNKNOWN" ? '140px' : '120px',
                                    // left: props.SearchData?.riskLevel === "UNKNOWN" ? '0px' : '0px',
                                }}
                                ref={riskLevelRef}
                            >
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(LOW)=>handleRiskLevelSelect('LOW')}
                                >
                                    LOW</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(MEDIUM)=>handleRiskLevelSelect('MEDIUM')}
                                >
                                    MEDIUM</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(HIGH)=>handleRiskLevelSelect('HIGH')}
                                >
                                    HIGH</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={(UNKNOWN)=>handleRiskLevelSelect('UNKNOWN')}
                                >
                                    UNKNOWN</p>
                            </div>)
                        }

                        <div className="flex gap-[4px] relative cursor-pointer"
                             onClick={handleMatchStatus}
                        >
                            {/*match status*/}
                            <div className="flex px-[12px] py-[0px] absolute top-[-8px] left-[10px] ">
                                <div className="flex px-[4px] py-[0px] bg-[#1E333F]">
                                    <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#FFFFFF80]">Match
                                        Status</p>
                                </div>
                            </div>
                            <div
                                className="flex min-w-[220px] justify-between gap-[10px] border-[1px] border-[#D2D6D9] rounded-[8px] px-[16px] py-[12px]  "
                                // style={{width: props.hitData?.doc?.['match_status']?.toUpperCase() === "POTENTIAL_MATCH" ? '210px' : '150px'}}
                            >
                                {
                                    (fetchingMatchStatus)
                                    ?
                                    [1]?.map((item, index) => (
                                        <Shimmer key={index} className="w-[100%] h-[20px] rounded-[8px]"/>
                                    ))
                                    :
                                    <div
                                        className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#FFFFFF]">
                                        <ComplianceStatusTag status={props.hitData?.doc?.['match_status'] || "No_Match"} type="light"/>
                                    </div>
                                }
                                <img src={downPointer} className=""/>
                            </div>
                        </div>
                        {matchStatusOpen &&
                            (<div
                                className="flex min-w-[220px] flex-col gap-[10px] bg-[#FFFFFF] shadow-lg rounded-[8px] px-[16px] py-[16px] absolute top-[52px] left-[155px] z-10"
                                style={{
                                    // width: props.hitData?.doc?.['match_status']?.toUpperCase() === "POTENTIAL_MATCH" ? '210px' : '150px',
                                    // left: props.hitData?.doc?.['risk_level']?.toUpperCase() === "UNKNOWN" ? '155px' : '135px',
                                }}
                                ref={matchStatusRef}
                            >
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('NO_MATCH')}
                                >NO MATCH</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('POTENTIAL_MATCH')}
                                >POTENTIAL MATCH</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('UNKNOWN')}
                                >UNKNOWN</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('FALSE_POSITIVE')}
                                >FALSE POSITIVE</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('TRUE_POSITIVE')}
                                >TRUE POSITIVE</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('TRUE_POSITIVE_REJECT')}
                                >TRUE POSITIVE - REJECT</p>
                                <p className="font-inter ml-[8px] font-[500] text-[12px] leading-[20px] text-[#1E333F] cursor-pointer"
                                   onClick={() => handleMatchStatusSelect('TRUE_POSITIVE_APPROVE')}
                                >TRUE POSITIVE - APPROVE</p>
                            </div>)
                        }

                    </div>
                </div>

                <div className="flex justify-start gap-[24px] px-[20px] md:px-[60px] py-[20px] bg-[#F3F6FA]">
                    <div
                        className="flex flex-col w-full gap-[48px] "
                        style={{
                            background: '#F3F6FA',
                        }}
                    >
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HitDetailsModalComp
