import clsx from "clsx";
import classes from "./ComplianceChecks.module.scss";
import Modal from "../../../Layout/Modal";
import {useDispatch} from "react-redux";
import useClickOutside from "../../../hooks/useClickOutside";
import {modalActions} from "../../../Store/modal-redux";
import ReactDOM from "react-dom";
import Backdrop from "../../../Layout/Backdrop";
import crossImg from '../../../assets/Icons/crossNew.svg'
import arrowLeft from '../../../assets/Icons/leftBack.svg'
import arrowDown from '../../../assets/Icons/arrow-DownIcon.svg'
import arrowup from '../../../assets/Icons/arrow-up.svg'
import cross from '../../../assets/Icons/crossWhite.svg'

import React, {useEffect, useRef, useState} from "react";
import TextInput from "../../../Layout/TextInput";
import {ConfigProvider, DatePicker, Slider} from "antd";
import dayjs from "dayjs";
import CountryDropdown from "../../Dropdown/CountryDropdown";
import UseApiCall from "../../../hooks/useApiCall";
import {apiGetCustomSources} from "../../../services";

const CustomSearch = (props) => {
    const {
        customerName,
        setCustomerName,
        isCustomerNameValid,
        setIsCustomerNameValid,
        otherRefrences,
        setOtherRefrences,
        isOtherRefrencesValid,
        setIsOtherRefrencesValid,
        yofB,
        setYofB,
        countryList,
        setCountryList,
        handleCountrySelection,
        handleSourcesSelection,
        handleAllSanctions,
    } = props
    const dispatch = useDispatch();
    const [countryOpen, setCountryOpen] = useState(false);
    const [sanctionsOpen, setSanctionsOpen] = useState(false);
    const [pepsOpen, setPepsOpen] = useState(false);
    const [adverseOpen, setAdverseOpen] = useState(false);
    const noneRef = useRef(null);
    const focusRef = useRef(null);
    const countryRef = useClickOutside(()=>setCountryOpen(false))
    const sanctionsRef = useClickOutside(()=>setSanctionsOpen(false))
    const pepsRef = useClickOutside(()=>setPepsOpen(false))
    const adverseRef = useClickOutside(()=>setAdverseOpen(false));

    const [getCustomSources, fetchingCustomSources,CustomSourcesData] = UseApiCall(apiGetCustomSources, (res) => {
        // console.log('res',res?.data?.sanctions)
    })

    useEffect(()=>{
        getCustomSources()
    },[])

    const handleCrossCountry = (e,country) => {
        e.stopPropagation();
        setCountryList((prev) => {
            const index = prev.findIndex(item => item.name === country.name);
            if (index !== -1) {
                // If the country is already included, remove it
                return prev.filter((item, i) => i !== index);
            }
        });
    }

    const onChange = (newValue) => {
        const roundedValue = Math.round(newValue / 10) * 10;
        // Set the rounded value
        props.setFuzinessValue(roundedValue);
    };

    return(
        <div
        >
            <Modal className={clsx(
                classes.modal,
                'p-0 min-w-[100px] max-w-[100px] z-[60] bg-[#FFFFFF] bottom-0 !pb-[20px]'
            )}
            >
                {
                    ReactDOM.createPortal(
                        <Backdrop
                            onClick={() => {
                                dispatch(modalActions.setShowComplianceCustomSearch(false))
                            }}
                            className={classes.backdropCustomSearch}
                        />,
                        document.getElementById('backdrop-root')
                    )
                }

                <div className="flex items-center justify-start w-[100%] ">
                    <div className="flex flex-col gap-[32px] w-[100%]">
                        {/*top*/}
                        <div className="flex items-center justify-between w-[100%] max-h-[68px] px-[40px] pt-[30px]"
                             onClick={()=>dispatch(modalActions.setShowComplianceCustomSearch(false))}
                        >
                            <div className="flex items-center max-h-[48px]  gap-[12px] cursor-pointer">
                                <div className="flex items-center justify-center bg-[#1E333F1F] rounded-[4px] w-[24px] h-[24px] ">
                                    <img src={arrowLeft}/>
                                </div>
                                <p className="font-inter text-[16px] font-[600] leading-[48px] text-center text-[#1E333F]">Custom Search</p>
                            </div>

                            <img src={crossImg} className="w-[32px] h-[32px] cursor-pointer"/>
                        </div>

                        {/*bottom*/}
                        <div className="grid-cols-1 gap-x-6 sm:grid w-[100%] gap-[24px] px-[40px]">
                            <div className="flex flex-col items-start gap-[8px]">
                                <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">Customer
                                    Name</p>
                                <TextInput
                                    type="text"
                                    placeholder="Required"
                                    className="col-span-2"
                                    validation={[(value) => value !== '']}
                                    // errorMessage="Please enter a valid Invoice Id"
                                    inputValidity={(value) => {
                                        setIsCustomerNameValid(value)
                                    }}
                                    setInput={(value) => {
                                        setCustomerName(value)
                                    }}
                                    enteredInput={customerName}
                                    // hasError={(submitClicked || draftClicked) && !isCustomerNameValid}
                                    initiateFocus={!isCustomerNameValid}
                                    focusRef={focusRef}
                                    noneRef={noneRef}
                                />
                            </div>

                            <div className="flex flex-col items-start gap-[8px]">
                                <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">Other
                                    References</p>
                                <TextInput
                                    type="text"
                                    placeholder="Optional"
                                    className="col-span-2"
                                    // validation={[(value) => value === '']}
                                    // errorMessage="Please enter a valid Invoice Id"
                                    // inputValidity={(value) => {
                                    //     setIsOtherRefrencesValid(value)
                                    // }}
                                    setInput={(value) => {
                                        setOtherRefrences(value)
                                    }}
                                    enteredInput={otherRefrences}
                                    // hasError={(submitClicked || draftClicked) && !isInvoiceIdValid}
                                    // initiateFocus={!isInvoiceIdValid}
                                    focusRef={focusRef}
                                    noneRef={noneRef}
                                />
                            </div>

                            <div className="flex flex-col items-start gap-[8px]">
                                <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">Year of Birth</p>
                                <DatePicker
                                    onChange={(date, dateString)=>{
                                        // console.log('date',date?.year)
                                        // console.log('dateString',dateString)
                                        props.setYofB(dateString)
                                    }}

                                    className={clsx(
                                        `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                                    )}

                                    placeholder="Optional"
                                    size="large"
                                    format="YYYY"
                                    picker="year"
                                />
                            </div>


                            <div className="flex flex-col items-start relative gap-[8px]" ref={countryRef}>
                                <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">Country
                                    (Optional)</p>
                                <div
                                    className="flex w-full items-center justify-between rounded-[8px] border-[1px] border-[#D2D6D9] px-[16px] py-[12px] cursor-pointer"
                                    onClick={() => setCountryOpen(prev => !prev)}
                                >
                                    <div className="flex items-center gap-[4px]">
                                        {countryList?.length === 0 ? (
                                            <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">
                                                All
                                            </p>
                                        ) : (
                                            <>
                                                {countryList?.slice(0, 3)?.map((country, index) => (
                                                    <div key={index}
                                                         className="flex items-center gap-[4px] bg-[#1E333F] rounded-[100px] px-[16px] py-[4px]"
                                                         onClick={(e) => handleCrossCountry(e, country)}
                                                    >
                                                        <p className="font-inter text-[12px] font-[600] leading-[16px] text-center text-[#FFFFFF]">
                                                            {country?.name}
                                                        </p>
                                                        <img src={cross} alt="cross" className="w-[16px] h-[16px]"/>
                                                    </div>
                                                ))}
                                                {countryList?.length > 3 && (
                                                    <div
                                                        className="flex items-center gap-[4px] bg-[#1E333F] rounded-[100px] px-[16px] py-[4px]">
                                                        <p className="font-inter text-[12px] font-[600] leading-[16px] text-center text-[#FFFFFF]">
                                                            +{countryList?.length - 3}
                                                        </p>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <img src={countryOpen ? arrowup : arrowDown}/>
                                </div>

                                {countryOpen && (
                                    <CountryDropdown
                                        whereCalled={'customSearch'}
                                        countryList={countryList}
                                        setCountryList={setCountryList}
                                        handleCountrySelection={handleCountrySelection}
                                        countryRef={countryRef}
                                        handleAllCountrySelection={props.handleAllCountrySelection}
                                        setAllSelected={props.setAllSelected}
                                        allSelected={props.allSelected}
                                    />
                                )}
                            </div>

                            <div className="flex flex-col items-start gap-[8px]">
                                <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">Sources</p>
                                <div className="flex w-full flex-col rounded-[8px] border-[1px] border-[#D2D6D9]">
                                    {/*sanctions*/}
                                    <div className="relative" ref={sanctionsRef}>
                                        <div
                                            className={`flex w-full items-center justify-between  px-[16px] py-[12px] cursor-pointer ${
                                                sanctionsOpen ? 'border-[2px] border-[#1E333F]' : 'border-b-[1px] border-b-[#D2D6D9]'
                                            }`}
                                            onClick={() => setSanctionsOpen(!sanctionsOpen)}
                                        >
                                            <div className="flex items-center gap-[16px]"
                                                // key={index}
                                                // onClick={(e) => props.handleCountrySelection(e, country)}
                                            >
                                                <input
                                                    type={"checkbox"}
                                                    className={classes.dropdownCheckbox}
                                                    name={'All'}
                                                    checked={props.allSanctions}
                                                    onClick={(e) => handleAllSanctions(e, CustomSourcesData?.data?.sanctions)}
                                                />
                                                <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">{'Sanctions'}</p>

                                                {props.sourcesList?.length > 0 && (<div
                                                    className="flex w-[22px] h-[22px] rounded-[44px] items-center justify-center bg-[#E6EAF0]">
                                                    <p className="font-inter text-[12px] font-[600] leading-[16px] text-center text-[#1E333F]">
                                                        {props.sourcesList?.length}
                                                    </p>
                                                </div>)}
                                            </div>
                                            <img src={sanctionsOpen ? arrowup : arrowDown}/>
                                        </div>
                                        {sanctionsOpen &&
                                            <div
                                                className="flex flex-col items-start w-full max-h-[300px] overflow-y-scroll absolute top-[60px] z-[60]  bg-[#FFFFFF] rounded-bl-[8px] rounded-br-[8px]  shadow-lg cursor-pointer"
                                            >
                                                {CustomSourcesData?.data?.sanctions?.map((sanction, indSanc) => (
                                                    <div
                                                        className={`flex w-full gap-[12px] items-center px-[16px] py-[12px] ${indSanc + 1 < CustomSourcesData?.data?.sanctions?.length ? 'border-b-[1px] border-b-[#D2D6D9]' : ''}`}
                                                        key={indSanc}
                                                        onClick={(e) => handleSourcesSelection(e, sanction, CustomSourcesData?.data?.sanctions)}
                                                    >
                                                        <input
                                                            type={"checkbox"}
                                                            className={classes.dropdownCheckbox}
                                                            name={'All'}
                                                            checked={props.sourcesList?.includes(sanction?.key)}
                                                        />
                                                        <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">{sanction?.heading}</p>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        }
                                    </div>

                                    {/*peps*/}
                                    <div className="relative" ref={pepsRef}>
                                        <div
                                            className={`flex w-full items-center justify-between  px-[16px] py-[12px] cursor-pointer ${
                                                pepsOpen ? 'border-[2px] border-[#1E333F]' : 'border-b-[1px] border-b-[#D2D6D9]'
                                            }`}
                                            onClick={() => setPepsOpen(!pepsOpen)}
                                        >
                                            <div className="flex items-center gap-[16px]"
                                                // key={index}
                                                // onClick={(e) => props.handleCountrySelection(e, country)}
                                            >
                                                <input
                                                    type={"checkbox"}
                                                    className={classes.dropdownCheckbox}
                                                    name={'All'}
                                                    checked={props.allPeps}
                                                    onClick={(e) => props.handleAllPeps(e, CustomSourcesData?.data?.pep)}
                                                />
                                                <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">{'PEPs'}</p>
                                                {props.pepsList?.length > 0 && (<div
                                                    className="flex w-[22px] h-[22px] rounded-[44px] items-center justify-center bg-[#E6EAF0]">
                                                    <p className="font-inter text-[12px] font-[600] leading-[16px] text-center text-[#1E333F]">
                                                        {props.pepsList?.length}
                                                    </p>
                                                </div>)}
                                            </div>
                                            <img src={pepsOpen ? arrowup : arrowDown}/>
                                        </div>
                                        {pepsOpen &&
                                            <div
                                                className="flex flex-col items-start w-full max-h-[300px] overflow-y-scroll absolute top-[60px] z-[60]  bg-[#FFFFFF] rounded-bl-[8px] rounded-br-[8px]  shadow-lg cursor-pointer"
                                            >
                                                {CustomSourcesData?.data?.pep?.map((pep, indPep) => (
                                                    <div
                                                        className={`flex w-full gap-[12px] items-center px-[16px] py-[12px] ${indPep + 1 < CustomSourcesData?.data?.pep?.length ? 'border-b-[1px] border-b-[#D2D6D9]' : ''}`}
                                                        key={indPep}
                                                        onClick={(e) => props.handlePepSelection(e, pep, CustomSourcesData?.data?.pep)}
                                                    >
                                                        <input
                                                            type={"checkbox"}
                                                            className={classes.dropdownCheckbox}
                                                            name={'All'}
                                                            checked={props.pepsList?.includes(pep?.key)}
                                                        />
                                                        <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">{pep?.heading}</p>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        }
                                    </div>

                                    {/*adverse media*/}
                                    <div className="relative" ref={adverseRef}>
                                        <div
                                            className={`flex w-full items-center justify-between  px-[16px] py-[12px] cursor-pointer ${
                                                adverseOpen ? 'border-[2px] border-[#1E333F]' : 'border-b-[1px] border-b-[#D2D6D9]'
                                            }`}
                                            onClick={() => setAdverseOpen(!adverseOpen)}
                                        >
                                            <div className="flex items-center gap-[16px]"
                                                // key={index}
                                                // onClick={(e) => props.handleCountrySelection(e, country)}
                                            >
                                                <input
                                                    type={"checkbox"}
                                                    className={classes.dropdownCheckbox}
                                                    name={'All'}
                                                    checked={props.allAdverse}
                                                    onClick={(e) => props.handleAllAdverses(e, CustomSourcesData?.data?.adverseMedia)}
                                                />
                                                <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">{'Adverse Media'}</p>
                                                {props.adverseList?.length > 0 && (<div
                                                    className="flex w-[22px] h-[22px] rounded-[44px] items-center justify-center bg-[#E6EAF0]">
                                                    <p className="font-inter text-[12px] font-[600] leading-[16px] text-center text-[#1E333F]">
                                                        {props.adverseList?.length}
                                                    </p>
                                                </div>)}
                                            </div>
                                            <img src={adverseOpen ? arrowup : arrowDown}/>
                                        </div>
                                        {adverseOpen &&
                                            <div
                                                className="flex flex-col items-start w-full max-h-[300px] overflow-y-scroll absolute top-[60px] z-[60]  bg-[#FFFFFF] rounded-bl-[8px] rounded-br-[8px]  shadow-lg cursor-pointer"
                                            >
                                                {CustomSourcesData?.data?.adverseMedia?.map((adverse, indAdv) => (
                                                    <div
                                                        className={`flex w-full gap-[12px] items-center px-[16px] py-[12px] ${indAdv + 1 < CustomSourcesData?.data?.adverseMedia?.length ? 'border-b-[1px] border-b-[#D2D6D9]' : ''}`}
                                                        key={indAdv}
                                                        onClick={(e) => props.handleAdverseSelection(e, adverse, CustomSourcesData?.data?.adverseMedia)}
                                                    >
                                                        <input
                                                            type={"checkbox"}
                                                            className={classes.dropdownCheckbox}
                                                            name={'All'}
                                                            checked={props.adverseList?.includes(adverse?.key)}
                                                        />
                                                        <p className="font-inter text-[16px] font-[400] leading-[24px] text-center text-[#1E333F]">{adverse?.heading}</p>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col items-start gap-[8px]">
                                <div className="flex w-full items-center justify-between">
                                    <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">Fuzziness
                                        Percentage</p>
                                    <p className="font-inter text-[14px] font-[500] leading-[22px] text-center text-[#1E333F]">{props.fuzinessValue + '%'}</p>
                                </div>
                                <div className="rounded-[8px] px-[16px] py-[12px] bg-[#F4F6FA] w-full z-40">
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: '1E333F',
                                            },
                                        }}
                                    >
                                        <Slider
                                            min={1}
                                            max={100}
                                            onChange={onChange}
                                            value={props.fuzinessValue}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>

                            <div className="flex  items-end justify-end ">
                                <div
                                    className="flex rounded-[8px] px-[60px] py-[12px] bg-[#181C30] cursor-pointer"
                                    onClick={props.customerName && props.handleCustomSearchSubmit}
                                >
                                    <p className='font-inter text-[16px] font-[600] leading-[24px] text-center text-[#FFFFFF]'
                                       style={{opacity : !props.customerName && 0.5}}
                                    >
                                        Submit
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CustomSearch;