import { useEffect, useState } from 'react'
import clsx from 'clsx'

import classes from './StatusTag.module.scss'
import InitiatedIcon from '../../../assets/Icons/initiated-arrow-white.svg'
import LinkSentIcon from '../../../assets/Icons/link-sent-white.svg'
import TickCircleIcon from '../../../assets/Icons/tick-circle-white.svg'
import CrossIcon from '../../../assets/Icons/cross-circle-white.svg'
import EditWhiteIcon from '../../../assets/Icons/edit-white.svg'
import DiscWhiteIcon from '../../../assets/Icons/disc-white.svg'
import InfoIcon from '../../../assets/Icons/info_brown_circle.svg'
import alertCircle from '../../../assets/Icons/alert-circle.svg'
import StatusVerifiedIcon from '../../../assets/Icons/status-verified.svg'

const getStatusDetails = (status) => {
  switch (status.split('_').join('').toLowerCase()) {
    case 'created':
      return {
        icon: EditWhiteIcon,
        title: 'UNDER REVIEW',
        bgColor: 'rgb(255, 184, 0)',
        textColor: '#ffffff',
      }
    case 'initiated':
      return {
        icon: InitiatedIcon,
        title: 'INITIATED',
        bgColor: '#FF8934',
        textColor: '#ffffff',
      }
    case 'inprogress':
      return {
        icon: DiscWhiteIcon,
        title: 'IN PROGRESS',
        bgColor: '#ADB45B',
        textColor: '#ffffff',
      }
    case 'linksent':
      return {
        icon: LinkSentIcon,
        title: 'LINK SENT',
        bgColor: 'rgb(57, 152, 221)',
        textColor: '#ffffff',
      }
    case 'settled':
      return {
        icon: TickCircleIcon,
        title: 'SETTLED',
        bgColor: '#38B565',
        textColor: '#ffffff',
      }
    case 'inreview':
      return {
        icon: EditWhiteIcon,
        title: 'UNDER REVIEW',
        bgColor: 'rgb(255, 184, 0)',
        textColor: '#ffffff',
      }
    case 'pendingverification':
      return {
        icon: InfoIcon,
        title: 'UNDER REVIEW',
        bgColor: '#FFF4DE',
        textColor: '#8E6C28',
      }
    case 'overdue':
      return {
        icon: alertCircle,
        title: 'OVERDUE',
        bgColor: '#e3506e',
        textColor: '#ffff',
      }
    case 'verified':
      return {
        icon: StatusVerifiedIcon,
        title: 'VERIFIED',
        bgColor: '#7750CB',
      }
    case 'cancelled':
      return {
        icon: CrossIcon,
        title: 'CANCELLED',
        bgColor: '#D34646',
        textColor: '#ffff',
      }
    case 'settledoutside':
      return {
        icon: TickCircleIcon,
        title: 'SETTLED',
        bgColor: '#D34646',
        textColor: '#ffff',
      }
    case 'indraft':
      return {
        icon: EditWhiteIcon,
        title: 'DRAFT',
        bgColor: '#4380A2',
        textColor: '#ffff',
      }
    case 'partiallypaid':
      return {
        icon: TickCircleIcon,
        title: 'PARTIALLY PAID',
        bgColor: '#2FD4D4',
        textColor: '#ffff',
      }
    default:
      return {
        icon: EditWhiteIcon,
        title: status,
        bgColor: '#FFB800',
        textColor: '#ffffff',
      }
  }
}

const StatusTag = (props) => {
  let { status } = props
  if (
    status.toLowerCase() === 'in review' ||
    status.toLowerCase() === 'in_review'
  ) {
    status = 'created'
  } else if (status.toLowerCase() === 'created') {
    status = 'verified'
  }
  const [statusDetails, setStatusDetails] = useState(
    getStatusDetails(status || '')
  )

  useEffect(() => {
    setStatusDetails(getStatusDetails(status || ''))
  }, [props])

  return (
    <div
      className={clsx(
        'px-[8px] min-h-[20px] rounded-full flex flex-row items-center w-fit',
        props.className
      )}
      style={{
        backgroundColor: statusDetails?.bgColor
          ? statusDetails?.bgColor
          : 'inherit',
      }}
    >
      {statusDetails.icon && (
        <img
          className="w-[16px] aspect-square mr-1"
          src={statusDetails.icon}
          alt="icon"
        />
      )}
      <div className={clsx(classes.text)}>
        <p
          className="text-white w-max -md:text-xl -sm:text-2xl"
          style={{
            color: statusDetails?.textColor ? statusDetails?.textColor : '#fff',
          }}
        >
          {statusDetails?.title}
        </p>
      </div>
    </div>
  )
}

export default StatusTag
